import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import Chip from '@mui/material/Chip';

import { SupportDialog } from '../SupportDialog/SupportDialog';

interface SupportButtonProps {
  showText?: boolean;
}

export const SupportButton = ({ showText }: SupportButtonProps) => {
  const { t } = useTranslation('navigation');
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      {isDialogOpen && (
        <SupportDialog isDialogOpen={isDialogOpen} onDialogClose={() => setIsDialogOpen(false)} />
      )}
      {!showText ? (
        <ContactSupportOutlinedIcon
          className="cursor-pointer pointer-events-auto fill-white-100 text-[18px]"
          onClick={() => setIsDialogOpen(true)}
        />
      ) : (
        <Chip
          icon={
            <ContactSupportOutlinedIcon className="cursor-pointer pointer-events-auto fill-white-100 text-[18px]" />
          }
          variant="support"
          className="w-full font-bold text-xs"
          onClick={() => setIsDialogOpen(true)}
          label={t('bottom.support')}
        />
      )}
    </>
  );
};
