import { useEffect } from 'react';

import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { TariffSectionEnum } from 'models/extension-generated';

import { RequiresTariffsProvider, useProvidedTariffs } from '../../../contexts/TariffsContext';

const Content = () => {
  const { populateTariffs } = useProvidedTariffs();

  useEffect(() => {
    // Trigger population in case there are auto-add tariffs
    populateTariffs([], { autoIncludeEmpty: true });
  }, []);

  // IMPORTANT NOTE: this is a "blank" component just that section works same as others
  // the importance is in the useEffect that will audo-add the tariffs (there is no UI for that at all)

  return <></>;
};

export const ObligatoryCareTariffs = ({ tariffs }: TariffSectionProps) => {
  return (
    <RequiresTariffsProvider section={TariffSectionEnum.PFLEGEPFLICHT} tariffs={tariffs}>
      <Content />
    </RequiresTariffsProvider>
  );
};
