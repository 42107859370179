import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from '@mui/material/Button';

import { buttonClasses } from '../../DownloadButton';
import { DownloadOfferDialog } from '../DonwloadOfferDialog/DownloadOfferDialog';

export function DownloadOfferButton() {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const [isDownloadOfferDialogOpen, setIsDownloadOfferDialogOpen] = useState(false);

  return (
    <>
      {isDownloadOfferDialogOpen && (
        <DownloadOfferDialog
          isDownloadOfferDialogOpen={isDownloadOfferDialogOpen}
          setIsDownloadOfferDialogOpen={setIsDownloadOfferDialogOpen}
        />
      )}
      <Button
        className="mt-[0px] m:mt-[16px]"
        size="small"
        classes={buttonClasses}
        variant="outlined"
        startIcon={<FileDownloadOutlinedIcon />}
        onClick={() => setIsDownloadOfferDialogOpen(true)}
      >
        {t('actions.downloadOffer.label')}
      </Button>
    </>
  );
}
