import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { ArrowRight } from 'pages/News/components/Icons/ArrowRight';

import styles from './ProductCard.module.scss';

type ProductCardProps = {
  headerText: string;
  imageSource: string;
  imageAlt?: string;
  onClick?: () => void;
};

export const ProductCard = ({ headerText, imageSource, onClick }: ProductCardProps) => {
  const { t } = useTranslation('productCard');

  return (
    <div
      className={classNames(
        styles.card,
        'hover:bg-navigation-80 group transition-all duration-200'
      )}
    >
      <div className={styles.imageContainer}>
        <img src={imageSource} className={classNames(styles.cardCover)} />
      </div>
      <div className={styles.cardContent}>
        <div className="mb-[8px]">
          <Typography
            variant="bodyLGBold"
            className="text-navigation-80 group-hover:text-white-100"
          >
            {headerText}
          </Typography>
        </div>
        <div className="mb-[24px] text-text-80 group-hover:text-white-80">
          <Typography variant="bodyMDRegular">{t('description')}</Typography>
        </div>
        <div className="mb-[16px]">
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={onClick}
            endIcon={<ArrowRight />}
          >
            {t('button')}
          </Button>
        </div>
      </div>
    </div>
  );
};
