import { ApplicationGroups } from '../ApplicationFilterReducer';
import { ActionFunction, ApplicationsState, TabType } from '../types';

/**
 * Resets currently applied filters to default configuration.
 * By default user should see only open applications
 * @param state Current state
 * @returns
 */
export const resetFilters: ActionFunction = (state: ApplicationsState) => {
  return {
    ...state,
    selectedTab: TabType.OpenApplications,
    filters: {
      creationDateFrom: '',
      creationDateUntil: '',
      firstname: '',
      lastname: '',
      id: '',
      statuses: ApplicationGroups.OpenApplications
    }
  };
};
