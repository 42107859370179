export type SelectedAgent = {
  agentId: string;
  name: string;
  hasServiceLKHInternalRole: boolean;
  agentPickerEnabled: boolean;
};

/**
 * Queries based on agent role:
 * - [default] id should be sent in JWT
 * - [service-center without switch to agent] id should be sent in JWT
 * - [service-center with switch to agent] id should be sent in payload
 */
export const getAgentIdForFilters = (agentSession: SelectedAgent) => {
  if (!agentSession.hasServiceLKHInternalRole) {
    return undefined;
  }
  return !agentSession.agentPickerEnabled ? undefined : agentSession.agentId;
};
