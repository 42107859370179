import { useEffect, useState } from 'react';

import { Article, NewsService } from 'models/portal-generated';

import { NewsHook } from '../context';

export async function fetchArticle(id: number): Promise<Article> {
  return NewsService.getArticle({ id });
}

type useNewsDetailProps = {
  id: number;
};

export function useNewsDetail({ id }: useNewsDetailProps): NewsHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [article, setArticle] = useState<Article | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const fetchArticleFn = async () => {
      try {
        const response: Article = await fetchArticle(id);
        if (response) {
          setArticle(response);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchArticleFn();
  }, []);

  return { article, loading, error };
}
