import React, { ReactNode, useContext, useMemo } from 'react';

import { Article } from 'models/portal-generated';

import { useNewsDetail } from '../hooks/useNewsDetail';

export type NewsDetailContextValue = {
  article: Article | undefined;
  loading: boolean;
  error: boolean;
};

export const NewsContext = React.createContext<NewsDetailContextValue | null>(null);

type NewsDetailContextProviderProps = { children: ReactNode; id: number };

export const NewsDetailContextProvider = ({ children, id }: NewsDetailContextProviderProps) => {
  const { article, loading, error } = useNewsDetail({ id });

  const ctx: NewsDetailContextValue = useMemo(() => {
    return {
      article,
      loading,
      error: error || false
    };
  }, [article, loading, error]);

  return <NewsContext.Provider value={ctx}>{children}</NewsContext.Provider>;
};

export const useNewsPageContext = (): NewsDetailContextValue => {
  const context = useContext(NewsContext);

  if (!context) {
    throw new Error('useNewsPageContext was used outside of its Provider');
  }

  return context;
};
