/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DocumentMetadata } from '../models/DocumentMetadata';
import type { NamedFile } from '../models/NamedFile';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * Upload document
     * @returns any Document successfully uploaded
     * @throws ApiError
     */
    public static upload({
        formData,
    }: {
        formData: {
            files: Array<Blob>;
            metadata: Array<DocumentMetadata>;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/document/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Validation error`,
                403: `Forbidden`,
                417: `Virus detected in file`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download GDV file for agent
     * @returns NamedFile GDV file successfully downloaded
     * @throws ApiError
     */
    public static downloadGdvFile(): CancelablePromise<Array<NamedFile>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/document/GDVFile',
            errors: {
                400: `Bad Request. Any input-value has a wrong format or doesnt exist.`,
                401: `Unauthorized`,
                403: `Forbidden. None HTTPS Protocol or User is not allowed.`,
                500: `Internal Server Error`,
                502: `Bad Gateway. Cant create internal request.`,
                504: `Gateway Timeout. Timeout on internal communication.`,
            },
        });
    }

}
