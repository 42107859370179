import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Button, Dialog, MenuItem } from '@mui/material';

import {
  ChevronIcon,
  DownloadIcon,
  Icon,
  Loader,
  LoaderType,
  UploadIcon
} from 'lkh-portal-ui-library';

import { Complete, Uploader } from 'components/UploadDocuments';
import { UploadContextProvider, UploadStage, useUploadContext } from 'contexts/UploadContext';
import { ActionMenu } from 'pages/Contract360Page/components/ActionMenu';
import { Dropzone } from 'pages/Contract360Page/components/UploadDocuments/Dropzone';
import { use360ViewContext } from 'pages/Contract360Page/context/View360Context';
import { downloadContract } from 'utilities/fetchContract';

type ManualSignatureProps = {
  applicationId: string;
  policyHolderName: string;
};

const ManualSignatureComponent = ({ applicationId }: ManualSignatureProps) => {
  const { t } = useTranslation('view360');
  const [isDownloadingOffer, setIsDownloadingOffer] = useState(false);
  const { data } = use360ViewContext();
  const { close, stage, open } = useUploadContext();

  const handleDownloadOffer = async () => {
    try {
      setIsDownloadingOffer(true);
      await downloadContract({ id: applicationId });
    } catch (error) {
      toast.error('applicationdownloadFailed');
    } finally {
      setIsDownloadingOffer(false);
    }
  };

  const handleOpen = () => {
    open();
  };

  return (
    <>
      <div>
        <ActionMenu
          items={
            <div>
              <MenuItem onClick={handleDownloadOffer}>
                <div className="m-r-8">
                  {isDownloadingOffer ? (
                    <Loader type={LoaderType.Circular} />
                  ) : (
                    <Icon icon={<DownloadIcon />} />
                  )}
                </div>
                {t('signature.manual.downloadOffer')}
              </MenuItem>
              <MenuItem onClick={handleOpen}>
                <Icon className="m-r-8" icon={<UploadIcon />} />
                {t('signature.manual.uploadOffer')}
              </MenuItem>
            </div>
          }
          button={
            <Button variant="outlined">
              {t('signature.manual.menuTitle')}
              <Icon size="xs" className="m-l-8" icon={<ChevronIcon />} />
            </Button>
          }
        />
      </div>
      <Dialog open={stage !== UploadStage.Closed} onClose={close}>
        {stage === UploadStage.Browse && (
          <Dropzone id={data.id} policyHolderName={data.policyHolder.name} showIntent={false} />
        )}
        {stage === UploadStage.Upload && <Uploader translationNameSpace="view360" />}
        {stage === UploadStage.Complete && <Complete translationNameSpace="view360" />}
      </Dialog>
    </>
  );
};

type UploadFileProps = {
  uploadUrl: string;
  onUploadDone?: () => void;
};

export const ManualSignature = (props: UploadFileProps & ManualSignatureProps) => {
  const { uploadUrl, onUploadDone, ...rest } = props;
  return (
    <UploadContextProvider uploadUrl={uploadUrl} onDone={onUploadDone}>
      <ManualSignatureComponent {...rest} />
    </UploadContextProvider>
  );
};
