import { useKeycloak } from '@react-keycloak/web';
import classNames from 'classnames';
import { subDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Autocomplete, TextField, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';
import { filterTheme } from 'styles/theme/components/filterTheme';

import { RealmRole } from 'models';
import { InSignService } from 'models/extension-generated';
import { getAgentIdFilterOptions } from 'pages/Contracts/components/Filter/models';

import { GeneralUpload } from '../GeneralUpload';
import { useConfigContext } from 'contexts/ConfigContext';
import { useUser } from 'hooks/useUser';
import { useDashboardPageContext } from 'pages/Dashboard/context';
import { SelectedAgent } from 'pages/PrivateHealthInsurance/components/AgentsSearch/AgentsSearch';
import { formatDate, formatInput } from 'utilities/dates';

import styles from './Filters.module.scss';

export const Filters = () => {
  const { t } = useTranslation('dashboard');
  const { actions, state } = useDashboardPageContext();
  const { agentIds } = useUser();
  const { config } = useConfigContext();
  const { keycloak } = useKeycloak();
  const isServiceAgent = keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);

  const agentIdOptions = getAgentIdFilterOptions(
    isServiceAgent ? config.agentIds || [] : agentIds
  ).list;

  function resetFilters() {
    actions.resetFilters();
    const now = new Date();
    const sevenDaysAgo = subDays(now, 7).toISOString();
    // Assuming formatDate is a function you have that formats ISO strings to your desired format.
    // Update 'formatDate' usage if it's not needed or if the function requires different handling.
    const fromDateString = formatDate(sevenDaysAgo);
    const toDateString = formatDate(now.toISOString());
    actions.setCreationDateRange({
      from: fromDateString,
      to: toDateString
    });
  }

  const from = state.filters.creationDateFrom
    ? formatInput(new Date(state.filters.creationDateFrom.replace(/\//g, '-')))
    : '';
  const to = state.filters.creationDateUntil
    ? formatInput(new Date(state.filters.creationDateUntil.replace(/\//g, '-')))
    : '';

  const id = state.filters.id;
  const agentId = state.filters.agentId;
  const agent: SelectedAgent | null = agentId
    ? {
        id: agentId
      }
    : null;

  const firstname = state.filters.firstname;
  const lastname = state.filters.lastname;
  const disabledFilterReset =
    id === '' && firstname === '' && lastname === '' && from === '' && to === '';

  const handleInSignRedirect = async () => {
    try {
      const response = await InSignService.createInSignDashboard();
      window.open(response.url, '_blank');
    } catch (err) {
      toast.error('common:toastMessages.somethingWentWrong');
    }
  };

  return (
    <>
      <div className="flex justify-end gap-[8px]">
        <Button
          variant="outlined"
          size="small"
          onClick={() => resetFilters()}
          disabled={disabledFilterReset}
        >
          {t('tab.resetFilter')}
        </Button>
        <GeneralUpload />
        <Button
          variant="outlined"
          startIcon={<OpenInNewIcon fontSize="medium" />}
          size="small"
          onClick={() => handleInSignRedirect()}
        >
          {t('tab.inSignButton')}
        </Button>
      </div>
      <ThemeProvider theme={filterTheme}>
        <div className={classNames(styles.filters, 'mt-[24px]')}>
          <div>
            <DatePicker
              value={from ? new Date(from) : null}
              label={t('tab.creationDateFrom')}
              onChange={(value, context) => {
                if (context.validationError) return;
                const date = value ? formatDate(value) : null;
                actions.setCreationDateRange({
                  from: date || undefined
                });
              }}
            />
          </div>
          <div>
            <DatePicker
              value={to ? new Date(to) : null}
              label={t('tab.creationDateUntil')}
              onChange={(value, context) => {
                if (context.validationError) return;
                const date = value ? formatDate(value) : null;
                actions.setCreationDateRange({
                  to: date || undefined
                });
              }}
            />
          </div>
          <div>
            <Autocomplete
              value={{
                value: agent?.id || '',
                label: agent?.id
              }}
              blurOnSelect
              options={agentIdOptions}
              renderInput={(params) => <TextField {...params} label={t('tab.vermittlerNumber')} />}
              onChange={(_event, value) => {
                actions.setAgentId({
                  agentId: value?.value as string
                });
              }}
            />
          </div>
          <div>
            <TextField
              value={id}
              label={t('tab.id')}
              placeholder={t('tab.id')}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                actions.setId(value.replace(/\s/g, ''));
              }}
            />
          </div>
          <div>
            <TextField
              value={firstname}
              label={t('tab.firstname')}
              placeholder={t('tab.firstname')}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                actions.setFirstName(value.trim());
              }}
            />
          </div>
          <div>
            <TextField
              value={lastname}
              label={t('tab.lastname')}
              placeholder={t('tab.lastname')}
              onChange={(event) => {
                const {
                  target: { value }
                } = event;
                actions.setLastName(value.trim());
              }}
            />
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};
