import { ColDef } from 'ag-grid-community';
import i18n from 'i18next';

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import {
  DownloadIconGroup,
  DownloadIconGroupProps
} from 'components/DownloadIconGroup/DownloadIconGroup';
import { formatGermanDate } from 'utilities/dates';
import { downloadDocumentForPolicy } from 'utilities/fetchDocument';

export const columnDefs: ColDef[] = [
  {
    field: 'createdAt',
    headerName: i18n.t('documents.table.createdAt', { ns: 'view360' }),
    resizable: true,
    flex: 1,
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellRenderer: (props: { value: string }) => {
      return (
        <Typography variant="bodySMRegular" className="text-text-80">
          {formatGermanDate(props.value)}
        </Typography>
      );
    }
  },
  {
    field: 'docArt',
    headerName: i18n.t('documents.table.docArt', { ns: 'view360' }),
    resizable: true,
    flex: 2,
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    tooltipField: 'name',
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellRenderer: (props: { value: string }) => (
      <div translate="no" className="flex gap-[10px] items-center">
        <Avatar sx={{ bgcolor: colors.surface[60], width: 24, height: 24 }}>
          <InsertDriveFileIcon className="text-text-80" sx={{ width: 16, height: 16 }} />
        </Avatar>
        <Typography variant="bodySMRegular" className="text-text-80">
          {props.value}
        </Typography>
      </div>
    )
  },
  {
    field: 'docType',
    headerName: i18n.t('documents.table.docType', { ns: 'view360' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    resizable: true,
    sortable: true,
    flex: 2,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellRenderer: (props: { value: string }) => {
      return (
        <Typography variant="bodySMRegular" className="text-text-80">
          {props.value}
        </Typography>
      );
    }
  },
  {
    field: 'showDownload',
    headerName: '',
    headerClass:
      'border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    flex: 1,
    cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    cellRenderer: (params: { value: DownloadIconGroupProps['documentInfo'] }) => (
      <span className="ag-action-button">
        <DownloadIconGroup
          documentInfo={params.value}
          downloadCallback={{
            type: 'DownloadDocumentForPolicyFunction',
            func: downloadDocumentForPolicy
          }}
        />
      </span>
    )
  }
];
