import { useState } from 'react';
import { useLocation } from 'react-router';

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import i18n from 'services/translation';

import { ContractsSearch } from './ContractsSearch';
import { WizardDialog } from 'components/WizardDialog';
import { CreateNewPictogram } from 'pages/PrivateHealthInsurance/components/pictograms';

const linkDefinitions: Array<{ name: string; link: string }> = [
  {
    name: i18n.t('navigation:items.dashboard'),
    link: '/dashboard'
  },
  {
    name: i18n.t('navigation:items.products'),
    link: '/produkte'
  },
  {
    name: i18n.t('navigation:items.inventory'),
    link: '/bestand'
  },
  {
    name: i18n.t('navigation:items.form'),
    link: '/formulare'
  },
  {
    name: i18n.t('navigation:items.messages'),
    link: '/neuigkeiten'
  },
  {
    name: i18n.t('navigation:items.profile'),
    link: '/profil'
  }
];

const getPageHeader = (pathname: string) => {
  const extractedLocation = '/' + pathname.split('/')[1];
  const current = linkDefinitions.find((item) => item.link === extractedLocation);

  return current?.name || i18n.t('navigation:items.dashboard');
};

export const Header = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation('header');
  const [isWizardDialogOpen, setIsWizardDialogOpen] = useState(false);

  return (
    <>
      {isWizardDialogOpen && (
        <WizardDialog
          isWizardDialogOpen={isWizardDialogOpen}
          onDialogClose={() => setIsWizardDialogOpen(false)}
        />
      )}
      <div className="flex items-center justify-between pt-[28px] pb-[28px] pl-[32px] pr-[32px] space-x-[24px]">
        <Typography variant="headlineXLGBold" className="text-title-80 ">
          {getPageHeader(pathname)}
        </Typography>

        <div className="flex items-center justify-items-center space-x-[24px]">
          <div>
            <ContractsSearch />
          </div>
          <div>
            <Button
              color="primary"
              variant="contained"
              size="large"
              className="truncate"
              startIcon={<CreateNewPictogram />}
              onClick={() => setIsWizardDialogOpen(true)}
            >
              {t('actions.createOffer')}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
