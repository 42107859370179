export enum ColumnIds {
  id = 'id',
  HolderName = 'policyHolder.lastname',
  PartnersCount = 'insuredPartnersCount',
  Product = 'product',
  Amount = 'monthlyAmount',
  ValidFrom = 'validFrom',
  Status = 'status',
  Address = 'mainContact.postalCode'
}
