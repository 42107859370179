/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Criteria } from '../models/Criteria';
import type { Partner } from '../models/Partner';
import type { PartnersResponse } from '../models/PartnersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PartnerService {

    /**
     * Get partner list with respect to filters, sorting and pagination query parameters
     * @returns PartnersResponse Successfully retrieved data
     * @throws ApiError
     */
    public static getPartners({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<PartnersResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/partner',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `When trying to sort by non supported field.
                `,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get partner by id
     * @returns Partner Successfully retrieved
     * @throws ApiError
     */
    public static getPartnerById({
        id,
    }: {
        /**
         * Uniquer partner identifier
         */
        id: number,
    }): CancelablePromise<Partner> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/partner/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not Found - The partner was not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Get agent information by token id.
     * @returns Partner Successfully retrieved
     * @throws ApiError
     */
    public static getAgentById(): CancelablePromise<Partner> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/partner/agent',
            errors: {
                404: `Not Found - The agent was not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

}
