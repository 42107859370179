import { useTranslation } from 'react-i18next';

import { Form } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { PersonRoleEnum } from 'models/extension-generated';

import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { isAdult } from 'utilities/dates';

const { YesNo } = Form.Components;

export const CustodyInformation = () => {
  const { t } = useTranslation('wizardSummary');
  const { state } = useHealthInsuranceContext();
  const { getSinglePartnerByRole, getPartnersByRole } = useApplicationHelpers(state.model);
  const policyHolder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);

  const areAllPartnersAdults = insuredPartners.every((partner) => {
    if (state.model.applicationStart) {
      return isAdult(partner.birthDate);
    }
  });

  if (areAllPartnersAdults) return <></>;

  return (
    <div className="mt-[40px]">
      <Form.Section title={t('underAgeLegal')}>
        <div className="grid-res mt-[0px]">
          <div>
            <YesNo
              question={t('questions.hasLegalRepresentative')}
              componentKey={partnerField(
                policyHolder.id || '',
                'applicationInformation.acceptsLegalRepresentative'
              )}
            />
          </div>
        </div>
        <div className="grid-res">
          <div>
            <YesNo
              question={t('questions.acceptsSoleCustody')}
              componentKey={partnerField(
                policyHolder.id || '',
                'applicationInformation.acceptsSoleCustody'
              )}
            />
          </div>
        </div>
      </Form.Section>
    </div>
  );
};
