import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { PartnerIdProp } from 'models';
import { PreInsurance } from 'models/extension-generated';
import { PreInsuranceToggle } from 'models/preInsurance';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { useExtendedReducer } from '../../../../../hooks/useExtendedReducer';

type PreInsuranceContextMenuProps = PartnerIdProp & {
  title?: string;
  preInsurance: PreInsurance;
  toggleKey: PreInsuranceToggle;
};

export const PreInsuranceContextMenu = ({
  title,
  preInsurance,
  partnerId,
  toggleKey
}: PreInsuranceContextMenuProps) => {
  const { t } = useTranslation('wizardGeneral');
  const { dispatch } = useHealthInsuranceContext();
  const { removePreInsurance } = useExtendedReducer(dispatch);

  return (
    <div className="flex flex-row space-x-[16px] grid-mx-res items-center">
      <Typography variant="bodyLGBold">{title}</Typography>
      <div>
        <Link
          className="text-primary-80 text-[16px] leading-[24px]"
          component="button"
          onClick={() =>
            removePreInsurance({
              id: preInsurance.id,
              partnerId,
              type: preInsurance.type,
              toggleKey
            })
          }
        >
          {t('longTerm.preInsurance.actions.delete')}
        </Link>
      </div>
    </div>
  );
};
