import {
  FormCategory,
  FormService,
  FormsResponse,
  SearchOperationEnum
} from 'models/portal-generated';

export type CounterType = {
  [FormCategory.LKH]: number;
  [FormCategory.VERTRIEB]: number;
};

export function fetchMetadataLKH(): Promise<FormsResponse> {
  return FormService.getFormsMetadata({
    requestBody: {
      searchCriteria: [
        {
          criteria: [
            {
              key: 'category',
              operation: SearchOperationEnum.EQUALS,
              value: FormCategory.LKH
            }
          ]
        }
      ],
      page: 0,
      size: 10
    }
  });
}

export function fetchMetadataSales(): Promise<FormsResponse> {
  return FormService.getFormsMetadata({
    requestBody: {
      searchCriteria: [
        {
          criteria: [
            {
              key: 'category',
              operation: SearchOperationEnum.EQUALS,
              value: FormCategory.VERTRIEB
            }
          ]
        }
      ],
      page: 0,
      size: 10
    }
  });
}

export async function fetchCounts(): Promise<CounterType> {
  const [lkhs, sales] = await Promise.all([fetchMetadataLKH(), fetchMetadataSales()]);
  return {
    LKH: lkhs.total,
    VERTRIEB: sales.total
  };
}
