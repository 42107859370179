import { ReactNode } from 'react';

import classNames from 'classnames';

interface RowsProps {
  hasDivider?: boolean;
  rows: Array<{
    isLabelBoldDisabled?: boolean;
    label: string | number | ReactNode;
    value: string | number | ReactNode;
  }>;
}

export function Rows({ hasDivider = true, rows }: RowsProps) {
  return (
    <>
      {rows.map((row) => (
        <div
          key={`$simple-table-${row.label}-${row.value}`}
          className={classNames('flex space-x-[32px] w-full gap-[16px]}', {
            ['border-solid border-b-[1px] border-l-0 border-r-0 border-t-0 border-b-text-20']:
              hasDivider
          })}
        >
          <div className="basis-1/2">
            <div
              className={classNames(
                'text-xs text-text-80 py-[8px]',
                row.isLabelBoldDisabled ? '' : 'font-bold'
              )}
            >
              {row.label}
            </div>
          </div>
          <div className="basis-1/2">
            <div className="text-xs text-text-100 font-normal py-[8px]">{row.value}</div>
          </div>
        </div>
      ))}
    </>
  );
}
