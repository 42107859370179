import { cloneDeep } from 'lodash';

import Typography from '@mui/material/Typography';

import { Form } from 'lkh-portal-ui-library';
import { Tariff } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../context/HealthInsuranceContext';
import { useExtendedReducer } from '../../../../hooks/useExtendedReducer';
import { useProvidedTariffs } from '../../contexts/TariffsContext';
import { Slider } from '../Slider';

type TariffWithSliderProps = {
  title?: string;
  min: number;
  max: number;
  step: number;
};

export const TariffWithSlider = ({ title, min, max, step }: TariffWithSliderProps) => {
  const { dispatch, partnerId } = useHealthInsuranceContext();
  const { clearTariffs, giveTariff } = useExtendedReducer(dispatch);
  const { sectionTariffs, section, selectedTariffs, updateHealthQuestions } = useProvidedTariffs();
  const initialValue = selectedTariffs[0]?.highMoneyAmount || min;

  function updateTariffSelection(tariff: Tariff, amount: number): void {
    const copy = cloneDeep(tariff);
    copy.highMoneyAmount = amount;

    clearTariffs({ partnerId, section });
    giveTariff({ partnerId, tariff: copy });
    updateHealthQuestions();
  }

  function handleSliderChange(value: number): void {
    const tariff = getActiveTariff();
    updateTariffSelection(tariff, value);
  }

  function getActiveTariff(): Tariff {
    return selectedTariffs[0] ?? sectionTariffs[0];
  }

  return (
    <Form.Section>
      <div className="grid-res ml-[0px] pb-[32px]">
        <div>
          <Typography variant="bodyLGBold" className="mb-[8px]">
            {title}
          </Typography>
          <Slider
            min={min}
            max={max}
            step={step}
            initialValue={initialValue}
            showHelper={{ unit: '€' }}
            onChange={handleSliderChange}
          />
        </div>
      </div>
    </Form.Section>
  );
};
