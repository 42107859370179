import { useMemo } from 'react';

import intersection from 'lodash/intersection';
import { useTranslation } from 'react-i18next';

import { WizardRoutesEnum } from '../../constants';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';
import { WizardProgress } from './WizardProgress';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';
import { getSchemaByScreen } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation/schemas';

export const PartnerProgress = () => {
  const { goTo, currentScreen, resolveCompleteScreens, ScreenOrder } = useWizardNavigation();
  const { state, partnerId, reducer } = useHealthInsuranceContext();

  const { validateNextPage } = useApplicationValidation(state, reducer, partnerId);
  const { t } = useTranslation('wizardPrivateHealthInsurance');

  const hasStepError = (step: WizardRoutesEnum): boolean => {
    const validationResults = Array.from(state.validationResults.keys());
    const partnerValidationResults = validationResults.filter((result) =>
      result.includes(partnerId)
    );

    const stepFields = getSchemaByScreen(step, state.model, partnerId);

    return intersection(partnerValidationResults, stepFields).length > 0;
  };

  const completed = useMemo(() => {
    return resolveCompleteScreens();
  }, [screen]);

  const handleClick = async (target: string) => {
    const indexOfTargetPage = ScreenOrder.indexOf(target as WizardRoutesEnum);
    const indexOfCurrentPage = ScreenOrder.indexOf(currentScreen);
    const isNavigatingBackwards = indexOfTargetPage < indexOfCurrentPage;

    if (isNavigatingBackwards) {
      goTo(target as WizardRoutesEnum, state.model.id);
      return;
    }

    const result = await validateNextPage(target as WizardRoutesEnum);

    if (result.size !== 0) return;

    goTo(target as WizardRoutesEnum, state.model.id);
  };

  return (
    <div className="layout-res py-[16px]">
      <div className="grid-mx-res">
        <WizardProgress.Container
          active={currentScreen}
          completed={completed}
          onClick={handleClick}
        >
          <WizardProgress.Step
            id={WizardRoutesEnum.PrivateData}
            text={t('insuredPeople.steps.personalData.step')}
            hasError={hasStepError(WizardRoutesEnum.PrivateData)}
          />
          <WizardProgress.Step
            id={WizardRoutesEnum.Tariffs}
            text={t('insuredPeople.steps.tariffs.step')}
            hasError={hasStepError(WizardRoutesEnum.Tariffs)}
          />
          <WizardProgress.Step
            id={WizardRoutesEnum.Offer}
            text={t('insuredPeople.steps.offer.step')}
            hasError={hasStepError(WizardRoutesEnum.Offer)}
          />
          <WizardProgress.Step
            id={WizardRoutesEnum.GeneralQuestions}
            text={t('insuredPeople.steps.generalQuestions.step')}
            hasError={hasStepError(WizardRoutesEnum.GeneralQuestions)}
          />
          <WizardProgress.Step
            id={WizardRoutesEnum.HealthQuestions}
            text={t('insuredPeople.steps.health.step')}
            hasError={hasStepError(WizardRoutesEnum.HealthQuestions)}
          />
        </WizardProgress.Container>
      </div>
    </div>
  );
};
