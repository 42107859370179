import { ReactNode } from 'react';

import classNames from 'classnames';

import { Header } from '../../components/Header';
import { NavigationBar } from '../../components/NavigationBar';
import { LegalFooter } from 'components/LegalFooter';

import styles from './PortalLayout.module.scss';

type PortalLayoutProps = {
  children?: ReactNode;
};

export const PortalLayout = ({ children }: PortalLayoutProps) => {
  return (
    <div className={styles.portalLayout}>
      <NavigationBar />
      <section className={classNames(styles.content, 'overflow-hidden m:overflow-auto')}>
        <div className="bg-surface-60">
          <Header />
        </div>
        <main className={classNames(styles.main, 'overflow-hidden m:overflow-auto')}>
          {children}
        </main>
        <LegalFooter />
      </section>
    </div>
  );
};
