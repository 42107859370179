import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Icon } from 'lkh-portal-ui-library';

import { NoQuestionsPictogram } from 'pages/PrivateHealthInsurance/components/pictograms';

import styles from './NoHealthQuestions.module.scss';

export const NoHealthQuestions = () => {
  const { t } = useTranslation('wizardHealth');
  return (
    <section className={styles.section}>
      <Icon className="m-b-24" color="lkh-color-primary-80" icon={<NoQuestionsPictogram />} />
      <Typography variant="headlineXXLGRegular" className="text-logo-500">
        {t('section.noQuestions.message')}
      </Typography>
    </section>
  );
};
