import { useTranslation } from 'react-i18next';

import { Loader, LoaderType } from 'lkh-portal-ui-library';

import { useNewsPageContext } from '../../context';
import { NewsCard } from '../NewsCard/NewsCard';

import styles from './News.module.scss';

export function News() {
  const { news, loading, error } = useNewsPageContext();
  const { t } = useTranslation();

  const renderNews = () =>
    news.map((n) => (
      <NewsCard
        key={n.id}
        id={n.id}
        createdAt={n.createdAt}
        headline={n.headline}
        image={n.image}
        text={n.text}
      />
    ));

  if (loading) {
    return (
      <div className={styles.newsContainerLoader}>
        <Loader type={LoaderType.Circular} />
      </div>
    );
  }

  if (error) {
    return (
      <div className={styles.newsContainer}>
        {t('toastMessages.somethingWentWrong', { ns: 'common' })}
      </div>
    );
  }

  return <div className={styles.newsContainer}>{renderNews()}</div>;
}
