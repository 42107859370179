import { v4 } from 'uuid';

import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';

import { HealthQuestion, Question } from 'models/extension-generated';

import { HealthQuestionDetailJoined } from './DiagnosesModal';

export enum DetailActions {
  DUPLICATE,
  SET_ERRORS,
  RESET_STATE,
  DELETE_DIAGNOSIS,
  SET_DETAIL_PROPERTY,
  CREATE_DETAIL_TEMPLATE,
  SET_DETAILS,
  OPEN_ACCORDION,
  CLOSE_ACCORDIONS
}

interface Action<T, P> {
  type: T;
  payload: P;
}

export type Errors = Map<keyof HealthQuestionDetailJoined, boolean>;

export type OpenDetailAccordionPayload = string;

export type SetDetailPropertyPayload = {
  id: string;
  key: keyof HealthQuestionDetailJoined;
  value: any;
};

export type DeleteDiagnosisPayload = {
  id: string;
};

export type DetailAction =
  | Action<DetailActions.RESET_STATE, undefined>
  | Action<DetailActions.SET_ERRORS, Map<string, Errors>>
  | Action<DetailActions.DUPLICATE, HealthQuestionDetailJoined>
  | Action<DetailActions.DELETE_DIAGNOSIS, DeleteDiagnosisPayload>
  | Action<DetailActions.SET_DETAIL_PROPERTY, SetDetailPropertyPayload>
  | Action<DetailActions.CREATE_DETAIL_TEMPLATE, Partial<HealthQuestionDetailJoined>>
  | Action<DetailActions.SET_DETAILS, Map<string, HealthQuestionDetailJoined>>
  | Action<DetailActions.OPEN_ACCORDION, OpenDetailAccordionPayload>;

type DiagnosisState = {
  questions: Array<Question>;
  errors?: Map<string, Errors>;
  healthQuestions: Array<HealthQuestion>;
  details: Map<string, HealthQuestionDetailJoined>;
  newDetails: Map<string, HealthQuestionDetailJoined>;
  accordionsState: Map<string, boolean>;
  isTouched: boolean;
};

export type ActionFunction<T = undefined> = (state: DiagnosisState, payload: T) => DiagnosisState;

const deleteDiagnosis: ActionFunction<DeleteDiagnosisPayload> = (state, payload) => {
  const stateCopy = cloneDeep(state);

  stateCopy.newDetails.delete(payload.id);
  stateCopy.details.delete(payload.id);
  stateCopy.isTouched = true;
  return stateCopy;
};

const setDetailProperty: ActionFunction<SetDetailPropertyPayload> = (state, payload) => {
  const { newDetails, details } = state;
  const detail = cloneDeep(state.newDetails.get(payload.id));
  const existingDetail = cloneDeep(state.details.get(payload.id));

  state.isTouched = true;

  if (detail) {
    newDetails.delete(payload.id);
    set(detail, payload.key, payload.value);
    state.newDetails.set(payload.id, detail);
  }

  if (existingDetail) {
    details.delete(payload.id);
    set(existingDetail, payload.key, payload.value);
    state.newDetails.set(payload.id, existingDetail);
  }

  return state;
};

const createDetailTemplate: ActionFunction<Partial<HealthQuestionDetailJoined>> = (
  state,
  payload
) => {
  const { newDetails, details } = state;
  const { question } = payload;
  const id = v4();

  const lastOrderNo = Math.max(
    ...Array.from(newDetails.values(), (detail) => detail.orderNo),
    ...Array.from(details.values(), (detail) => detail.orderNo)
  );

  const nextOrderNo = lastOrderNo === -Infinity ? 0 : lastOrderNo + 1;

  newDetails.set(id, {
    ...payload,
    id,
    orderNo: nextOrderNo,
    question: question || { id: '', order: '' },
    diagnosis: '',
    treatmentStart: '',
    treatmentEnd: '',
    sickLeaveDuration: '',
    hasConsenquences: undefined,
    hasOperation: undefined,
    doctor: ''
  });

  return {
    ...state,
    isTouched: true,
    accordionsState: new Map([[id, true]])
  };
};
const setError: ActionFunction<Map<string, Errors>> = (state, payload) => {
  const stateCopy = cloneDeep(state);
  stateCopy.errors = payload;

  return stateCopy;
};

const duplicateDiagnosis = (state: DiagnosisState, payload: HealthQuestionDetailJoined) => {
  const { newDetails, details } = cloneDeep(state);

  // remove question reference and let user provide it
  const { question, ...content } = payload;
  const id = v4();

  const lastOrderNo = Math.max(
    ...Array.from(newDetails.values(), (detail) => detail.orderNo),
    ...Array.from(details.values(), (detail) => detail.orderNo)
  );
  const nextOrderNo = lastOrderNo === -Infinity ? 0 : lastOrderNo + 1;

  newDetails.set(id, {
    question: undefined,
    treatmentStart: '',
    treatmentEnd: '',
    sickLeaveDuration: '',
    hasConsenquences: undefined,
    hasOperation: undefined,
    doctor: '',
    ...content,
    orderNo: nextOrderNo,
    id
  });

  return {
    ...state,
    isTouched: true,
    accordionsState: new Map([[id, true]]),
    newDetails
  };
};

const resetState: ActionFunction = () => {
  return {
    isTouched: false,
    accordionsState: new Map(),
    newDetails: new Map(),
    details: new Map(),
    questions: [],
    healthQuestions: []
  };
};

const openAccordion: ActionFunction<string> = (state, payload) => {
  const stateCopy = cloneDeep(state);

  stateCopy.accordionsState.clear();
  stateCopy.accordionsState.set(payload, true);

  return stateCopy;
};

const setDetails: ActionFunction<Map<string, HealthQuestionDetailJoined>> = (state, payload) => {
  const stateCopy = cloneDeep(state);

  stateCopy.details.clear();

  payload.forEach((detail) => {
    stateCopy.details.set(detail.id || '', detail);
  });

  return stateCopy;
};

export const diagnosisReducer = (
  prevState: DiagnosisState,
  action: DetailAction
): DiagnosisState => {
  const state = cloneDeep(prevState);

  switch (action.type) {
    case DetailActions.SET_DETAIL_PROPERTY: {
      return setDetailProperty(state, action.payload);
    }
    case DetailActions.CREATE_DETAIL_TEMPLATE: {
      return createDetailTemplate(state, action.payload);
    }
    case DetailActions.SET_ERRORS: {
      return setError(state, action.payload);
    }
    case DetailActions.RESET_STATE: {
      return resetState(state, action.payload);
    }
    case DetailActions.DELETE_DIAGNOSIS: {
      return deleteDiagnosis(state, action.payload);
    }
    case DetailActions.DUPLICATE: {
      return duplicateDiagnosis(state, action.payload);
    }
    case DetailActions.SET_DETAILS: {
      return setDetails(state, action.payload);
    }
    case DetailActions.OPEN_ACCORDION: {
      return openAccordion(state, action.payload);
    }

    default: {
      return state;
    }
  }
};
