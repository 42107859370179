import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

import { ApplicationService } from 'models/extension-generated';

import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';

type DuplicateApplicationDialogProps = {
  appId: string;
  isOpen: boolean;
  onClose: () => void;
  process?: () => void;
};
export const DuplicateApplicationDialog = ({
  appId,
  isOpen,
  onClose,
  process = () => {
    /**/
  }
}: DuplicateApplicationDialogProps) => {
  const { t } = useTranslation(['common', 'dashboard', 'wizardPrivateHealthInsurance']);
  const [isDuplicating, setIsDuplicating] = useState(false);

  const handleCloseDialog = () => {
    onClose();
  };

  const duplicateApplication = async () => {
    try {
      setIsDuplicating(true);
      const applicationCopy = await ApplicationService.copyApplication({
        id: appId
      });
      const application = await ApplicationService.createApplication({
        requestBody: applicationCopy
      });

      toast.success(t<string>('wizardPrivateHealthInsurance:orderOptions.menu.duplicateConfirm'));
      window.open(`${WizardRoutesEnum.Home}?id=${application.id}`);
    } catch (error) {
      toast.error(t<string>('common:toastMessages.somethingWentWrong'));
    } finally {
      setIsDuplicating(false);
      handleCloseDialog();
      process();
    }
  };

  return (
    <Dialog disableScrollLock fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <div>
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('dashboard:actions.duplicateConfirmation.title')}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                {t('dashboard:actions.duplicateConfirmation.message')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="flex gap-[16px] justify-end mt-[32px]">
          <Button size="large" onClick={handleCloseDialog}>
            {t('dashboard:actions.duplicateConfirmation.abort')}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={
              isDuplicating ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <ContentCopyOutlinedIcon />
              )
            }
            onClick={duplicateApplication}
            disabled={isDuplicating}
          >
            {isDuplicating
              ? t('dashboard:actions.duplicateConfirmation.wait')
              : t('dashboard:actions.duplicateConfirmation.duplicate')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
