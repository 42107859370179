import { useEffect } from 'react';

import { Dialog } from '@mui/material';

import { Complete, Uploader } from 'components/UploadDocuments';
import { UploadContextProvider, UploadStage, useUploadContext } from 'contexts/UploadContext';
import { Dropzone } from 'pages/Contract360Page/components/UploadDocuments/Dropzone';

const SubmitSignedContent = ({
  id,
  open,
  policyHolderName,
  onClose
}: {
  id: string;
  open: boolean;
  policyHolderName: string;
  onClose: () => void;
}) => {
  const { close, stage, open: openDialog } = useUploadContext();

  useEffect(() => {
    if (open) {
      openDialog();
    } else {
      close();
    }
  }, [open]);

  return (
    <Dialog
      maxWidth="md"
      open={stage !== UploadStage.Closed}
      onClose={() => {
        onClose();
        close();
      }}
    >
      {stage === UploadStage.Browse && (
        <Dropzone
          minimalNumberOfDocuments={1}
          translations={{
            ns: 'wizardSummary',
            prefix: 'upload'
          }}
          id={id}
          policyHolderName={policyHolderName}
          showIntent={false}
        />
      )}
      {stage === UploadStage.Upload && <Uploader translationNameSpace="view360" />}
      {stage === UploadStage.Complete && <Complete translationNameSpace="view360" />}
    </Dialog>
  );
};

export const SubmitSigned = ({
  open,
  holderName,
  applicationId,
  onDone,
  onClose,
  onBeforeUpload
}: {
  open: boolean;
  applicationId: string;
  holderName: string;
  onDone: (id?: string) => void;
  onClose: () => void;
  onBeforeUpload?: () => Promise<void>;
}) => {
  return (
    <UploadContextProvider
      data={applicationId}
      uploadUrl={`/api/application/${applicationId}/sign/manual`}
      onDone={onDone}
      onClose={onClose}
      onBeforeUpload={onBeforeUpload}
    >
      <SubmitSignedContent
        id={applicationId}
        policyHolderName={holderName}
        onClose={onClose}
        open={open}
      />
    </UploadContextProvider>
  );
};
