/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Criteria } from '../models/Criteria';
import type { FormsResponse } from '../models/FormsResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FormService {

    /**
     * Gel all files as metadata
     * @returns FormsResponse List of all forms successfully returned
     * @throws ApiError
     */
    public static getFormsMetadata({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<FormsResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/form/all',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Gel all files metadata in excel
     * @returns string Metadata excel successfully downloaded
     * @throws ApiError
     */
    public static getFormsMetadataExcel({
        requestBody,
    }: {
        requestBody?: Criteria,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/form/excel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download form
     * @returns string Form successfully downloaded
     * @throws ApiError
     */
    public static downloadForm({
        id,
    }: {
        /**
         * Uniquer file identifier
         */
        id: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/form/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Form not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download form
     * @returns string Form successfully downloaded
     * @throws ApiError
     */
    public static downloadPublicForm({
        id,
    }: {
        /**
         * Uniquer file identifier
         */
        id: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/form/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Form not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download form by referenceId
     * @returns string Form successfully downloaded
     * @throws ApiError
     */
    public static downloadFormByReferenceId({
        id,
        date,
    }: {
        /**
         * Uniquer file identifier
         */
        id: string,
        /**
         * Date based on which the relevant version of the document will be downloaded
         */
        date?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/form/getByReferenceId/{id}',
            path: {
                'id': id,
            },
            query: {
                'date': date,
            },
            errors: {
                404: `Form not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Download form by referenceId
     * @returns string Form successfully downloaded
     * @throws ApiError
     */
    public static downloadPublicFormByReferenceId({
        id,
        date,
    }: {
        /**
         * Uniquer file identifier
         */
        id: string,
        /**
         * Date based on which the relevant version of the document will be downloaded
         */
        date?: string,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/form/getByReferenceId/{id}',
            path: {
                'id': id,
            },
            query: {
                'date': date,
            },
            errors: {
                404: `Form not found`,
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Delete form by Id
     * @returns any Form successfully deleted
     * @throws ApiError
     */
    public static deleteForm({
        id,
    }: {
        /**
         * Uniquer file identifier
         */
        id: string,
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/admin/form/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

    /**
     * Upload multiple new forms
     * @returns any Forms successfully uploaded
     * @throws ApiError
     */
    public static uploadMultipleForms({
        formData,
    }: {
        formData: {
            files?: Array<Blob>;
            metadata: Blob;
        },
    }): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/admin/form',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                500: `Internal Server Error - unexpected error occured`,
            },
        });
    }

}
