import { formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { Application, PreInsurance, PreInsuranceTypeEnum } from 'models/extension-generated';

export function usePreInsurances(
  application: Application,
  partnerId: string,
  type: PreInsuranceTypeEnum
) {
  const listKey = partnerField(partnerId, `applicationInformation.preInsurances`);

  function preInsuranceKey(id: string, key: keyof PreInsurance): string {
    return partnerField(partnerId, `applicationInformation.preInsurances[${id}].${key}`);
  }

  function getPreInsurances(): Array<PreInsurance> {
    return (formModelGet(application, listKey) || []).filter(
      (preInsurance: PreInsurance) => preInsurance.type === type
    );
  }

  return {
    preInsurances: getPreInsurances(),
    preInsuranceKey
  };
}
