/* eslint-disable @typescript-eslint/no-non-null-assertion */
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField, partnerField, RecursiveKeyOf } from 'models';
import { Application, Partner, PersonRoleEnum } from 'models/extension-generated';

import { WizardRoutesEnum } from '../../constants';
import {
  generalDataScreenFields,
  minimalRequiredPartnerFields,
  privateDataScreenFields,
  summaryDataScreenFields,
  tariffsDataScreenFields
} from './fields';
import { isAdult } from 'utilities/dates';

/**
 * Helper for mapping the fields to the partner version (indexed with partner[id])
 */
function generatePartnerSchema(
  id: string,
  fields: Array<RecursiveKeyOf<Partner>>
): Array<RecursiveKeyOf<Application>> {
  return fields.map((field) => partnerField(id, field) as RecursiveKeyOf<Application>);
}

export function getPrivateDataScreenSchema(
  application: Application,
  onlyMinimalSubsetOfFields: boolean,
  currentPartnerId?: string
): Array<RecursiveKeyOf<Application>> {
  const fields = onlyMinimalSubsetOfFields
    ? minimalRequiredPartnerFields
    : [...minimalRequiredPartnerFields, ...privateDataScreenFields];
  // Filter for insured persons only as on this screen there is no other person
  const currentPartner = application.partners.find(({ id }) => id === currentPartnerId);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );

  const partners = currentPartner ? [currentPartner] : applicationPartners;

  return flatten([...partners.map((partner) => generatePartnerSchema(partner.id!, fields))]);
}

function getTariffsDataScreenSchema(
  application: Application,
  partnerId?: string
): Array<RecursiveKeyOf<Application>> {
  // Filter for insured persons only as on this screen there is no other person
  const currentPartner = application.partners.find(({ id }) => id === partnerId);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const partners = currentPartner ? [currentPartner] : applicationPartners;
  const applicationFields = [applicationField('applicationStart') as RecursiveKeyOf<Application>];

  const partnersFields = flatten(
    partners.map((partner) => {
      const tariffFields: Array<RecursiveKeyOf<Partner>> =
        partner.applicationInformation?.tariffInformation?.selectedTariffs.map(({ id }) => {
          return `applicationInformation.tariffInformation.selectedTariffs[${id}].highMoneyAmount` as RecursiveKeyOf<Partner>;
        }) || [];

      return generatePartnerSchema(partner.id!, [...tariffsDataScreenFields, ...tariffFields]);
    })
  );

  return [...partnersFields, ...applicationFields];
}

function getSummaryDataScreenSchema(application: Application): Array<RecursiveKeyOf<Application>> {
  const policyHolder = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.POLICY_HOLDER)
  );
  const insuredPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const areSomeInsuredPersonsMinors = !insuredPartners.every((partner) => {
    if (application.applicationStart) {
      return isAdult(partner.birthDate);
    }
  });

  const policyHolderFields = flatten(
    policyHolder.map((partner) => {
      // Custody fields are only validated and visible if there is at least one partner younger than 18 yo
      const soleCustodyFields: Array<RecursiveKeyOf<Partner>> = [
        'applicationInformation.acceptsLegalRepresentative',
        'applicationInformation.acceptsSoleCustody'
      ];

      if (areSomeInsuredPersonsMinors) {
        return generatePartnerSchema(partner.id!, [
          ...summaryDataScreenFields,
          ...soleCustodyFields
        ]);
      }
      return generatePartnerSchema(partner.id!, [...summaryDataScreenFields]);
    })
  );

  return [...policyHolderFields, 'agentId', 'subAgentId'];
}

function getInsuranceHolderScreenSchema(
  application: Application
): Array<RecursiveKeyOf<Application>> {
  // Filter for insured persons only as on this screen there is no other person
  const partners = application.partners.filter(
    ({ roles }) =>
      roles.includes(PersonRoleEnum.POLICY_HOLDER) ||
      roles.includes(PersonRoleEnum.PAYMENT_CONTRIBUTOR)
  );

  const partnerFields = flatten(
    partners.map((partner) => {
      const roles = partner.roles;
      let fields: RecursiveKeyOf<Application>[] = [];

      if (roles.includes(PersonRoleEnum.POLICY_HOLDER)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const holderFields = generatePartnerSchema(partner.id!, [
          'firstname',
          'lastname',
          'birthDate',
          'gender',
          'maritalStatus',
          'livingInGermany',
          'applicationInformation.profession',
          'applicationInformation.employer',
          'applicationInformation.employmentGroup',
          'applicationInformation.trainingStart',
          'applicationInformation.trainingEnd',
          'permanentResidence.street',
          'applicationInformation.hasInsolvency',
          'permanentResidence.houseNumber',
          'permanentResidence.postalCode',
          'permanentResidence.city',
          'taxNumber',
          'applicationInformation.hasUsedEmergencyTariff',
          'applicationInformation.hasInsuranceDept',
          'permanentResidence.email',
          'permanentResidence.phone'
        ]);
        fields = fields.concat(holderFields);
      }

      if (roles.includes(PersonRoleEnum.PAYMENT_CONTRIBUTOR)) {
        const paymentContributorFields = generatePartnerSchema(partner.id!, [
          'firstname',
          'lastname',
          'gender',
          'permanentResidence.street',
          'permanentResidence.houseNumber',
          'permanentResidence.postalCode',
          'permanentResidence.city',
          'permanentResidence.country',
          'foreignCountry',
          'birthDate',
          'bankDetails.iban',
          'bankDetails.bankName',
          'bankDetails.bicNumber',
          'bankDetails.hasPaymentAuthorization'
        ]);

        fields = fields.concat(paymentContributorFields);
      }

      return uniq(fields);
    })
  );

  return partnerFields;
}

function getHealthDataScreenSchema(
  application: Application,
  partnerId?: string
): Array<RecursiveKeyOf<Application>> {
  // Filter for insured persons only as on this screen there is no other person
  const currentPartner = application.partners.find(({ id }) => id === partnerId);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const partners = currentPartner ? [currentPartner] : applicationPartners;

  return flatten(
    partners.map((partner) => {
      const healthQuestionsFields =
        flatten(
          partner.applicationInformation?.health?.map((question) => {
            return [
              `applicationInformation.health[${question.id}].answer` as RecursiveKeyOf<Partner>,
              `applicationInformation.health[${question.id}]` as RecursiveKeyOf<Partner>
            ];
          })
        ) || [];
      const healthDataScreenFields = flatten(
        partner.applicationInformation?.health?.map((question) => {
          const { details } = question;
          const detailFields = (() => {
            if (details && details.length > 0) {
              return details.map((detail) => {
                return [
                  `applicationInformation.health[${question.id}].details[${detail.id}].diagnosis` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].doctor` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].sickLeaveDuration` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].treatmentStart` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].treatmentEnd` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].hasOperation` as RecursiveKeyOf<Partner>,
                  `applicationInformation.health[${question.id}].details[${detail.id}].hasConsenquences` as RecursiveKeyOf<Partner>
                ];
              });
            }
            return [];
          })();
          const detailsFieldsList = flatten(detailFields);

          return detailsFieldsList;
        })
      );

      return generatePartnerSchema(partner.id!, [
        'applicationInformation.hasLegalObligationToNotify',
        ...healthDataScreenFields,
        ...healthQuestionsFields
      ]);
    })
  );
}

function getGeneralQuestionsDataScreenSchema(
  application: Application,
  partnerId?: string
): Array<RecursiveKeyOf<Application>> {
  const currentPartner = application.partners.find(({ id }) => id === partnerId);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const partners = currentPartner ? [currentPartner] : applicationPartners;

  return flatten(
    partners.map((partner) => {
      const preInsurancesFields: Array<RecursiveKeyOf<Partner>> = flatten(
        partner.applicationInformation?.preInsurances?.map(({ id }) => {
          return [
            `applicationInformation.preInsurances[${id}].privateInsuranceCompany` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].insuranceCompany` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].isPublic` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].startDate` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].endDate` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].supplementaryInsuranceType` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].benefitAmount` as RecursiveKeyOf<Partner>,
            `applicationInformation.preInsurances[${id}].ongoing` as RecursiveKeyOf<Partner>
          ];
        })
      );
      return generatePartnerSchema(partner.id!, [
        ...generalDataScreenFields,
        ...preInsurancesFields
      ]);
    })
  );
}

function getOfferDataScreenSchema(
  application: Application,
  partnerId?: string
): Array<RecursiveKeyOf<Application>> {
  const currentPartner = application.partners.find(({ id }) => id === partnerId);
  const applicationPartners = application.partners.filter(({ roles }) =>
    roles.includes(PersonRoleEnum.INSURED_PERSON)
  );
  const partners = currentPartner ? [currentPartner] : applicationPartners;

  return flatten(
    partners.map((partner) => {
      const addedRiskFields =
        partner.applicationInformation?.preInsurances?.map(({ id }) => {
          return `applicationInformation.tariffInformation.selectedTariffs[${id}].addedRiskAmount` as RecursiveKeyOf<Partner>;
        }) || [];

      return generatePartnerSchema(partner.id!, addedRiskFields);
    })
  );
}

/**
 * Resolves the keys relevant for validation per given Route
 */
export function getSchemaByScreen(
  route: WizardRoutesEnum,
  application: Application,
  partnerId?: string
): Array<RecursiveKeyOf<Application>> {
  switch (route) {
    case WizardRoutesEnum.PrivateData:
      return getPrivateDataScreenSchema(application, false, partnerId);
    case WizardRoutesEnum.Tariffs:
      return getTariffsDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.Offer:
      return getOfferDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.GeneralQuestions:
      return getGeneralQuestionsDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.HealthQuestions:
      return getHealthDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.InsuranceHolder:
      return getInsuranceHolderScreenSchema(application);
    case WizardRoutesEnum.Summary:
      return getSummaryDataScreenSchema(application);

    default:
      return [];
  }
}

/**
 * Retrieves joined validation data based on the specified wizard route.
 *
 * @param route - The current wizard route.
 * @param application - The application object containing data.
 * @param currentPartner - Optional. The current partner's identifier.
 * @param completeValidation - Optional. Flag indicating complete validation.
 * @returns An array of keys representing joined validation data for the specified route.
 *
 * @remarks
 * This function determines the validation data to be retrieved based on the provided wizard route.
 * It combines fields with values and the schema for the current route in the wizard.
 *
 * @example
 * const validationData = getJoinedValidationByRoute(
 *   WizardRoutesEnum.PrivateData,
 *   myApplication,
 *   'partnerA',
 *   true
 * );
 */
export function getJoinedValidationByRoute(
  route: WizardRoutesEnum,
  application: Application,
  currentPartner?: string,
  completeValidation?: boolean
): Array<RecursiveKeyOf<Application>> {
  const getFieldsWithValue = (() => {
    if (!completeValidation && currentPartner) {
      const applicationFieldsWithValue: Array<RecursiveKeyOf<Application>> = privateDataScreenFields
        .map((field) => {
          return partnerField(currentPartner, field) as RecursiveKeyOf<Application>;
        })
        .filter(Boolean)
        .filter((field) => {
          const value = formModelGet(application, field?.toString() || '');
          return Boolean(value);
        });
      return applicationFieldsWithValue;
    }
    return [];
  })();

  const partnerId = completeValidation ? undefined : currentPartner;

  switch (route) {
    case WizardRoutesEnum.PrivateData:
      return [
        ...getFieldsWithValue,
        ...getPrivateDataScreenSchema(application, !completeValidation, partnerId)
      ];
    case WizardRoutesEnum.Tariffs:
      return getTariffsDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.Offer:
      return getOfferDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.GeneralQuestions:
      return getGeneralQuestionsDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.HealthQuestions:
      return getHealthDataScreenSchema(application, partnerId);
    case WizardRoutesEnum.InsuranceHolder:
      return getInsuranceHolderScreenSchema(application);
    case WizardRoutesEnum.Summary:
      return getSummaryDataScreenSchema(application);

    default:
      return [];
  }
}
