import { useDropzone } from 'react-dropzone';

import { useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { Document } from 'components/UploadDocuments/Document';
import styles from 'components/UploadDocuments/UploadDocuments.module.scss';
import { useUploadContext } from 'contexts/UploadContext';

type DropzoneProps = {
  id: string;
  showIntent?: boolean;
  policyHolderName?: string;
  minimalNumberOfDocuments?: number;
  translations?: {
    ns?: 'wizardSummary';
    prefix?: 'upload';
  };
};

export const Dropzone = ({
  id,
  policyHolderName,
  showIntent = true,
  minimalNumberOfDocuments = 0,
  translations = {}
}: DropzoneProps) => {
  const { ns, prefix } = translations;
  const { t } = useTranslation(ns || 'view360', { keyPrefix: prefix || 'upload' });
  const {
    files,
    error,
    errors,
    setFiles,
    setError,
    close,
    upload,
    setIntent,
    setComment,
    handleDelete
  } = useUploadContext();
  const isSubmitDisabled =
    Boolean(minimalNumberOfDocuments) && files.length < minimalNumberOfDocuments;
  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setError(undefined);
    setFiles((oldFiles) => {
      const joinedFiles = oldFiles?.concat(acceptedFiles);
      const uniqueNames: Array<string> = [];
      const uniqueFiles: Array<File> = [];

      joinedFiles.forEach((item) => {
        const name = item.name.trim();
        if (!uniqueNames.includes(name)) {
          uniqueNames.push(name);
          uniqueFiles.push(item);
        } else {
          const errorMsg = t('dropzone.toastMessages.youAddedFileThatAlreadyExist');
          toast.error(errorMsg);
        }
      });
      return uniqueFiles;
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-[24px]">
      <div className="mb-[28px] flex flex-row justify-between items-center">
        <Typography variant="bodyMDBold">{t('title')}</Typography>
        <div onClick={close} className="cursor-pointer">
          <CloseIcon fontSize="inherit" />
        </div>
      </div>

      <div className="row mb-[16px]">
        <div className="col flex justify-between bg-surface-60 px-[16px] py-[12px] rounded">
          <Typography variant="bodySMRegular" className="text-text-80">
            <b>#{id}</b> - {policyHolderName}
          </Typography>

          <span className="text-text-60 text-[10px] font-bold tracking-wide">
            {/* {data.documentType} */}
          </span>
        </div>
      </div>

      <div className="row reset-container ">
        <div className="col">
          <div
            {...getRootProps({
              className: classNames(
                styles.dropzone,
                'border-dashed border-2 rounded-md border-text-20 w-full',
                {
                  [styles.isDragActive]: isDragActive
                }
              )
            })}
          >
            <input data-testid="dropzone-input" {...getInputProps()} />
            <div className="row text-center">
              <div className="flex justify-center items-center mt-[36px]">
                <div className="rounded-[4px] bg-surface-60 px-[8px] pt-[8px] pb-[4px]">
                  <FileUploadOutlinedIcon />
                </div>
              </div>
              <div className="flex justify-center items-center mt-[24px]">
                <Typography variant="bodyMDRegular">{t('dropzone.dropText')}</Typography>
                &nbsp;
                <Link component="button" color="primary" className="text-sm">
                  {t('dropzone.clickText')}
                </Link>
                .
              </div>
              <div className="col-12 mt-[8px] mb-[24px]">
                <Typography variant="bodySMRegular" className="text-text-60">
                  {t('dropzone.acceptsText')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>

      {files && files.length > 0 && (
        <>
          <div className="mt-[24px] flex flex-row justify-between mb-[8px] text-left text-[12px] text-text-60 tracking-wide leading-[16px]">
            <div>{t('files.title')}</div>
            <div>
              <span className="font-bold">{files.length}</span>&nbsp;{t('files.documents')}
            </div>
          </div>
          <div className={classNames('flex flex-col')}>
            {files.map((file) => {
              return (
                <Document
                  key={file.name}
                  translationNameSpace="view360"
                  file={file}
                  showIntent={showIntent}
                  showError={Boolean(errors.get(file.name || ''))}
                  onDelete={handleDelete}
                  onIntentSelection={setIntent}
                  onCommentChange={setComment}
                />
              );
            })}
          </div>
        </>
      )}

      <div className="row reset-container a-i-center m-t-36">
        {error && (
          <div className="col-auto text-left">
            <span className="text-error text-xs d-inline-flex a-i-center text-wide">{error}</span>
          </div>
        )}

        <div className="col text-right">
          <Button className="mr-[16px] py-[11px]" size="large" onClick={close}>
            {t('actions.cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={upload}
            className="py-[11px]"
            disabled={isSubmitDisabled}
          >
            {t('actions.submit')}
          </Button>
        </div>
      </div>
    </div>
  );
};
