import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import LockClockRoundedIcon from '@mui/icons-material/LockClockRounded';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { DocumentTypeEnum } from '../../models/view360models';
import { RealmRole } from 'models';
import { ApplicationStatusEnum } from 'models/extension-generated';

import { Accounts } from './components/Accounts';
import { ActionMenu } from './components/ActionMenu';
import { AdditionalContractInfo } from './components/AdditionalContractInfo';
import { Agent } from './components/Agent';
import { ContractReminders } from './components/ContractReminders';
import { ContractSummary } from './components/ContractSummary';
import {
  DocumentNavigation,
  DocumentNavigationAnchor,
  View360NavigationEnum
} from './components/DocumentNavigation';
import { DocumentsDownload } from './components/DocumentsDownload';
import { DocumentsTable } from './components/DocumentsTable';
import { InsuredPersonDetail } from './components/InsuredPersonDetail';
import { PolicyHolderDetail } from './components/PolicyHolderDetail';
import { PolicyHolderSummary } from './components/PolicyHolderSummary';
import { Revisions } from './components/Revisions';
import { AccordionHandle } from './components/SectionAccordion';
import { UploadDocuments } from './components/UploadDocuments';
import { use360ViewContext } from './context/View360Context';
import { formatEnum, optionalValue } from './utils/formatters';
import { DuplicateApplicationDialog } from 'pages/Dashboard/components/Table/DuplicateApplicationDialog';
import { SignApplicationDialog } from 'pages/Dashboard/components/Table/SignApplicationDialog';
import { NewBornDialog } from 'pages/Products/modals/NewbornDialog';
import { formatGermanDate } from 'utilities/dates';
import { onRootScrollHandler, topView360Element } from 'utilities/scroll';

type View360PageProps = {
  policyId: string;
  refetchData: () => Promise<void>;
};

export const View360Page = ({ policyId, refetchData }: View360PageProps) => {
  const { pathname } = useLocation();
  const isAntrag = pathname.startsWith('/360/antrag');
  const isVertrag = pathname.startsWith('/360/vertrag');
  const context = use360ViewContext();
  const { enableUpload, enableRevisions } = context;
  const {
    documentType,
    id,
    status,
    reminders,
    policyHolder,
    contract,
    insuredPersons,
    accounts,
    documents,
    additionalContractInfo,
    agent,
    revisions,
    signatureStatus,
    currentRevision
  } = context.data;
  const { t } = useTranslation('view360');

  const { keycloak } = useKeycloak();
  const [isSignDialogOpen, setSignDialogOpen] = useState(false);
  const [isDuplicationDialogOpen, setDuplicationDialogOpen] = useState(false);
  const [isNewBornDialogOpen, setIsNewBornDialogOpen] = useState(false);

  const onMenuClose = () => {
    setSignDialogOpen(false);
    setDuplicationDialogOpen(false);
  };

  const processSign = async () => {
    await refetchData();
  };

  const isContract = documentType === DocumentTypeEnum.Contract;
  const getRef = (key: string): MutableRefObject<AccordionHandle | null> | undefined => {
    return context.navigation.items.find((item) => item.key === key)?.ref;
  };

  const isOption = useMemo(() => {
    const isApplicationCreated =
      formatEnum(
        ApplicationStatusEnum.APPLICATION_CREATED,
        'ApplicationStatusEnum',
        'contractsEnums'
      ) === status;

    const isApplicationPublished =
      formatEnum(
        ApplicationStatusEnum.APPLICATION_PUBLISHED,
        'ApplicationStatusEnum',
        'contractsEnums'
      ) === status;

    return {
      duplicateEnabled: isApplicationCreated || isApplicationPublished,
      submissionEnabled: isApplicationPublished
    };
  }, [status]);

  useEffect(() => {
    document.addEventListener('scroll', onRootScrollHandler);
    return () => {
      document.removeEventListener('scroll', onRootScrollHandler);
    };
  }, []);

  const hasAppropriateRealmRole =
    keycloak.hasRealmRole(RealmRole.MASTER_AGENT) ||
    keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);

  return (
    <div data-testid="single-page" className={'bg-background pt-[24px] min-h-screen'}>
      {isDuplicationDialogOpen && (
        <DuplicateApplicationDialog
          appId={context?.data?.id}
          isOpen={isDuplicationDialogOpen}
          onClose={() => setDuplicationDialogOpen(false)}
        />
      )}
      {isSignDialogOpen && (
        <SignApplicationDialog
          appId={context?.data?.id}
          isOpen={isSignDialogOpen}
          onClose={() => setSignDialogOpen(false)}
          process={processSign}
        />
      )}
      {isNewBornDialogOpen && (
        <NewBornDialog
          isNewBornDialogOpen={isNewBornDialogOpen}
          setIsNewBornDialogOpen={setIsNewBornDialogOpen}
        />
      )}
      <div className="relative max-w-[1366px] my-0 mx-[auto]">
        <div className="grid grid-cols-12 gap-s">
          <div className="col-span-12 s:col-span-3 m:col-span-3 xl:col-span-3 px-m m:px-l">
            <DocumentNavigation isContract={isContract} items={context.navigation.items} />
          </div>
          <div className="col-span-12 s:col-span-9 m:col-span-9 xl:col-span-9 px-m pb-l bg-white">
            <div id={topView360Element} className="sticky top-0 pb-[10px] pt-[24px] bg-white z-20">
              <div className="px-[24px]">
                <div className="flex justify-between">
                  <div className="flex items-center space-x-[8px]">
                    <div>
                      <SummarizeOutlinedIcon />
                    </div>
                    <div className="text-[12px] font-bold leading-[20px]">{documentType}</div>
                    <div className="text-[12px] font-bold text-text-80 bg-surface-60 px-[8px] py-[8px] flex items-center rounded-[4px]">
                      {status}
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="flex items-center space-x-[8px]">
                        {(isOption.duplicateEnabled || isOption.submissionEnabled) && (
                          <div>
                            <ActionMenu
                              onMenuClose={onMenuClose}
                              items={
                                <div>
                                  {isOption.duplicateEnabled && (
                                    <MenuItem onClick={() => setDuplicationDialogOpen(true)}>
                                      <ListItemIcon>
                                        <ContentCopyOutlinedIcon className="fill-text-80" />
                                      </ListItemIcon>
                                      <ListItemText className="text-text-80">
                                        {t('dashboard:actions.duplicate')}
                                      </ListItemText>
                                    </MenuItem>
                                  )}
                                  {isOption.submissionEnabled && (
                                    <MenuItem onClick={() => setSignDialogOpen(true)}>
                                      <ListItemIcon>
                                        <BookmarkAddedOutlinedIcon className="fill-text-80" />
                                      </ListItemIcon>
                                      <ListItemText className="text-text-80">
                                        {t('dashboard:actions.markProcessAsCompleted')}
                                      </ListItemText>
                                    </MenuItem>
                                  )}
                                  <MenuItem onClick={() => setIsNewBornDialogOpen(true)}>
                                    <ListItemIcon>
                                      <PersonAddAltIcon className="fill-text-80" />
                                    </ListItemIcon>
                                    <ListItemText className="text-text-80">
                                      {t('dashboard:actions.registerNewborn')}
                                    </ListItemText>
                                  </MenuItem>
                                </div>
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col m:flex-row m:items-center justify-items-start justify-start m:justify-between my-[18px]">
                  <div className="flex justify-items-start items-center space-x-[24px]">
                    <div className="text-navigation-80 font-bold text-[20px] leading-[28px]">
                      {id}
                    </div>
                    <div>
                      {enableRevisions && currentRevision && (
                        <Revisions
                          contractId={id}
                          revisions={revisions || []}
                          current={currentRevision}
                        />
                      )}
                    </div>
                  </div>
                  <div className="flex space-x-[24px]">
                    {enableUpload && (
                      <div>
                        <UploadDocuments id={id} />
                      </div>
                    )}
                    {signatureStatus?.actionButtons}
                  </div>
                </div>
                {documentType === DocumentTypeEnum.Offer ? (
                  <div className="flex items-center">
                    <LockClockRoundedIcon className="text-[20px] mr-[8px]" />
                    <span className="text-[14px] text-text-80">
                      {t('contract.attributes.createdAt')}
                      <span className="font-bold ml-[4px]">
                        {contract.createdDate ? formatGermanDate(contract.createdDate) : ''}
                      </span>
                    </span>
                  </div>
                ) : (
                  <>
                    <div className="flex flex-col m:flex-row m:items-center justify-items-start">
                      <div className="flex mb-[8px] m:mb-[0px] items-center">
                        <LockClockRoundedIcon className="text-[20px] mr-[8px]" />
                        <span className="text-[12px] mr-[4px]">
                          {t('contract.attributes.lastChange')}{' '}
                        </span>
                        <span className="font-bold text-[12px]">
                          {optionalValue(additionalContractInfo?.lastUpdated, formatGermanDate)}
                        </span>
                      </div>
                      <span className="bg-text-20 w-[4px] h-[4px] rounded-full ml-[16px] mr-[12px] m:inline hidden" />
                      <div className="flex items-center">
                        <BookmarkAddedIcon className="text-[20px] mr-[8px]" />
                        <span className="text-[12px] mr-[4px]">
                          {t('contract.attributes.editReason')}{' '}
                        </span>
                        <span className="font-bold text-[12px]">
                          {additionalContractInfo?.reasonForEdit}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="px-[24px]">
              <DocumentNavigationAnchor name={View360NavigationEnum.Overview}>
                {signatureStatus?.banner && (
                  <div className="mb-[32px] p-[24px] bg-white-100 border-solid border-[1px] border-text-20 rounded-[4px]">
                    <div className="a-i-center">
                      <div>
                        <div className="flex items-center justify-between mb-[16px]">
                          <div className="mr-[16px] font-bold text-[14px] text-text-100 leading-20">
                            {t('signature.infoBox.title')}
                          </div>
                          {signatureStatus?.banner.label}
                        </div>
                        <div className="text-[12px] text-text-80 leading-20 tracking-wide">
                          {signatureStatus?.banner.description}
                        </div>
                      </div>
                      <div className="m-t-8">{signatureStatus?.banner.openDashboardButton}</div>
                    </div>
                  </div>
                )}
                <div className="row reset-container m-t-32 m-b-32">
                  <div className="col">
                    <PolicyHolderSummary {...policyHolder} />
                  </div>
                  <div className="col">
                    <ContractSummary
                      contract={contract}
                      additionalContractInfo={additionalContractInfo}
                    />
                  </div>
                </div>
                {documentType !== DocumentTypeEnum.Offer && reminders && (
                  <ContractReminders reminders={reminders.reminders} />
                )}
              </DocumentNavigationAnchor>
              <DocumentNavigationAnchor name={View360NavigationEnum.Documents}>
                {isAntrag && (
                  <DocumentsDownload
                    documents={documents.documents}
                    ref={getRef(View360NavigationEnum.Documents)}
                  />
                )}
                {isVertrag && (
                  <DocumentsTable
                    policyId={policyId}
                    ref={getRef(View360NavigationEnum.Documents)}
                  />
                )}
              </DocumentNavigationAnchor>

              <DocumentNavigationAnchor name={View360NavigationEnum.PolicyHolder}>
                <PolicyHolderDetail
                  {...policyHolder}
                  ref={getRef(View360NavigationEnum.PolicyHolder)}
                />
              </DocumentNavigationAnchor>

              {/* TODO Temporarily hidden due to not 100% requirements & Accounts section covers it properly */}
              {/* <DocumentNavigationAnchor name={View360NavigationEnum.PaymentContributor}>
                <PaymentContributorDetail
                  {...paymentContributor}
                  ref={getRef(View360NavigationEnum.PaymentContributor)}
                />
              </DocumentNavigationAnchor> */}

              {insuredPersons.map((person) => {
                return (
                  <DocumentNavigationAnchor
                    name={`${View360NavigationEnum.InsuredPerson}-${person.order}`}
                    key={person.name}
                  >
                    <InsuredPersonDetail
                      key={person.name}
                      {...person}
                      ref={getRef(`${View360NavigationEnum.InsuredPerson}-${person.order}`)}
                    />
                  </DocumentNavigationAnchor>
                );
              })}

              {isContract && (
                <DocumentNavigationAnchor name={View360NavigationEnum.Account}>
                  <Accounts
                    accounts={accounts?.accounts}
                    ref={getRef(View360NavigationEnum.Account)}
                  />
                </DocumentNavigationAnchor>
              )}

              {isContract && (
                <DocumentNavigationAnchor name={View360NavigationEnum.AdditionalContractInfo}>
                  <AdditionalContractInfo
                    {...additionalContractInfo}
                    ref={getRef(View360NavigationEnum.AdditionalContractInfo)}
                  />
                </DocumentNavigationAnchor>
              )}

              {isContract && hasAppropriateRealmRole && (
                <DocumentNavigationAnchor name={View360NavigationEnum.Agent}>
                  <Agent {...agent} ref={getRef(View360NavigationEnum.Agent)} />
                </DocumentNavigationAnchor>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
