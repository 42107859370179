import { v4 } from 'uuid';

import { useState } from 'react';

import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { getStringUuid } from 'lkh-portal-ui-library';
import {
  Application,
  ApplicationService,
  IdResponse,
  InsuranceCompanyEnum,
  Partner,
  PersonRoleEnum
} from 'models/extension-generated';

import { createApplicationPartner } from '../../utils';

export const useApplicaitionPersistance = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('wizardPrivateHealthInsurance');

  /**
   * Creates if not yet exists or saves the application
   *
   * NOTE: the invalid partners are removed from the data in order to prevent the BE call to fail due to proposal restrictions.
   *
   * @param application to save (do not provide ID to create a new DB entry)
   * @returns ID of the saved application
   */
  async function save(application: Application): Promise<IdResponse> {
    try {
      setIsLoading(true);
      const body = cloneDeep(application);
      body.partners = body.partners.filter((p: Partner) => {
        if (p.roles.includes(PersonRoleEnum.INSURED_PERSON)) {
          // This is mandatory for insured partners to save in proposal
          return p.birthDate && p.gender;
        }
        return true;
      });

      const response = await ApplicationService.createApplication({ requestBody: body });
      setIsLoading(false);
      return response;
    } finally {
      setIsLoading(false);
    }
  }

  /**
   * Attempts to load a saved application and returns it back
   *
   * NOTE #1: it could happen that invalid persons were removed on save. This function ensures that
   * there are always all required persons to work with.
   *
   * NOTE #2: since BE/proposal has a different ID patterns, ALL partners will have their IDs
   * re-generated. They're used for internal purposes only and this will avoid of unwanted usage of
   * special proposal-like IDs.
   *
   * @param id lookup ID
   * @throws error if something goes wrong (not very good error handling so far by proposal/BE)
   * @returns saved application
   */
  async function load(id: string): Promise<Application> {
    try {
      setIsLoading(true);
      const fetchedApplication = await ApplicationService.getApplicationById({ id });
      sortPartners(fetchedApplication.partners);
      regeneratePartnerIds(fetchedApplication.partners);

      const latestOrderSequence = getLatestOrderSequence(fetchedApplication.partners);

      ensureRoleExists(
        fetchedApplication.partners,
        PersonRoleEnum.POLICY_HOLDER,
        latestOrderSequence
      );
      ensureRoleExists(
        fetchedApplication.partners,
        PersonRoleEnum.PAYMENT_CONTRIBUTOR,
        latestOrderSequence
      );

      return fetchedApplication;
    } catch (e) {
      const msg = t('orderOptions.menu.loadFailed');
      toast.error(msg);
      throw e;
    } finally {
      setIsLoading(false);
    }
  }

  function sortPartners(partners: Partner[]) {
    partners.sort((a, b) => ((a.order || 0) < (b.order || 0) ? -1 : 1));
  }

  // re-generate IDs for internal purposes
  // change private insurance company number to string
  function regeneratePartnerIds(partners: Partner[]) {
    partners.forEach((partner) => {
      partner.id = v4();
      if (partner.roles.includes(PersonRoleEnum.INSURED_PERSON)) {
        // if partner has some preinsurance private company then cast it to string
        castPrivateInsuranceCompanyToString(partner);
        // generate unique id fore each detail
        generateHealthQuestionDetailsIds(partner);
      }
    });
  }

  function castPrivateInsuranceCompanyToString(partner: Partner) {
    const preInsurances = partner.applicationInformation?.preInsurances || [];
    preInsurances.forEach((preInsurance) => {
      if (
        preInsurance.privateInsuranceCompany &&
        typeof preInsurance.privateInsuranceCompany !== 'string'
      ) {
        preInsurance.privateInsuranceCompany =
          preInsurance.privateInsuranceCompany.toString() as unknown as InsuranceCompanyEnum;
      }
    });
  }

  function generateHealthQuestionDetailsIds(partner: Partner) {
    const healthQuestions = partner.applicationInformation?.health || [];
    healthQuestions.forEach((question) => {
      (question.details || []).forEach((detail) => {
        detail.id = getStringUuid();
      });
    });
  }

  function getLatestOrderSequence(partners: Partner[]) {
    return partners
      .map(({ order }) => order || -Infinity)
      .reduce((a, b) => Math.max(a, b), -Infinity);
  }

  function ensureRoleExists(
    partners: Partner[],
    role: PersonRoleEnum,
    latestOrderSequence: number
  ) {
    const hasRole = partners.some(({ roles }) => roles.includes(role));
    if (!hasRole) {
      partners.push(createApplicationPartner([role], ++latestOrderSequence));
    }
  }

  /**
   * Deletes the application by ID
   *
   * @param id lookup
   * @returns promise of BE call
   */
  function drop(id: string): Promise<void> {
    try {
      setIsLoading(true);
      return ApplicationService.deleteApplicationById({ id });
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    save,
    load,
    drop
  };
};
