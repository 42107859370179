import { ReactNode, useEffect, useMemo, useState } from 'react';

import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

import { Form } from 'lkh-portal-ui-library';

import { useProvidedTariffs } from '../../contexts/TariffsContext';
import { Select, SelectOption } from '../Select';
import { TariffOption } from '../TariffOption';

type TariffRadioGroupProps = {
  title: ReactNode;
  tariffLabel: string;
};

export const TariffRadioGroup = ({ title, tariffLabel }: TariffRadioGroupProps) => {
  const { sectionTariffs, populateTariffs, selectedTariffs, updateHealthQuestions } =
    useProvidedTariffs();
  const [selected, setSelected] = useState<Array<string>>(selectedTariffs.map((t) => t.id));

  const radioItems: Array<SelectOption> = useMemo(() => {
    // Filter out tariffs without "options"
    // These are added automatically anyway and we don't need to show them
    // Actual radioItems are stored in Tariff.options
    // find all options for current section.
    // every option can hold multiple tariff ids
    // Tariff id is assigned if option is found in that tariff
    const manuallyAddedTariffs = sectionTariffs.filter(({ options }) => options?.length);
    const options = manuallyAddedTariffs.map(({ options }) => options);
    const joinedUniqueOptions = uniq(flatten(options));

    return joinedUniqueOptions.map((option) => {
      const tariffsRelatedToOption = manuallyAddedTariffs.filter(({ options }) => {
        return options?.includes(option || '');
      });
      const tariffIds = tariffsRelatedToOption.map(({ id }) => id);

      return {
        label: <TariffOption label={`${tariffLabel}:`} options={[option || '']} />,
        value: tariffIds
      };
    });
  }, [sectionTariffs]);

  useEffect(() => {
    // Trigger population in case there are auto-add tariffs
    populateTariffs(
      selectedTariffs.map((t) => t.id),
      { autoIncludeEmpty: true }
    );
  }, []);

  function handleChangeSelection(selected: Array<string>): void {
    setSelected(selected);
    populateTariffs(selected, { autoIncludeEmpty: true });
    updateHealthQuestions();
  }

  if (!radioItems.length) {
    // There are cases when there ARE tariffs in section but only empty ones
    // In that case, there ARE NOT tariffs to display so it is required to NOT render anything (empty section with title)
    return <></>;
  }

  return (
    <Form.Section title={title}>
      <div className="grid-res mt-[0px]">
        <div>
          <Select onChange={handleChangeSelection} options={radioItems} selected={selected} />
        </div>
      </div>
    </Form.Section>
  );
};
