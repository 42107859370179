import { ChangeEvent, useState } from 'react';

import { GridApi, GridReadyEvent, RowClassParams, SortChangedEvent } from 'ag-grid-community';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import TablePagination, { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';

import { columnDefs } from './models';
import { Table } from 'lkh-portal-ui-library';
import { lkhTypeOptions, vertriebTypeOptions } from 'models';
import { FormCategory, FormMetadata } from 'models/portal-generated';

import { useFormsPageContext } from '../../context';
import { FormsFilter } from '../FormsFilter';
import { DownloadIconGroupProps } from 'components/DownloadIconGroup/DownloadIconGroup';
import { NoResult } from 'components/NoResult/NoResult';
import { useAgGridOverlay } from 'hooks/useAgGridOverlay';
import { PageSize } from 'hooks/usePagination';
import { SortingOrder } from 'hooks/useSort';

export type RowFormMetadata = FormMetadata & {
  showDownload: DownloadIconGroupProps['documentInfo'];
};

export function FormsData() {
  const { t } = useTranslation('formsPage');
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const { loading, formsData, pagination, sorting, total, category } = useFormsPageContext();
  useAgGridOverlay(gridApi, loading, formsData.length, [
    pagination.page,
    pagination.size,
    sorting.field,
    sorting.order
  ]);

  const handleGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const handlePageChange = (page: number) => {
    pagination.goTo(page);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    pagination.sizeTo(rowsPerPage as PageSize);
    pagination.goTo(0);
  };

  const handleSortChange = (e: SortChangedEvent) => {
    const sortingColumn = e.columnApi.getColumns()?.find((col) => col.isSorting());

    const resolvedOrder: SortingOrder | null = (() => {
      const agSortValue = sortingColumn?.getSort();

      if (agSortValue === 'asc') return SortingOrder.ASCENDING;

      return SortingOrder.DESCENDING;
    })();

    if (sortingColumn) {
      sorting?.set(sortingColumn.getColId(), resolvedOrder);
    } else {
      sorting?.reset();
    }
  };

  const typesInCategory = (() => {
    if (category === FormCategory.LKH) {
      return sortBy(lkhTypeOptions, (o) => o.label?.toLowerCase());
    } else if (category === FormCategory.VERTRIEB) {
      return sortBy(vertriebTypeOptions, (o) => o.label?.toLowerCase());
    } else {
      return sortBy([...lkhTypeOptions, ...vertriebTypeOptions], (o) => o.label?.toLowerCase());
    }
  })();

  const getRows: RowFormMetadata[] = formsData.map((field) => {
    return {
      category: field.category ? t(`table.categories.${field.category}`) : undefined,
      type: field.type ? t(`FormTypeEnum.${field.type}`) : undefined,
      name: field.name,
      id: field.referenceId,
      version: field.version,
      showDownload: [field.id || '', field.name || '']
    };
  });

  function renderOddRowDifferentColor(params: RowClassParams) {
    const rowIndex = params.node.rowIndex || 0;
    if (rowIndex % 2 === 0) {
      return 'rounded-lg';
    }
    return 'bg-surface-60 rounded-lg';
  }

  const renderLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) =>
    `${paginationInfo.from}-${paginationInfo.to} ${t('common:pagination.of')} ${
      paginationInfo.count
    }`;

  return (
    <div className="pl-[32px] pr-[64px]">
      <div className="flex flex-col justify-center gap-[16px] pt-[24px]">
        <FormsFilter typeOptions={typesInCategory} />
        <Table
          className="table-override mt-[16px]"
          suppressCellFocus
          rowHeight={50}
          onSortChanged={handleSortChange}
          onGridReady={handleGridReady}
          getRowClass={renderOddRowDifferentColor}
          defaultColDef={{
            flex: 2,
            suppressMovable: true,
            comparator: () => 0
          }}
          columnDefs={columnDefs}
          rowData={getRows}
          suppressLoadingOverlay
          enableCellTextSelection
          noRowsOverlayComponent={NoResult}
          noRowsOverlayComponentParams={{
            loading: loading,
            warningMessage: t('table.noResultMessage'),
            warningDescription: t('table.noResultDescription')
          }}
        />
        <TablePagination
          component="div"
          count={total}
          page={pagination.page}
          onPageChange={(_e, page) => handlePageChange(page)}
          rowsPerPage={pagination.size as number}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t('common:pagination.rowsOnPage')}
          labelDisplayedRows={renderLabelDisplayedRows}
          slotProps={{ select: { MenuProps: { disableScrollLock: true } } }}
        />
      </div>
    </div>
  );
}
