import getProperty from 'lodash/get';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField, PartnerIdProp } from 'models';
import { PreInsurance, PreInsuranceTypeEnum } from 'models/extension-generated';

import { calculateAge } from '../../../../../../../utilities/dates';
import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';

const { Input } = Form.Components;

export const GeneralValues = ({ partnerId }: PartnerIdProp) => {
  const { t } = useTranslation('wizardGeneral');
  const { state } = useHealthInsuranceContext();
  const preInsurances: Array<PreInsurance> =
    formModelGet(state.model, partnerField(partnerId, 'applicationInformation.preInsurances')) ||
    [];

  const healthPreinsurance =
    preInsurances.find((preInsurance) => preInsurance.type === PreInsuranceTypeEnum.HEALTH) || {};
  const carePreinsurance =
    preInsurances.find((preInsurance) => preInsurance.type === PreInsuranceTypeEnum.CARE) || {};

  const hasPublicHealthPreInsurance = getProperty(healthPreinsurance, 'isPublic', true);
  const hasCarePreInsurance = getProperty(carePreinsurance, 'isPublic', true);

  const dateOfBirth = formModelGet(state.model, partnerField(partnerId, 'birthDate'));
  const isUnder65Years = calculateAge(dateOfBirth) < 65;
  const isUnder18Years = calculateAge(dateOfBirth) < 18;

  return (
    <>
      <div className="grid-res-2 mb-[24px]">
        <Typography variant="bodyLGBold">{t('values.title')}</Typography>
      </div>
      <div className="grid-res-2">
        <div>
          <Input
            type="number"
            componentKey={partnerField(
              partnerId,
              'applicationInformation.comprehensiveHealthTransferValue'
            )}
            disabled={hasPublicHealthPreInsurance}
            label={t('values.fields.comprehensiveHealthTransferValue.title')}
            placeholder={'0'}
          />
        </div>
        <div>
          <Input
            type="number"
            componentKey={partnerField(partnerId, 'applicationInformation.referenceValuePPV')}
            label={t('values.fields.referenceValuePPV.title')}
            disabled={hasCarePreInsurance || isUnder18Years}
            placeholder={'0'}
          />
        </div>
      </div>
      <div className="grid-res-2">
        <div>
          <Input
            type="number"
            label={t('values.fields.fundsFromGZ.title')}
            componentKey={partnerField(partnerId, 'applicationInformation.fundsFromGZ')}
            placeholder={'0'}
            disabled={isUnder65Years || (hasPublicHealthPreInsurance && !isUnder65Years)}
          />
          <p className="text-text-60 text-xs font-normal ml-[16px] mt-[4px]">
            {t('values.fields.fundsFromGZ.subtitle')}
          </p>
        </div>
      </div>
    </>
  );
};
