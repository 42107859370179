import { useMemo } from 'react';

import { ITooltipParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { FormMetadata } from 'models/portal-generated';

import { TooltipWrapper } from 'components/TableTooltipWrapper';

export const DocumentName = (props: ITooltipParams<FormMetadata>) => {
  const { t } = useTranslation('formsPage');
  const data = useMemo(() => {
    if (props.rowIndex) return props.api.getDisplayedRowAtIndex(props.rowIndex)?.data;
  }, []);

  if (data?.name) {
    return (
      <TooltipWrapper>
        <Typography variant="bodySMBold">{t('table.name')}</Typography>
        <div className="m-t-12">
          <Typography>{data.name}</Typography>
        </div>
      </TooltipWrapper>
    );
  }
  return <></>;
};
