import { useTranslation } from 'react-i18next';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import { Partner } from 'models/extension-generated';

import { formatName } from 'pages/Contract360Page/utils/formatters';

type SummaryProps = {
  partner: Partner;
  applicationStart: string;
  policyOrPaymentHolder?: boolean;
  subHeading?: string;
};

export const Summary = ({
  partner,
  applicationStart,
  policyOrPaymentHolder,
  subHeading
}: SummaryProps) => {
  const { t } = useTranslation('common');
  const fullName = formatName(partner);

  const applicationStartDate = new Intl.DateTimeFormat('de-DE', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  }).format(new Date(applicationStart));

  return (
    <div className="flex justify-between bg-surface-60 px-[16px] py-[24px] rounded-t-lg">
      <div className="flex flex-col">
        <div className="flex items-center align-middle">
          {!policyOrPaymentHolder && (
            <Avatar sx={{ bgcolor: colors.navigation[80], width: 24, height: 24 }}>
              <PersonOutlinedIcon sx={{ width: 16, height: 16 }} />
            </Avatar>
          )}
          <Typography
            component={'span'}
            variant="bodyMDBold"
            translate="no"
            className={`text-navigation-80 ${!policyOrPaymentHolder && 'ml-[8px]'}`}
          >
            {fullName}
          </Typography>
        </div>
        {subHeading && (
          <Typography variant="bodySMRegular" className="text-text-60 mt-[4px]">
            {subHeading}
          </Typography>
        )}
      </div>
      {!policyOrPaymentHolder && (
        <div>
          <Typography component={'div'} variant="bodyMDBold" className="text-text-80 text-right">
            {applicationStartDate}
          </Typography>
          <Typography
            component={'div'}
            variant="bodySMRegular"
            className="text-text-60 text-right mt-[4px]"
          >
            {t('components.policyDetail.insuranceStart')}
          </Typography>
        </div>
      )}
    </div>
  );
};
