import { useEffect, useState } from 'react';

import { NestedSearchCriteria } from 'models/extension-generated';
import {
  Criteria,
  CriteriaType,
  FormCategory,
  FormMetadata,
  FormService,
  FormsResponse,
  SearchOperationEnum
} from 'models/portal-generated';

import { TYPE_DOCUMENTS_FILTER } from '../components/FormsFilter/constatnts';
import { FormsDatatHook } from '../context';
import { UseFilter } from 'hooks/useFilter';
import { Paginated } from 'hooks/usePagination';
import { Sortable } from 'hooks/useSort';

interface UseFormsData {
  filters: UseFilter;
  pagination: Paginated;
  sorting: Sortable;
  category: FormCategory;
  name: string;
  code: string;
}

export function useFormsData({
  filters,
  pagination,
  sorting,
  category,
  name,
  code
}: UseFormsData): FormsDatatHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [formsData, setFormsData] = useState<Array<FormMetadata>>([]);
  const [total, setTotal] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  async function fetchFormsMetadata(): Promise<FormsResponse> {
    const typeFilterGroup = filters.active.find(
      (group) => group.groupId === 'type' && group.groupKey === 'type'
    );
    const typeFilterCriteria: NestedSearchCriteria = {
      criteriaType: CriteriaType.OR,
      criteria: typeFilterGroup?.criteria || []
    };
    const documents2012FilterGroup = filters.active.find(
      (group) => group.groupId === TYPE_DOCUMENTS_FILTER
    );
    const documents2012FilterCriteria: NestedSearchCriteria = {
      criteriaType: CriteriaType.OR,
      criteria: documents2012FilterGroup?.criteria || []
    };
    const categoryCriteria: NestedSearchCriteria = {
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: 'category',
          operation: SearchOperationEnum.EQUALS,
          value: category
        }
      ]
    };
    const nameCriteria: NestedSearchCriteria = {
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: 'name',
          operation: SearchOperationEnum.LIKE,
          value: name
        }
      ]
    };
    const codeCriteria: NestedSearchCriteria = {
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: 'id',
          operation: SearchOperationEnum.LIKE,
          value: code
        }
      ]
    };
    const query: Criteria = {
      page: pagination.page,
      size: pagination.size || 10,
      criteriaType: CriteriaType.AND,
      searchCriteria: [
        typeFilterCriteria,
        categoryCriteria,
        nameCriteria,
        codeCriteria,
        documents2012FilterCriteria
      ]
    };

    if (sorting.field) {
      query.sort = [
        {
          field: sorting.field,
          order: sorting.order
        }
      ];
    }

    return FormService.getFormsMetadata({ requestBody: query });
  }

  useEffect(() => {
    setLoading(true);

    const fetchFormsMetadataFn = async () => {
      try {
        const response: FormsResponse = await fetchFormsMetadata();
        if (response) {
          const { results, total } = response;
          setFormsData(results);
          setTotal(total);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchFormsMetadataFn();
  }, [
    pagination.page,
    pagination.size,
    filters.active,
    filters.active.length,
    sorting.field,
    sorting.order,
    category,
    name,
    code
  ]);

  return { formsData, total, loading, error };
}
