/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IntentEnum {
    AKTIONSANGEBOT_ANNEHMEN = '0301',
    BESCHEINIGUNG_UND_INFORMATIONSSCHREIBEN = '1401',
    ANDERUNG_ORG_EINHEIT = '0401',
    ANGEBOT_ERSTELLEN_BESTANDSGESCHAEFT = '0904',
    BASISDATEN_VERSICHERUNGSNEHMER_VERWALTEN = '1904',
    ANTRAG_ABLEHNEN = '0201',
    BESCHWERDE_SERVICE_CENTER_KUNDE = '1501',
    KUENDIGUNG_DURCH_VERSICHERUNGSNEHMER_AUSFUEHREN = '2803',
    BESCHWERDE_VERTRAG = '1502',
    GUTHABEN_AUSZAHLEN = '1301',
    BESCHWERDE_SERVICE_CENTER_VERTRIEB = '1503',
    BEIHILFE_VERWALTEN = '1101',
    BEITRAGSEINSTUFUNG_PRIVATE_PFLEGEVERSICHERUNG_AENDERN = '0705',
    BERECHTIGUNG_RABATT_PPV_VERWALTEN = '1201',
    BESCHEINIGUNG_BEITRAGSFREISTELLUNG_AUSBILDUNG_PPV_VERWALTEN = '0707',
    BESCHEINIGUNG_BEITRAGSFREISTELLUNG_BEHINDERUNG_PPV_VERWALTEN = '0702',
    BESCHEINIGUNG_DIENSTPFLICHT_VERWALTEN = '0703',
    PARTNERDATEN_AENDERN = '1902',
    PROVISION_BEAUSKUNFTEN = '2001',
    ANGEBOT_ERSTELLEN_NEUGESCHAEFT = '1601',
    VERSICHERUNG_AUSLAND_VERWALTEN = '2702',
    VERTRAG_AENDERN = '0902',
    EHEGATTENBEITRAG_PRIVATE_PFLEGEVERSICHERUNG_EINRICHTEN = '0709',
    EHEGATTENBEITRAG_PRIVATE_PFLEGEVERSICHERUNG_KORRIGIEREN = '0706',
    VERTRAG_BEAUSKUNFTEN = '2701',
    HILFSBEDUERFTIGKEIT_VERWALTEN = '0704',
    KONTEN_VERWALTEN = '0602',
    VERTRAG_INKASSO_BEAUSKUNFTEN = '3001',
    VERTRAGSTEIL_KUENDIGEN = '2501',
    MELDUNG_EINWOHNERMELDEAMT_VERWALTEN = '1901',
    MELDUNG_EINWOHNERMELDEAMT_VERWALTEN_AUSLAND = '1702',
    MELDUNG_RUHESTAND_VERWALTEN = '0903',
    ZAHLUNGSWEISE_INKASSO_AENDERN = '3101',
    RATENZAHLUNG_VERWALTEN = '2101',
    VERSICHERUNGSBEGINN_VERLEGEN = '1001',
    RECHTE_VORVERSICHERUNG_PFLEGE_VERWALTEN = '0708',
    RISIKO_UEBERPRUEFEN = '2201',
    VERTRAG_IN_ANWARTSCHAFT_AENDERN = '0502',
    VERTRAG_WIEDERINKRAFTSETZEN = '2901',
    STEUER_ID_ERFASSEN_PARTNER = '2301',
    STUDIUM_VERWALTEN = '0701',
    VORABANFRAGE_VERSICHERUNG_VERWALTEN = '1602',
    VERSICHERUNGSNACHWEIS_VERWALTEN = '0103',
    AUSLAND_ADRESSE_VERSICHERUNGSNEHMER_VERWALTEN = '1701',
    VERTRAG_AENDERN_MIT_ANTRAG_MIT_WAGNISPRUEFUNG = '2202',
    VERTRAG_AENDERN_MIT_ANTRAG_OHNE_WAGNISPRUEFUNG = '0901',
    AUSLAND_VERZUG_GESAMTVERTRAG_STORNIEREN = '2802',
    AUSLAND_VERZUG_VERSICHERTE_PERSON_VERWALTEN = '0710',
    BANKDATEN_VERSICHERUNGSNEHMER_VERWALTEN = '0601',
    LASTSCHRIFT_VERWALTEN = '1801',
    SEPA_LASTSCHRIFT_MANDAT_VORDRUCK_VERWALTEN = '1802',
    MAKLERVOLLMACHT_VERWALTEN = '0104',
    VOLLMACHT_VERWALTEN_AUSKUNFT = '0102',
    VOLLMACHT_VERWALTEN_BETREUUNGSVORSORGE = '0105',
    VOLLMACHT_VERWALTEN_EMAIL = '0106',
    VOLLMACHT_VERWALTEN_LKH = '0107',
    VOLLMACHT_VERWALTEN_SONSTIGE = '0108',
    VOLLMACHT_VERWALTEN_WIDERRUF = '0109',
    TOD_VERWALTEN = '2801',
    WARTEZEIT_VERWALTEN = '0801',
    WEHRUEBUNG_VERWALTEN = '0501',
    WERTE_VORVERSICHERUNG_UEBERTRAGEN = '2401',
    DOKUMENTATION_TELEFONIE = '0905',
    POSTRUECKLAEUFER_BEARBEITEN = '1903',
    ALLGEMEINE_BESTANDSBEARBEITUNG_DURCHFUEHREN = '0101',
}
