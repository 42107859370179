import { TFunction } from 'react-i18next';

import Typography from '@mui/material/Typography';

type AccordionHeaderProps = {
  className?: string;
  t: TFunction<'personalData', undefined>;
};

export const AccordionHeader = ({ className, t }: AccordionHeaderProps) => {
  return (
    <div className={className}>
      <Typography variant="bodyMDRegular" className="text-text-100">
        {t('personalData.personalDataHeader')}
      </Typography>
    </div>
  );
};
