import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { applicationField, partnerField } from '../../../../../../models';
import { formModelGet } from 'lkh-portal-ui-library';
import { Tariff, TariffSectionEnum } from 'models/extension-generated';

import { useApplicationHelpers } from '../../../../../../hooks/useApplicationHelpers/useApplicationHelpers';
import {
  HealthInsuranceContextType,
  useHealthInsuranceContext
} from '../../../../context/HealthInsuranceContext';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';

type PopulateTariffsParams = {
  autoIncludeEmpty?: boolean;
};

/**
 * Extension to the Application Form context
 * Provides access & few utility functions for the Tariff manipulation
 */
export type TariffsContextType = HealthInsuranceContextType & {
  /**
   * Section identifier of the filtered tariffs
   */
  section: TariffSectionEnum;

  /**
   * List of available tariffs (ignoring section)
   */
  all: Array<Tariff>;

  /**
   * Available tariffs within the section
   */
  sectionTariffs: Array<Tariff>;

  /**
   * Currently selected tariffs within the section
   */
  selectedTariffs: Array<Tariff>;

  /**
   * Assigns givem tariffs (IDs) to a partner
   *
   * When optional `autoIncludeEmpty` flag is enabled, all tariffs from the current section without "options" parmeters will be auto-included
   *
   * @param selected list of Tariff IDs to select
   * @param options additional parameters
   */
  populateTariffs: (selected: Array<string>, params?: PopulateTariffsParams) => void;

  /**
   * Checks if partner has tariff with the given id (checks within "all" tariffs)
   * @param id tariff identifier to check
   * @returns result
   */
  hasTariff: (id: string) => boolean;

  /**
   * Updates the health questions data structure based on the current tariff selection.
   */
  updateHealthQuestions: () => void;
};

export const TariffsContext = createContext<TariffsContextType | null>(null);

type RequiresTariffsProps = PropsWithChildren & {
  /**
   * Section used for filter tariffs
   */
  section: TariffSectionEnum;

  /**
   * All available tariffs
   */
  tariffs: Array<Tariff>;
};

/**
 * Section tariff provider
 */
export const RequiresTariffsProvider = ({ tariffs, section, children }: RequiresTariffsProps) => {
  const context = useHealthInsuranceContext();
  const { fetchQuestions } = useQuestionsContext();
  const { partnerId, state, reducer } = context;
  const { getPartnerById } = useApplicationHelpers(state.model);
  const { clearTariffs, giveTariff, createQuestions } = reducer;
  const sectionTariffs = filterBySection();
  const selectedTariffs = getSlectedTariffs(section);

  function filterBySection(): Array<Tariff> {
    return tariffs.filter((tariff) => tariff.section === section);
  }

  function populateTariffs(selected: Array<string>, params?: PopulateTariffsParams) {
    function filterTariffs(tariff: Tariff): boolean {
      if (params?.autoIncludeEmpty && tariff.options?.length === 0) {
        return true;
      }
      if (selected.includes(tariff.id)) {
        return true;
      }
      return false;
    }

    function giveTariffToPartner(tariff: Tariff) {
      giveTariff({ partnerId, tariff });
    }

    function replaceWithExisting(tariff: Tariff): Tariff {
      const lookup = selectedTariffs.find(({ id }) => id === tariff.id);
      return lookup || tariff;
    }

    clearTariffs({ partnerId, section });
    sectionTariffs.filter(filterTariffs).map(replaceWithExisting).forEach(giveTariffToPartner);
  }

  function hasTariff(id: string): boolean {
    return tariffs.findIndex((tariff) => tariff.id === id) !== -1;
  }

  function getSlectedTariffs(section?: TariffSectionEnum): Array<Tariff> {
    return (
      getPartnerById(partnerId).applicationInformation?.tariffInformation?.selectedTariffs || []
    ).filter((tariff) => (section ? tariff.section === section : true));
  }

  function updateHealthQuestions() {
    // IMPORTANT NOTICE: unfortunately, this function is usually triggerd in sequence with reducer actions
    // but as this is NOT an action itself, it does NOT have a latest state and thus it behaves like it was 1 state behind
    // and that may cause critical issues later (the health questions not reflecting latest tariff selection).
    // This is fully functional workaround; the proper way would be using reducer middleware with support of async actions (action creates new action)
    setTimeout(async () => {
      const birthDate = formModelGet(state.model, partnerField(partnerId, 'birthDate'));
      const insuranceStartKey = applicationField('applicationStart');
      const insuranceStart = formModelGet(state.model, insuranceStartKey);
      const questions = await fetchQuestions({
        tariffs: getSlectedTariffs(),
        birthDate,
        insuranceStart
      });
      createQuestions({
        healthKey: partnerField(partnerId, 'applicationInformation.health'),
        healthQuestions: questions
      });
    });
  }

  const ctx = useMemo(() => {
    return {
      ...context,
      partnerId,
      section,
      all: tariffs,
      sectionTariffs,
      selectedTariffs,
      populateTariffs,
      hasTariff,
      updateHealthQuestions
    };
  }, [
    context,
    partnerId,
    section,
    tariffs,
    sectionTariffs,
    selectedTariffs,
    populateTariffs,
    hasTariff,
    updateHealthQuestions
  ]);

  return (
    <TariffsContext.Provider value={ctx}>
      {sectionTariffs.length > 0 && children}
    </TariffsContext.Provider>
  );
};

export function useProvidedTariffs() {
  const ctx = useContext(TariffsContext);
  if (!ctx) {
    throw new Error(
      'To use "useProvidedTariffs" some of the parent components must be in <RequiresTariffsProvider>'
    );
  }

  return ctx;
}
