import { Trans, useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { DisclaimerCheckbox } from 'pages/PrivateHealthInsurance/components/DisclaimerCheckbox';

const { Checkbox } = Form.Components;

type MarketingConsentFormProps = {
  holderId: string;
};

export const MarketingConsentForm = ({ holderId }: MarketingConsentFormProps) => {
  const { state, reducer } = useHealthInsuranceContext();
  const { updateMultipleValues } = reducer;
  const { t } = useTranslation('wizardLegal');

  const acceptsPromotionalPurposesKey = partnerField(
    holderId,
    'applicationInformation.acceptsPromotionalPurposes'
  );
  const acceptsPromotionalPurposesPhoneKey = partnerField(
    holderId,
    'applicationInformation.acceptsPromotionalPurposesPhone'
  );
  const acceptsPromotionalPurposesEmailKey = partnerField(
    holderId,
    'applicationInformation.acceptsPromotionalPurposesEmail'
  );

  const hasConsent = formModelGet(state.model, acceptsPromotionalPurposesKey);

  function handleConsentChange(value: boolean) {
    if (!value) {
      updateMultipleValues([
        { key: acceptsPromotionalPurposesPhoneKey, value: null },
        { key: acceptsPromotionalPurposesEmailKey, value: null }
      ]);
    }
  }

  return (
    <>
      <div className="grid-res gap-[8px] mt-[0px] mb-[8px]">
        <Typography component={'div'} variant="bodyLGBold" className="text-text-80 mt-[30px]">
          {t('marketing.title')}
        </Typography>
        <Typography component={'div'} variant="bodyMDRegular" className="text-text-60 mb-[15px]">
          {t('marketing.description')}
        </Typography>
      </div>
      <div className="grid-res">
        <DisclaimerCheckbox
          componentKey={acceptsPromotionalPurposesKey}
          onChange={handleConsentChange}
          description={
            <div className="flex flex-col">
              <Typography component={'div'} variant="bodyMDRegular" className="text-text-80">
                <Trans i18nKey={'wizardLegal:marketing.checkbox.text'} />
              </Typography>
              <div className="flex gap-[24px] mt-[12px]">
                <div>
                  <Checkbox
                    className="pl-[16px]"
                    componentKey={acceptsPromotionalPurposesPhoneKey}
                    disabled={!hasConsent}
                    description={
                      <Typography variant="bodyMDRegular" className="my-0">
                        {t('marketing.checkbox.optionPhone')}
                      </Typography>
                    }
                  />
                </div>
                <div>
                  <Checkbox
                    componentKey={acceptsPromotionalPurposesEmailKey}
                    disabled={!hasConsent}
                    description={
                      <Typography variant="bodyMDRegular" className="my-0">
                        {t('marketing.checkbox.optionEmail')}
                      </Typography>
                    }
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>
    </>
  );
};
