import { PortalLayout } from 'layout/portal';

import { useParams } from 'react-router-dom';

import { NewsDetail } from './components/NewsDetail';
import { NewsDetailContextProvider } from './context';

export function NewsDetailPage() {
  const { newsId } = useParams();
  const articleId = newsId ? newsId : '0';

  return (
    <PortalLayout>
      <NewsDetailContextProvider id={parseInt(articleId)}>
        <NewsDetail />
      </NewsDetailContextProvider>
    </PortalLayout>
  );
}
