import { FilterGroup } from 'models/criteriaTypes';

import {
  CONTRACT_FILTERS,
  CONTRACT_FILTERS_RANGE,
  getActiveFiltersCount,
  INSURED_PERSON_FILTERS,
  INSURED_PERSON_FILTERS_RANGE,
  SPECIAL_SEARCH_FILTERS
} from './filtersByTabs';

export type ActiveFiltersCount = {
  contract: number;
  insuredPerson: number;
  specialSearch: number;
};

export const useCount = (filters: FilterGroup[]): ActiveFiltersCount => {
  const contract = getActiveFiltersCount(filters, CONTRACT_FILTERS, CONTRACT_FILTERS_RANGE);
  const insuredPartners = getActiveFiltersCount(
    filters,
    INSURED_PERSON_FILTERS,
    INSURED_PERSON_FILTERS_RANGE
  );
  const specialSearch = getActiveFiltersCount(filters, SPECIAL_SEARCH_FILTERS, []);

  return {
    contract,
    insuredPerson: insuredPartners,
    specialSearch
  };
};
