import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { filterTheme } from '../../../../styles/theme/components/filterTheme';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ThemeProvider,
  Typography
} from '@mui/material';

import { ContractFilters } from './Filters/ContractFilters';
import { ExpandedContractFilters } from './Filters/ExpandedContractFilters';
import { ExpandedInsuredPeopleFilters } from './Filters/ExpandedInsuredPeopleFilters';
import { InsuredPeopleFilters } from './Filters/InsuredPeopleFilters';
import { SpecialSearch } from './Filters/SpecialSearch';
import { TabType } from 'pages/Contracts/constants';
import { SecondLevelTabsProps } from 'pages/Contracts/types';

import styles from './filter.module.scss';

type FilterProps = {
  setSelectedTab?: (value: TabType) => void;
  secondarySelectedTab: SecondLevelTabsProps;
};

export const Filter = ({ secondarySelectedTab }: FilterProps) => {
  const { t } = useTranslation('contract');
  const [showMoreContractFilters, setShowMoreContractFilters] = useState(false);

  return (
    <ThemeProvider theme={filterTheme}>
      <div className={classNames(styles.container, 'm-t-24')}>
        <div className={styles.filterGroup}>
          {secondarySelectedTab === SecondLevelTabsProps.SpecialSearch && <SpecialSearch />}
          {secondarySelectedTab === SecondLevelTabsProps.InsuredPerson && <InsuredPeopleFilters />}
          {secondarySelectedTab === SecondLevelTabsProps.Contract && <ContractFilters />}
          <Accordion expanded={showMoreContractFilters} className="mt-s" disableGutters>
            <AccordionSummary
              sx={{
                height: '1px',
                border: 'none'
              }}
            />
            <AccordionDetails
              sx={{
                padding: '0px'
              }}
            >
              {secondarySelectedTab === SecondLevelTabsProps.Contract && (
                <ExpandedContractFilters />
              )}
              {secondarySelectedTab === SecondLevelTabsProps.InsuredPerson && (
                <ExpandedInsuredPeopleFilters />
              )}
            </AccordionDetails>
          </Accordion>

          <div
            className={classNames('mt-[16px]', {
              invisible: secondarySelectedTab === SecondLevelTabsProps.SpecialSearch
            })}
            onClick={() => setShowMoreContractFilters(!showMoreContractFilters)}
          >
            <Typography variant="bodySMRegularLink" className="text-primary-80 cursor-pointer">
              {!showMoreContractFilters ? t('filter.showMore') : t('filter.showLess')}
            </Typography>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Filter;
