import { useTranslation } from 'react-i18next';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import Button from '@mui/material/Button';

import { WizardRoutesEnum } from '../../constants';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';

export const BackButton = () => {
  const { t } = useTranslation();
  const { back, currentScreen } = useWizardNavigation();
  const { state } = useHealthInsuranceContext();

  return (
    <Button
      color="primary"
      variant="text"
      size="large"
      startIcon={<ArrowBackOutlinedIcon className="w-[24px] h-[24px]" />}
      onClick={() => back(state.model.id)}
      disabled={currentScreen === WizardRoutesEnum.PrivateData}
    >
      {t('wizard.actions.back')}
    </Button>
  );
};
