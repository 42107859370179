import { useQuery } from '@tanstack/react-query';

import { performSearch } from 'components/Header/utils';

type Params = {
  searchQuery: string;
};

export const useSearchPolicies = ({ searchQuery }: Params) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['partnerPolicies', searchQuery],
    queryFn: () => performSearch(searchQuery),
    enabled: !!searchQuery.length
  });

  return {
    result: data || [],
    isLoading,
    isError,
    error
  };
};
