import { forwardRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Typography from '@mui/material/Typography';

import { Document, DocumentsProps } from '../../../../models/view360models';
import { FileDownloadBox } from 'lkh-portal-ui-library';

import { AccordionHandle, SectionAccordion } from '../SectionAccordion';

export const DocumentsDownload = forwardRef<AccordionHandle, DocumentsProps>(
  ({ documents }, ref) => {
    const { t } = useTranslation('view360', { keyPrefix: 'documents' });
    const [downloaded, setDownloaded] = useState(false);

    const handleDownload = async (document: Document) => {
      try {
        await document.callback({ id: document.offerId ?? '' });
      } catch (err) {
        const errorMsg = t('common:toastMessages.somethingWentWrong');
        toast.error(errorMsg);
      } finally {
        setDownloaded(true);
      }
    };

    return (
      <SectionAccordion
        header={<Typography variant="bodyMDBold">{t('title')}</Typography>}
        ref={ref}
      >
        <div className="row reset-container m-t-24 m-b-24">
          <div className="col">
            {documents.map((doc) => (
              <span key={doc.offerId}>
                <FileDownloadBox
                  isDone={downloaded}
                  label={doc.name}
                  onClick={() => handleDownload(doc)}
                />
              </span>
            ))}
          </div>
        </div>
      </SectionAccordion>
    );
  }
);
