import { ChangeEvent, forwardRef, useState } from 'react';

import { GridApi, GridReadyEvent, RowClassParams, SortChangedEvent } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import TablePagination, { LabelDisplayedRowsArgs } from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';

import { Table } from 'lkh-portal-ui-library';
import { PolicyDocumentMetadata } from 'models/portal-generated';

import { AccordionHandle, SectionAccordion } from '../SectionAccordion';
import { columnDefs } from './columnDefs';
import { DownloadIconGroupProps } from 'components/DownloadIconGroup/DownloadIconGroup';
import { NoResult } from 'components/NoResult/NoResult';
import { useAgGridOverlay } from 'hooks/useAgGridOverlay';
import { PageSize, usePagination } from 'hooks/usePagination';
import { usePolicyDocuments } from 'hooks/usePolicyDocuments';
import { SortingOrder, useSort } from 'hooks/useSort';

type DocumentsProps = {
  policyId: string;
};

type RowPolicyDocumentMetadata = PolicyDocumentMetadata & {
  showDownload: DownloadIconGroupProps['documentInfo'];
};

export const DocumentsTable = forwardRef<AccordionHandle, DocumentsProps>(({ policyId }, ref) => {
  const { t } = useTranslation(['view360', 'common']);
  const pagination = usePagination({ page: 0, size: 10 });
  const sorting = useSort();
  const { result, total, isLoading } = usePolicyDocuments({
    policyId: policyId,
    pagination: pagination,
    sorting: sorting
  });
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  useAgGridOverlay(gridApi, isLoading, result.length);

  const handleGridReady = (e: GridReadyEvent) => {
    setGridApi(e.api);
  };

  const handlePageChange = (page: number) => {
    pagination.goTo(page);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    pagination.sizeTo(rowsPerPage as PageSize);
    pagination.goTo(0);
  };

  const handleSortChange = (e: SortChangedEvent) => {
    const sortingColumn = e.columnApi.getColumns()?.find((col) => col.isSorting());

    const resolvedOrder: SortingOrder | null = (() => {
      const agSortValue = sortingColumn?.getSort();

      if (agSortValue === 'asc') return SortingOrder.ASCENDING;

      return SortingOrder.DESCENDING;
    })();

    if (sortingColumn) {
      sorting?.set(sortingColumn.getColId(), resolvedOrder);
    } else {
      sorting?.reset();
    }
  };

  const getRows: RowPolicyDocumentMetadata[] = result.map((field: PolicyDocumentMetadata) => {
    return {
      createdAt: field.createdAt,
      docArt: field.art,
      docType: field.type,
      showDownload: [field.id || '', field.name || '', policyId]
    };
  });

  function renderOddRowDifferentColor(params: RowClassParams) {
    const rowIndex = params.node.rowIndex || 0;
    if (rowIndex % 2 === 0) {
      return 'rounded-lg';
    }
    return 'bg-surface-60 rounded-lg';
  }

  const renderLabelDisplayedRows = (paginationInfo: LabelDisplayedRowsArgs) =>
    `${paginationInfo.from}-${paginationInfo.to} ${t('common:pagination.of')} ${
      paginationInfo.count
    }`;

  return (
    <SectionAccordion
      header={<Typography variant="bodyMDBold">{t('view360:documents.title')}</Typography>}
      ref={ref}
    >
      <div className="row reset-container m-t-24 m-b-24">
        <div className="col">
          <Table
            className="table-override mt-[16px]"
            suppressCellFocus
            rowHeight={50}
            onSortChanged={handleSortChange}
            onGridReady={handleGridReady}
            getRowClass={renderOddRowDifferentColor}
            defaultColDef={{
              flex: 2,
              suppressMovable: true,
              comparator: () => 0
            }}
            columnDefs={columnDefs}
            rowData={getRows}
            enableCellTextSelection
            suppressLoadingOverlay
            noRowsOverlayComponent={NoResult}
            noRowsOverlayComponentParams={{
              loading: isLoading,
              warningMessage: t('view360:documents.noDocumentsMsg')
            }}
          />
          <TablePagination
            component="div"
            count={total}
            page={pagination.page}
            onPageChange={(_e, page) => handlePageChange(page)}
            rowsPerPage={pagination.size as number}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('common:pagination.rowsOnPage')}
            labelDisplayedRows={renderLabelDisplayedRows}
            slotProps={{ select: { MenuProps: { disableScrollLock: true } } }}
          />
        </div>
      </div>
    </SectionAccordion>
  );
});
