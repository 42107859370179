import { useTranslation } from 'react-i18next';

import DescriptionIcon from '@mui/icons-material/Description';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import {
  AdditionalContractInfoProps,
  ContractSummaryProps
} from '../../../../models/view360models';
import { TooltipInfo } from 'lkh-portal-ui-library';

import { optionalValue } from '../../utils/formatters';
import { SimpleTable } from 'components/SimpleTable';
import { formatGermanDate } from 'utilities/dates';
import { formatPrice } from 'utilities/string';

const getAdditionalContractSummary = (
  additionalContractInfo: AdditionalContractInfoProps | undefined
) => {
  if (!additionalContractInfo) return null;

  const { t } = useTranslation('view360', { keyPrefix: 'additionalContractInfo' });
  const list = [
    {
      label: t('attributes.amountKV'),
      value: optionalValue(additionalContractInfo.amountKV, formatPrice)
    },
    {
      label: t('attributes.amountPPV'),
      value: optionalValue(additionalContractInfo.amountPPV, formatPrice)
    },
    {
      label: t('attributes.amountBRE'),
      value: optionalValue(additionalContractInfo.amountBRE, formatPrice)
    },
    {
      label: t('attributes.amountSTABZ'),
      value: optionalValue(additionalContractInfo.amountSTABZ, formatPrice)
    },
    {
      label: t('attributes.amountWithoutRZUBRE'),
      value: optionalValue(additionalContractInfo.amountWithoutRZUBRE, formatPrice)
    }
  ];
  return (
    <>
      <Typography className="flex justify-between" variant="bodySMBold">
        <span>{t('attributes.sumTotal')}</span>
        <span>{optionalValue(additionalContractInfo.sumTotal, formatPrice)}</span>
      </Typography>
      {list.map((item) => {
        return (
          <Typography key={item.label} className="flex justify-between space-x-[24px]">
            <span>{item.label}</span>
            <span>{item.value}</span>
          </Typography>
        );
      })}
    </>
  );
};

type SummaryProps = {
  contract: ContractSummaryProps;
  additionalContractInfo: AdditionalContractInfoProps | undefined;
};

export const ContractSummary = ({
  contract: { amount, product, insuredPersonsCount, startDate },
  additionalContractInfo
}: SummaryProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'contract' });
  const additionalContractSummary = getAdditionalContractSummary(additionalContractInfo);

  return (
    <div className="flex space-x-[0px]">
      <div className="flex-none w-14">
        <Avatar className="bg-surface-60">
          <DescriptionIcon className="text-highlight-80" />
        </Avatar>
      </div>
      <div className="flex-1">
        <div className="m-b-8 text-neutral-600">
          <Typography>{t('title')}</Typography>
        </div>
        <div className="mb-[16px]">
          <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
            <div>
              <Typography variant="bodyMDBold">{optionalValue(amount, formatPrice)}</Typography>
            </div>
            {additionalContractSummary && (
              <span>
                <TooltipInfo dark text={additionalContractSummary} placement="bottom" width={500} />
              </span>
            )}
          </div>
        </div>
        <SimpleTable
          rows={[
            {
              label: t('attributes.product'),
              value: optionalValue(product)
            },
            {
              label: t('attributes.insuredPersonsCount'),
              value: optionalValue(insuredPersonsCount)
            },
            {
              label: t('attributes.startDate'),
              value: optionalValue(startDate, formatGermanDate)
            }
          ]}
        />
      </div>
    </div>
  );
};
