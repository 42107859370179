import { Application } from 'models/extension-generated';

import { ActionFunction, ApplicationsState } from '../types';

export const setApplications: ActionFunction<Array<Application>> = (
  state: ApplicationsState,
  payload
) => {
  return {
    ...state,
    applications: payload || []
  };
};
