import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { TariffSectionEnum } from 'models/extension-generated';

export const WorkIncomeLast12MonthsTooltip = () => {
  const { t } = useTranslation('wizardTariffs', {
    keyPrefix: `sections.${TariffSectionEnum.KRANKENTAGEGELD}.informationTooltip.workIncomeLast12Months`
  });

  return (
    <>
      <Typography variant="bodyLGRegular" className="mb-[10px]">
        {t('title')}
      </Typography>
      <Typography variant="bodyMDBold">{t('subheading')}</Typography>
      <br />
      <Typography>{t('content')}</Typography>
    </>
  );
};
