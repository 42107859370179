import { ReactNode } from 'react';

import Typography from '@mui/material/Typography';

type SectionTitleProps = {
  title: ReactNode;
};

export const SectionTitle = ({ title }: SectionTitleProps) => {
  return <Typography variant="bodyLGBold">{title}</Typography>;
};
