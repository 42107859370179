import { orderBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { InsuredPersonDetailProps } from 'models/view360models';

import { Accordion, AccordionDetails, AccordionSummary } from 'components/Accordion';
import { AccordionHeader } from 'components/AccordionHeader';
import { SimpleTable } from 'components/SimpleTable';
import { GeneralQuestions } from 'pages/Contract360Page/components/GeneralQuestions';
import { HealthQuestions } from 'pages/Contract360Page/components/HealthQuestions';
import { formatBoolean, optionalValue } from 'pages/Contract360Page/utils/formatters';
import { formatGermanDate, formatShortDate } from 'utilities/dates';
import { formatPrice } from 'utilities/string';

export const InsuredPersonDetailSummary = (props: InsuredPersonDetailProps) => {
  const { t: tl } = useTranslation(['policy', 'contractsEnums']);
  const { t } = useTranslation('view360');
  const hasRefunds = props.contributionRefunds && props.contributionRefunds.length > 0;

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="insured-person-detail-summary"
        id="insured-person-detail-summary-header"
      >
        <AccordionHeader t={tl} />
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col py-[24px] bg-surface-60 px-[16px] rounded-b-lg">
          <div className="flex space-x-[32px] mb-[32px] w-full">
            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.personalData')}
                rows={[
                  {
                    label: t('person.attributes.salutation'),
                    value: optionalValue(props.salutation)
                  },
                  {
                    label: t('person.attributes.academicTitle'),
                    value: optionalValue(props.academicTitle)
                  },
                  {
                    label: t('person.attributes.firstName'),
                    value: optionalValue(props.firstName)
                  },
                  {
                    label: t('person.attributes.lastName'),
                    value: optionalValue(props.lastName)
                  },
                  {
                    label: t('person.attributes.birthDate'),
                    value: optionalValue(props.birthDate, formatGermanDate)
                  },
                  {
                    label: t('person.attributes.nationality'),
                    value: t('person.attributes.deutschland')
                  }
                ]}
              />
            </div>
            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.companyData')}
                rows={[
                  {
                    label: t('person.attributes.professionalPosition'),
                    value: optionalValue(props.professionalPosition)
                  },
                  {
                    label: t('person.attributes.currentOccupation'),
                    value: optionalValue(props.currentOccupation)
                  },
                  {
                    label: t('person.attributes.employer'),
                    value: optionalValue(props.employer)
                  },
                  {
                    label: t('person.attributes.trainingStart'),
                    value: optionalValue(props.trainingStart, formatGermanDate)
                  },
                  {
                    label: t('person.attributes.trainingEnd'),
                    value: optionalValue(props.trainingEnd, formatGermanDate)
                  }
                ]}
              />
            </div>
          </div>
          <div className="flex space-x-[32px] mb-[32px] w-full">
            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.address')}
                rows={[
                  {
                    label: t('person.attributes.isLivingInGermany'),
                    value: optionalValue(props.isLivingInGermany, formatBoolean)
                  },
                  {
                    label: t('person.attributes.street'),
                    value: optionalValue(props.street)
                  },
                  {
                    label: t('person.attributes.houseNumber'),
                    value: optionalValue(props.houseNumber)
                  },
                  {
                    label: t('person.attributes.postalCode'),
                    value: optionalValue(props.postalCode)
                  },
                  { label: t('person.attributes.city'), value: optionalValue(props.city) },
                  {
                    label: t('person.attributes.country'),
                    value: optionalValue(props.country)
                  },
                  {
                    label: t('person.attributes.hasForeignResidence'),
                    value: optionalValue(props.hasForeignResidence, formatBoolean)
                  },
                  {
                    label: t('person.attributes.foreignCountry'),
                    value: optionalValue(props.foreignCountry)
                  }
                ]}
              />
            </div>

            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.otherData')}
                rows={[
                  {
                    label: t('person.attributes.taxNumber'),
                    value: optionalValue(props.taxNumber)
                  },
                  {
                    label: t('person.attributes.hasConsentToContact'),
                    value: optionalValue(props.hasConsentToContact, formatBoolean)
                  },
                  {
                    label: t('person.attributes.consentToContactDate'),
                    value: optionalValue(props.consentToContactDate, formatShortDate)
                  }
                ]}
              />
            </div>
          </div>
          <div className="flex space-x-[32px] mb-[32px] w-full">
            <div className="basis-1/2">
              <SimpleTable
                title={t('insuredPerson.attributes.requirementsData')}
                rows={[
                  {
                    label: t('insuredPerson.attributes.type'),
                    value: optionalValue(props.insuranceType)
                  },
                  {
                    label: t('insuredPerson.attributes.needSituation'),
                    value: optionalValue(props.needSituation)
                  },
                  {
                    label: t('insuredPerson.attributes.insuranceStart'),
                    value: optionalValue(props.insuranceStart, formatGermanDate)
                  },
                  {
                    label: t('insuredPerson.attributes.federalState'),
                    value: optionalValue(props.federalState)
                  },
                  {
                    label: t('insuredPerson.attributes.aidClaim'),
                    value: optionalValue(props.aidClaim)
                  },
                  {
                    label: t('insuredPerson.attributes.hadInsuranceSince2012'),
                    value: optionalValue(props.hadInsuranceSince2012, formatBoolean)
                  }
                ]}
              />
            </div>
            <div className="basis-1/2">
              {hasRefunds && (
                <>
                  <Typography variant="bodyMDBold" className="mb-[5px]">
                    {t('insuredPerson.attributes.premiumsData')}
                  </Typography>
                  {orderBy(props.contributionRefunds, 'year', 'desc').map(
                    ({ year, claimFreeYears, amount }) => (
                      <SimpleTable
                        key={`simple-table-${year}`}
                        title={`${t('insuredPerson.attributes.contributionYear')} ${year}`}
                        titleClass="no-margin pt-[20px]"
                        rows={[
                          {
                            label: t('insuredPerson.attributes.contributionClaimFreeYears'),
                            value: optionalValue(claimFreeYears)
                          },
                          {
                            label: t('insuredPerson.attributes.contributionAmount'),
                            value: optionalValue(amount, formatPrice)
                          }
                        ]}
                      />
                    )
                  )}
                </>
              )}
            </div>
          </div>

          {props.healthQuestions && (
            <div className="pb-[32px]">
              <HealthQuestions {...props.healthQuestions} />
            </div>
          )}
          {props.generalQuestions && <GeneralQuestions {...props.generalQuestions} />}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
