import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ConfigResponse, ConfigService } from 'models/extension-generated';

type ConfigContextType = {
  config: ConfigResponse;
  isLoading: boolean;
};

export const ConfigContext = createContext<ConfigContextType | null>(null);

export const ConfigContextProvider = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation('common');
  const [config, setConfig] = useState<ConfigResponse>({});
  const [isConfigLoading, setIsConfigLoading] = useState(false);

  // fetch configuration for wizard
  useEffect(() => {
    const fetchConfigFn = async () => {
      setIsConfigLoading(true);
      try {
        const response = await ConfigService.getConfig();

        setConfig(response);
        setIsConfigLoading(false);
      } catch (error) {
        const genericError = t('common:toastMessages.somethingWentWrong');
        toast.error(genericError);
      }
    };

    fetchConfigFn();
  }, []);

  const contextValue = useMemo(
    () => ({ config, isLoading: isConfigLoading }),
    [config, isConfigLoading]
  );

  return <ConfigContext.Provider value={contextValue}>{children}</ConfigContext.Provider>;
};

export function useConfigContext(): ConfigContextType {
  const configContext = useContext(ConfigContext);

  if (!configContext) {
    throw new Error(
      'To use "useConfigContext" some of the parent components must be in <ConfigContextProvider>'
    );
  }

  return configContext;
}
