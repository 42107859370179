import { useTranslation } from 'react-i18next';

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useUploadContext } from 'contexts/UploadContext';

type CompleteProps = {
  translationNameSpace: string;
};
export function Complete({ translationNameSpace }: CompleteProps) {
  const { t } = useTranslation(translationNameSpace, { keyPrefix: 'upload' });
  const { close } = useUploadContext();

  return (
    <div className="row text-center py-[24px] w-full">
      <div className="col-12 mb-[26px]">
        <CheckCircleOutlineOutlinedIcon color="primary" sx={{ width: 24, height: 24 }} />
      </div>
      <div className="col-12 mb-[24px]">
        <div className="mb-[8px]">
          <Typography variant="bodyMDBold">{t('complete.title')}</Typography>
        </div>
        <div>
          <Typography className="text-text-60">{t('complete.description')}</Typography>
        </div>
      </div>
      <div className="col-12">
        <Button onClick={close} size="large">
          {t('actions.close')}
        </Button>
      </div>
    </div>
  );
}
