import remove from 'lodash/remove';

import { ActionFunction, DeleteApplicationPayload } from '../types';

export const deleteApplication: ActionFunction<DeleteApplicationPayload> = (state, payload) => {
  const { applications } = state;
  const toBeDeletedApplicationId = payload?.id;

  remove(applications, { id: toBeDeletedApplicationId });

  return {
    ...state,
    applications: applications
  };
};
