import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { Button, Dialog, Typography } from '@mui/material';

type WizardDialogProps = {
  isWizardDialogOpen: boolean;
  onDialogClose: () => void;
};

export const WizardDialog = ({ isWizardDialogOpen, onDialogClose }: WizardDialogProps) => {
  const { t } = useTranslation('productsPage');
  const handleCloseWizardDialog = () => onDialogClose();

  return (
    <Dialog disableScrollLock open={isWizardDialogOpen} onClose={handleCloseWizardDialog}>
      <div className="py-[24px] px-[32px]">
        <div className="flex gap-8">
          <ErrorOutlineOutlinedIcon className="text-warning-60 text-2xl" />
          <div className="pl-[16px]">
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('productsPage:modal.wizard.header')}
            </Typography>
            <Typography variant="bodyMDRegular" className="text-text-80 flex mt-[4px]">
              {t('productsPage:modal.wizard.description')}
            </Typography>
            <div className="flex mt-[20px]">
              <Typography
                component="a"
                variant="bodyMDRegularLink"
                color="primary"
                href="https://www.lkh.de/datenschutz/"
                target="_blank"
              >
                {t('productsPage:modal.wizard.newPageText')}&nbsp;
                {t('productsPage:modal.wizard.linkText')}.
              </Typography>
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-[16px] mt-[32px]">
          <Button size="large" onClick={handleCloseWizardDialog}>
            {t('productsPage:modal.cancelButton')}
          </Button>
          <Link
            to={'/wizard'}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleCloseWizardDialog}
          >
            <Button size="large" variant="contained">
              {t('productsPage:modal.acceptButton')}
            </Button>
          </Link>
        </div>
      </div>
    </Dialog>
  );
};
