import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';

import { DeleteTariffDialog } from 'components/OfferTariffTable/components/DeleteTariffDialog/DeleteTariffDialog';
import { ActionMenu } from 'pages/Contract360Page/components/ActionMenu';

export type ActionMenuCellProps = {
  onProspectiveEntitlementClick?: () => void;
  onRemoveTariffClick?: () => void;
  tariffId?: string;
  isEditable?: boolean;
  showDeleteTariff?: boolean;
  showEntitlement?: boolean;
  hasEntitlement?: boolean;
};

export const ActionMenuCell = ({
  tariffId,
  isEditable,
  hasEntitlement,
  showEntitlement,
  showDeleteTariff,
  onRemoveTariffClick,
  onProspectiveEntitlementClick
}: ActionMenuCellProps) => {
  const { t } = useTranslation('wizardOffer');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const hasSomeVisibleAction = showEntitlement || showDeleteTariff;

  const toggleEntitlement = () => {
    onProspectiveEntitlementClick?.();
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const deleteTariff = () => {
    onRemoveTariffClick?.();
  };

  if (isEditable && hasSomeVisibleAction) {
    return (
      <>
        <ActionMenu
          items={
            <div>
              {showEntitlement && (
                <MenuItem onClick={toggleEntitlement}>
                  <ListItemIcon>
                    <CalendarTodayOutlinedIcon className="fill-text-80" />
                  </ListItemIcon>
                  <ListItemText>
                    {!hasEntitlement && t('table.actions.addEntitlement')}
                    {hasEntitlement && t('table.actions.removeEntitlement')}
                  </ListItemText>
                </MenuItem>
              )}
              {showDeleteTariff && (
                <MenuItem onClick={openDialog} className="hover:bg-danger-10">
                  <ListItemIcon>
                    <DeleteForeverOutlinedIcon className="fill-danger-60" />
                  </ListItemIcon>
                  <ListItemText className="text-danger-60">
                    {t('table.actions.deleteTariff')}
                  </ListItemText>
                </MenuItem>
              )}
            </div>
          }
        />
        {isDialogOpen && (
          <DeleteTariffDialog
            id={tariffId}
            isOpen={isDialogOpen}
            onClose={() => setDialogOpen(false)}
            onDelete={deleteTariff}
          />
        )}
      </>
    );
  }

  return <></>;
};
