import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { Question, Tariff } from 'models/extension-generated';

import { getQuestionsByCriteria } from './getQuestionsByCriteria';
import {
  getQuestionKey,
  getQuestionLabel,
  getQuestionMaxValue,
  getQuestionText,
  getQuestionUnit
} from './questionsGetters';

export type QuestionsContextReturnType = {
  questions: Array<Question>;
  isLoading: boolean;
  /**
   * Asynchronously fetches the array of question objects.
   */
  fetchQuestions: (params: {
    tariffs: Array<Tariff>;
    birthDate: string;
    insuranceStart: string;
  }) => Promise<Array<Question>>;

  /**
   * Get the text of a question by its ID.
   *
   * @param {string} id - The ID of the question.
   * @param {boolean} withOrder - Whether to include the question order number. Defaults to true.
   * @returns {string} The text of the question.
   */
  getQuestionText: (id: string, withOrder?: boolean) => string;

  /**
   * Get the label of a question by its ID.
   *
   * @param {string} id - The ID of the question.
   * @returns {string | undefined} The label of the question, or undefined if not found.
   */
  getQuestionLabel: (id: string) => string | undefined;

  /**
   * Get the unit of a question by its ID.
   *
   * @param {string} id - The ID of the question.
   * @returns {string | undefined} The unit of the question, or undefined if not found.
   */
  getQuestionUnit: (id: string) => string | undefined;

  /**
   * Get the maximum value of a question by its ID.
   *
   * @param {string} id - The ID of the question.
   * @returns {number | undefined} The maximum value of the question, or undefined if not found.
   */
  getQuestionMaxValue: (id: string) => number | undefined;

  /**
   * Get the question key for a partner and question ID.
   *
   * @param {string} partnerId - The partner's ID.
   * @param {string} id - The ID of the question.
   * @returns {string} The question key.
   */
  getQuestionKey: (partnerId: string, id: string) => string;
};

export const QuestionsContext = createContext<QuestionsContextReturnType | null>(null);

type QuestionsContextProviderProps = PropsWithChildren;

export const QuestionsContextProvider = ({ children }: QuestionsContextProviderProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [questions, setQuestions] = useState<Array<Question>>([]);

  const fetchQuestions = async ({
    tariffs,
    birthDate,
    insuranceStart
  }: {
    tariffs: Array<Tariff>;
    birthDate: string;
    insuranceStart: string;
  }): Promise<Array<Question>> => {
    try {
      const questions = await getQuestionsByCriteria({ tariffs, birthDate, insuranceStart });
      setQuestions(questions);
      return questions;
    } finally {
      setIsLoading(false);
    }
  };

  const ctx = useMemo(() => {
    return {
      fetchQuestions,
      getQuestionKey,
      getQuestionText: getQuestionText(questions),
      getQuestionLabel: getQuestionLabel(questions),
      getQuestionUnit: getQuestionUnit(questions),
      getQuestionMaxValue: getQuestionMaxValue(questions),
      questions,
      isLoading
    };
  }, [fetchQuestions, questions, isLoading]);

  return <QuestionsContext.Provider value={ctx}>{children}</QuestionsContext.Provider>;
};

export function useQuestionsContext(): QuestionsContextReturnType {
  const questionsContext = useContext(QuestionsContext);

  if (!questionsContext) {
    throw new Error(
      'To use "useQuestionsContext" some of the parent components must be in <QuestionsContextProvider/>'
    );
  }

  return questionsContext;
}
