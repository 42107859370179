import { ChangeEvent } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import CheckIcon from '@mui/icons-material/Check';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';

import { DownloadOfferOption } from '../../DownloadOfferDialog';

type SelectionStageProps = {
  downloadOfferOption: DownloadOfferOption;
  handleDownloadOfferOptionChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCloseDownloadOfferDialog: () => void;
  downloadOffer: () => Promise<void>;
};

export default function SelectionStage({
  downloadOfferOption,
  handleCloseDownloadOfferDialog,
  handleDownloadOfferOptionChange,
  downloadOffer
}: SelectionStageProps) {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'downloadOfferDialog'
  });
  return (
    <>
      <DialogTitle className="flex align-middle justify-between">
        <Typography variant="bodyMDBold" className="text-text-80">
          {t('title')}
        </Typography>
        <CloseOutlinedIcon onClick={handleCloseDownloadOfferDialog} />
      </DialogTitle>
      <DialogContent className="p-[24px]" dividers>
        <Typography variant="bodyMDBold" className="flex text-text-80">
          {t('subtitle')}
        </Typography>
        <FormControl className="mt-m">
          <RadioGroup
            value={downloadOfferOption}
            onChange={handleDownloadOfferOptionChange}
            name="radio-buttons-group"
          >
            <FormControlLabel
              className="px-s"
              value={DownloadOfferOption.Full}
              control={<Radio className="self-start" />}
              label={
                <>
                  <Typography component="div" variant="bodyMDRegular" className="mt-[10px]">
                    {t('downloadOptions.FullDownload.label')}
                  </Typography>
                  <List>
                    {Array.from({ length: 5 }).map((_, index) => (
                      <ListItem key={`fullDownloadListOption-${index}`}>
                        <ListItemIcon className="self-start min-w-[28px]">
                          <CheckIcon sx={{ width: 20, height: 20 }} className="fill-text-40" />
                        </ListItemIcon>
                        <Typography variant="bodyMDRegular">
                          {t(`downloadOptions.FullDownload.list.${index}`)}
                        </Typography>
                      </ListItem>
                    ))}
                  </List>
                </>
              }
            />
            <FormControlLabel
              className="px-s"
              value={DownloadOfferOption.Partial}
              control={<Radio />}
              label={
                <Typography variant="bodyMDRegular">
                  <Trans
                    i18nKey="downloadOfferDialog.downloadOptions.PartialDownload.label"
                    ns="wizardPrivateHealthInsurance"
                    components={{ bold: <strong /> }}
                  />
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
        <div className="flex justify-end gap-[16px] mt-[30px]">
          <Button size="large" onClick={handleCloseDownloadOfferDialog}>
            {t('cancel')}
          </Button>
          <Button size="large" variant="contained" onClick={downloadOffer}>
            {t('downloadOffer')}
          </Button>
        </div>
      </DialogContent>
    </>
  );
}
