import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Tariff } from 'models/portal-generated';
import { PriceChange } from 'models/portal-generated/models/PriceChange';

import { ContractTariffsTable } from 'components/ContractTariffsTable';
import { TariffRow } from 'components/ContractTariffsTable/types';
import { getPriceLabel } from 'utilities/calc';
import { formatShortDate } from 'utilities/dates';
import { formatPrice, formatPriceAsText, formatPriceAsTextWithoutCurrency } from 'utilities/string';

type TariffsTableProps = {
  tariffs: Tariff[];
};

const renderPriceChanges = (priceChanges?: Array<PriceChange>, prefix = ''): ReactNode => {
  if ((priceChanges?.length || 0) > 0) {
    return (
      <>
        {priceChanges?.map((change) => (
          <Typography key={change.id} component="div">
            {prefix}
            {prefix
              ? formatPrice(Math.abs(change.monthlyAmount || 0))
              : formatPrice(change.monthlyAmount || 0)}{' '}
            {change.reason}
          </Typography>
        ))}
      </>
    );
  }
  return <></>;
};

const renderTooltip = (title: string, bodyContent: ReactNode) => (hasContent: boolean) =>
  hasContent ? (
    <>
      <Typography className="mb-[8px]" variant="bodySMBold" component="div">
        {title}
      </Typography>
      {bodyContent}
    </>
  ) : (
    <></>
  );

const calculateTotal = (tariffs: Tariff[], key: keyof Tariff): number =>
  tariffs.reduce((total, tariff) => total + ((tariff[key] as number) || 0), 0);

const calculatePriceChangesTotal = (tariffs: Tariff[], key: keyof Tariff): number =>
  tariffs.reduce((total, tariff) => {
    const priceChanges = (tariff[key] as PriceChange[]) || [];
    return (
      total + priceChanges.reduce((subTotal, change) => subTotal + (change.monthlyAmount || 0), 0)
    );
  }, 0);

export const TariffsTable = ({ tariffs }: TariffsTableProps) => {
  const { t } = useTranslation('common');

  const rows: TariffRow[] = tariffs.map((tariff) => {
    const {
      tariffId,
      tariffDescription,
      highMoneyAmount,
      tariffMonthlyAmount, // "originalPrice": "Tarifbeitrag"
      validFrom,
      deductionPriceChanges = [], // "reduction": "Abschlag"
      risikoPriceChanges = [], // "surcharge": "Risikozuschlag"
      otherPriceChanges = [], // "other": "Sonstiges"
      bestandMonthlyAmount, // "bestandTotal": "Gesamt"
      discountPriceChanges = [], // "discount": "Nachlass"
      effectiveMonthlyAmount // "finalPrice": "Effektiver Beitrag"
    } = tariff;

    const label = tariffId || '';
    const tier = formatPriceAsTextWithoutCurrency(highMoneyAmount);
    const tariffStartDate = formatShortDate(validFrom || '');
    const finalTariffPrice = formatPriceAsText(effectiveMonthlyAmount);
    const originalTariffPrice = formatPriceAsText(tariffMonthlyAmount);

    const hasPriceAdditions = risikoPriceChanges.length > 0;
    const hasPriceReductions = discountPriceChanges.length > 0;

    const surchargeLabel = hasPriceAdditions
      ? formatPriceAsText(
          risikoPriceChanges.reduce((total, change) => total + (change.monthlyAmount || 0), 0)
        )
      : undefined;

    const discountLabel = hasPriceReductions
      ? formatPriceAsText(
          discountPriceChanges.reduce((total, change) => total + (change.monthlyAmount || 0), 0)
        )
      : undefined;

    const adjustmentPriceLabel = getPriceLabel(
      hasPriceAdditions,
      hasPriceReductions,
      effectiveMonthlyAmount
    );

    const createTooltip = (title: string, changes: PriceChange[]) =>
      renderTooltip(title, renderPriceChanges(changes))(changes.length > 0);

    return {
      title: {
        label,
        tooltip: tariffDescription
      },
      status: tariff.fachStatus,
      tier,
      finalTariffPrice,
      validFrom: tariffStartDate,
      originalTariffPrice: {
        label: originalTariffPrice,
        tooltip: createTooltip(t('tariffs.originalPrice'), [{ monthlyAmount: tariffMonthlyAmount }])
      },
      reduction: {
        label: deductionPriceChanges.length
          ? formatPriceAsText(
              deductionPriceChanges.reduce(
                (total, change) => total + (change.monthlyAmount || 0),
                0
              )
            )
          : null,
        tooltip: createTooltip(t('tariffs.reduction'), deductionPriceChanges)
      },
      surcharge: {
        label: surchargeLabel,
        tooltip: createTooltip(t('tariffs.surcharge'), risikoPriceChanges)
      },
      other: {
        label: otherPriceChanges.length
          ? formatPriceAsText(
              otherPriceChanges.reduce((total, change) => total + (change.monthlyAmount || 0), 0)
            )
          : null,
        tooltip: createTooltip(t('tariffs.other'), otherPriceChanges)
      },
      bestandTotal: {
        label: formatPriceAsText(bestandMonthlyAmount || 0)
      },
      discount: {
        label: discountLabel,
        tooltip: createTooltip(t('tariffs.discount'), discountPriceChanges)
      },
      adjustmentPrice: {
        label: adjustmentPriceLabel,
        tooltip: renderTooltip(
          t('tariffs.priceAdjustment'),
          <>
            {renderPriceChanges(risikoPriceChanges, '+')}
            {renderPriceChanges(discountPriceChanges, '-')}
          </>
        )(hasPriceAdditions || hasPriceReductions)
      }
    };
  });

  const prices = {
    total: formatPriceAsText(calculateTotal(tariffs, 'effectiveMonthlyAmount')),
    totalBruto: formatPriceAsText(calculateTotal(tariffs, 'tariffMonthlyAmount')),
    totalReduction: formatPriceAsText(calculatePriceChangesTotal(tariffs, 'deductionPriceChanges')),
    totalSurcharge: formatPriceAsText(calculatePriceChangesTotal(tariffs, 'risikoPriceChanges')),
    totalOther: formatPriceAsText(calculatePriceChangesTotal(tariffs, 'otherPriceChanges')),
    bestandTotal: formatPriceAsText(calculateTotal(tariffs, 'bestandMonthlyAmount')),
    totalDiscount: formatPriceAsText(calculatePriceChangesTotal(tariffs, 'discountPriceChanges')),
    totalAdjustment: formatPriceAsText(
      calculatePriceChangesTotal(tariffs, 'risikoPriceChanges') +
        calculatePriceChangesTotal(tariffs, 'discountPriceChanges')
    )
  };

  return <ContractTariffsTable tariffs={rows} prices={prices} />;
};
