import React, { ReactNode, useMemo, useState } from 'react';

import join from 'lodash/join';

import { Autocomplete, createFilterOptions, TextField } from '@mui/material';
import { StyledMutiselectPopper } from 'styles/theme/components/muiPopper';

import { DarkTooltip } from 'lkh-portal-ui-library';

import i18n from 'services/translation';

import { ListboxComponent } from 'components/Listbox/Listbox';
import { DropdownOption } from 'pages/Contracts/types';

const SELECT_ALL = 'select-all';
const getOptionLabel = (option: DropdownOption) => `${option.label}`;

interface MultiSelectAllProps {
  options: DropdownOption[];
  selectAllLabel?: string;
  error?: boolean;
  value: DropdownOption[];
  label: string;
  loading?: boolean;
  onChange: (selectedOptions: DropdownOption[]) => void;
}

const MultiSelectAll: React.FC<MultiSelectAllProps> = ({
  options,
  selectAllLabel = i18n.t('common:selectAll'),
  error,
  value,
  label,
  loading = false,
  onChange
}) => {
  const [openMultiselect, setOpenMultiselect] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);

  const handleMultiselectClose = () => {
    setOpenMultiselect(false);
  };

  const handleMultiselectOpen = () => {
    if (openTooltip) {
      setOpenTooltip(false);
    }
    setOpenMultiselect(true);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    if (!openMultiselect) {
      setOpenTooltip(true);
    }
  };
  const allItemsSelected = options.length === value.length;

  const tooltip = useMemo(() => {
    if (allItemsSelected) {
      return i18n.t('common:allOptionsSelected');
    }
    if (value.length >= 10) {
      return (
        <ul className="list-none m-0 p-0">
          {value.slice(0, 10).map((option) => (
            <li key={option.label} className="list-none m-0 p-0">
              <p>{option.label}</p>
            </li>
          ))}
          {value.length > 10 && (
            <li className="list-none m-0 p-0">
              <p>... + {value.length - 10}</p>
            </li>
          )}
        </ul>
      );
    }
    return join(
      value.map(({ label }) => label),
      ', '
    );
  }, [allItemsSelected, value]);

  const handleChange = (_event: React.SyntheticEvent, newValue: DropdownOption[]) => {
    if (newValue.find((option: DropdownOption) => option.value === SELECT_ALL)) {
      if (allItemsSelected) {
        onChange([]);
      } else {
        onChange(options);
      }
    } else {
      onChange(newValue);
    }
  };

  return (
    <Autocomplete
      data-testid="multiSelectAll"
      open={openMultiselect}
      onOpen={handleMultiselectOpen}
      onClose={handleMultiselectClose}
      multiple
      options={options}
      value={value}
      loading={loading}
      PopperComponent={StyledMutiselectPopper}
      ListboxComponent={ListboxComponent}
      getOptionLabel={getOptionLabel}
      filterOptions={(options, params) => {
        const filtered = createFilterOptions<DropdownOption>()(options, params);
        if (filtered.length === 0) {
          return [];
        }
        return [{ label: selectAllLabel, value: SELECT_ALL }, ...filtered];
      }}
      disableListWrap
      disableCloseOnSelect
      disableClearable={value !== null}
      onChange={handleChange}
      isOptionEqualToValue={(option, val) => option.value === val.value}
      renderOption={(props, option, state) => {
        const selectAllOptions = option.value === SELECT_ALL ? allItemsSelected : undefined;
        return [props, option, state.index, state.selected, selectAllOptions] as ReactNode;
      }}
      renderInput={(params) => (
        <DarkTooltip
          title={tooltip}
          open={openTooltip}
          onClose={handleTooltipClose}
          onOpen={handleTooltipOpen}
        >
          <TextField {...params} label={label} error={error} onClick={handleTooltipClose} />
        </DarkTooltip>
      )}
    />
  );
};

export default MultiSelectAll;
