import { Loader, LoaderType } from 'lkh-portal-ui-library';

import styles from './authenticationLoader.module.scss';

export const AuthenticationLoader = () => {
  return (
    <div className={styles.authLoader}>
      <Loader type={LoaderType.Circular} />
    </div>
  );
};
