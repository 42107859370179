import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

export type WizardProgressContextType = {
  active: string;
  completed: Array<string>;
  onClick?: (item: string) => void;
};

const WizardProgressContext = createContext<WizardProgressContextType | null>(null);

type WizardProgressProviderProps = PropsWithChildren & WizardProgressContextType;

export const WizardProgressContextProvider = ({
  children,
  active,
  completed,
  onClick
}: WizardProgressProviderProps) => {
  const ctx = useMemo(() => {
    return { active, completed, onClick };
  }, [active, completed, onClick]);

  return <WizardProgressContext.Provider value={ctx}>{children}</WizardProgressContext.Provider>;
};

export const useWizardProgessContext = () => {
  // get the context
  const context = useContext(WizardProgressContext);

  if (context === null) {
    throw new Error('useWizardProgessContext was used outside of its Provider');
  }

  return context;
};
