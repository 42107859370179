import { ActionFunction, ApplicationsState, DateRange } from '../types';

export const setDateFilter: ActionFunction<DateRange> = (state: ApplicationsState, payload) => {
  const { filters } = state;

  return {
    ...state,
    filters: {
      ...filters,
      creationDateFrom: payload?.from || filters.creationDateFrom,
      creationDateUntil: payload?.to || filters.creationDateUntil
    }
  };
};
