import { inputClasses } from '@mui/material/Input';
import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Components, Theme } from '@mui/material/styles';
import { colors } from 'styles/colors';

import { fieldHeight } from '../constants';

const disableSpinButtonStyle = {
  '-webkit-appearance': 'none',
  margin: 0
};

export const muiTextFieldOverride: Components<Omit<Theme, 'components'>>['MuiTextField'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      'input[type=number]::-webkit-outer-spin-button': disableSpinButtonStyle,
      'input[type=number]::-webkit-inner-spin-button': disableSpinButtonStyle,
      fontSize: '14px',
      [`& .${inputClasses.root}`]: {
        '&:hover fieldset': {
          borderColor: colors.primary[80]
        }
      },
      [`& .${outlinedInputClasses.root}`]: {
        '&:hover fieldset': {
          borderColor: colors.primary[80]
        }
      },
      [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
        borderColor: colors.primary[80]
      },
      [`.${inputLabelClasses.focused}`]: {
        maxWidth: '100%'
      },
      [`.${inputLabelClasses.root}`]: {
        maxWidth: 'calc(100% - 40px)'
      },
      ...(ownerState.error === true && {
        [`& .${inputClasses.root}`]: {
          '&:hover fieldset': {
            borderColor: colors.danger[60]
          }
        },
        [`& .${outlinedInputClasses.root}`]: {
          '&:hover fieldset': {
            borderColor: colors.danger[60]
          }
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: colors.danger[60]
        }
      }),
      ...(ownerState.rows === undefined && {
        height: fieldHeight
      }),
      ...(ownerState.disabled === true && {
        [`& .${inputClasses.root}`]: {
          '&:hover fieldset': {
            borderColor: 'none'
          }
        },
        [`& .${outlinedInputClasses.root}`]: {
          '&:hover fieldset': {
            borderColor: 'none'
          }
        },
        [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
          borderColor: 'none'
        }
      })
    })
  }
};
