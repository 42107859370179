import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import {
  Chip,
  FileBookmarkIcon,
  Icon,
  Props,
  UserShieldIcon,
  UserSingleIcon
} from 'lkh-portal-ui-library';
import { Partner } from 'models/portal-generated';

import { formatShortDate } from 'utilities/dates';

import styles from './SearchResult.module.scss';

type SearchResultProps = {
  partner: Partner;
  onClick?: (policyId: number) => void;
};

export const SearchResult = ({ partner, onClick }: SearchResultProps) => {
  const { t } = useTranslation('header');

  const fullName = `${partner.lastname}, ${partner.firstname}`;
  const { holderPolicies, insuredPolicies } = partner;
  const policyId = holderPolicies?.[0] || insuredPolicies?.[0];
  const isHolder = (holderPolicies || []).length > 0;
  const isInsuredPerson = (insuredPolicies || []).length > 0;

  const formattedPolicyId = (() => {
    const idAsString = String(policyId);
    return `${idAsString}`;
  })();

  const handleOnClick = () => {
    policyId && onClick?.(policyId);
  };

  return (
    <div
      className={classNames('p-8', styles.container)}
      onClick={handleOnClick}
      data-testid="SearchResult"
    >
      <div className={styles.left}>
        <Typography variant="bodySMRegular">{formattedPolicyId}</Typography>
        <Typography variant="bodySMRegular">{fullName}</Typography>
        <Typography variant="bodySMRegular" className="text-neutral-800">
          {formatShortDate(partner.birthDate || '')}
        </Typography>
      </div>
      <section className={styles.right}>
        <Chip
          onClick={handleOnClick}
          className={classNames(styles.contractChip, 'm-r-16')}
          color={Props.BaseColors.Secondary.S600}
        >
          <Icon className="m-r-8" icon={<FileBookmarkIcon />} />
          <Typography variant="bodyXSBold">{t('search.contract')}</Typography>
        </Chip>
        {isHolder && (
          <Chip
            onClick={handleOnClick}
            className={classNames(styles.personChip, 'm-r-16')}
            color={Props.BaseColors.Secondary.S600}
          >
            <Icon className="m-r-8" icon={<UserShieldIcon />} />
            <Typography variant="bodyXSBold">{t('search.holder')}</Typography>
          </Chip>
        )}
        {isInsuredPerson && (
          <Chip
            onClick={handleOnClick}
            className={classNames(styles.personChip, 'm-r-16')}
            color={Props.BaseColors.Secondary.S600}
          >
            <Icon className="m-r-8" icon={<UserSingleIcon />} />
            <Typography variant="bodyXSBold">{t('search.insuredPerson')}</Typography>
          </Chip>
        )}
      </section>
    </div>
  );
};
