export const PyramidPictogram = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M30.192 29.6401L16.36 8.89114V5.36014H20.869L19.009 3.50014L20.869 1.64014H15.64V8.89114L1.807 29.6401H1V30.3611H31V29.6401H30.192ZM16.36 2.36014H19.131L17.991 3.50014L19.131 4.64014H16.36V2.36014ZM2.673 29.6401L8 21.6491L13.327 29.6391L2.673 29.6401ZM14.193 29.6401L8.433 21.0001L12 15.6491L21.327 29.6401H14.193ZM22.192 29.6401L12.433 15.0001L16 9.64914L29.327 29.6401H22.192Z"
        fill="currentColor"
      />
    </svg>
  );
};
