import { Dispatch, Fragment, ReactElement, SetStateAction } from 'react';

import { Question, QuestionTypeEnum } from 'models/extension-generated';

import { QuestionWrapperProps } from '../HealthQuestions';
import { ClosedQuestion } from './ClosedQuestions';

interface QuestionsListProps {
  questions: Question[];
  partnerId: string;
  isUnder15: boolean;
  renderIntQuestion: (key: string, props?: QuestionWrapperProps | undefined) => ReactElement;
  renderTextQuestion: (key: string, props?: QuestionWrapperProps | undefined) => ReactElement;
  renderNumberQuestion: (key: string, props?: QuestionWrapperProps | undefined) => ReactElement;
  setIsDiagnosisModalOpen: Dispatch<SetStateAction<boolean>>;
}

export function QuestionsList({
  questions,
  partnerId,
  isUnder15,
  renderIntQuestion,
  renderTextQuestion,
  renderNumberQuestion,
  setIsDiagnosisModalOpen
}: QuestionsListProps) {
  return (
    <>
      {questions.map((question) => (
        <Fragment key={question.id}>
          {question.questionType === QuestionTypeEnum.CLOSED_QUESTION && (
            <ClosedQuestion
              healthQuestionId={question.id}
              partnerId={partnerId}
              onChipClick={(value) => {
                if (value) {
                  setIsDiagnosisModalOpen(true);
                }
              }}
              onYesNoClick={(value) => {
                if (value) {
                  setIsDiagnosisModalOpen(value);
                }
              }}
            />
          )}
          {question.questionType === QuestionTypeEnum.INT_QUESTION &&
            renderIntQuestion(question.id)}
          {question.questionType === QuestionTypeEnum.TEXT_QUESTION &&
            renderTextQuestion(question.id)}
          {question.questionType === QuestionTypeEnum.NUMBER_QUESTION &&
            renderNumberQuestion(question.id, {
              isDisabled: isUnder15
            })}
        </Fragment>
      ))}
    </>
  );
}
