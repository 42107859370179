import { PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from '../../WizardLayout.module.scss';

export const RightPanel = ({ children }: PropsWithChildren) => {
  return (
    <aside className={classNames(styles.rightPanel, 'hidden [@media(width>992px)]:block')}>
      {children}
    </aside>
  );
};
