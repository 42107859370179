import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { onWizardScrollHandler } from 'utilities/scroll';

import styles from '../../WizardLayout.module.scss';

export const WIZARD_MAIN_ID = 'wizard-main';

export const Main = forwardRef((props: PropsWithChildren, ref: ForwardedRef<HTMLElement>) => {
  return (
    <main id={WIZARD_MAIN_ID} ref={ref} onScroll={onWizardScrollHandler} className={styles.main}>
      {props.children}
    </main>
  );
});
