import { PropsWithChildren, ReactNode } from 'react';

import Typography from '@mui/material/Typography';

import { Form, Radio } from 'lkh-portal-ui-library';
import { partnerField, RecursiveKeyOf } from 'models';
import { Partner } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../context/HealthInsuranceContext';
import { useProvidedTariffs } from '../../contexts/TariffsContext';
import { getOptions } from './helpers';
import { Error } from 'pages/PrivateHealthInsurance/components/Error';

export type OptionSelect = {
  label: string;
  value: boolean;
};

type OptionalTariffProps = PropsWithChildren & {
  title: ReactNode;
  selected: OptionSelect | undefined;
  noAnswerText?: string;
  componentKey: RecursiveKeyOf<Partner>;
  onChange?: (selection: boolean) => void;
};

export const OptionalSection = ({
  children,
  selected,
  title,
  noAnswerText,
  componentKey,
  onChange
}: OptionalTariffProps) => {
  const { reducer } = useHealthInsuranceContext();
  const { section, partnerId, updateHealthQuestions } = useProvidedTariffs();
  const { hasError, message } = Form.hooks.useFormComponent(partnerField(partnerId, componentKey));

  const handleOnChange = (value: boolean): void => {
    if (value === false) {
      reducer.clearTariffs({ partnerId, section });
    }

    reducer.updateValue({
      key: partnerField(partnerId, componentKey),
      value
    });

    // always update health questions even if agent sets everything to false and does not add any tariff after all
    updateHealthQuestions();
    onChange?.(value);
  };

  const options = getOptions(noAnswerText);

  return (
    <Form.Section>
      <div className="grid-mx-res grid-my-res">
        <div className="mb-[0px] s:mb-[16px]">
          <Typography variant="bodyLGBold">{title}</Typography>
        </div>

        <div>
          <Radio
            className="ml-[16px]"
            error={hasError}
            options={options}
            selected={selected}
            onChange={(opt) => handleOnChange(opt.value as boolean)}
          />
          <div className="mt-[4px]">{hasError && <Error>{message}</Error>}</div>
        </div>
        {selected?.value && <div>{children}</div>}
      </div>
    </Form.Section>
  );
};
