import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { formsFilter2012Options } from 'models';
import { CriteriaType, FormCategory, SearchOperationEnum } from 'models/portal-generated';

import { useFormsPageContext } from '../../context';
import { FormTabs } from '../FormTabs';
import { TYPE_DOCUMENTS_FILTER, TYPE_GROUP_ID } from './constatnts';
import MultiSelectAll from 'pages/Contracts/components/Filter/Multiselect';
import { DropdownOption } from 'pages/Contracts/types';

type FormsFilterProp = {
  typeOptions: DropdownOption[];
};

export const FormsFilter = ({ typeOptions }: FormsFilterProp) => {
  const { t } = useTranslation('formsFilter');
  const { filters, name, code, category, setCategory, setCode, setName } = useFormsPageContext();
  const types: Array<string> =
    filters.active.find((filter) => filter.groupId === TYPE_GROUP_ID)?.criteria?.[0].value || [];
  const typesValue = types
    .map((type) => {
      return typeOptions.find((option) => option.value === type) as DropdownOption;
    })
    .filter(Boolean);

  const documents2012 =
    filters.active.find((filter) => filter.groupId === TYPE_DOCUMENTS_FILTER)?.criteria || [];
  const documents2012Value = documents2012.map((criteria) => {
    return formsFilter2012Options.find(
      (option) => option.value === criteria.value
    ) as DropdownOption;
  });

  const disabledFilterReset =
    category === FormCategory.LKH &&
    typesValue.length === 0 &&
    documents2012Value.length === 0 &&
    name == '' &&
    code == '';

  function resetFilters(category: FormCategory) {
    filters.reset();
    setCategory(category);
    setCode('');
    setName('');
  }

  function handleSelection(value: DropdownOption[]) {
    if (value.length === 0) {
      filters.remove(TYPE_GROUP_ID);
    } else {
      const filterCriteria: Array<string> = value.map((option) => option.value as string);
      filters.set([
        {
          groupKey: 'type',
          groupId: TYPE_GROUP_ID,
          criteriaType: CriteriaType.OR,
          criteria: [
            {
              key: 'type',
              operation: SearchOperationEnum.IN,
              value: filterCriteria
            }
          ]
        }
      ]);
    }
  }

  function handle2012DocumentsFilterSelection(value: DropdownOption[]) {
    if (value.length === 0) {
      filters.remove(TYPE_DOCUMENTS_FILTER);
    } else {
      const filterCriteria: Array<string> = value.map((option) => option.value as string);
      filters.set([
        {
          groupKey: 'documents2012Filter',
          groupId: TYPE_DOCUMENTS_FILTER,
          criteriaType: CriteriaType.OR,
          criteria: filterCriteria.map((val) => {
            return {
              key: 'isInventoryTariffUntil2012',
              operation: SearchOperationEnum.EQUALS,
              value: val
            };
          })
        }
      ]);
    }
  }

  return (
    <>
      <div className="w-full border-solid border-b-[1px] border-l-0 border-r-0 border-t-0 border-b-text-20">
        <FormTabs category={category} resetFilters={resetFilters} />
      </div>
      <div className="flex justify-end">
        <Button
          variant="outlined"
          size="small"
          className="float-right"
          onClick={() => {
            resetFilters(FormCategory.LKH);
            filters.reset();
          }}
          disabled={disabledFilterReset}
        >
          {t('resetFilter')}
        </Button>
      </div>
      <div className="w-full grid grid-cols-3 s:grid-cols-5 gap-[24px]">
        <MultiSelectAll
          options={formsFilter2012Options}
          onChange={handle2012DocumentsFilterSelection}
          value={documents2012Value}
          label={t('documents2012Filter')}
        />
        <MultiSelectAll
          options={typeOptions}
          onChange={handleSelection}
          value={typesValue}
          label={t('type')}
        />
        <TextField
          fullWidth
          value={name}
          label={t('name')}
          onChange={(value) => setName(value.target.value)}
        />
        <TextField
          fullWidth
          value={code}
          label={t('code')}
          onChange={(value) => setCode(value.target.value)}
        />
      </div>
    </>
  );
};
