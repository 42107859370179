import { Question, QuestionsService, Tariff } from 'models/extension-generated';
import { Criteria, CriteriaType, SearchOperationEnum } from 'models/portal-generated';

import { calculateAge } from 'utilities/dates';

const getDateBirthCriteria = (birthDate: string, insuranceStart: string) => {
  const age = calculateAge(birthDate, insuranceStart);
  return age >= 6
    ? { key: 'tag', value: 'ADULT', operation: SearchOperationEnum.LIKE }
    : { key: 'tag', value: 'CHILD', operation: SearchOperationEnum.LIKE };
};

const getRequestBody = ({
  selectedTariffsIds,
  birthDate,
  insuranceStart
}: {
  selectedTariffsIds: string[];
  birthDate: string;
  insuranceStart: string;
}): { requestBody: Criteria } => {
  const searchCriteria = [
    {
      criteriaType: CriteriaType.AND,
      criteria: [
        {
          key: 'tariffs.name',
          operation: SearchOperationEnum.IN,
          value: selectedTariffsIds
        },
        {
          key: 'product',
          value: 'HEALTH',
          operation: SearchOperationEnum.LIKE
        }
      ]
    }
  ];
  // add birth criteria
  searchCriteria[0].criteria.push(getDateBirthCriteria(birthDate, insuranceStart));

  return {
    requestBody: {
      page: 0,
      size: 0,
      sort: [{ field: 'orderNo', order: 'ASCENDING' }],
      searchCriteria
    }
  };
};

const getQuestionsByCriteria = async ({
  tariffs,
  birthDate,
  insuranceStart
}: {
  tariffs: Array<Tariff>;
  birthDate: string;
  insuranceStart: string;
}): Promise<Array<Question>> => {
  const selectedTariffsIds = tariffs.map((tariff: Tariff) => tariff.id);
  const requestBody = getRequestBody({
    selectedTariffsIds,
    birthDate,
    insuranceStart
  });
  const response = await QuestionsService.getQuestionsByCriteria(requestBody);
  return response.questions;
};

export { getQuestionsByCriteria };
