import { partnerField } from 'models';
import { Question } from 'models/extension-generated';

/**
 * Get the text of a question based on its key.
 *
 * @param {Array<Question>} questions - An array of Question objects.
 * @param {string} key - The key of the question to retrieve.
 * @param {boolean} withOrder - Whether to include the question order number. Defaults to true.
 * @returns {string} The text of the question.
 */
export const getQuestionText =
  (questions: Array<Question>) =>
  (key: string, withOrder = true): string => {
    const translation = questions?.find(({ id }) => id === key)?.text || key;
    if (withOrder) {
      return `${getQuestionOrderNum(questions, key)}. ${translation}`;
    }
    return translation;
  };

/**
 * Get the order number of a question based on its key.
 *
 * @param {Array<Question>} questions - An array of Question objects.
 * @param {string} key - The key of the question to retrieve the order number for.
 * @returns {string} The order number of the question.
 */
function getQuestionOrderNum(questions: Array<Question>, key: string): string {
  const orderNum = questions.find(({ id }) => id === key)?.orderNo || key;
  return orderNum.toString();
}

/**
 * Get the label of a question based on its key.
 *
 * @param {Array<Question>} questions - An array of Question objects.
 * @param {string} key - The key of the question to retrieve the label for.
 * @returns {string | undefined} The label of the question, or undefined if not found.
 */
export const getQuestionLabel =
  (questions: Array<Question>) =>
  (key: string): string | undefined => {
    const label = questions.find(({ id }) => id === key)?.label;
    return label;
  };

/**
 * Get the unit of a question based on its key.
 *
 * @param {Array<Question>} questions - An array of Question objects.
 * @param {string} key - The key of the question to retrieve the unit for.
 * @returns {string | undefined} The unit of the question, or undefined if not found.
 */
export const getQuestionUnit =
  (questions: Array<Question>) =>
  (key: string): string | undefined => {
    const unit = questions.find(({ id }) => id === key)?.unit || key;
    return unit;
  };

/**
 * Get the maximum value of a question based on its key.
 *
 * @param {Array<Question>} questions - An array of Question objects.
 * @param {string} key - The key of the question to retrieve the maximum value for.
 * @returns {number | undefined} The maximum value of the question, or undefined if not found.
 */
export const getQuestionMaxValue =
  (questions: Array<Question>) =>
  (key: string): number | undefined => {
    const maxValue = questions.find(({ id }) => id === key)?.maxValue;
    return maxValue;
  };

/**
 * Get the question key for a partner and key combination.
 *
 * @param {string} partnerId - The partner's ID.
 * @param {string} key - The key of the question.
 * @returns {string} The question key.
 */
export const getQuestionKey = (partnerId: string, key: string): string => {
  return partnerField(partnerId, `applicationInformation.health[${key}].answer`);
};
