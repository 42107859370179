import { useTranslation } from 'react-i18next';

import { partnerField, PartnerIdProp } from 'models';
import { PreInsuranceTypeEnum } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { usePreInsurances } from '../../hooks/usePreInsurance';
import { PreInsuranceContextMenu } from '../PreInsuranceContextMenu';
import { PreInsuranceStartEndForm } from '../PreInsuranceStartEndForm';
import { PreInsuranceTypeForm } from '../PreInsuranceTypeForm';
import { PreInsuranceWrapper } from '../PreInsuranceWrapper';

export const PrivateHealthPreInsurance = ({ partnerId }: PartnerIdProp) => {
  const TYPE = PreInsuranceTypeEnum.HEALTH;
  const TOGGLE = `hasHealthPreInsurance`;

  const { t } = useTranslation('wizardGeneral');
  const { state, reducer } = useHealthInsuranceContext();

  const { preInsurances, preInsuranceKey } = usePreInsurances(state.model, partnerId, TYPE);

  const handleIsPublicChange = (isPublic: boolean) => {
    // reset dependant values when switching from public to private
    if (isPublic === true) {
      reducer.updateMultipleValues([
        {
          key: partnerField(partnerId, 'applicationInformation.fundsFromGZ'),
          value: ''
        },
        {
          key: partnerField(partnerId, 'applicationInformation.comprehensiveHealthTransferValue'),
          value: ''
        }
      ]);
    }
  };

  return (
    <PreInsuranceWrapper type={TYPE} partnerId={partnerId} preInsuranceKey={TOGGLE}>
      {preInsurances.map((preInsurance) => (
        <section key={preInsurance.id}>
          <PreInsuranceContextMenu
            title={t(`longTerm.preInsurance.title`)}
            toggleKey={TOGGLE}
            partnerId={partnerId}
            preInsurance={preInsurance}
          />
          <PreInsuranceTypeForm
            insuranceCompanyKey={preInsuranceKey(preInsurance.id, 'insuranceCompany')}
            privateInsuranceCompanyKey={preInsuranceKey(preInsurance.id, 'privateInsuranceCompany')}
            isPublicKey={preInsuranceKey(preInsurance.id, 'isPublic')}
            onIsPublicChange={handleIsPublicChange}
          />
          <PreInsuranceStartEndForm
            startDateKey={preInsuranceKey(preInsurance.id, `startDate`)}
            endDateKey={preInsuranceKey(preInsurance.id, `endDate`)}
            ongoingKey={preInsuranceKey(preInsurance.id, `ongoing`)}
          />
        </section>
      ))}
    </PreInsuranceWrapper>
  );
};
