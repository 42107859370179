import axios from 'axios';

import keycloak from './keycloak';

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers = {
      authorization: `Bearer ${keycloak.token}`
    };
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
export { axios };
