import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';

type AgentPickerLoadingDialogProps = {
  isDialogOpen: boolean;
  cancelAction: () => void;
};

export const AgentPickerLoadingDialog = ({
  isDialogOpen,
  cancelAction
}: AgentPickerLoadingDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'navigation.agentPicker.agentPickerDialog'
  });
  const [isClicked, setIsClicked] = useState(false);

  return (
    <Dialog PaperProps={{ maxwidth: 325 }} open={isDialogOpen} disableScrollLock>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex flex-col mb-[8px]">
          <div className="flex justify-center mb-[32px]">
            <CircularProgress color="primary" size={24} />
          </div>
          <div>
            <DialogContentText>
              <span className="flex text-sm font-normal leading-5 text-text-60">
                {t('loading.description')}
              </span>
            </DialogContentText>
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            fullWidth
            size="large"
            variant="text"
            color="primary"
            disabled={isClicked}
            onClick={() => {
              setIsClicked(true);
              cancelAction();
            }}
          >
            {t('loading.cancel')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
