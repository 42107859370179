import { CellClickedEvent, ColDef } from 'ag-grid-community';
import i18n from 'i18next';

import { TableTooltips } from '../../TableTooltip';
import { RowFormMetadata } from '../FormsData';
import {
  DownloadIconGroup,
  DownloadIconGroupProps
} from 'components/DownloadIconGroup/DownloadIconGroup';
import { downloadForm } from 'utilities/fetchDocument';

const handleCellClick = async (event: CellClickedEvent<RowFormMetadata>) => {
  if (event.data) {
    const { showDownload } = event.data;
    const [formId] = showDownload;
    const doc = await downloadForm({ id: formId || '' });
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank', 'noreferrer');
  }
};

export const columnDefs: ColDef[] = [
  {
    field: 'type',
    resizable: true,
    flex: 1.5,
    headerName: i18n.t('table.type', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-bold text-text-80',
    onCellClicked: handleCellClick
  },
  {
    field: 'name',
    resizable: true,
    flex: 4,
    headerName: i18n.t('table.name', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    tooltipField: 'name',
    tooltipComponent: TableTooltips.DocumentName,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80',
    onCellClicked: handleCellClick
  },
  {
    field: 'id',
    headerName: i18n.t('table.code', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    resizable: true,
    sortable: true,
    flex: 2,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80',
    onCellClicked: handleCellClick
  },
  {
    field: 'version',
    flex: 1,
    resizable: true,
    headerName: i18n.t('table.version', { ns: 'formsPage' }),
    headerClass:
      'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    sortable: true,
    cellStyle: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80',
    onCellClicked: handleCellClick
  },
  {
    headerName: '',
    headerClass:
      'border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
    flex: 1,
    field: 'showDownload',
    cellRenderer: (params: { value: DownloadIconGroupProps['documentInfo'] }) => (
      <span className="ag-action-button">
        <DownloadIconGroup
          documentInfo={params.value}
          downloadCallback={{ type: 'DownloadFormFunction', func: downloadForm }}
        />
      </span>
    ),
    cellStyle: { display: 'flex', justifyContent: 'center', alignItems: 'center' },
    cellClass: 'text-xs font-normal text-text-80'
  }
];
