import { ApplicationStatusEnum } from 'models/extension-generated';

import { ActionFunction, ApplicationsState } from '../types';

export const setStatus: ActionFunction<Array<ApplicationStatusEnum>> = (
  state: ApplicationsState,
  payload
) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      statuses: payload
    }
  };
};
