import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export const NoResults = ({ onResetSearch }: { onResetSearch: () => void }) => {
  const { t } = useTranslation('header');
  return (
    <div className="flex items-center justify-between">
      <Typography variant="bodySMRegular">{t('search.noResults')}</Typography>
      <Button variant="text" onClick={onResetSearch}>
        {t('search.resetSearch')}
      </Button>
    </div>
  );
};
