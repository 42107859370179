import { ActionMenuCell } from './ActionMenuCell';
import { AdditionalRiskCell } from './AdditionalRiskCell';
import { EntitlementCell } from './EntitlementCell';
import { HighMoneyAmount } from './HighMoneyAmount';
import { Price } from './Price';
import { Product } from './Product';
import { TariffLabel } from './TariffLabel';

export const Cells = {
  Price,
  TariffLabel,
  Product,
  ActionMenuCell,
  EntitlementCell,
  HighMoneyAmount,
  AdditionalRiskCell
};
