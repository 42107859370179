import { Chip } from '@mui/material';

import { formatPriceAsTextWithoutCurrency } from 'utilities/string';

export const HighMoneyAmount = ({ highMoneyAmount }: { highMoneyAmount?: number }) => {
  return (
    <>
      {highMoneyAmount && (
        <Chip
          label={formatPriceAsTextWithoutCurrency(highMoneyAmount)}
          className="rounded-[8px] text-[12px] text-highlight-80 bg-none border border-solid font-bold border-highlight-80"
          variant="filled"
          color="primary"
        />
      )}
    </>
  );
};
