import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { formatDateAndTime } from 'utilities/dates';

import styles from './NewsCard.module.scss';

type NewsCardProps = {
  id: string | undefined;
  createdAt: string | undefined;
  headline: string | undefined;
  image: string | undefined;
  text: string | TrustedHTML | undefined;
};

export function NewsCard({ id, createdAt, headline, image, text }: NewsCardProps) {
  const createMarkup = (maxSize = 160) => {
    return { __html: text?.toString().slice(0, maxSize) || '' };
  };

  const spanRef = useRef<HTMLSpanElement>(null);
  const headlineRef = useRef<HTMLDivElement>(null);
  const [maxLines, setMaxLines] = useState(2);
  const [headlineMaxLines, setHeadlineMaxLines] = useState(2);
  const imagePath = image ? image : '/images/defaultImage.png';

  const handleResize = () => {
    if (spanRef.current) {
      const contentLineHeight = spanRef.current.clientHeight || 0;
      const contentFontSize = parseInt(window.getComputedStyle(spanRef.current).fontSize);
      const newMaxLines = Math.floor(contentLineHeight / contentFontSize) * 2;
      setMaxLines(newMaxLines > 2 ? 2 : newMaxLines);
    }

    if (headlineRef.current) {
      const headlineLineHeight = headlineRef.current.clientHeight || 0;
      const headlineFontSize = parseInt(window.getComputedStyle(headlineRef.current).fontSize);
      const newHeadlineMaxLines = Math.floor(headlineLineHeight / headlineFontSize) * 2;
      setHeadlineMaxLines(newHeadlineMaxLines > 2 ? 2 : newHeadlineMaxLines);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const spanStyle: React.CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: maxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };

  const headlineStyle: React.CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: headlineMaxLines,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden'
  };

  const fallbackImage = '/images/default-news.png';

  return (
    <div className={`${styles.card} flex flex-col justify-between h-full`}>
      <div>
        <div className="p-x-4 p-t-20 flex justify-center items-center">
          <img
            src={imagePath || fallbackImage}
            alt={headline}
            className="h-40 w-[212.5px] object-cover rounded-md mt-4"
          />
        </div>

        <div className={`${styles.cardContent} flex flex-col`}>
          <Typography variant="bodySMRegular" className="text-text-60 pb-[10px]">
            {createdAt && formatDateAndTime(createdAt)}
          </Typography>

          <div>
            <div>
              <Typography
                variant="bodyLGBold"
                ref={headlineRef}
                style={headlineStyle}
                className="text-navigation-80 font-bold"
              >
                {headline}
              </Typography>
            </div>
            <div className="m-0">
              <Typography
                variant="bodySMRegular"
                className="text-text-80 m-0"
                style={spanStyle}
                dangerouslySetInnerHTML={createMarkup()}
                ref={spanRef}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.link}>
        <Typography variant="bodyMDRegularLink">
          <Link to={'/neuigkeiten/' + id}>mehr</Link>
        </Typography>
      </div>
    </div>
  );
}
