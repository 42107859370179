import { Loader, LoaderType } from 'lkh-portal-ui-library';

import { useHealthInsuranceContext } from '../../../context/HealthInsuranceContext';
import {
  AmbulantTariffs,
  CareAllowanceTariffs,
  DailyHospitalTariffs,
  DentalTariffs,
  ObligatoryCareTariffs,
  OptionalTariffs,
  SickBenefitTariffs,
  StationaryTariffs,
  TravelTariffs
} from './sections';
import { useTariffQuery } from './useTariffQuery';

export const TariffQuestions = () => {
  const { partnerId } = useHealthInsuranceContext();
  const { isLoading, tariffs } = useTariffQuery(partnerId);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center">
        <Loader type={LoaderType.Circular} />
      </div>
    );
  }

  return (
    <>
      <AmbulantTariffs tariffs={tariffs} />
      <StationaryTariffs tariffs={tariffs} />
      <DentalTariffs tariffs={tariffs} />
      <SickBenefitTariffs tariffs={tariffs} />
      <DailyHospitalTariffs tariffs={tariffs} />
      <CareAllowanceTariffs tariffs={tariffs} />
      <TravelTariffs tariffs={tariffs} />
      <OptionalTariffs tariffs={tariffs} />
      <ObligatoryCareTariffs tariffs={tariffs} />
    </>
  );
};
