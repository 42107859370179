import { createRef, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';

import { Policy, PolicyService } from '../../../../models/portal-generated';
import { DocumentTypeEnum, InsuredPersonDetailProps } from '../../../../models/view360models';
import { CertificateIcon, Icon, Loader, LoaderType } from 'lkh-portal-ui-library';
import { RealmRole } from 'models';

import { formatShortDate } from '../../../../utilities/dates';
import { createNavigation, View360NavigationEnum } from '../../components/DocumentNavigation';
import { View360ContextProvider } from '../../context/View360Context';
import { formatAddress, formatEnum, formatName, optionalValue } from '../../utils/formatters';
import { View360Page } from '../../View360Page';
import { Status } from './components/Status';
import { TariffsTable } from './components/TariffsTable';
import { useConfigContext } from 'contexts/ConfigContext';
import { AccordionHandle } from 'pages/Contract360Page/components/SectionAccordion';

type FetchPolicyRequestBody = {
  policyId: number;
  revisionId?: number;
};

/**
 * 360 View for an existing contract (Vertrag)
 *
 * This component simply maps the lifestream data structure to a common data-model used by a 360 view
 */
export const View360Contract = () => {
  const { contractId, revisionId } = useParams();
  const { config } = useConfigContext();
  const { keycloak } = useKeycloak();
  const [contract, setContract] = useState<Policy>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasAppropriateRealmRole =
    keycloak.hasRealmRole(RealmRole.MASTER_AGENT) ||
    keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);

  const refetchContract = async () => {
    if (contractId) {
      setIsLoading(true);
      const requestBody: FetchPolicyRequestBody = {
        policyId: Number(contractId)
      };

      if (revisionId) {
        requestBody.revisionId = Number(revisionId);
      }

      try {
        const policyResponse = await PolicyService.getPolicyById(requestBody);
        setContract(policyResponse);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Effect responsible for a loading od the data
   */
  useEffect(() => {
    refetchContract();
  }, [contractId, revisionId]);

  if (!contract || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader type={LoaderType.Circular} />
      </div>
    );
  }

  const {
    policyHolder,
    mainContact: policyHolderResidence,
    mainBankDetails: paymentContributorAccount
  } = contract;

  // TODO this is not correct as contributor may be different (currently, in v9.3.4 it's the only way)
  const paymentContributor = contract.policyHolder;

  // TODO there may be multiple accounts associated, it's to be further discussed,
  // however, should be always only 1 contributor and the primary account should be based on "mainBankDetailsId"
  // this is not in DTO (v9.3.2) but may be added later; in case it is not, we should pick first entry
  const paymentContributorResidence = paymentContributor.contactList?.[0];

  return (
    <View360ContextProvider
      enableUpload
      enableRevisions
      navigation={createNavigation({
        insuredPersons: (contract.insuredPartners || [])?.map((p, i) => {
          const label = (
            <div translate="no">
              {optionalValue(p.partner, formatName)}
              {p.partner?.id === policyHolder?.id && (
                <Icon icon={<CertificateIcon />} className="m-l-8 text-logo-500" />
              )}
            </div>
          );
          return {
            label,
            key: `${View360NavigationEnum.InsuredPerson}-${i + 1}`,
            ref: createRef<AccordionHandle>()
          };
        }),
        includeContractItems: true,
        hasAppropriateRealmRole: hasAppropriateRealmRole
      })}
      data={{
        id: contractId || '',
        status: <Status status={contract.status} />,
        revisions: contract.revisions,
        currentRevision: {
          revision: contract.revision,
          policyId: contractId || '',
          validFrom: contract.validFrom || ''
        },
        documentType: DocumentTypeEnum.Contract,
        contract: {
          amount: contract.monthlyAmount,
          insuredPersonsCount: contract.insuredPartnersCount,
          product: contract.product,
          startDate: contract.validFrom
        },
        documents: {
          documents: []
        },
        reminders: {
          reminders: (contract.reminders || []).map((reminder) => ({
            amount: reminder.owedAmount,
            date: reminder.reminderSince,
            level: reminder.level,
            levelText: reminder.levelText
          }))
        },
        policyHolder: {
          // [Personal Data]
          name: optionalValue(policyHolder, formatName) as string,
          firstName: policyHolder?.firstname,
          lastName: policyHolder?.lastname,
          birthDate: policyHolder?.birthDate,
          address: formatAddress(policyHolderResidence),
          salutation: formatEnum(policyHolder?.salutation, 'SalutationEnum', 'contractsEnums'),
          academicTitle: policyHolder?.academicTitle,
          maritalStatus: policyHolder?.maritalStatus, // [NOT IN LS]
          // [Employment Data]
          professionalPosition: formatEnum(
            policyHolder?.jobType,
            'EmploymentGroupEnum',
            'contractsEnums'
          ), // berufliche grouppe
          currentOccupation: formatEnum(
            policyHolder?.workActivity,
            'ProfessionEnum',
            'contractsEnums'
          ),
          // employer?: policyHolder?. [NOT IN LS]
          // training start/end dates are also [NOT IN LS]
          // [Address]
          // isLivingInGermany: policyHolderResidence.??? [NOT IN LS]
          street: policyHolderResidence?.street,
          houseNumber: policyHolderResidence?.houseNumber,
          postalCode: policyHolderResidence?.postalCode,
          city: policyHolderResidence?.city,
          country: config?.countries?.find(
            (country) => country.code == policyHolderResidence?.country
          )?.name,
          // hasForeignResidence: policyHolder?.??? [NOT IN LS]
          // foreignCountry: policyHolder?.??? [NOT IN LS]
          // [Contact]
          email: policyHolderResidence?.email,
          phone: policyHolderResidence?.phone
          // we have also fax, mobile but these are not in the spec (figma)
          // [Legal]
          // hasLegalRepresentative: policyHolder?.??? [NOT IN LS]
          // acceptsSoleCustody: policyHolder?.??? [NOT IN LS]
          // acceptedMarketingStreams: policyHolder?.??? [NOT IN LS]
        },
        paymentContributor: {
          // [Personal Data]
          name: optionalValue(paymentContributor, formatName) as string,
          firstName: paymentContributor?.firstname,
          lastName: paymentContributor?.lastname,
          birthDate: paymentContributor?.birthDate,
          address: formatAddress(paymentContributorResidence),
          salutation: formatEnum(
            paymentContributor?.salutation,
            'SalutationEnum',
            'contractsEnums'
          ),
          academicTitle: paymentContributor?.academicTitle,
          // [Address]
          street: paymentContributorResidence?.street,
          houseNumber: paymentContributorResidence?.houseNumber,
          postalCode: paymentContributorResidence?.postalCode,
          city: paymentContributorResidence?.city,
          country: config?.countries?.find(
            (country) => country.code == paymentContributorResidence?.country
          )?.name,
          // hasForeignResidence: paymentContributor?.??? [NOT IN LS]
          // foreignCountry: paymentContributor?.??? [NOT IN LS]
          // [Bank/Payment]
          isPolicyHolder: true, // TODO check the contributor comment above; NOW it is true although it may not be like this
          iban: paymentContributorAccount?.iban,
          bankName: paymentContributorAccount?.bankName,
          bic: paymentContributorAccount?.bankIdentifierCode
          // hasPaymentAuthorization: paymentContributorAccount.??? [NOT IN LS] but LS has something similar, but it is enum
        },
        insuredPersons: (contract.insuredPartners || [])?.map(
          (person, i): InsuredPersonDetailProps => {
            const partner = person.partner;
            const tariffs = person.tariffs || [];
            const residenceAddress = partner?.contactList?.[0]; // TODO check if this is correct
            return {
              // [Utils]
              order: i + 1,
              tariffTable: <TariffsTable tariffs={tariffs} />,
              isPolicyHolder: policyHolder?.id === partner?.id,
              // [Personal data]
              name: optionalValue(partner, formatName) as string,
              firstName: partner?.firstname,
              lastName: partner?.lastname,
              birthDate: partner?.birthDate,
              address: formatAddress(residenceAddress),
              salutation: formatEnum(partner?.salutation, 'SalutationEnum', 'contractsEnums'),
              academicTitle: partner?.academicTitle,
              nationality: partner?.nationality,
              // [Employment Data]
              professionalPosition: formatEnum(
                partner?.jobType,
                'EmploymentGroupEnum',
                'contractsEnums'
              ),
              currentOccupation: formatEnum(
                partner?.workActivity,
                'ProfessionEnum',
                'contractsEnums'
              ),
              // employer?: partner?.??? [NOT IN LS]
              // trainingStart?: partner?.??? [NOT IN LS]
              // trainingEnd?: partner?.?? [NOT IN LS]
              // [Address]
              // isLivingInGermany: residenceAddress?.??? [NOT IN LS]
              street: residenceAddress?.street,
              houseNumber: residenceAddress?.houseNumber,
              postalCode: residenceAddress?.postalCode,
              city: residenceAddress?.city,
              country: config?.countries?.find(
                (country) => country.code == residenceAddress?.country
              )?.name,
              // hasForeignResidence: partner?.??? [NOT IN LS]
              // foreignCountry: partner?.??? [NOT IN LS]
              // [Other]
              taxNumber: partner?.taxNumber,
              hasConsentToContact: person.consent,
              consentToContactDate: person.consentDate ? formatShortDate(person.consentDate) : '',
              // [Bedarf]
              // insuranceType: partner?.??? [NOT IN LS]
              // needSituation: partner?.??? [NOT IN LS]
              // insuranceStart: contract.validFrom [NOT IN LS]
              federalState: person.federalState,
              // aidClaim: partner?.??? [NOT IN LS]
              // hadInsuranceSince2012: partner?.??? [NOT IN LS]
              // [Refunds]
              contributionRefunds: person?.contributionRefunds
              // [NOT IN LS]
            };
          }
        ),
        accounts: {
          accounts: (contract.accounts || []).map((account) => ({
            type: account.tariffType,
            owner: optionalValue(account.owner, formatName) as string,
            collectionType: account.inkassoTypeText,
            bankName: account.bankName,
            iban: account.iban,
            bic: account.bankIdentifierCode,
            validFrom: account.validFrom
          }))
        },
        additionalContractInfo: {
          reasonForEdit: contract.editReason,
          lastUpdated: contract.editDate,
          startDate: contract.startDate,
          applicationDate: contract.applicationDate,
          sumTotal: contract.monthlyAmount,
          amountKV: contract.monthlyAmountKV,
          amountPPV: contract.monthlyAmountPPV,
          amountBRE: contract.monthlyAmountBRE,
          amountSTABZ: contract.monthlyAmountSTABZ,
          amountWithoutRZUBRE: contract.monthlyAmountWithoutRZUBRE
          // specialConditions: undefined, // [NOT IN LS]
          // specialConditionsExplanation: undefined// [NOT IN LS]
        },
        agent: {
          salutation: formatEnum(
            contract.agentData?.partner?.salutation,
            'SalutationEnum',
            'contractsEnums'
          ),
          academicTitle: contract.agentData?.partner?.academicTitle,
          firstName: contract.agentData?.partner?.firstname,
          lastName: contract.agentData?.partner?.lastname,
          birthDate: contract.agentData?.partner?.birthDate,
          agentId: contract.agentData?.agentId,
          provisions: (contract.agentData?.provisions || []).map((provision) => ({
            tariffType: provision.bvTariffType,
            comissionReferenceDate: provision.provisionDeadline,
            paymentFrequency: formatEnum(
              provision.provisionPaymentFrequency,
              'PaymentFrequencyEnum',
              'contractsEnums'
            ).toLocaleLowerCase(),
            rate: provision.provisionBvPercentage,
            amount: provision.provisionBvAmount
          }))
        },
        organization: {
          // organizationUnit: policyHolder?.organizationUnits[0].,
          companyName: undefined, // TODO mapping
          academicTitle: undefined, // TODO mapping
          firstName: undefined, // TODO mapping
          lastName: undefined, // TODO mapping
          street: undefined, // TODO mapping
          houseNumber: undefined, // TODO mapping
          postalCode: undefined, // TODO mapping
          city: undefined, // TODO mapping
          email: undefined, // TODO mapping
          phone: undefined // TODO mapping
        }
      }}
    >
      <View360Page policyId={contractId || ''} refetchData={refetchContract} />
    </View360ContextProvider>
  );
};
