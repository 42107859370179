import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { useAgentSession } from 'hooks/useAgentSession';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

export const AgentPickerButton = () => {
  const { state } = useHealthInsuranceContext();
  const { agentSession } = useAgentSession();

  return agentSession.hasServiceLKHInternalRole ? (
    <Chip
      variant="support"
      className="border-0 bg-navigation-70"
      label={
        <Typography className="block w-[84px] text-center" variant="bodySMBold">
          {state.model.agentId}
        </Typography>
      }
    />
  ) : null;
};
