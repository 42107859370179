import { createContext, forwardRef, HTMLAttributes, memo, ReactElement, useContext } from 'react';

import { ListChildComponentProps, VariableSizeList } from 'react-window';

import Checkbox from '@mui/material/Checkbox';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { useResetCache } from 'hooks/useResetCache/useResetCache';
import { DropdownOption } from 'pages/Contracts/types';

type ConstructedReactElementChild = [
  HTMLAttributes<HTMLLIElement>,
  DropdownOption,
  number,
  boolean,
  boolean | undefined
];
type ListRowData = [
  HTMLAttributes<HTMLLIElement>,
  DropdownOption,
  number,
  boolean,
  boolean | undefined
];

enum MULTIPLIER {
  Default = 1,
  Long = 3,
  Medium = 2,
  Short = 1.5
}

enum BreakpointAdjustment {
  LgUp = 1,
  MdUp = 1.2,
  SmUp = 1.3,
  XsUp = 1.4,
  Default = 1.5
}
const SM_UP_SIZE = 36;
const NOT_SM_UP_SIZE = 48;
const LISTBOX_PADDING = 8;
const RENDERED_ITEMS_COUNT = 8;

const renderRow = memo((props: ListChildComponentProps) => {
  const { data, index, style } = props;

  const [elProps, option, optionIndex, optionSelected, allOptionsSelected] = data[
    index
  ] as ListRowData;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING
  };

  return (
    <Typography
      key={`${option.value}-${optionIndex}`}
      variant="bodyLGRegular"
      component="li"
      style={inlineStyle}
      sx={{ whiteSpace: 'normal' }}
      {...elProps}
    >
      <Checkbox
        sx={{ mr: 1, p: 0 }}
        checked={allOptionsSelected ? allOptionsSelected : optionSelected}
      />
      <DarkTooltip
        title={Number(option?.label?.length) > 30 ? option.label : ''}
        placement="bottom"
        disableInteractive
        arrow
      >
        <Typography>{option.label}</Typography>
      </DarkTooltip>
    </Typography>
  );
});

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});
// Adapter for react-window
export const ListboxComponent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: ReactElement[] = [];
    (children as ReactElement[]).forEach((item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const xsUp = useMediaQuery(theme.breakpoints.up('xs'), { noSsr: true });
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), { noSsr: true });
    const mdUp = useMediaQuery(theme.breakpoints.up('md'), { noSsr: true });
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? SM_UP_SIZE : NOT_SM_UP_SIZE;

    const getChildSize = (child: ConstructedReactElementChild) => {
      const text = child[1];
      const length = text.label?.length || 0;

      const getMultiplierByLength = (length: number) => {
        if (length > 100) return MULTIPLIER.Long;
        if (length > 50) return MULTIPLIER.Medium;
        if (length > 20) return MULTIPLIER.Short;
        return MULTIPLIER.Default;
      };

      const multiplier = getMultiplierByLength(length);

      // Adjust size based on breakpoints
      let sizeAdjustment;

      if (lgUp) {
        sizeAdjustment = BreakpointAdjustment.LgUp; // Standard size for large screens
      } else if (mdUp) {
        sizeAdjustment = BreakpointAdjustment.MdUp; // Slightly bigger for medium screens
      } else if (smUp) {
        sizeAdjustment = BreakpointAdjustment.SmUp; // Even bigger for small screens
      } else if (xsUp) {
        sizeAdjustment = BreakpointAdjustment.XsUp; // Largest size for extra small screens
      } else {
        sizeAdjustment = BreakpointAdjustment.Default; // Default largest for xs and smaller screens
      }

      // Calculate final height by applying the adjustment
      return itemSize * multiplier * sizeAdjustment;
    };

    const getHeight = () => {
      if (itemCount > RENDERED_ITEMS_COUNT) {
        return RENDERED_ITEMS_COUNT * itemSize;
      }
      return itemData
        .map((item) => getChildSize(item as unknown as ConstructedReactElementChild))
        .reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * LISTBOX_PADDING}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index: number) =>
              getChildSize(itemData[index] as unknown as ConstructedReactElementChild)
            }
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  }
);
