import { t } from 'i18next';

import {
  ConfigResponse,
  NeedSituationEnum,
  Partner,
  QuestionTypeEnum
} from 'models/extension-generated';

import {
  formatBoolean,
  formatEnum,
  formatName,
  formatPercent,
  optionalValue
} from 'pages/Contract360Page/utils/formatters';
import { formatShortDate } from 'utilities/dates';
import { formatAddress } from 'utilities/string';

export type ParsedPartner = Partner & {
  fullName: string;
  dateOfBirth: string;
  formattedAddress: string;
  IBAN: string;
};

export const parsePartner = (partner: Partner): ParsedPartner => {
  return {
    ...partner,
    fullName: `${partner.firstname} ${partner.lastname}`,
    dateOfBirth: formatShortDate(partner.birthDate || ''),
    formattedAddress: formatAddress(partner.permanentResidence),
    IBAN: (partner.bankDetails?.iban || '').toLocaleUpperCase()
  };
};

export const getHolderFormat = (holder: ParsedPartner, config: ConfigResponse) => {
  const _holder = {
    // [Personal Data]
    name: optionalValue(holder, formatName) as string,
    firstName: holder.firstname,
    lastName: holder.lastname,
    birthDate: holder.birthDate,
    address: formatAddress(holder.permanentResidence),
    salutation: formatEnum(holder?.salutation, 'SalutationEnum', 'contractsEnums'),
    academicTitle: formatEnum(holder.title, 'TitleEnum', 'contractsEnums'),
    // nobleTitle: ??? [NOT IN DTO]
    maritalStatus: formatEnum(holder.maritalStatus, 'MaritalStatusEnum', 'contractsEnums'),
    // [Employment Data]
    professionalPosition: formatEnum(
      holder.applicationInformation?.employmentGroup,
      'EmploymentGroupEnum',
      'contractsEnums'
    ),
    currentOccupation: formatEnum(
      holder?.applicationInformation?.profession,
      'ProfessionEnum',
      'contractsEnums'
    ),
    employer: holder.applicationInformation?.employer,
    // [Address]
    isLivingInGermany: holder.livingInGermany,
    street: holder.permanentResidence.street,
    houseNumber: holder.permanentResidence.houseNumber,
    postalCode: holder.permanentResidence.postalCode,
    city: holder.permanentResidence.city,
    country: config?.countries?.find((country) => country.code == holder.permanentResidence.country)
      ?.name,
    hasForeignResidence: holder.hasLivedInForeignCountry,
    foreignCountry: config?.countries?.find((country) => country.code == holder.foreignCountry)
      ?.name,
    // [Contact]
    email: holder.permanentResidence.email,
    phone: holder.permanentResidence.phone,
    // [Bank details]
    iban: holder.IBAN,
    bankName: holder.bankDetails?.bankName,
    bic: holder.bankDetails?.bicNumber,
    hasPaymentAuthorization: holder.bankDetails?.hasPaymentAuthorization,
    // [Legal]
    hasLegalRepresentative: holder.applicationInformation?.acceptsLegalRepresentative,
    acceptsSoleCustody: holder.applicationInformation?.acceptsSoleCustody,
    acceptedMarketingStreams: optionalValue(holder, formatMarketingStreams) as string
  };

  return _holder;
};

export const getPartnerFullDetails = (partner: Partner, questions: any, config: ConfigResponse) => {
  const _partner = {
    order: partner.order || 0,
    // [Personal data]
    name: optionalValue(partner, formatName) as string,
    firstName: partner.firstname,
    lastName: partner.lastname,
    birthDate: partner.birthDate,
    address: formatAddress(partner.permanentResidence),
    salutation: formatEnum(partner?.salutation, 'SalutationEnum', 'contractsEnums'),
    academicTitle: formatEnum(partner.title, 'TitleEnum', 'contractsEnums'),
    // nobleTitle: ??? [NOT IN DTO]
    maritalStatus: formatEnum(partner.maritalStatus, 'MaritalStatusEnum', 'contractsEnums'),
    // nationality: partner?.nationality,
    // [Employment Data]
    professionalPosition: formatEnum(
      partner.applicationInformation?.employmentGroup,
      'EmploymentGroupEnum',
      'contractsEnums'
    ),
    currentOccupation: formatEnum(
      partner?.applicationInformation?.profession,
      'ProfessionEnum',
      'contractsEnums'
    ),
    employer: partner.applicationInformation?.employer,
    trainingStart: partner.applicationInformation?.trainingStart,
    trainingEnd: partner.applicationInformation?.trainingEnd,
    // [Address]
    isLivingInGermany: partner.livingInGermany,
    street: partner.permanentResidence.street,
    houseNumber: partner.permanentResidence.houseNumber,
    postalCode: partner.permanentResidence.postalCode,
    city: partner.permanentResidence.city,
    country: config?.countries?.find(
      (country) => country.code == partner.permanentResidence.country
    )?.name,
    hasForeignResidence: partner.hasLivedInForeignCountry,
    foreignCountry: config?.countries?.find((country) => country.code == partner.foreignCountry)
      ?.name,
    // [Other]
    taxNumber: partner?.taxNumber,
    hasConsentToContact: partner.applicationInformation?.acceptsPromotionalPurposes,
    // consentToContactDate: ???
    // [Bedarf]
    insuranceType:
      partner.applicationInformation?.tariffInformation?.needSituation !== NeedSituationEnum.ZVGKV
        ? t('enums:InsuranceTypeEnum.FULL_INSURANCE')
        : t('enums:InsuranceTypeEnum.ADDITIONAL_INSURANCE'),
    needSituation: formatEnum(
      partner.applicationInformation?.tariffInformation?.needSituation,
      'NeedSituationEnum',
      'contractsEnums'
    ),
    federalState: formatEnum(
      partner.applicationInformation?.federalState,
      'FederalStateEnum',
      'contractsEnums'
    ),
    aidClaim: optionalValue(
      partner?.applicationInformation?.tariffInformation?.claimAmount,
      formatPercent
    ) as string,
    hadInsuranceSince2012: partner?.applicationInformation?.tariffInformation?.hasClaimSince2012,
    // [Health]
    healthQuestions: {
      acknowledgment: true,
      statutoryReporting: false,
      questions: partner.applicationInformation?.health?.map((q) => {
        const def = questions?.find((def: { id: string }) => def.id === q.id);
        return {
          id: q.id,
          text: `${def?.orderNo}. ${def?.text}`,
          answer:
            def?.questionType === QuestionTypeEnum.CLOSED_QUESTION
              ? formatBoolean(q.answer)
              : q.answer,
          details: q.details
        };
      })
    },
    generalQuestions: {
      insuranceNumber: partner.applicationInformation?.insuranceNumberLKH?.toString(),
      hasHealthPreInsurance: partner.applicationInformation?.hasHealthPreInsurance,
      hasCarePreInsurance: partner.applicationInformation?.hasCarePreInsurance,
      hasSickPayPreInsurance: partner.applicationInformation?.hasSickPayPreInsurance,
      hasSupplementaryPreInsurance: partner.applicationInformation?.hasSupplementaryPreInsurance,
      preInsurances: partner.applicationInformation?.preInsurances,
      comprehensiveHealthTransferValue:
        partner.applicationInformation?.comprehensiveHealthTransferValue,
      referenceValuePPV: partner.applicationInformation?.referenceValuePPV,
      fundsFromGZ: partner.applicationInformation?.fundsFromGZ,
      hasInsuranceDept: partner.applicationInformation?.hasInsuranceDept,
      hasUsedEmergencyTariff: partner.applicationInformation?.hasUsedEmergencyTariff,
      hasInsolvency: partner.applicationInformation?.hasInsolvency
    }
  };

  return _partner;
};

export const formatMarketingStreams = (partner: Partner): string => {
  const streams = [];
  if (partner.applicationInformation?.acceptsPromotionalPurposesEmail) {
    streams.push(t('view360:utils.email'));
  }
  if (partner.applicationInformation?.acceptsPromotionalPurposesPhone) {
    streams.push(t('view360:utils.phone'));
  }

  return streams.length > 0 ? streams.join(', ') : '-';
};
