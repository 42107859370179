import React, { ReactNode, useContext, useMemo } from 'react';

import { Article } from 'models/portal-generated';

import { useNews } from '../hooks/useNews';

export type NewsContextValue = {
  news: Array<Article>;
  loading: boolean;
  error: boolean;
};

export const NewsContext = React.createContext<NewsContextValue | null>(null);

type NewsContextProviderProps = { children: ReactNode };

export const NewsContextProvider = ({ children }: NewsContextProviderProps) => {
  const { news, loading, error } = useNews();

  const ctx: NewsContextValue = useMemo(() => {
    return {
      news,
      loading,
      error: error || false
    };
  }, [news, loading, error]);

  return <NewsContext.Provider value={ctx}>{children}</NewsContext.Provider>;
};

export const useNewsPageContext = (): NewsContextValue => {
  const context = useContext(NewsContext);

  if (context === null) {
    throw new Error('useNewstPageContext was used outside of its Provider');
  }

  return context;
};
