import { useQuery } from '@tanstack/react-query';

import { ApplicationService, InSignStatus, InSignStatusEnum } from 'models/extension-generated';

type Params = {
  offerId?: string;
};

export const useSignatureStatusQuery = ({ offerId }: Params) => {
  return useQuery({
    queryKey: ['application-360-query', offerId],
    queryFn: async () => {
      if (!offerId) {
        throw new Error('Offer ID is missing');
      }
      try {
        const response = await ApplicationService.getApplicationSignatureStatus({
          id: offerId
        });

        return response;
      } catch (error) {
        const notStartedStatus: InSignStatus = {
          status: InSignStatusEnum.SERVICE_UNAVAILABLE
        };
        return notStartedStatus;
      }
    },
    refetchOnWindowFocus: 'always',
    refetchInterval: 10000
  });
};
