import { useState } from 'react';

import { HomeIcon, Icon, Props } from 'lkh-portal-ui-library';

import { HomeNavigationValidationDialog } from '../HomeNavigationValidationDialog/HomeNavigationValidationDialog';

export const HomeButton = () => {
  const [isValidationDialogOpen, setIsValidationDialogOpen] = useState(false);

  const handleOpenValidationDialog = () => {
    setIsValidationDialogOpen(true);
  };

  const handleCloseValidationDialog = () => {
    setIsValidationDialogOpen(false);
  };

  return (
    <>
      {isValidationDialogOpen && (
        <HomeNavigationValidationDialog
          isDialogOpen={isValidationDialogOpen}
          onDialogClose={handleCloseValidationDialog}
        />
      )}
      <div className="flex" data-testid="icon-wrapper">
        <Icon
          className="cursor-pointer pointer-events-auto"
          color={Props.StatusColors.Error.Base}
          icon={<HomeIcon />}
          size="xl"
          onClick={handleOpenValidationDialog}
        />
      </div>
    </>
  );
};
