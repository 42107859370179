import { useEffect } from 'react';

import { useKeycloak } from '@react-keycloak/web';
import { useLocalStorage } from 'usehooks-ts';

import { RealmRole } from 'models';

export type AgentSessionData = ReturnType<typeof useAgentSession>;

const AGENT_SESSION_KEY = 'LKH_AGENT';

/**
 * Hook is used for agent picker flow: init, update, reset, remove
 * current agent is set to localstorage allowing to persist selection instead of keycloak user
 */
export const useAgentSession = () => {
  const { keycloak } = useKeycloak();
  const [agentSession, setAgentSession, removeAgentSession] = useLocalStorage(AGENT_SESSION_KEY, {
    agentId: '',
    name: '',
    hasServiceLKHInternalRole: false,
    agentPickerEnabled: false
  });

  const agentId = keycloak?.tokenParsed?.agentId?.trim() || '';
  const name = keycloak?.tokenParsed?.name || '';
  const roles = keycloak?.tokenParsed?.realm_access?.roles || [];
  const isTokenExpired = keycloak?.isTokenExpired();

  useEffect(() => {
    if (isTokenExpired) removeAgentSession();
  }, [isTokenExpired]);

  useEffect(() => {
    if (!agentId || agentSession.agentPickerEnabled) return;
    resetAgentSession();
  }, [agentId]);

  const resetAgentSession = () => {
    setAgentSession({
      agentId,
      name,
      hasServiceLKHInternalRole: roles.some((role) => role === RealmRole.SERVICE_LKH_INTERNAL),
      agentPickerEnabled: false
    });
  };

  return {
    agentSession,
    setAgentSession,
    resetAgentSession,
    removeAgentSession
  };
};
