import sortBy from 'lodash/sortBy';

import {
  CalculationResponse,
  ConfigResponse,
  Partner,
  Question,
  Tariff
} from 'models/extension-generated';

import { InsuredPersonDetailSummary } from '../../Offer/components/PersonDetails/InsuredPersonDetailSummary';
import { PriceSummary } from '../../Offer/components/PriceSummary';
import { Summary as PersonalDataSummary } from '../../Offer/components/Summary';
import { Tariffs } from '../../Offer/components/Tariffs';
import { getPartnerFullDetails } from '../utils';

type PartnersSummaryProps = {
  partners: Array<Partner>;
  data: CalculationResponse | null | undefined;
  questionsData: { partnerId: string; questions: Question[] }[] | undefined;
  isLoading: boolean;
  insuranceStart: string;
  config: ConfigResponse;
};

export const PartnersSummary = ({
  partners,
  data,
  questionsData,
  isLoading,
  insuranceStart,
  config
}: PartnersSummaryProps) => {
  return (
    <div>
      {partners.map((partner) => {
        const calculation = data?.partners.find((p) => partner.id === p.partnerId);
        const questionsDefs =
          questionsData?.find(({ partnerId }) => partner.id === partnerId)?.questions || [];
        const needSituation = partner.applicationInformation?.tariffInformation?.needSituation;
        const selectedTariffs: Array<Tariff> = sortBy(
          partner.applicationInformation?.tariffInformation?.selectedTariffs || [],
          'sequenceOfOrder'
        );
        const addedRiskAmountTotal = selectedTariffs.reduce((prev, next) => {
          const addedRiskAmount = next.addedRiskAmount || 0;
          return prev + addedRiskAmount;
        }, 0);
        const partnerId = partner?.id;
        const _partner = getPartnerFullDetails(partner, questionsDefs, config);

        return (
          <div key={partner.id}>
            <div className="grid-mx-res">
              <PersonalDataSummary partner={partner} applicationStart={insuranceStart} />
              <InsuredPersonDetailSummary {..._partner} insuranceStart={insuranceStart} />
            </div>
            <Tariffs allowEditing={false} tariffs={selectedTariffs} partnerId={partnerId} />
            <div className="grid-res my-0">
              <PriceSummary
                calculation={calculation}
                isLoading={isLoading}
                needSituation={needSituation}
                applicationStart={insuranceStart}
                addedRiskAmountTotal={addedRiskAmountTotal}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
