import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField, Typography } from '@mui/material';

import { TariffSectionEnum } from '../../../../../../../models/extension-generated';
import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';

import { OptionalSection } from '../../../components/OptionalSection';
import { getOptions } from '../../../components/OptionalSection/helpers';
import { RequiresTariffsProvider, useProvidedTariffs } from '../../../contexts/TariffsContext';
import { DetailsLink } from '../DetailsLink/DetailsLink';
import { useConfigContext } from 'contexts/ConfigContext';
import { INTERNATIONAL_TRAVEL_HEALTH_INSURANCE_DOCUMENT } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

const { Field } = Form.Components;

export const Content = () => {
  const { populateTariffs, partnerId, section, state, sectionTariffs, updateHealthQuestions } =
    useProvidedTariffs();
  const { t } = useTranslation('wizardTariffs', { keyPrefix: `sections.${section}` });
  const { config } = useConfigContext();
  const { reducer } = useHealthInsuranceContext();
  const { updateValue } = reducer;

  const nationalityKey = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.nationality'
  );
  const { hasError, message } = Form.hooks.useFormComponent(nationalityKey);
  const selectedNationalityId = formModelGet(state.model, nationalityKey);

  const countries = useMemo(() => {
    return (
      config?.countries?.map((country) => {
        return {
          id: country.code || '',
          label: country.name || ''
        };
      }) || []
    );
  }, [config?.countries]);

  const selectedNationalityValue = useMemo(() => {
    const selectedNationalityValue = countries.find(
      (country) => country.id == selectedNationalityId
    );
    return selectedNationalityValue;
  }, [selectedNationalityId]);

  const handleWantsTariffChange = (value: boolean): void => {
    if (value) {
      populateTariffs([...sectionTariffs.map((t) => t.id)]);
    } else {
      updateValue({ key: nationalityKey, value: undefined });
    }
    updateHealthQuestions();
  };

  const options = getOptions(t('noAnswer'));
  const hasInternationalTravelInsurancePath =
    'applicationInformation.tariffInformation.hasInternationalTravelInsurance';
  const value = formModelGet(
    state.model,
    partnerField(partnerId, hasInternationalTravelInsurancePath)
  );
  const selected = options.find((opt) => opt.value === value);

  return (
    <OptionalSection
      selected={selected}
      componentKey={hasInternationalTravelInsurancePath}
      title={
        <div className="flex justify-start items-center space-x-2">
          <div>
            <Typography variant="bodyLGBold">{t('question')}</Typography>
          </div>
          <div>
            <DetailsLink
              isGetByReferenceId
              documentId={INTERNATIONAL_TRAVEL_HEALTH_INSURANCE_DOCUMENT}
              applicationStart={state.model.applicationStart}
            />
          </div>
        </div>
      }
      noAnswerText={t('noAnswer')}
      onChange={handleWantsTariffChange}
    >
      <Form.Section>
        <div className="grid-res-2 ml-[0px]">
          <Field error={hasError} message={message}>
            <Autocomplete
              fullWidth
              blurOnSelect
              value={selectedNationalityValue}
              options={countries}
              isOptionEqualToValue={(option, val) => option.id === val.id}
              getOptionKey={(option) => option.id}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} label={t('nationality.label')} />}
              onChange={(
                _event: React.SyntheticEvent,
                newValue: { id: string; label: string } | null
              ) => {
                if (!newValue?.id) return;
                updateValue({ key: nationalityKey, value: newValue?.id });
              }}
            />
          </Field>
        </div>
      </Form.Section>
    </OptionalSection>
  );
};

export const TravelTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.AUSLANDSREISEKRANKENVERSICHERUNG;

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <Content />
    </RequiresTariffsProvider>
  );
};
