import { createRef, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { useKeycloak } from '@react-keycloak/web';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { TFunction, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Button from '@mui/material/Button';

import {
  DocumentsProps,
  DocumentTypeEnum,
  InsuredPersonDetailProps,
  SignatureStatus
} from '../../../../models/view360models';
import { Loader, LoaderType, Props } from 'lkh-portal-ui-library';
import { RealmRole } from 'models';
import {
  ApplicationService,
  ApplicationStatusEnum,
  CalculationResponse,
  InSignService,
  InSignStatusEnum,
  Partner,
  PersonRoleEnum,
  Question,
  QuestionsService,
  QuestionTypeEnum
} from 'models/extension-generated';

import { useApplicationHelpers } from '../../../../hooks/useApplicationHelpers/useApplicationHelpers';
import { PriceSummary } from '../../../PrivateHealthInsurance/subpages/Offer/components/PriceSummary';
import { createNavigation, View360NavigationEnum } from '../../components/DocumentNavigation';
import { View360ContextProvider } from '../../context/View360Context';
import {
  formatAddress,
  formatBoolean,
  formatEnum,
  formatName,
  formatPercent,
  optionalValue
} from '../../utils/formatters';
import { View360Page } from '../../View360Page';
import { DigitalSignature } from './components/DigitalSignature';
import { ManualSignature } from './components/ManualSignature';
import { useApplicationQuery } from './hooks/useApplicationQuery';
import { useSignatureStatusQuery } from './hooks/useSignatureStatusQuery';
import { OfferTariffTable } from 'components/OfferTariffTable';
import { useConfigContext } from 'contexts/ConfigContext';
import { AccordionHandle } from 'pages/Contract360Page/components/SectionAccordion';
import { downloadContract } from 'utilities/fetchContract';

const handleDigitalSignature = async (offerId: string | undefined, refetch: () => void) => {
  try {
    if (!offerId) return;
    const response = await ApplicationService.signApplicationDigitally({
      id: offerId
    });
    refetch();
    const inSignRedirectUrl = response.inSignSession?.redirectUrl;

    if (inSignRedirectUrl) {
      window.open(inSignRedirectUrl, '_blank');
    }
  } catch (error) {
    toast.error('digitalSignatureFailed');
  }
};

const handleOpenSignatureDashboard = async () => {
  const response = await InSignService.createInSignDashboard();
  window.open(response.url, '_blank');
};

const DashboardButton = ({ t }: { t: TFunction<'view360', undefined> }) => {
  return (
    <Button
      variant="outlined"
      size="small"
      startIcon={<OpenInNewIcon />}
      onClick={handleOpenSignatureDashboard}
    >
      {t('signatureBox.openDashboard')}
    </Button>
  );
};

const ManualSignatureAction = ({
  offerId,
  policyHolder,
  refetchApplication
}: {
  offerId: string | undefined;
  policyHolder: Partner | undefined;
  refetchApplication: (options?: RefetchOptions) => Promise<QueryObserverResult>;
}) => (
  <ManualSignature
    uploadUrl={`/api/application/${offerId}/sign/manual`}
    applicationId={offerId || ''}
    policyHolderName={formatName(policyHolder)}
    onUploadDone={() => {
      refetchApplication();
    }}
  />
);

const Actions = ({ children }: PropsWithChildren) => (
  <div className="flex items-center gap-[16px]">{children}</div>
);

const Status = ({ status }: { status: ApplicationStatusEnum | undefined }) => {
  const statusColor = (() => {
    if (status === ApplicationStatusEnum.APPLICATION_CREATED) {
      return 'bg-success-60';
    }
    return 'bg-warning-60';
  })();
  return (
    <div className="flex flex-row items-center gap-[8px]">
      <div className={classNames('text-[16px] w-[8px] aspect-square rounded-full', statusColor)} />
      <span>{formatEnum(status as string, 'ApplicationStatusEnum', 'contractsEnums')}</span>
    </div>
  );
};

/**
 * 360 View for an offer (Antrag)
 *
 * This component simply maps the proposal data structure to a common data-model used by a 360 view
 */
export const View360Offer = () => {
  const { offerId } = useParams();
  const { config } = useConfigContext();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation('view360');

  const { data: application, refetch: refetchApplication } = useApplicationQuery({ offerId });
  const [calculation, setCalculation] = useState<CalculationResponse>();
  const [questionDefs, setQuestionDefs] = useState<Array<Question>>();

  const { getPartnersByRole, getSinglePartnerByRole } = useApplicationHelpers(application);

  const { data: signatureStatus, refetch } = useSignatureStatusQuery({
    offerId
  });

  const hasAppropriateRealmRole =
    keycloak.hasRealmRole(RealmRole.MASTER_AGENT) ||
    keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL);
  /**
   * Effect responsible for a loading of the data
   */
  useEffect(() => {
    async function loadData(id: string) {
      const [applicationResponse, questionResponse] = await Promise.all([
        ApplicationService.getApplicationById({ id }),
        QuestionsService.getQuestionsByCriteria({
          requestBody: { page: 0, size: 0 }
        })
      ]);
      const calculationResponse = await ApplicationService.calculateApplication({
        requestBody: {
          ...applicationResponse,
          partners: applicationResponse.partners.filter(({ roles }) => {
            return roles.includes(PersonRoleEnum.INSURED_PERSON);
          })
        }
      });

      setCalculation(calculationResponse);
      setQuestionDefs(questionResponse.questions);
    }
    if (offerId) {
      loadData(offerId);
    }
  }, [offerId]);

  if (!application || !calculation) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader type={LoaderType.Circular} />
      </div>
    );
  }

  const signatureStatusData: SignatureStatus = (() => {
    const statusTranslationKey = signatureStatus?.status || InSignStatusEnum.SERVICE_UNAVAILABLE;
    const policyHolder = application.partners.find(({ roles }) =>
      roles.includes(PersonRoleEnum.POLICY_HOLDER)
    );

    const statusText = t(`signatureStatus.${statusTranslationKey}.label`, {});
    const statusDescription = t(`signatureStatus.${statusTranslationKey}.description`, {
      signedCount: `${signatureStatus?.signaturesDone}/${signatureStatus?.signaturesNeeded}`,
      sessionName: `"${application.id} - ${policyHolder?.firstname} ${policyHolder?.lastname}"`
    });

    const statusConfiguration: {
      color: Props.ThemeColorType | Props.NeutralColorType | string;
      icon: ReactNode;
      actions?: ReactNode;
    } = (() => {
      switch (signatureStatus?.status) {
        case InSignStatusEnum.INCOMPLETE:
        case InSignStatusEnum.EXTERN:
          return {
            color: Props.BaseColors.Primary.S800,
            icon: <InfoIcon className="text-warning-60 text-[16px]" />,
            actions: (
              <Actions>
                <ManualSignatureAction
                  offerId={offerId}
                  policyHolder={policyHolder}
                  refetchApplication={refetchApplication}
                />
              </Actions>
            )
          };
        case InSignStatusEnum.COMPLETE:
          return {
            color: '',
            icon: <CheckCircleIcon className="text-success-60 text-[16px]" />,
            actions: (
              <Actions>
                <ManualSignatureAction
                  offerId={offerId}
                  policyHolder={policyHolder}
                  refetchApplication={refetchApplication}
                />
              </Actions>
            )
          };

        case InSignStatusEnum.SIGNED:
          return {
            color: Props.BaseColors.Primary.S800,
            icon: <CheckCircleIcon className="text-success-60 text-[16px]" />,
            actions: (
              <Actions>
                <ManualSignatureAction
                  offerId={offerId}
                  policyHolder={policyHolder}
                  refetchApplication={refetchApplication}
                />
              </Actions>
            )
          };
        case InSignStatusEnum.REJECTED:
        case InSignStatusEnum.EXTERN_DECLINED:
        case InSignStatusEnum.EXTERN_EXPIRED:
          return {
            color: Props.StatusColors.Error.S500,
            icon: <InfoIcon className="text-danger-60 text-[16px]" />,
            actions: (
              <Actions>
                <ManualSignatureAction
                  offerId={offerId}
                  policyHolder={policyHolder}
                  refetchApplication={refetchApplication}
                />
              </Actions>
            )
          };
        case InSignStatusEnum.NOT_STARTED:
          return {
            color: Props.StatusColors.Error.S500,
            icon: <InfoIcon className="text-danger-60 text-[16px]" />,
            actions: (
              <Actions>
                <ManualSignatureAction
                  offerId={offerId}
                  policyHolder={policyHolder}
                  refetchApplication={refetchApplication}
                />
                <DigitalSignature
                  onSignDigitallyClick={() => handleDigitalSignature(offerId, refetch)}
                />
              </Actions>
            )
          };

        default:
          // Service not available status
          return {
            color: 'bg-danger-60',
            icon: <ErrorIcon className="text-danger-60 text-[16px]" />,
            actions: (
              <div className="flex items-center">
                <div className="mr-[8px]">
                  <ManualSignature
                    uploadUrl={`/api/application/${offerId}/sign/manual`}
                    applicationId={offerId || ''}
                    policyHolderName={formatName(policyHolder)}
                    onUploadDone={() => {
                      refetchApplication();
                    }}
                  />
                </div>
                <DigitalSignature
                  onSignDigitallyClick={() => handleDigitalSignature(offerId, refetch)}
                />
              </div>
            )
          };
      }
    })();

    const banner = signatureStatus
      ? {
          label: (
            <div
              className={classNames(
                'flex items-center justify-between bg-surface-60 font-bold text-text-80 tracking-wide text-xs px-[12px] py-[8px] rounded-[4px]'
              )}
            >
              {statusConfiguration.icon}
              <div className="ml-[8px]">{statusText}</div>
            </div>
          ),
          description: statusDescription,
          openDashboardButton: ![
            InSignStatusEnum.NOT_STARTED,
            InSignStatusEnum.SERVICE_UNAVAILABLE
          ].includes(statusTranslationKey) ? (
            <div className="mt-[24px]">
              <DashboardButton t={t} />
            </div>
          ) : null
        }
      : undefined;
    return {
      actionButtons: statusConfiguration.actions,
      banner
    };
  })();
  // Sort by original order but then reassign to 1, 2, 3, ... as otherwise there might be gaps between numbers
  const insuredPersons = getPartnersByRole(PersonRoleEnum.INSURED_PERSON)
    .sort((a, b) => {
      const aValue = a.order === undefined ? Number.NEGATIVE_INFINITY : a.order;
      const bValue = b.order === undefined ? Number.NEGATIVE_INFINITY : b.order;
      return aValue > bValue ? 1 : -1;
    })
    .map((p, i) => ({ ...p, order: 1 * (i + 1) }));
  const policyHolder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const paymentContributor = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR);
  const documents: DocumentsProps = {
    documents: [
      {
        name: t('downloadContract'),
        callback: downloadContract,
        offerId: offerId
      }
    ]
  };

  const formatMarketingStreams = (partner: Partner): string => {
    const streams = [];
    if (partner.applicationInformation?.acceptsPromotionalPurposesEmail) {
      streams.push(t('utils.email'));
    }
    if (partner.applicationInformation?.acceptsPromotionalPurposesPhone) {
      streams.push(t('utils.phone'));
    }

    return streams.length > 0 ? streams.join(', ') : '-';
  };

  return (
    <View360ContextProvider
      enableGeneralQuestions
      enableHealthQuestions
      navigation={createNavigation({
        insuredPersons: insuredPersons.map((p) => ({
          label: formatName(p),
          key: `${View360NavigationEnum.InsuredPerson}-${p.order}`,
          ref: createRef<AccordionHandle>()
        })),
        includeContractItems: false,
        hasAppropriateRealmRole: hasAppropriateRealmRole
      })}
      data={{
        id: offerId || '',
        status: <Status status={application.status} />,
        signatureStatus:
          application.status === ApplicationStatusEnum.APPLICATION_PUBLISHED
            ? signatureStatusData
            : undefined,
        documentType: DocumentTypeEnum.Offer,
        contract: {
          amount: calculation.calculationMonthly?.totalAmount,
          product: 'KV',
          insuredPersonsCount: insuredPersons.length,
          startDate: application.applicationStart,
          createdDate: application.creationDate
        },
        policyHolder: {
          // [Personal Data]
          name: optionalValue(policyHolder, formatName) as string,
          firstName: policyHolder.firstname,
          lastName: policyHolder.lastname,
          birthDate: policyHolder.birthDate,
          address: formatAddress(policyHolder.permanentResidence),
          salutation: formatEnum(policyHolder.salutation, 'SalutationEnum', 'contractsEnums'),
          academicTitle: formatEnum(policyHolder.title, 'TitleEnum', 'contractsEnums'),
          // nobleTitle: ??? [NOT IN DTO]
          maritalStatus: formatEnum(
            policyHolder.maritalStatus,
            'MaritalStatusEnum',
            'contractsEnums'
          ),
          // [Employment Data]
          professionalPosition: formatEnum(
            policyHolder.applicationInformation?.employmentGroup,
            'EmploymentGroupEnum',
            'contractsEnums'
          ),
          currentOccupation: formatEnum(
            policyHolder.applicationInformation?.profession,
            'ProfessionEnum',
            'contractsEnums'
          ),
          employer: policyHolder.applicationInformation?.employer,
          // [Address]
          isLivingInGermany: policyHolder.livingInGermany,
          street: policyHolder.permanentResidence.street,
          houseNumber: policyHolder.permanentResidence.houseNumber,
          postalCode: policyHolder.permanentResidence.postalCode,
          city: policyHolder.permanentResidence.city,
          country: config?.countries?.find(
            (country) => country.code == policyHolder.permanentResidence.country
          )?.name,
          hasForeignResidence: policyHolder.hasLivedInForeignCountry,
          foreignCountry: config?.countries?.find(
            (country) => country.code == policyHolder.foreignCountry
          )?.name,
          // [Contact]
          email: policyHolder.permanentResidence.email,
          phone: policyHolder.permanentResidence.phone,
          // [Legal]
          hasLegalRepresentative: policyHolder.applicationInformation?.acceptsLegalRepresentative,
          acceptsSoleCustody: policyHolder.applicationInformation?.acceptsSoleCustody,
          acceptedMarketingStreams: optionalValue(policyHolder, formatMarketingStreams) as string
        },
        paymentContributor: {
          name: formatName(paymentContributor),
          isPolicyHolder: policyHolder.id === paymentContributor.id
        },
        insuredPersons: insuredPersons.map((partner): InsuredPersonDetailProps => {
          const selectedTariffs = sortBy(
            partner.applicationInformation?.tariffInformation?.selectedTariffs || [],
            'sequenceOfOrder'
          );
          const partnerCalculation = calculation.partners.find((p) => {
            return p.partnerId === partner.id;
          });
          const addedRiskAmountTotal = selectedTariffs.reduce((prev, next) => {
            const addedRiskAmount = next.addedRiskAmount || 0;
            return prev + addedRiskAmount;
          }, 0);

          return {
            // [Utils]
            order: partner.order,
            tariffTable: (
              <>
                <OfferTariffTable
                  allowEditing={false}
                  summaryRow={{
                    id: 'sampleId',
                    product: t('monthlyPayment', {
                      ns: 'wizardOffer'
                    }),
                    addedRiskAmount: addedRiskAmountTotal,
                    price: { total: partnerCalculation?.calculationMonthly?.amount }
                  }}
                  tariffs={selectedTariffs.map((tariff) => {
                    const {
                      section,
                      description,
                      highMoneyAmount,
                      addedRiskAmount,
                      hasProspectiveEntitlement,
                      id
                    } = tariff;
                    const partnerCalculation = calculation.partners.find((p) => {
                      return p.partnerId === partner.id;
                    });
                    const tariffPrice = partnerCalculation?.tariffCalculations?.find((t) => {
                      return t.tariffId === id;
                    })?.calculationMonthly;
                    return {
                      id: id,
                      product: t(`TariffSectionEnum.${section}`, {
                        ns: 'contractsEnums'
                      }),
                      tariffLabel: { tariffLabel: id, tooltip: description || '' },
                      highMoneyAmount: highMoneyAmount,
                      entitlement: hasProspectiveEntitlement,
                      addedRiskAmount: addedRiskAmount || 0,
                      price: {
                        total: tariffPrice?.amount,
                        statutorySurcharge: tariffPrice?.statutorySurcharge
                      }
                    };
                  })}
                />
                <PriceSummary
                  isLoading={false}
                  calculation={partnerCalculation}
                  needSituation={partner.applicationInformation?.tariffInformation?.needSituation}
                  applicationStart={application.applicationStart || ''}
                  addedRiskAmountTotal={addedRiskAmountTotal}
                />
              </>
            ),
            isPolicyHolder: policyHolder.id === partner.id,
            amount: calculation.partners.find(({ partnerId }) => partnerId === partner.id)
              ?.calculationMonthly?.totalAmount,
            // [Personal data]
            name: optionalValue(partner, formatName) as string,
            firstName: partner.firstname,
            lastName: partner.lastname,
            birthDate: partner.birthDate,
            address: formatAddress(partner.permanentResidence),
            salutation: formatEnum(partner.salutation, 'SalutationEnum', 'contractsEnums'),
            academicTitle: formatEnum(partner.title, 'TitleEnum', 'contractsEnums'),
            // nobleTitle: ??? [NOT IN DTO]
            maritalStatus: formatEnum(partner.maritalStatus, 'MaritalStatusEnum', 'contractsEnums'),
            // nationality: partner?.nationality,
            // [Employment Data]
            professionalPosition: formatEnum(
              partner.applicationInformation?.employmentGroup,
              'EmploymentGroupEnum',
              'contractsEnums'
            ),
            currentOccupation: formatEnum(
              partner.applicationInformation?.profession,
              'ProfessionEnum',
              'contractsEnums'
            ),
            employer: partner.applicationInformation?.employer,
            trainingStart: partner.applicationInformation?.trainingStart,
            trainingEnd: partner.applicationInformation?.trainingEnd,
            // [Address]
            isLivingInGermany: partner.livingInGermany,
            street: partner.permanentResidence.street,
            houseNumber: partner.permanentResidence.houseNumber,
            postalCode: partner.permanentResidence.postalCode,
            city: partner.permanentResidence.city,
            country: config?.countries?.find(
              (country) => country.code == partner.permanentResidence.country
            )?.name,
            hasForeignResidence: partner.hasLivedInForeignCountry,
            foreignCountry: config?.countries?.find(
              (country) => country.code == partner.foreignCountry
            )?.name,
            // [Other]
            taxNumber: partner.taxNumber,
            hasConsentToContact: partner.applicationInformation?.acceptsPromotionalPurposes,
            // consentToContactDate: ???
            // [Bedarf]
            // insuranceType: ???
            needSituation: formatEnum(
              partner.applicationInformation?.tariffInformation?.needSituation,
              'NeedSituationEnum',
              'contractsEnums'
            ),
            insuranceStart: application.applicationStart,
            federalState: formatEnum(
              partner.applicationInformation?.federalState,
              'FederalStateEnum',
              'contractsEnums'
            ),
            aidClaim: optionalValue(
              partner.applicationInformation?.tariffInformation?.claimAmount,
              formatPercent
            ) as string,
            hadInsuranceSince2012:
              partner.applicationInformation?.tariffInformation?.hasClaimSince2012,
            // [Health]
            healthQuestions: {
              acknowledgment: true,
              statutoryReporting: false,
              questions: partner.applicationInformation?.health?.map((q) => {
                const def = questionDefs?.find((def) => def.id === q.id);
                return {
                  id: q.id,
                  text: `${def?.orderNo}. ${def?.text}`,
                  answer:
                    def?.questionType === QuestionTypeEnum.CLOSED_QUESTION
                      ? formatBoolean(q.answer)
                      : q.answer,
                  details: q.details
                };
              })
            },
            generalQuestions: {
              insuranceNumber: partner.applicationInformation?.insuranceNumberLKH?.toString(),
              hasHealthPreInsurance: partner.applicationInformation?.hasHealthPreInsurance,
              hasCarePreInsurance: partner.applicationInformation?.hasCarePreInsurance,
              hasSickPayPreInsurance: partner.applicationInformation?.hasSickPayPreInsurance,
              hasSupplementaryPreInsurance:
                partner.applicationInformation?.hasSupplementaryPreInsurance,
              preInsurances: partner.applicationInformation?.preInsurances,
              comprehensiveHealthTransferValue:
                partner.applicationInformation?.comprehensiveHealthTransferValue,
              referenceValuePPV: partner.applicationInformation?.referenceValuePPV,
              fundsFromGZ: partner.applicationInformation?.fundsFromGZ,
              hasInsuranceDept: partner.applicationInformation?.hasInsuranceDept,
              hasUsedEmergencyTariff: partner.applicationInformation?.hasUsedEmergencyTariff,
              hasInsolvency: partner.applicationInformation?.hasInsolvency
            }
          };
        }),
        documents
      }}
    >
      <View360Page
        policyId={offerId || ''}
        refetchData={async () => {
          await refetchApplication();
        }}
      />
    </View360ContextProvider>
  );
};
