/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Status of the policy
 */
export enum PolicyStatusEnum {
    ACTIVE = 'ACTIVE',
    PENDING = 'PENDING',
    HISTORIC = 'HISTORIC',
    REACTIVATED = 'REACTIVATED',
    CANCELED = 'CANCELED',
    IN_PROCESS = 'IN_PROCESS',
    REVISION_IN_PROCESS = 'REVISION_IN_PROCESS',
}
