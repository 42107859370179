import { createContext, PropsWithChildren, useContext } from 'react';

import { View360Data } from '../../../models/view360models';

import { View360Navigation } from '../components/DocumentNavigation';

/**
 * Contextual data available anywhere under the provider
 */
type View360ContextType = {
  data: View360Data;
  navigation: View360Navigation;
  enableUpload?: boolean;
  enableRevisions?: boolean;
  enableGeneralQuestions?: boolean;
  enableHealthQuestions?: boolean;
};

const View360Context = createContext<View360ContextType | null>(null);

type View360ContextProviderProps = PropsWithChildren & View360ContextType;

export function View360ContextProvider(props: View360ContextProviderProps) {
  return <View360Context.Provider value={props}>{props.children}</View360Context.Provider>;
}

export function use360ViewContext(): View360ContextType {
  const context = useContext(View360Context);

  if (!context) {
    throw new Error(
      'To use "use360ViewContext" some of the parent components must be within its Provider'
    );
  }

  return context;
}
