import { useTranslation } from 'react-i18next';

import EmailIcon from '@mui/icons-material/Email';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Dialog, DialogContent, Typography } from '@mui/material';

type SupportDialogProps = {
  isDialogOpen: boolean;
  onDialogClose: () => void;
};

export const SupportDialog = ({
  isDialogOpen: isSupportDialogOpen,
  onDialogClose
}: SupportDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'navigation.support.supportDialog'
  });
  return (
    <Dialog
      fullWidth
      maxWidth={'md'}
      open={isSupportDialogOpen}
      onClose={onDialogClose}
      disableScrollLock
    >
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <Typography variant="headlineXLGBold" className="flex text-title-80">
            {t('title')}
          </Typography>
        </div>
        <div className="flex flex-wrap justify-center items-center gap-[32px] py-[24px] px-[32px] mt-[32px] bg-surface-60 rounded">
          <div>
            <div className="flex">
              <FmdGoodIcon />
              <div className="ml-[8px]">
                <Typography component={'div'} variant="bodySMBold" className="text-text-80">
                  {t('contactLocation.title')}
                </Typography>
                <Typography
                  component={'div'}
                  variant="bodySMRegular"
                  className="text-text-60 mt-[8px]"
                >
                  {t('contactLocation.address')}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <LocalPhoneIcon />
              <div className="ml-[8px]">
                <Typography component={'div'} variant="bodySMBold" className="text-text-80 ">
                  {t('contactPhone.phone')}
                </Typography>
                <Typography
                  component={'div'}
                  variant="bodySMRegular"
                  className="text-text-60 mt-[8px]"
                >
                  {t('contactPhone.contactTimeInfo')}
                </Typography>
              </div>
            </div>
          </div>
          <div>
            <div className="flex">
              <EmailIcon />
              <div className="ml-[8px]">
                <Typography
                  component={'a'}
                  variant="bodySMBoldLink"
                  className="text-text-80"
                  href={`mailto:${t('contactEmail')}`}
                >
                  {t('contactEmail')}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
