import { useDropzone } from 'react-dropzone';

import { useCallback, useState } from 'react';

import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CloseIcon from '@mui/icons-material/Close';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import { Document } from '../../../../../components/UploadDocuments/Document';
import { useUploadContext } from 'contexts/UploadContext';

import styles from './Dropzone.module.scss';

type DropzoneProps = {
  showIntent?: boolean;
};

export default function Dropzone({ showIntent = true }: DropzoneProps) {
  const { t } = useTranslation('dashboard', { keyPrefix: 'upload' });
  const {
    files,
    setFiles,
    error,
    setError,
    close,
    upload,
    errors,
    setIntent,
    setComment,
    setFirstName,
    setLastName,
    setPolicyId,
    handleDelete
  } = useUploadContext();
  const [firstName, setFirstNameVal] = useState(files.at(0)?.firstname || '');
  const [lastName, setLastNameVal] = useState(files.at(0)?.lastname || '');
  const [policyId, setPolicyIdVal] = useState(files.at(0)?.policyId || '');

  const disabledUpload = firstName === '' || lastName === '' || files.length <= 0;

  const handleUpload = () => {
    files.forEach((file) => {
      setFirstName(file.name, firstName);
      setLastName(file.name, lastName);
      setPolicyId(file.name, policyId);
    });
    upload();
  };

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    setError(undefined);
    setFiles((oldFiles) => {
      const joinedFiles = oldFiles?.concat(acceptedFiles);
      const uniqueNames: Array<string> = [];
      const uniqueFiles: Array<File> = [];

      joinedFiles.forEach((item) => {
        const name = item.name.trim();
        if (!uniqueNames.includes(name)) {
          uniqueNames.push(name);
          uniqueFiles.push(item);
        } else {
          const errorMsg = t('dropzone.toastMessages.youAddedFileThatAlreadyExist');
          toast.error(errorMsg);
        }
      });
      return uniqueFiles;
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="p-[24px]">
      <div className="flex flex-row justify-between items-center">
        <Typography variant="bodyMDBold" className="text-text-80">
          {t('title')}
        </Typography>
        <div onClick={close} className="cursor-pointer">
          <CloseIcon fontSize="inherit" sx={{ width: 24, height: 24 }} />
        </div>
      </div>

      <div className="row mt-[40px]">
        <Alert
          variant="outlined"
          severity="error"
          color="warning"
          className="w-full"
          sx={{
            py: '18px',
            px: '32px',
            borderWidth: '2px',
            borderColor: colors.text[20],
            backgroundColor: colors.surface[60]
          }}
        >
          <AlertTitle>
            <Typography variant="bodyLGBold" className="text-text-100">
              {t('infoText.title')}
            </Typography>
          </AlertTitle>
          <Typography variant="bodyMDRegular" className="text-text-80">
            <Trans
              i18nKey="upload.infoText.description"
              ns="dashboard"
              components={{ bold: <strong /> }}
            />
          </Typography>
        </Alert>
      </div>

      <div className="row mt-[40px]">
        <Typography component="div" variant="bodyMDBold" className="text-text-80">
          {t('policyHolderSection.title')}
        </Typography>
        <div className="grid grid-cols-2 gap-x-[16px] gap-y-m mt-m">
          <div>
            <TextField
              label={t('policyHolderSection.firstName.label')}
              variant="outlined"
              value={firstName}
              fullWidth
              required
              placeholder={t('policyHolderSection.firstName.placeholder')}
              type="text"
              onChange={(event) => {
                setFirstNameVal(event.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              label={t('policyHolderSection.lastName.label')}
              variant="outlined"
              value={lastName}
              fullWidth
              required
              placeholder={t('policyHolderSection.lastName.placeholder')}
              type="text"
              onChange={(event) => {
                setLastNameVal(event.target.value);
              }}
            />
          </div>
          <div>
            <TextField
              label={t('policyHolderSection.policyId.label')}
              variant="outlined"
              value={policyId}
              fullWidth
              placeholder={t('policyHolderSection.policyId.placeholder')}
              type="number"
              onChange={(event) => {
                setPolicyIdVal(event.target.value);
              }}
            />
          </div>
        </div>
      </div>

      <div className="row reset-container mt-[40px]">
        <div className="col">
          <Typography component="div" variant="bodyMDBold" className="text-text-80">
            {t('dropzone.title')}
          </Typography>
          <div
            {...getRootProps({
              className: classNames(
                styles.dropzone,
                'border-dashed border-2 rounded-md border-text-20 mt-m p-m',
                {
                  [styles.isDragActive]: isDragActive
                }
              )
            })}
          >
            <input data-testid="dropzone-input" {...getInputProps()} />
            <div className="flex gap-s w-full">
              <div className="flex justify-center items-center">
                <CloudUploadOutlinedIcon className="fill-text-80" />
              </div>
              <div>
                <Typography variant="bodyMDRegular" className="text-text-80">
                  {t('dropzone.dropText')}
                </Typography>
                &nbsp;
                <Link component="button" color="primary" className="text-sm">
                  {t('dropzone.clickText')}
                </Link>
                .
                <div className="mt-[8px]">
                  <Typography variant="bodySMRegular" className="text-text-60">
                    {t('dropzone.acceptsText')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {files && files.length > 0 && (
        <>
          <div className="mt-[24px] flex flex-row justify-between mb-[8px] text-left text-[12px] text-text-60 tracking-wide leading-[16px]">
            <Typography component="div" variant="bodyMDBold" className="text-text-80">
              {t('files.title')}
            </Typography>
            <Typography variant="bodyMDRegular" className="text-text-80">
              <Typography variant="bodyMDBold" className="text-text-80">
                {files.length}
              </Typography>
              &nbsp;{t('files.documents')}
            </Typography>
          </div>
          <div className="row">
            {files.map((file) => (
              <Document
                key={file.name}
                translationNameSpace="dashboard"
                file={file}
                showIntent={showIntent}
                showError={Boolean(errors.get(file.name || ''))}
                onDelete={handleDelete}
                onIntentSelection={setIntent}
                onCommentChange={setComment}
              />
            ))}
          </div>
        </>
      )}

      <div className="row reset-container a-i-center m-t-36">
        {error && (
          <div className="col-auto text-left">
            <span className="text-error text-xs d-inline-flex a-i-center text-wide">{error}</span>
          </div>
        )}

        <div className="col text-right">
          <Button className="mr-[16px] py-[11px]" size="large" onClick={close}>
            {t('actions.cancel')}
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleUpload}
            className="py-[11px]"
            disabled={disabledUpload}
          >
            {t('actions.submit')}
          </Button>
        </div>
      </div>
    </div>
  );
}
