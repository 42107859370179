import { RowClassParams } from 'ag-grid-community';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Resolutions, Table, useCurrentScreenSize } from 'lkh-portal-ui-library';

import { getColumnDefinition } from './columns';
import { TariffRow } from './types';
import { NoResult } from 'components/NoResult/NoResult';

import styles from './ContractTariffsTable.module.scss';

export type ContractTariffPrices = {
  // total price after dis/surcharges are applied
  total: string;
  // Price for all tariffs before applying dis/surcharges
  totalBruto?: string;
  totalReduction?: string;
  // Sum of all surcharges
  totalSurcharge?: string;
  totalOther?: string;
  bestandTotal?: string;
  // Sum of all discounts
  totalDiscount?: string;
  totalAdjustment?: string;
};

type ContractTariffsTableProps = {
  tariffs: Array<TariffRow>;
  prices?: ContractTariffPrices;
};

export const ContractTariffsTable = ({ tariffs, prices }: ContractTariffsTableProps) => {
  const { t } = useTranslation();
  const {
    total,
    totalDiscount,
    totalBruto,
    totalReduction,
    totalSurcharge,
    totalOther,
    bestandTotal,
    totalAdjustment
  } = prices || {};
  const currentResolution = useCurrentScreenSize();

  const summaryRow: TariffRow = {
    title: {
      label: <Typography variant="bodySMBold">{t('policy:monthlyPaymentTotal')}</Typography>
    },
    reduction: {
      label: totalReduction ? <Typography variant="bodySMBold">{totalReduction}</Typography> : null
    },
    surcharge: {
      label: totalSurcharge ? <Typography variant="bodySMBold">{totalSurcharge}</Typography> : null
    },
    other: {
      label: totalOther ? <Typography variant="bodySMBold">{totalOther}</Typography> : null
    },
    bestandTotal: {
      label: bestandTotal ? <Typography variant="bodySMBold">{bestandTotal}</Typography> : null
    },
    discount: {
      label: totalDiscount ? <Typography variant="bodySMBold">{totalDiscount}</Typography> : null
    },
    adjustmentPrice: {
      label: totalAdjustment ? (
        <Typography variant="bodySMBold">{totalAdjustment}</Typography>
      ) : null
    },
    originalTariffPrice: {
      label: totalBruto ? <Typography variant="bodySMBold">{totalBruto}</Typography> : null
    },
    finalTariffPrice: total
  };
  return (
    <div className={classNames(styles.tableOverride, 'table-override')}>
      <Table
        rowHeight={50}
        columnDefs={getColumnDefinition(currentResolution || Resolutions.lg)}
        rowData={[...tariffs, summaryRow]}
        suppressCellFocus
        tooltipMouseTrack
        enableCellTextSelection
        defaultColDef={{
          sortable: false,
          comparator: () => 0,
          suppressMovable: true,
          suppressSizeToFit: false,
          headerClass: styles.header,
          cellClass: styles.cellVerticalAlign
        }}
        tooltipShowDelay={0}
        getRowClass={(params: RowClassParams) => {
          const rowIndex = params.node.rowIndex || 0;

          if (rowIndex % 2 === 0) {
            return styles.row;
          }
          return `bg-background ${styles.row}`;
        }}
        noRowsOverlayComponent={NoResult}
        noRowsOverlayComponentParams={{
          warningMessage: t('table.warningMessage'),
          warningDescription: t('table.warningDescription')
        }}
        className={styles.tableOverride}
      />
      <div className="mt-[16px]">
        <Typography variant="bodyMDRegular">{t('view360:tableNote')}</Typography>
      </div>
    </div>
  );
};
