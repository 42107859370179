import { useTranslation } from 'react-i18next';

import { PreInsuranceTypeEnum } from '../../../../models/extension-generated';
import { GeneralQuestionProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { formatPreInsuranceCompany, formatPrice } from '../../../../utilities/string';
import { formatBoolean, optionalValue } from '../../utils/formatters';
import { Rows } from '../Rows';
import { SimpleTable } from 'components/SimpleTable';

export const GeneralQuestions = ({
  insuranceNumber,
  hasHealthPreInsurance,
  hasCarePreInsurance,
  hasSickPayPreInsurance,
  hasSupplementaryPreInsurance,
  preInsurances = [],
  comprehensiveHealthTransferValue,
  referenceValuePPV,
  fundsFromGZ,
  hasInsolvency,
  hasInsuranceDept,
  hasUsedEmergencyTariff
}: GeneralQuestionProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'insuredPerson.generalQuestions' });

  return (
    <>
      <div className="flex space-x-[32px] mb-[32px] w-full">
        <div className="basis-1/2">
          <div className="mb-[8px]">
            <span className="text-sm text-text-60 font-normal">{t('preInsuranceTitle')}</span>
          </div>
          <Rows
            hasDivider={false}
            rows={[
              {
                label: t('attributes.insuranceNumber'),
                value: optionalValue(insuranceNumber)
              }
            ]}
          />
        </div>
        <div className="basis-1/2">
          <SimpleTable
            title={t('health.title')}
            rows={[
              {
                label: t('health.question'),
                value: optionalValue(hasHealthPreInsurance, formatBoolean)
              }
            ].concat(
              ...preInsurances
                .filter((pi) => pi.type === PreInsuranceTypeEnum.HEALTH)
                .map((pi) => [
                  {
                    label: t('attributes.company'),
                    value: optionalValue(formatPreInsuranceCompany(pi))
                  },
                  {
                    label: t('attributes.begin'),
                    value: optionalValue(pi.startDate, formatGermanDate)
                  },
                  {
                    label: t('attributes.end'),
                    value: optionalValue(pi.endDate, formatGermanDate)
                  }
                ])
            )}
          />
        </div>
      </div>

      <div className="flex space-x-[32px] mb-[32px] w-full">
        <div className="basis-1/2">
          <SimpleTable
            title={t('care.title')}
            rows={[
              {
                label: t('care.question'),
                value: optionalValue(hasCarePreInsurance, formatBoolean)
              }
            ].concat(
              ...preInsurances
                .filter((pi) => pi.type === PreInsuranceTypeEnum.CARE)
                .map((pi) => [
                  {
                    label: t('attributes.company'),
                    value: optionalValue(formatPreInsuranceCompany(pi))
                  },
                  {
                    label: t('attributes.begin'),
                    value: optionalValue(pi.startDate, formatGermanDate)
                  },
                  {
                    label: t('attributes.end'),
                    value: optionalValue(pi.endDate, formatGermanDate)
                  }
                ])
            )}
          />
        </div>
        <div className="basis-1/2">
          <SimpleTable
            title={t('sickPay.title')}
            rows={[
              {
                label: t('sickPay.question'),
                value: optionalValue(hasSickPayPreInsurance, formatBoolean)
              }
            ].concat(
              ...preInsurances
                .filter((pi) => pi.type === PreInsuranceTypeEnum.SICK_PAY)
                .map((pi) => [
                  {
                    label: t('attributes.benefitAmount'),
                    value: optionalValue(pi.benefitAmount, formatPrice)
                  },
                  {
                    label: t('attributes.company'),
                    value: optionalValue(formatPreInsuranceCompany(pi))
                  },
                  {
                    label: t('attributes.begin'),
                    value: optionalValue(pi.startDate, formatGermanDate)
                  },
                  {
                    label: t('attributes.end'),
                    value: optionalValue(pi.endDate, formatGermanDate)
                  }
                ])
            )}
          />
        </div>
      </div>

      <div className="flex space-x-[32px] mb-[32px] w-full">
        <div className="basis-1/2">
          <SimpleTable
            title={t('supplementary.title')}
            rows={[
              {
                label: t('supplementary.question'),
                value: optionalValue(hasSupplementaryPreInsurance, formatBoolean)
              }
            ].concat(
              ...preInsurances
                .filter((pi) => pi.type === PreInsuranceTypeEnum.SUPPLEMENTARY)
                .map((pi) => [
                  {
                    label: t('attributes.company'),
                    value: optionalValue(formatPreInsuranceCompany(pi))
                  },
                  {
                    label: t('attributes.begin'),
                    value: optionalValue(pi.startDate, formatGermanDate)
                  },
                  {
                    label: t('attributes.end'),
                    value: optionalValue(pi.endDate, formatGermanDate)
                  },
                  {
                    label: t('attributes.type'),
                    value: pi.supplementaryInsuranceType
                      ? pi.supplementaryInsuranceType
                      : t(`attributes.typeValue.${pi.type}`)
                  }
                ])
            )}
          />
        </div>
        <div className="basis-1/2">
          <SimpleTable
            title={t('transferValues.title')}
            rows={[
              {
                label: t('transferValues.comprehensiveHealthTransferValue'),
                value: optionalValue(comprehensiveHealthTransferValue, formatPrice)
              },
              {
                label: t('transferValues.referenceValuePPV'),
                value: optionalValue(referenceValuePPV, formatPrice)
              },
              {
                label: t('transferValues.fundsFromGZ'),
                value: optionalValue(fundsFromGZ, formatPrice)
              }
            ]}
          />
        </div>
      </div>
      <div className="flex space-x-[32px] w-full">
        <div className="basis-1/2">
          <SimpleTable
            title={t('bonity.title')}
            rows={[
              {
                label: t('bonity.hasInsuranceDept'),
                value: optionalValue(hasInsuranceDept, formatBoolean)
              },
              {
                label: t('bonity.hasUsedEmergencyTariff'),
                value: optionalValue(hasUsedEmergencyTariff, formatBoolean)
              },
              {
                label: t('bonity.hasInsolvency'),
                value: optionalValue(hasInsolvency, formatBoolean)
              }
            ]}
          />
        </div>
      </div>
    </>
  );
};
