import { Link as ReactLink } from 'react-router-dom';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Breadcrumbs, Typography } from '@mui/material';

import { Loader, LoaderType } from 'lkh-portal-ui-library';

import { useNewsPageContext } from '../context';
import { formatDateAndTime } from 'utilities/dates';

import styles from './NewsDetail.module.scss';

export function NewsDetail() {
  const { article, loading, error } = useNewsPageContext();

  const { t } = useTranslation();
  const imagePath = article?.image ? article?.image : '/images/default-news.png';

  const createMarkup = () => {
    return { __html: article?.text || '' };
  };

  if (loading) {
    return <Loader type={LoaderType.Circular} />;
  }

  if (error) {
    return (
      <div className={styles.content}>
        <Typography variant="bodyMDRegular">{t('common:page.news.articleNotFound')}</Typography>
      </div>
    );
  }

  const breadcrumbs = [
    <ReactLink key="1" to="/neuigkeiten" className="no-underline">
      <Typography variant="bodyMDRegularLink" className="text-text-80 hover:underline">
        Neuigkeiten
      </Typography>
    </ReactLink>,
    <Typography key="2" variant="bodyMDRegularLink" className="text-primary-80">
      {article && article.headline}
    </Typography>
  ];

  return (
    <div className={styles.wrapper}>
      <Breadcrumbs
        separator={<ChevronRightIcon fontSize="medium" className="text-text-20" />}
        aria-label="breadcrumb"
        className="px-[36px] py-[24px]"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <div className={styles.content}>
        <div className={classNames('col', styles.firstCol)}>
          <img src={imagePath} alt="" className="w-[178px] h-[178px] object-cover rounded-lg" />
        </div>
        <div className="col">
          <Typography variant="bodySMRegular" className="text-text-60">
            {article?.createdAt && formatDateAndTime(article?.createdAt)}
          </Typography>
          <Typography variant="headlineXLGBold" className="text-text-100" marginTop="10px">
            {article?.headline}
          </Typography>
          {article && article.subheadline && (
            <Typography variant="bodyLGRegular" color="#39393B" marginTop="10px">
              {article?.subheadline}
            </Typography>
          )}
          <Typography
            variant="bodyMDRegular"
            className={classNames(styles.markupContent, 'text-text-80 mt-[24px]')}
          >
            <span dangerouslySetInnerHTML={createMarkup()} />
          </Typography>
        </div>
      </div>
    </div>
  );
}
