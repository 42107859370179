import { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { AccountsProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { optionalValue } from '../../utils/formatters';
import { AccordionHandle, SectionAccordion } from '../SectionAccordion';
import { SimpleTable } from 'components/SimpleTable';

export const Accounts = forwardRef<AccordionHandle, AccountsProps>(({ accounts }, ref) => {
  const { t } = useTranslation('view360');

  if (!accounts) {
    return <></>;
  }

  return (
    <SectionAccordion
      header={<Typography variant="bodyMDBold">{t(`accounts.title`)}</Typography>}
      ref={ref}
    >
      <div className="row reset-container m-t-24 m-b-24">
        {accounts.map((account, index) => (
          <div className="col-6" key={`${account.type}-${account.iban}`}>
            <SimpleTable
              title={`${t('accounts.attributes.accountTitle')} ${index + 1}`}
              rows={[
                {
                  label: t('accounts.attributes.type'),
                  value: optionalValue(account.type)
                },
                {
                  label: t('accounts.attributes.owner'),
                  value: optionalValue(account.owner)
                },
                {
                  label: t('accounts.attributes.collectionType'),
                  value: optionalValue(account.collectionType)
                },
                {
                  label: t('accounts.attributes.bankName'),
                  value: optionalValue(account.bankName)
                },
                {
                  label: t('accounts.attributes.iban'),
                  value: optionalValue(account.iban)
                },
                {
                  label: t('accounts.attributes.bic'),
                  value: optionalValue(account.bic)
                },
                {
                  label: t('accounts.attributes.validFrom'),
                  value: optionalValue(account.validFrom, formatGermanDate)
                }
              ]}
            />
          </div>
        ))}
      </div>
    </SectionAccordion>
  );
});
