export const style = {
  top: '0px 10px 10px -10px #1C1B1F29',
  bottom: '0px -10px 10px -10px #1C1B1F29'
};
export const topView360Element = 'view360';
export const wizardElement = {
  top: 'partner-progress',
  bottom: 'wizard-actions'
};
const transition = '0.2s';

/** Add divider style based on root page of HTML  */
export const onRootScrollHandler = (): void => {
  const scrollTop = document?.documentElement?.scrollTop || document?.body?.scrollTop;
  const isBetween = scrollTop > 0;

  let topBoxShadow = 'none';
  if (isBetween) {
    topBoxShadow = style.top;
  }

  const topElement = document.getElementById(topView360Element);
  if (topElement) {
    topElement.style.transition = transition;
    topElement.style.boxShadow = topBoxShadow;
  }
};

/** Add divider style based on child page by specifying the child id  */
export const onWizardScrollHandler = (event: any): void => {
  const scrollTop = event?.target?.scrollTop;
  const scrollHeight = event?.target?.scrollHeight;
  const clientHeight = event?.target?.clientHeight;

  const isTop = scrollTop === 0;
  const isBottom = clientHeight === scrollHeight - scrollTop;
  const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

  let topBoxShadow = 'none';
  let bottomBoxShadow = 'none';
  if (isBetween || isBottom) {
    topBoxShadow = style.top;
  }
  if (isTop || isBetween) {
    bottomBoxShadow = style.bottom;
  }

  const topElement = document.getElementById(wizardElement.top);
  const bottomElement = document.getElementById(wizardElement.bottom);

  if (topElement) {
    topElement.style.transition = transition;
    topElement.style.boxShadow = topBoxShadow;
  }
  if (bottomElement) {
    bottomElement.style.transition = transition;
    bottomElement.style.boxShadow = bottomBoxShadow;
  }
};
