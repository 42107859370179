import { ValueGetterFunc } from 'ag-grid-community';

import { Policy } from 'models/portal-generated';

import i18n from 'services/translation';

import { ColumnIds } from './constants';
import { formatShortDate } from 'utilities/dates';
import { formatAddress } from 'utilities/string';

/*
  return number with spaces after every 3rd character
*/
function formatIdNumber(id: string): string {
  return id.toString();
}

export const VALUE_GETTERS: {
  [K in ColumnIds]?: ValueGetterFunc<Policy>;
} = {
  [ColumnIds.Address]: (params) => {
    const mainContact = params?.data?.mainContact;

    const formattedAddress = mainContact && formatAddress(mainContact);

    return { text: formattedAddress, data: params?.data };
  },
  [ColumnIds.id]: (params) => {
    const id = params?.data?.id?.toString() || '';
    return formatIdNumber(id);
  },
  [ColumnIds.HolderName]: (params) => {
    return {
      text: `${params.data?.policyHolder?.lastname}, ${params.data?.policyHolder?.firstname}`,
      data: params?.data
    };
  },
  [ColumnIds.PartnersCount]: (params) => {
    return {
      text: `${params.data?.insuredPartnersCount}`,
      data: params?.data
    };
  },
  [ColumnIds.Amount]: (params) => {
    const price = params?.data?.monthlyAmount;
    return price;
  },
  [ColumnIds.ValidFrom]: (params) => {
    return formatShortDate(params?.data?.validFrom || '');
  },
  [ColumnIds.Status]: (params) => {
    return i18n.t(`table.statusEnum.${params?.data?.status || ''}`, { ns: 'contract' });
  }
};
