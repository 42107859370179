import Typography from '@mui/material/Typography';

import styles from './TariffOption.module.scss';

type TariffOptionProps = {
  /**
   * Label of the tariff type (shown on the left)
   */
  label: string;

  /**
   * Optional description shown after the chips (usually option text)
   */
  description?: string;

  /**
   * List of represented tariffs (one item = one chip)
   */
  options?: Array<string>;
};

export const TariffOption = ({ label, options, description }: TariffOptionProps) => {
  return (
    <div className={styles.optionLabel}>
      <Typography className="m-r-24">{label}</Typography>
      {options?.map((opt) => (
        <span key={opt} className={styles.chipWrap}>
          <span key={opt} className={styles.chip}>
            {opt}
          </span>
        </span>
      ))}
      {description && <Typography className="m-0 p-r-12">{description}</Typography>}
    </div>
  );
};
