import { Route } from 'react-router';

import { WizardRoutesEnum } from './constants';
import { PrivateHealthInsurancePage } from './PrivateHealthInsurancePage';
import { GeneralDataStep } from './subpages/GeneralData/GeneralDataStep';
import { HealthDataStep } from './subpages/HealthData/HealthDataStep';
import { InsuranceHolderStep } from './subpages/InsuranceHolder/InsuranceHolderStep';
import { Offer } from './subpages/Offer';
import { PrivateDataStep } from './subpages/PrivateData/PrivateDataStep';
import { Summary } from './subpages/Summary';
import { TariffDataStep } from './subpages/TariffData';

export const WizardRouter = (
  <>
    <Route path={WizardRoutesEnum.Home} element={<PrivateHealthInsurancePage />} />
  </>
);

type WizardScreenProps = {
  currentScreen: WizardRoutesEnum;
};

export const WizardScreen = ({ currentScreen }: WizardScreenProps) => {
  switch (currentScreen) {
    case WizardRoutesEnum.PrivateData:
      return <PrivateDataStep />;
    case WizardRoutesEnum.Tariffs:
      return <TariffDataStep />;
    case WizardRoutesEnum.Offer:
      return <Offer />;
    case WizardRoutesEnum.GeneralQuestions:
      return <GeneralDataStep />;
    case WizardRoutesEnum.HealthQuestions:
      return <HealthDataStep />;
    case WizardRoutesEnum.InsuranceHolder:
      return <InsuranceHolderStep />;
    case WizardRoutesEnum.Summary:
      return <Summary />;
    default:
      throw new Error(`Invalid wizard screen: ${currentScreen}`);
  }
};
