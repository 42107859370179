import sortBy from 'lodash/sortBy';

import { Application, PersonRoleEnum } from 'models/extension-generated';
import { ColumnIds } from 'pages/Dashboard/components/Table/models';

import { ActionFunction, ApplicationsState, SetSortByPayload } from '../types';
import { SortingOrder } from 'hooks/useSort';

export const sortApplications: ActionFunction<SetSortByPayload> = (
  state: ApplicationsState,
  payload
): ApplicationsState => {
  const { applications } = state;
  const sortedApplications: Application[] = [];

  if (payload?.columnId === ColumnIds.HolderName) {
    sortedApplications.push(
      ...sortBy(applications, (application) => {
        const holder = application.partners.find((partner) =>
          partner.roles.includes(PersonRoleEnum.POLICY_HOLDER)
        );
        return holder?.lastname;
      })
    );
  }

  if (payload?.columnId === ColumnIds.id) {
    sortedApplications.push(...sortBy(applications, (application) => application.id));
  }

  if (payload?.columnId === ColumnIds.PartnersCount) {
    sortedApplications.push(
      ...sortBy(
        applications,
        (application) =>
          application.partners.filter((partner) =>
            partner.roles.includes(PersonRoleEnum.INSURED_PERSON)
          ).length
      )
    );
  }

  if (payload?.columnId === ColumnIds.CreatedAt) {
    sortedApplications.push(...sortBy(applications, (application) => application.creationDate));
  }

  // resolve ascending or descending
  if (payload?.order === SortingOrder.DESCENDING) {
    return {
      ...state,
      applications: [...sortedApplications].reverse()
    };
  }

  return {
    ...state,
    applications: sortedApplications
  };
};
