/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InSignStatus } from '../models/InSignStatus';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InSignService {

    /**
     * Create InSign dashboard
     * @returns InSignStatus Dashboard created
     * @throws ApiError
     */
    public static createInSignDashboard(): CancelablePromise<InSignStatus> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/insign/dashboard',
            errors: {
                403: `Forbidden`,
                500: `Internal Server Error`,
                512: `Insign service unavailable`,
            },
        });
    }

}
