import { Fragment, KeyboardEvent, SetStateAction, useCallback, useState } from 'react';
import { useLocation } from 'react-router';

import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import { Loader, LoaderType } from 'lkh-portal-ui-library';

import { NoResults, SearchResult } from '../SearchResult';
import { useSearchPolicies } from 'hooks/useSearchPolicies';

const DEBOUNCE_TIME = 500;

const handleSearchResultClick = (
  policyId: number,
  pathname: string,
  setSearchQuery: { (value: SetStateAction<string>): void }
) => {
  const path = `/360/vertrag/${policyId}`;
  if (pathname === path) {
    setSearchQuery('');
  }

  const newTab = window.open(path, '_blank');
  if (newTab) {
    newTab.focus();
  }
};

export function ContractsSearch() {
  const { t } = useTranslation('header');
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { isLoading, result } = useSearchPolicies({ searchQuery });

  const getSearchDelayed = useCallback(
    debounce((text) => {
      setSearchQuery(text);
    }, DEBOUNCE_TIME),
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string | null) => {
    const eventKey = (event as KeyboardEvent)?.key;
    if (event?.type === 'keydown' && (eventKey === 'Backspace' || eventKey === 'Delete')) {
      return;
    }
    getSearchDelayed(newValue?.replace(/[.#-$]/g, '')?.trim() || '');
    setOpen(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && searchQuery.length) {
      setOpen(true);
      getSearchDelayed(searchQuery);
    }
  };

  const handleFocus = () => {
    if (searchQuery.length) {
      setOpen(true);
      getSearchDelayed(searchQuery);
    }
  };

  return (
    <Autocomplete
      id="async-search"
      sx={{ height: 46 }}
      ListboxProps={{ sx: { overflowX: 'hidden' } }}
      freeSolo={!!result.length}
      loadingText={
        <Typography variant="bodySMRegular" className="p-[20px] h-[28px] inline-flex items-center">
          {t('search.loadingText')}
        </Typography>
      }
      forcePopupIcon={false}
      open={open}
      onOpen={() => searchQuery.length && setOpen(true)}
      onClose={() => setOpen(false)}
      options={result}
      getOptionLabel={() => searchQuery}
      loading={isLoading}
      clearOnEscape
      noOptionsText={
        <NoResults
          onResetSearch={() => {
            setSearchQuery('');
            setOpen(false);
          }}
        />
      }
      onInputChange={handleChange}
      onKeyDown={handleKeyDown}
      onFocus={handleFocus}
      renderInput={(params) => (
        <TextField
          {...params}
          className="w-[50vw]"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: 23,
              bgcolor: colors.white[100],
              height: 46,
              input: {
                fontSize: 14,
                fontWeight: 400,
                lineHeight: 20,
                '&::placeholder': {
                  color: colors.text[60]
                }
              }
            },
            '& fieldset': {
              borderColor: colors.text[20]
            },
            '&:hover fieldset': {
              borderColor: `${colors.text[80]} !important`
            }
          }}
          size="small"
          placeholder={t('search.placeholder')}
          InputProps={{
            ...params.InputProps,
            startAdornment: <SearchIcon className="px-[10px]" />,
            endAdornment: (
              <Fragment>
                {isLoading ? <Loader type={LoaderType.Circular} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            )
          }}
        />
      )}
      renderOption={(props, partner) => {
        return (
          <li {...props} key={partner.id} className="m-[0px]">
            <SearchResult
              onClick={(policyId) => handleSearchResultClick(policyId, pathname, setSearchQuery)}
              partner={partner}
            />
          </li>
        );
      }}
    />
  );
}
