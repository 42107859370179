import { PortalLayout } from 'layout/portal';

import { Filters } from './components/Filters';
import { Table } from './components/Table';
import { DashboardTabs } from './components/Tabs/Tabs';
import { DashboardContextProvider } from './context/DashboardPageContext';

export const DashboardPage = () => {
  return (
    <PortalLayout>
      <DashboardContextProvider>
        <div className="w-full box-border relative bg-white-100">
          <div className="flex flex-col justify-center gap-[16px] pl-[36px] pr-[64px] pt-[24px]">
            <div className="w-full border-solid border-b-[1px] border-l-0 border-r-0 border-t-0 border-b-text-20">
              <DashboardTabs />
            </div>
            <Filters />
            <Table />
          </div>
        </div>
      </DashboardContextProvider>
    </PortalLayout>
  );
};
