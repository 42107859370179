import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { useUploadContext } from 'contexts/UploadContext';

type UploaderProps = {
  translationNameSpace: string;
};

export function Uploader({ translationNameSpace }: UploaderProps) {
  const { t } = useTranslation(translationNameSpace, { keyPrefix: 'upload' });
  const { completed, files, close } = useUploadContext();

  return (
    <div className="p-[24px]">
      <div className="mb-[12px] flex flex-row justify-between items-center">
        <Typography variant="bodyMDBold" className="text-text-100">
          {t('oneMoment')}
        </Typography>
        <div onClick={close} className="cursor-pointer">
          <CloseIcon fontSize="inherit" sx={{ width: 24, height: 24 }} />
        </div>
      </div>

      <div className="row reset-container mb-[24px]">
        <div className="col">
          <Typography variant="bodyMDRegular" className="text-text-60">
            {t('uploading.description')}
          </Typography>
        </div>
      </div>

      <div className="row reset-container">
        <div className="col flex justify-between items-center">
          <div className="flex flex-row justify-center text-text-80">
            <CircularProgress color="inherit" size={16} className="mr-[16px]" />
            <Typography variant="bodyMDRegular" className="text-text-80">
              {`${completed}/${files.length} ${t('uploading.counter')}`}
            </Typography>
          </div>
          <Button className="py-[11px]" size="large" onClick={close}>
            {t('actions.cancel')}
          </Button>
        </div>
      </div>
    </div>
  );
}
