import { ApplicationGroups } from '../ApplicationFilterReducer';
import { ActionFunction, ApplicationsState, TabType } from '../types';

export const setSelectedTab: ActionFunction<TabType> = (state: ApplicationsState, payload) => {
  const { filters } = state;

  if (payload === TabType.OpenApplications) {
    filters.statuses = ApplicationGroups.OpenApplications;
    state.selectedTab = TabType.OpenApplications;
  }

  if (payload === TabType.SubmittedApplications) {
    filters.statuses = ApplicationGroups.SubmittedApplications;
    state.selectedTab = TabType.SubmittedApplications;
  }
  return {
    ...state,
    filters: filters
  };
};
