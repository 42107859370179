import { useMemo, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import AddCommentIcon from '@mui/icons-material/AddComment';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Autocomplete, IconButton, TextField, Typography } from '@mui/material';

import { DarkTooltip, DropdownOption } from 'lkh-portal-ui-library';
import { IntentEnumOptions } from 'models';
import { IntentEnum } from 'models/portal-generated';

import { FileDetail } from 'contexts/UploadContext';

import styles from './UploadDocuments.module.scss';

type DocumentProps = {
  translationNameSpace: string;
  file: FileDetail;
  showError?: boolean;
  showIntent?: boolean;
  onDelete?: (file: FileDetail) => void;
  onIntentSelection?: (fileName: string, intent: IntentEnum) => void;
  onCommentChange?: (fileName: string, comment: string) => void;
};

export const Document = ({
  translationNameSpace,
  file,
  showError,
  showIntent,
  onDelete,
  onIntentSelection,
  onCommentChange
}: DocumentProps) => {
  const { t } = useTranslation(translationNameSpace, { keyPrefix: 'upload' });
  const [showCommentBox, setShowCommentBox] = useState(false);
  const intentValue = file.intent
    ? IntentEnumOptions.find((option) => option.value === file.intent)
    : null;

  function formatBytes(bytes: number, decimals: number) {
    if (bytes == 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  const categoryOptions = useMemo<Array<DropdownOption>>(() => IntentEnumOptions, []);

  function handleIntentChange(opts?: string | null | undefined) {
    const intent = categoryOptions.find((option) => option.value === opts)?.value as IntentEnum;
    onIntentSelection?.(file.name, intent);
  }

  const errorIntent = showError && !file.intent;

  const handleToggleUpdate = () => {
    setShowCommentBox((prev) => {
      if (prev) {
        onCommentChange?.(file.name, '');
      }
      return !prev;
    });
  };

  function handleCommentUpdate(text: string) {
    onCommentChange?.(file.name, text);
  }

  const maximalNameLength = showIntent ? 18 : 55;
  return (
    <>
      <div className={classNames(styles.fileWrapper, 'p-[16px] mb-[16px]')}>
        <div className="flex flex-row items-center justify-between">
          <div className="flex-none">
            {showIntent && <CheckCircleIcon color={intentValue ? 'primary' : 'disabled'} />}
            {!showIntent && <CheckCircleIcon color="primary" />}
          </div>
          <div
            className={classNames('flex-none items-center', {
              'basis-4/12': showIntent,
              'basis-8/12': !showIntent
            })}
          >
            <DarkTooltip title={file.name}>
              <Typography variant="bodyMDRegular" className="text-text-80  ml-[8px]">
                {file.name.length > maximalNameLength
                  ? file.name.slice(0, maximalNameLength).concat('...')
                  : file.name}
              </Typography>
            </DarkTooltip>
          </div>
          <div className="flex-1 justify-end text-right px-[8px] whitespace-nowrap">
            <span className="text-sm text-text-40">{formatBytes(file.size, 0)}</span>
          </div>
          <div className="flex-none">
            <div className="flex flex-row justify-end items-center w-full">
              {showIntent && (
                <div className="">
                  <Autocomplete
                    fullWidth
                    value={intentValue}
                    options={categoryOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={errorIntent}
                        label={t('files.intent')}
                        className="w-[271px]"
                      />
                    )}
                    onChange={(_event, value) => {
                      handleIntentChange(value?.value as string);
                    }}
                  />
                </div>
              )}
              <div>
                <IconButton aria-label="comment" onClick={handleToggleUpdate}>
                  {showCommentBox ? <AddCommentIcon /> : <AddCommentOutlinedIcon />}
                </IconButton>
              </div>
              <div>
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    onDelete?.(file);
                  }}
                >
                  <DeleteForeverOutlinedIcon />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCommentBox && (
        <div className="mb-m">
          <TextField
            label={t('files.commentPlaceholder')}
            fullWidth
            multiline
            className="max-h-[100px] min-h-[90px]"
            minRows={2}
            maxRows={3}
            onChange={(event) => handleCommentUpdate(event.target.value)}
          />
        </div>
      )}
    </>
  );
};
