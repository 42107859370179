import Typography from '@mui/material/Typography';

import { Form } from 'lkh-portal-ui-library';
import { Tariff } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../context/HealthInsuranceContext';
import { useExtendedReducer } from '../../../../hooks/useExtendedReducer';
import { useProvidedTariffs } from '../../contexts/TariffsContext';
import { StepOption, StepPicker } from '../StepPicker';

type TariffStepsProps = {
  title?: string;
  stepLabel: string;
};

export const TariffSteps = ({ title, stepLabel }: TariffStepsProps) => {
  const { dispatch, partnerId } = useHealthInsuranceContext();
  const { clearTariffs, giveTariff } = useExtendedReducer(dispatch);
  const { sectionTariffs, section, selectedTariffs, updateHealthQuestions } = useProvidedTariffs();

  const steps: Array<StepOption> = sectionTariffs.map((tariff) => {
    return {
      key: tariff.id,
      stepLabel: tariff.options?.[0] || tariff.description,
      isEnabled: selectedTariffs.map((t) => t.id).includes(tariff.id),
      value: selectedTariffs.find((t) => t.id === tariff.id)?.highMoneyAmount || undefined
    };
  });

  function handleChange(steps: Array<StepOption>): void {
    function mapStepToTariff(step: StepOption): Tariff {
      const selected = selectedTariffs.find(({ id }) => id === step.key);
      const original = selected || sectionTariffs.find(({ id }) => id === step.key);
      if (!original) {
        throw new Error('Original tariff not found!');
      }

      const copy: Tariff = { ...original };
      copy.highMoneyAmount = +(step.value || 0);
      return copy;
    }

    function giveTariffToPartner(tariff: Tariff) {
      giveTariff({ partnerId, tariff });
    }

    clearTariffs({ partnerId, section });

    steps
      .filter(({ isEnabled }) => isEnabled)
      .map(mapStepToTariff)
      .forEach(giveTariffToPartner);

    updateHealthQuestions();
  }

  return (
    <Form.Section>
      <Typography variant="bodyLGBold">{title}</Typography>
      <div>
        <StepPicker
          partnerId={partnerId}
          valueLabel={stepLabel}
          unit={'€'}
          steps={steps}
          onChange={(key, property, value) => {
            const newSteps = steps.map((step) => {
              if (step.key === key) {
                if (property === 'isEnabled' && typeof value === 'boolean') {
                  return { ...step, isEnabled: value };
                }
                return { ...step, value };
              }
              return step;
            });
            handleChange(newSteps);
          }}
        />
      </div>
    </Form.Section>
  );
};
