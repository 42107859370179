import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { AgentPickerDialog } from '../AgentPickerDialog';
import { AgentPickerLoadingDialog } from '../AgentPickerLoadingDialog';
import { useAgentSession } from 'hooks/useAgentSession';

export const AgentPickerPanel = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'navigation.agentPicker'
  });

  const { agentSession, setAgentSession, resetAgentSession } = useAgentSession();
  const [isAgentPickerDialogOpen, setIsAgentPickerDialogOpen] = useState(false);
  const [isAgentPickerLoadingDialogOpen, setIsAgentPickerLoadingDialogOpen] = useState(false);
  const isCancel = useRef(false);

  return (
    <>
      {isAgentPickerDialogOpen && (
        <AgentPickerDialog
          handleAgentSelection={async (agent) => {
            setAgentSession({
              agentId: agent.agentId,
              name: agent.name,
              hasServiceLKHInternalRole: true,
              agentPickerEnabled: true
            });
            setIsAgentPickerLoadingDialogOpen(false);
          }}
          isDialogOpen={isAgentPickerDialogOpen}
          onDialogClose={() => setIsAgentPickerDialogOpen(false)}
        />
      )}
      {isAgentPickerLoadingDialogOpen && (
        <AgentPickerLoadingDialog
          isDialogOpen={isAgentPickerLoadingDialogOpen}
          cancelAction={() => {
            isCancel.current = true;
          }}
        />
      )}
      {!agentSession.agentPickerEnabled && (
        <Chip
          variant="support"
          className="w-full font-bold text-xs"
          icon={<OpenInNewIcon className="fill-white-100" fontSize="small" />}
          onClick={() => setIsAgentPickerDialogOpen(true)}
          label={t('dialogButton.text')}
        />
      )}
      {agentSession.agentPickerEnabled && (
        <>
          <Chip
            variant="support"
            className="w-full font-bold text-xs"
            icon={<CloseIcon className="fill-white-100" fontSize="small" />}
            label={t('resetButton.text')}
            onClick={resetAgentSession}
          />
          <Box className="w-full bg-navigation-70 my-m rounded">
            <div className="p-s">
              <Typography variant="bodySMRegular" component="div">
                {t('currentAgent.text')}
              </Typography>
              <Typography variant="bodySMBold" component="div">
                {agentSession.name}
              </Typography>
              <Typography variant="bodySMBold" component="div">
                {agentSession.agentId}
              </Typography>
            </div>
          </Box>
        </>
      )}
    </>
  );
};
