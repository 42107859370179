import { PortalLayout } from 'layout/portal';

import { News } from './components/News/News';
import { NewsContextProvider } from './context';

export function NewsPage() {
  return (
    <PortalLayout>
      <NewsContextProvider>
        <News />
      </NewsContextProvider>
    </PortalLayout>
  );
}
