import { Fragment } from 'react';

import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { HealthQuestionsProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { formatBoolean, optionalValue } from '../../utils/formatters';
import { Rows } from '../Rows';

export const HealthQuestions = ({
  isView360 = false,
  statutoryReporting,
  acknowledgment,
  questions
}: HealthQuestionsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="mb-[8px]">
        <span className="text-sm text-text-60 font-normal">
          {t('view360:insuredPerson.health.legalObligation.title')}
        </span>
      </div>
      <Rows
        rows={[
          {
            label: t('view360:insuredPerson.health.legalObligation.attributes.statutoryReporting'),
            value: optionalValue(statutoryReporting, formatBoolean)
          },
          {
            isLabelBoldDisabled: true,
            label: (
              <Trans
                i18nKey="insuredPerson.health.legalObligation.attributes.acknowledgmentSummary"
                ns="view360"
                components={{ bold: <strong /> }}
              />
            ),
            value: optionalValue(acknowledgment, formatBoolean)
          }
        ]}
      />

      <div className="mt-[32px] mb-[8px]">
        <span className="text-sm text-text-60 font-normal">
          {t('view360:insuredPerson.health.questions.title')}
        </span>
      </div>
      {(questions || []).map((question) => {
        return (
          <Fragment key={question.id}>
            <Rows
              rows={[
                {
                  label: question.text,
                  value: question.answer
                }
              ]}
            />
            {(question.details || []).map((detail, index) => (
              <div
                key={detail.diagnosis}
                className={classNames('flex space-x-[32px] w-full my-[32px]', {
                  ['bg-surface-60 rounded-[4px] pl-[24px] py-[16px]']: isView360
                })}
              >
                <div className={classNames('w-full', { ['mr-[48px]']: isView360 })}>
                  <div className="mb-[8px]">
                    <span className="text-sm text-text-60 font-normal">
                      {`${t('view360:insuredPerson.health.questions.detail')} ${index + 1}`}
                    </span>
                  </div>
                  <Rows
                    hasDivider={false}
                    rows={[
                      {
                        label: t('view360:insuredPerson.health.questions.attributes.diagnose'),
                        value: detail.diagnosis
                      },
                      {
                        label: t(
                          'view360:insuredPerson.health.questions.attributes.treatmentDuration'
                        ),
                        value: `${optionalValue(
                          detail.treatmentStart,
                          formatGermanDate
                        )} - ${optionalValue(detail.treatmentEnd, formatGermanDate)}`
                      },
                      {
                        label: t('view360:insuredPerson.health.questions.attributes.operation'),
                        value: optionalValue(detail.hasOperation, formatBoolean)
                      },
                      {
                        label: t(
                          'view360:insuredPerson.health.questions.attributes.sickLeaveDuration'
                        ),
                        value: optionalValue(detail.sickLeaveDuration)
                      },
                      {
                        label: t('view360:insuredPerson.health.questions.attributes.doctor'),
                        value: optionalValue(detail.doctor)
                      },
                      {
                        label: t('view360:insuredPerson.health.questions.attributes.consenquences'),
                        value: optionalValue(detail.hasConsenquences, formatBoolean)
                      }
                    ]}
                  />
                </div>
              </div>
            ))}
          </Fragment>
        );
      })}
    </>
  );
};
