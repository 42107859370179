import { ITooltipParams } from 'ag-grid-community';

import Typography from '@mui/material/Typography';

export const TableHeaderTooltip = (props: ITooltipParams) => {
  const isHeader = props.rowIndex === undefined;
  const header = props.value || '';

  if (!isHeader) return <></>;

  return (
    <div className="bg-text-80 rounded-sm px-[8px] py-[4px]">
      <Typography variant="bodySMRegular" className="text-white-100">
        {header}
      </Typography>
    </div>
  );
};
