import { createContext, ReactNode, useContext, useMemo } from 'react';

import { QueryObserverResult } from '@tanstack/react-query';

import { FilterGroup } from 'models/criteriaTypes';
import {
  CriteriaType,
  PoliciesResponse,
  Policy,
  SearchOperationEnum
} from 'models/portal-generated';

import { useContracts } from '../components/Grid/hooks/useContracts';
import { FilterGroupsEnum, FilterGroupsKeys } from '../constants';
import { ActiveFiltersCount, useCount } from '../hooks/useCount';
import { DropdownOption } from '../types';
import { useFilter, UseFilter } from 'hooks/useFilter';
import { usePagination, UsePagination } from 'hooks/usePagination';
import { useSort, UseSort } from 'hooks/useSort';

type DateValue = Date | null;

export type ContractContextValue = {
  total: number;
  contracts: Array<Policy>;
  pagination: UsePagination;
  filters: UseFilter;
  sorting: UseSort;
  loading: boolean;
  count: ActiveFiltersCount;
  fetchContractsFn: () => Promise<QueryObserverResult<PoliciesResponse, Error>>;
  handleRangeChange: (val: { from?: string; to?: string; groupId: string }) => void;
  handleDateRangeChange: (val: { from?: DateValue; to?: DateValue; groupId: string }) => void;
  handleTextFilterChange: (groupId: string, value: string | undefined) => void;
  handleListFilterChange: (
    values: Array<DropdownOption>,
    groupId: FilterGroupsEnum,
    operation: SearchOperationEnum.IN | SearchOperationEnum.NOT_IN,
    options: Array<DropdownOption>
  ) => void;
  handleCustomFilterChange: (groupId: FilterGroupsEnum, filterGroup: FilterGroup) => void;
  removeFilter: (groupId: FilterGroupsEnum) => void;
  getSelectedValues: (groupId: FilterGroupsEnum, list: Array<DropdownOption>) => DropdownOption[];
  getSelectedValue: (groupId: FilterGroupsEnum) => string | null;
  getCustomFilterValue: (groupId: FilterGroupsEnum) => FilterGroup | undefined;
  handleTariffFilterChange: (
    values: Array<DropdownOption>,
    groupId: FilterGroupsEnum,
    options: Array<DropdownOption>
  ) => void;
  handleNotTariffFilterChange: (
    values: Array<DropdownOption>,
    groupId: FilterGroupsEnum,
    options: Array<DropdownOption>
  ) => void;
};

export const ContractContext = createContext<ContractContextValue | null>(null);

type ContractContextProviderProps = { children: ReactNode };

export const ContractContextProvider = ({ children }: ContractContextProviderProps) => {
  const pagination = usePagination({ page: 0, size: 10 });
  const filters = useFilter();
  const sorting = useSort();

  const count = useCount(filters.active);

  const { contracts, total, loading, fetchContractsFn } = useContracts({
    filters,
    pagination,
    sorting
  });

  /**
   * Commits current filter group selections or remove group if empty
   */
  function commitFilters(groupId: string, selection: Array<FilterGroup>) {
    if (selection.length) {
      filters?.set(selection);
    } else {
      filters?.remove(groupId);
    }

    pagination.goTo(0);
  }

  type Range = {
    from?: string;
    to?: string;
    oldFrom?: string;
    oldTo?: string;
  };

  function handleRangeChange({ from, to, groupId }: Range & { groupId: string }) {
    const groupKey = FilterGroupsKeys[groupId as FilterGroupsEnum];
    const group: FilterGroup = {
      groupKey,
      groupId,
      criteriaType: CriteriaType.AND,
      criteria: []
    };

    if (from) {
      group.criteria?.push({
        key: groupKey,
        value: from,
        operation: SearchOperationEnum.GREATER_THAN_EQUALS
      });
    }
    if (to) {
      group.criteria?.push({
        key: groupKey,
        value: to,
        operation: SearchOperationEnum.LESS_THAN_EQUALS
      });
    }

    commitFilters(groupId, [group]);
  }

  type DateRange = {
    from?: DateValue;
    to?: DateValue;
    oldFrom?: string;
    oldTo?: string;
  };

  function handleDateRangeChange({ from, to, groupId }: DateRange & { groupId: string }) {
    const groupKey = FilterGroupsKeys[groupId as FilterGroupsEnum];
    const group: FilterGroup = {
      groupKey,
      groupId,
      criteriaType: CriteriaType.AND,
      criteria: []
    };

    if (from) {
      group.criteria?.push({
        key: groupKey,
        value: from.toISOString(),
        operation: SearchOperationEnum.GREATER_THAN_EQUALS
      });
    }
    if (to) {
      group.criteria?.push({
        key: groupKey,
        value: to.toISOString(),
        operation: SearchOperationEnum.LESS_THAN_EQUALS
      });
    }

    commitFilters(groupId, [group]);
  }

  /**
   * Handles the change in a text input filters
   */
  function handleTextFilterChange(groupId: string, value: string | undefined) {
    const groupKey = FilterGroupsKeys[groupId as FilterGroupsEnum];

    if (value) {
      const group: FilterGroup = {
        groupKey,
        groupId,
        criteriaType: CriteriaType.OR,
        criteria: [
          {
            key: groupKey,
            operation: SearchOperationEnum.START_WITH,
            value: value
          }
        ]
      };

      commitFilters(groupId, [group]);
    } else {
      filters.remove(groupId);
    }
  }

  function handleListFilterChange(
    values: Array<DropdownOption>,
    groupId: FilterGroupsEnum,
    operation: SearchOperationEnum.IN | SearchOperationEnum.NOT_IN
  ) {
    if (values.length === 0) {
      filters.remove(groupId);
      return;
    }

    const groupKey = FilterGroupsKeys[groupId];
    const group: FilterGroup = {
      groupKey,
      groupId,
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: groupKey,
          operation,
          value: values.map(({ value }) => value)
        }
      ]
    };
    commitFilters(groupId, [group]);
  }

  function handleTariffFilterChange(values: Array<DropdownOption>, groupId: FilterGroupsEnum) {
    if (values.length === 0) {
      filters.remove(groupId);
      return;
    }

    const groupKey = FilterGroupsKeys[FilterGroupsEnum.TariffListView];
    const group: FilterGroup = {
      groupKey,
      groupId,
      criteriaType: CriteriaType.OR,
      criteria: values.map(({ value }) => ({
        key: groupKey,
        operation: SearchOperationEnum.LIKE,
        value: `:${String(value).trim()}:`
      }))
    };

    commitFilters(groupId, [group]);
  }

  function handleNotTariffFilterChange(values: Array<DropdownOption>, groupId: FilterGroupsEnum) {
    if (values.length === 0) {
      filters.remove(groupId);
      return;
    }

    const groupKey = FilterGroupsKeys[FilterGroupsEnum.TariffListView];
    const group: FilterGroup = {
      groupKey,
      groupId,
      criteriaType: CriteriaType.OR,
      criteria: values.map(({ value }) => ({
        key: groupKey,
        operation: SearchOperationEnum.NOT_LIKE,
        value: `:${String(value).trim()}:`
      }))
    };
    group.criteria?.push({
      key: FilterGroupsKeys.InsuredPartners,
      operation: SearchOperationEnum.IS_EMPTY,
      value: ''
    });

    commitFilters(groupId, [group]);
  }

  function removeFilter(groupId: FilterGroupsEnum) {
    filters.remove(groupId);
  }

  /**
   * Commit custom build query filter.
   * Use with caution, if you are handling onChange event from Basic Multiselect or Input use handleListFilterChange or handleTextFilterChange
   * This function will NOT remove existing filter group so be sure to remove it before commiting new one
   * @param groupId
   * @param filterGroup
   */
  function handleCustomFilterChange(groupId: FilterGroupsEnum, filterGroup: FilterGroup) {
    commitFilters(groupId, [filterGroup]);
  }

  function getSelectedValues(
    groupId: FilterGroupsEnum,
    options: Array<DropdownOption>
  ): Array<DropdownOption> {
    function getOptions(values: Array<string>, options: Array<DropdownOption>): DropdownOption[] {
      return values.reduce((acc: DropdownOption[], value: string) => {
        const option = options.find((o) => o.value === value.replace(/:/g, ''));
        if (option) acc.push(option);
        return acc;
      }, []);
    }
    const criteria = filters.active.find((filter) => filter.groupId === groupId)?.criteria;
    const firstCriteriaValue: string | string[] = criteria?.at(0)?.value;

    if (criteria && firstCriteriaValue) {
      const criteriaValues = Array.isArray(firstCriteriaValue)
        ? firstCriteriaValue
        : criteria.map((c) => c.value as string);

      return getOptions(criteriaValues, options);
    }

    return [];
  }

  function getSelectedValue(groupId: FilterGroupsEnum): string {
    const value = filters.active.find((filter) => {
      return filter.groupId === groupId;
    })?.criteria?.[0]?.value;
    return value || '';
  }
  function getCustomFilterValue(groupId: FilterGroupsEnum): FilterGroup | undefined {
    return filters.active.find((filter) => {
      return filter.groupId === groupId;
    });
  }

  const filterDetectionString = filters.active.map((filter) => filter.groupId).join('_');
  const ctx: ContractContextValue = useMemo(() => {
    return {
      count,
      total,
      pagination,
      filters,
      sorting,
      contracts,
      loading,
      handleRangeChange,
      handleDateRangeChange,
      handleTextFilterChange,
      handleListFilterChange,
      handleCustomFilterChange,
      removeFilter,
      getSelectedValues,
      getSelectedValue,
      getCustomFilterValue,
      handleTariffFilterChange,
      handleNotTariffFilterChange,
      fetchContractsFn
    };
  }, [total, pagination, filterDetectionString, sorting, contracts, loading]);

  return <ContractContext.Provider value={ctx}>{children}</ContractContext.Provider>;
};

export const useContractPageContext = (): ContractContextValue => {
  const context = useContext(ContractContext);

  if (context === null) {
    throw new Error('useContractPageContext was used outside of its Provider');
  }

  return context;
};
