import { useTranslation } from 'react-i18next';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Dialog } from '@mui/material';
import Button from '@mui/material/Button';

import Dropzone from './components/Dropzone';
import { Complete, Uploader } from 'components/UploadDocuments';
import { UploadContextProvider, UploadStage, useUploadContext } from 'contexts/UploadContext';

const UploadDocumentsContent = () => {
  const { t } = useTranslation('dashboard');
  const { open, close, stage } = useUploadContext();

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<FileUploadOutlinedIcon fontSize="medium" />}
        size="small"
        onClick={open}
      >
        {t('tab.submitPdfButton')}
      </Button>
      <Dialog
        disableScrollLock
        fullWidth
        maxWidth="md"
        open={stage !== UploadStage.Closed}
        onClose={close}
      >
        {stage === UploadStage.Browse && <Dropzone showIntent={false} />}
        {stage === UploadStage.Upload && <Uploader translationNameSpace="dashboard" />}
        {stage === UploadStage.Complete && <Complete translationNameSpace="dashboard" />}
      </Dialog>
    </>
  );
};

export const GeneralUpload = () => {
  const { t } = useTranslation('dashboard');

  return (
    <UploadContextProvider
      validationFn={(files) => {
        const errors = new Map<string, string>();
        if (files.length === 0) {
          return {
            hasError: true,
            errorMessage: t('upload.errors.noFiles')
          };
        }

        return {
          hasError: errors.size > 0,
          errorMessage: t('upload.errors.noIntent'),
          errors
        };
      }}
      uploadUrl={`/api/document/upload`}
    >
      <UploadDocumentsContent />
    </UploadContextProvider>
  );
};
