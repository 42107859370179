import { useTranslation } from 'react-i18next';

import { Form } from 'lkh-portal-ui-library';
import { PartnerIdProp } from 'models';
import { PreInsuranceTypeEnum } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { usePreInsurances } from '../../hooks/usePreInsurance';
import { PreInsuranceContextMenu } from '../PreInsuranceContextMenu';
import { PreInsuranceStartEndForm } from '../PreInsuranceStartEndForm';
import { PreInsuranceTypeForm } from '../PreInsuranceTypeForm';
import { PreInsuranceWrapper } from '../PreInsuranceWrapper';

const { Input } = Form.Components;

export const SupplementaryPreInsurance = ({ partnerId }: PartnerIdProp) => {
  const TYPE = PreInsuranceTypeEnum.SUPPLEMENTARY;
  const TOGGLE = 'hasSupplementaryPreInsurance';

  const { t } = useTranslation('wizardGeneral');

  const { state } = useHealthInsuranceContext();
  const { preInsurances, preInsuranceKey } = usePreInsurances(state.model, partnerId, TYPE);

  return (
    <PreInsuranceWrapper
      type={TYPE}
      partnerId={partnerId}
      preInsuranceKey={'hasSupplementaryPreInsurance'}
    >
      {preInsurances.map((preInsurance) => (
        <section key={preInsurance.id}>
          <PreInsuranceContextMenu
            title={t('longTerm.preInsurance.title')}
            toggleKey={TOGGLE}
            partnerId={partnerId}
            preInsurance={preInsurance}
          />
          <PreInsuranceTypeForm
            insuranceCompanyKey={preInsuranceKey(preInsurance.id, 'insuranceCompany')}
            privateInsuranceCompanyKey={preInsuranceKey(preInsurance.id, 'privateInsuranceCompany')}
            isPublicKey={preInsuranceKey(preInsurance.id, 'isPublic')}
          />
          <PreInsuranceStartEndForm
            startDateKey={preInsuranceKey(preInsurance.id, 'startDate')}
            endDateKey={preInsuranceKey(preInsurance.id, 'endDate')}
            ongoingKey={preInsuranceKey(preInsurance.id, 'ongoing')}
          />
          <div className="grid-res-2">
            <div>
              <Input
                componentKey={preInsuranceKey(preInsurance.id, 'supplementaryInsuranceType')}
                label={t('longTerm.preInsurance.fields.supplementaryType.placeholder')}
              />
            </div>
          </div>
        </section>
      ))}
    </PreInsuranceWrapper>
  );
};
