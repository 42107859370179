/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * Name of the federal repulic (Bundesrepublik)
 */
export enum FederalStateEnum {
    BUND = 'BUND',
    BADEN_WURTTEMBERG = 'BADEN_WURTTEMBERG',
    BAYERN = 'BAYERN',
    BERLIN = 'BERLIN',
    BRANDERBURG = 'BRANDERBURG',
    BREMEN = 'BREMEN',
    HAMBURG = 'HAMBURG',
    HESSEN = 'HESSEN',
    MECKLENBURG_VORPOMMERN = 'MECKLENBURG_VORPOMMERN',
    NIEDERSACHSEN = 'NIEDERSACHSEN',
    NORDHEIN_WESTFALEN = 'NORDHEIN_WESTFALEN',
    RHEINLAND_PFALZ = 'RHEINLAND_PFALZ',
    SAARLAND = 'SAARLAND',
    SACHSEN = 'SACHSEN',
    SACHSEN_ANHALT = 'SACHSEN_ANHALT',
    SCHLESWIG_HOLSTEIN = 'SCHLESWIG_HOLSTEIN',
    THURINGEN = 'THURINGEN',
}
