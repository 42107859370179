import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CheckCircle from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography
} from '@mui/material';

import { DarkTooltip } from 'lkh-portal-ui-library';
import { HealthQuestion } from 'models/extension-generated';

import { HealthQuestionDetailJoined } from './DiagnosesModal';
import { DetailValue, DiagnosisForm } from './DiagnosisForm';
import { Errors } from './diagnosisReducer';
import { useHealthQuestionDiagnosesErrors } from './useDiagnosesValidation';

export const DiagnosisAccordion = ({
  isExpanded: isInitallyExpanded,
  isCompleted,
  openOverride,
  detail,
  errors,
  healthQuestions,
  yesQuestionsOptions,
  onDelete,
  onDuplicate,
  handleNewDiagnosisChange
}: {
  errors: Errors;
  openOverride?: boolean;
  isCompleted?: boolean;
  isExpanded?: boolean;
  detail: HealthQuestionDetailJoined;
  healthQuestions: HealthQuestion[];
  yesQuestionsOptions: { label: string; value: string }[];
  onDelete: (id: string) => void;
  onDuplicate: (detail: HealthQuestionDetailJoined) => void;
  handleNewDiagnosisChange: (
    id: string,
    value: DetailValue,
    key: keyof HealthQuestionDetailJoined
  ) => void;
}) => {
  const { t } = useTranslation('wizardHealth', {
    keyPrefix: 'section.diagnosis'
  });
  const formErrors = useHealthQuestionDiagnosesErrors({
    healthQuestionId: detail.question?.id || ''
  });
  const hasError = formErrors.length > 0 || errors.size > 0;

  const [isExpanded, setIsExpanded] = useState(isInitallyExpanded);
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = useState(false);
  const { diagnosis, doctor, hasConsenquences, sickLeaveDuration, treatmentStart, treatmentEnd } =
    detail;
  const isDisabled =
    !diagnosis &&
    !doctor &&
    !hasConsenquences &&
    !sickLeaveDuration &&
    !treatmentStart &&
    !treatmentEnd;

  // if Open override changes update local state
  useEffect(() => {
    setIsExpanded(openOverride ?? isInitallyExpanded);
  }, [openOverride]);

  return (
    <>
      {/* Confirmation before deleting an item */}
      <Dialog fullWidth maxWidth="sm" open={isConfirmDeleteDialogOpen}>
        <DialogTitle className="flex flex-row items-center px-[32px] py-[24px]">
          <ErrorIcon className="self-baseline text-danger-60 mr-[16px]" />
          <div className="flex flex-col">
            <Typography
              fontWeight={700}
              fontSize="16px"
              lineHeight="20px"
              className="text-text-100 mb-[8px]"
            >
              {t('confirmationDelete.title')}
            </Typography>
            <Typography fontSize="14px" lineHeight="20px" className="text-text-80">
              {t('confirmationDelete.description')}
            </Typography>
          </div>
        </DialogTitle>
        <DialogActions className="flex justify-end border-none px-[32px] py-[24px]">
          <Button
            size="large"
            variant="text"
            onClick={() => {
              setIsConfirmDeleteDialogOpen(false);
            }}
          >
            {t('confirmationDelete.cancel')}
          </Button>

          <Button
            size="large"
            variant="contained"
            color="error"
            onClick={() => {
              if (!detail.id) return;

              onDelete(detail.id);
              setIsConfirmDeleteDialogOpen(false);
            }}
          >
            <DeleteForeverOutlinedIcon className="mr-[8px]" />
            {t('confirmationDelete.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
      <Accordion
        expanded={isExpanded}
        onChange={() => {
          setIsExpanded((prev) => {
            return !prev;
          });
        }}
        classes={{
          root: 'shadow-none'
        }}
      >
        <AccordionSummary className="border border-solid border-b-[1px] border-x-0 border-t-0 border-text-80 shadow-none">
          <div className="w-full flex items-center justify-between">
            <div className="flex flex-row items-center">
              <CheckCircle
                className={classNames('mr-[16px]', {
                  'text-success-60': isCompleted && !hasError,
                  'text-text-40': !isCompleted && !hasError,
                  'text-danger-60': hasError
                })}
              />
              <div className="flex flex-col">
                <Typography fontSize="14px" lineHeight="20px" className="text-text-100 font-bold">
                  {t('detailTitle')}
                </Typography>
                <Collapse in={!!detail.question?.id}>
                  <DarkTooltip
                    title={
                      detail.diagnosis ? (
                        <Typography
                          fontSize={'14px'}
                          lineHeight={'16px'}
                          className="text-white-80 tracking-wide"
                        >
                          {detail.diagnosis}
                        </Typography>
                      ) : null
                    }
                  >
                    <Typography
                      fontSize="14px"
                      lineHeight="20px"
                      className="text-text-100 underline mt-[4px]"
                    >
                      {t('detailSubtitle')} {detail.question?.order}
                    </Typography>
                  </DarkTooltip>
                </Collapse>
              </div>
            </div>
            <div>
              <Button
                variant="text"
                className="text-danger-60 underline"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsConfirmDeleteDialogOpen(true);
                }}
              >
                {t('remove')}
              </Button>
              <Button
                variant="text"
                className="underline"
                disabled={isDisabled}
                onClick={(e) => {
                  e.stopPropagation();
                  onDuplicate(detail);
                }}
              >
                {t('copy')}
              </Button>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-[16px]">
            <DiagnosisForm
              error={errors}
              diagnosis={detail}
              healthQuestions={healthQuestions}
              yesQuestionsOptions={yesQuestionsOptions}
              onChange={(value, key) => {
                if (!detail.id) return;
                handleNewDiagnosisChange(detail.id, value, key);
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};
