import { useCallback, useState } from 'react';

export type PageSize = 10 | 25 | 50 | 100 | undefined;

export interface Paginated {
  page: number;
  size: PageSize;
}
export type UsePagination = Paginated & {
  goTo: (page: number) => void;
  sizeTo: (size: PageSize) => void;
};

export function usePagination({ page, size }: Paginated): UsePagination {
  const [currentPage, setPage] = useState(page);
  const [currentSize, setSize] = useState<PageSize>(size);

  const goTo = useCallback((newPage: number) => {
    setPage(newPage);
  }, []);

  const sizeTo = useCallback((newSize: PageSize) => {
    setSize(newSize);
  }, []);

  return { page: currentPage, size: currentSize, goTo, sizeTo };
}
