import { IconProps, ToastClassName } from 'react-toastify';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';

export const configureToastClasses: ToastClassName = (context) => {
  if (context && context.type) {
    switch (context.type) {
      case 'success':
        return 'flex justify-between bg-success-60 text-white-100 rounded-[8px] py-[14px] px-[16px] shadow-2xl';
      case 'error':
        return 'flex justify-between bg-danger-60 text-white-100 rounded-[8px] py-[14px] px-[16px] shadow-2xl';
      case 'warning':
        return 'flex justify-between bg-warning-60 text-white-100 rounded-[8px] py-[14px] px-[16px] shadow-2xl';
      default:
        return context.defaultClassName || '';
    }
  }
  return '';
};

export const renderToastIcons = ({ type }: IconProps) => {
  if (type === 'success') return <CheckCircleOutlineIcon />;
  if (type === 'error') return <ErrorOutlineIcon />;
  if (type === 'warning') return <ReportGmailerrorredIcon />;
  if (type === 'info') return <InfoIcon />;
};
