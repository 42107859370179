import React, { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ReactKeycloakProvider } from '@react-keycloak/web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import de from 'date-fns/locale/de';
import { ToastContainer } from 'react-toastify';

import ClearIcon from '@mui/icons-material/Clear';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { deDE } from '@mui/x-date-pickers/locales';
import 'styles/table-override.scss';
import { theme } from 'styles/theme/theme';
import { configureToastClasses, renderToastIcons } from 'styles/toastConfiguration';

import { OpenAPI as ExtensionOpenAPI } from 'models/extension-generated';
import { OpenAPI as PortalOpenAPI } from 'models/portal-generated';

import keycloak from './services/keycloak';

import { config, queryConfig } from './config';
import { BlankPage } from './pages/Blank/BlankPage';
import { View360Contract } from './pages/Contract360Page/pages/View360Contract';
import { WizardRouter } from './pages/PrivateHealthInsurance/WizardRouter';
import { AuthenticationLoader } from 'components/AuthenticationLoader';
import { ScrollToTopOnPathChange } from 'components/ScrollToTop/ScrollToTopOnPathChange';
import { ConfigContextProvider } from 'contexts/ConfigContext';
import { View360Offer } from 'pages/Contract360Page/pages/View360Offer';
import { ContractsPage } from 'pages/Contracts/ContractsPage';
import { DashboardPage } from 'pages/Dashboard';
import { FormsPage } from 'pages/Forms';
import { NewsPage } from 'pages/News/NewsPage';
import { NewsDetailPage } from 'pages/NewsDetailPage/NewsDetailPage';
import { ProductsPage } from 'pages/Products';
import { ProfilePage } from 'pages/Profile/ProfilePage';

import styles from './app.module.scss';

const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText;

const client = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      gcTime: queryConfig.oneHourCache.gcTime,
      staleTime: queryConfig.oneHourCache.staleTime
    }
  }
});

function App() {
  const [authReady, setAuthReady] = useState<boolean>(false);

  useEffect(() => {
    // eslint-disable-next-line
    // @ts-expect-error
    window.toggleDevtools = () => setShowDevtools((old) => !old);
  }, []);

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<AuthenticationLoader />}
      isLoadingCheck={() => {
        return !authReady && !keycloak.authenticated;
      }}
      initOptions={config}
      onTokens={(tokens) => {
        PortalOpenAPI.TOKEN = tokens.token;
        ExtensionOpenAPI.TOKEN = tokens.token;
        setAuthReady(true);
      }}
    >
      <React.StrictMode>
        <QueryClientProvider client={client}>
          {process.env.REACT_APP_QUERY_DEBUG === 'true' && <ReactQueryDevtools initialIsOpen />}
          <ConfigContextProvider>
            <StyledEngineProvider injectFirst>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={de}
                localeText={germanLocale}
              >
                <ThemeProvider theme={theme}>
                  <div className={styles.App}>
                    <BrowserRouter>
                      <ScrollToTopOnPathChange />
                      <Routes>
                        {WizardRouter}
                        <Route path="/360/antrag/:offerId" element={<View360Offer />} />
                        <Route
                          path="/360/vertrag/:contractId/:revisionId?"
                          element={<View360Contract />}
                        />
                        <Route path="/" element={<Navigate to="/dashboard" />} />
                        <Route path="/dashboard" element={<DashboardPage />} />
                        <Route path="/produkte" element={<ProductsPage />} />
                        <Route path="/bestand" element={<ContractsPage />} />
                        <Route path="/formulare" element={<FormsPage />} />
                        <Route path="/neuigkeiten" element={<NewsPage />} />
                        <Route path="/neuigkeiten/:newsId" element={<NewsDetailPage />} />
                        <Route path="/profil" element={<ProfilePage />} />
                        <Route path="*" element={<BlankPage />} />
                      </Routes>
                    </BrowserRouter>
                    <ToastContainer
                      icon={renderToastIcons}
                      toastClassName={configureToastClasses}
                      closeButton={<ClearIcon className="self-center" />}
                      position="top-right"
                      autoClose={5000}
                      hideProgressBar
                      closeOnClick
                      pauseOnFocusLoss
                      pauseOnHover
                      theme="colored"
                    />
                  </div>
                  <div className="bg-navigation-80"> </div>
                </ThemeProvider>
              </LocalizationProvider>
            </StyledEngineProvider>
          </ConfigContextProvider>
        </QueryClientProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default App;
