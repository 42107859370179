import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { model } from '../models';
import { SearchOperationEnum } from 'models/portal-generated';

import MultiSelectAll from '../Multiselect';
import { FilterGroupsEnum } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';

export const InsuredPeopleFilters = () => {
  const { t } = useTranslation('contract');

  const {
    filters,
    handleListFilterChange,
    handleDateRangeChange,
    handleRangeChange,
    handleTextFilterChange,
    getSelectedValue,
    getSelectedValues
  } = useContractPageContext();

  const street = getSelectedValue(FilterGroupsEnum.InsuredPartnersStreet);
  const postalCode = getSelectedValue(FilterGroupsEnum.InsuredPartnersPostalCode);
  const lastName = getSelectedValue(FilterGroupsEnum.InsuredPartnersName);
  const city = getSelectedValue(FilterGroupsEnum.InsuredPartnersCity);
  const jobType = getSelectedValues(FilterGroupsEnum.InsuredPartnersJobType, model.profession.list);
  const workActivity = getSelectedValues(FilterGroupsEnum.WorkActivity, model.workActivity.list);
  const gender = getSelectedValues(FilterGroupsEnum.Gender, model.gender.list);
  const insuredPartnersHouseNumber = getSelectedValue(FilterGroupsEnum.InsuredPartnersHouseNumber);

  const insuredPartnersFilter = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.InsuredPartnersBirthDate;
  });
  const fromInsuredPartners =
    insuredPartnersFilter?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
    )?.value || '';
  const toInsuredPartners =
    insuredPartnersFilter?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
    )?.value || '';

  return (
    <>
      {/* Big screens */}
      <div className="w-full hidden s:grid grid-cols-5 gap-[24px] items-end">
        <MultiSelectAll
          value={gender}
          options={model.gender.list}
          label={t('filter.gender')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.Gender,
              SearchOperationEnum.IN,
              model.gender.list
            );
          }}
        />
        <DatePicker
          value={fromInsuredPartners ? new Date(fromInsuredPartners) : null}
          label={t('filter.fromDateBirth')}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleDateRangeChange({
              to: toInsuredPartners ? new Date(toInsuredPartners) : null,
              from: value,
              groupId: FilterGroupsEnum.InsuredPartnersBirthDate
            });
          }}
        />
        <TextField
          value={postalCode}
          type="number"
          inputProps={{ min: 0 }}
          label={t('filter.PLZ')}
          placeholder={t('filter.PLZ')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersPostalCode, value);
          }}
        />
        <TextField
          value={street}
          label={t('filter.street')}
          placeholder={t('filter.street')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersStreet, value);
          }}
        />
        <MultiSelectAll
          value={jobType}
          options={model.profession.list}
          label={t('filter.profession')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.InsuredPartnersJobType,
              SearchOperationEnum.IN,
              model.profession.list
            );
          }}
        />
        <TextField
          value={lastName}
          label={t('filter.lastName')}
          placeholder={t('filter.lastName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersName, value.toString());
          }}
        />
        <DatePicker
          value={toInsuredPartners ? new Date(toInsuredPartners) : null}
          label={t('filter.toDateBirth')}
          onChange={(value) => {
            const date: string = value && !isNaN(value.getTime()) ? value?.toISOString() : '';
            handleRangeChange({
              from: fromInsuredPartners,
              to: date,
              groupId: FilterGroupsEnum.InsuredPartnersBirthDate
            });
          }}
        />
        <TextField
          value={city}
          label={t('filter.city')}
          placeholder={t('filter.city')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersCity, value);
          }}
        />
        <TextField
          value={insuredPartnersHouseNumber}
          label={t('filter.houseNumber')}
          placeholder={t('filter.houseNumber')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;

            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersHouseNumber, value);
          }}
        />
        <MultiSelectAll
          label={t('filter.workActivity')}
          options={model.workActivity.list}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.WorkActivity,
              SearchOperationEnum.IN,
              model.workActivity.list
            );
          }}
          value={workActivity}
        />
      </div>

      {/* Small screens */}
      <div className="w-full grid s:hidden grid-cols-3  gap-[24px] items-end">
        <MultiSelectAll
          value={gender}
          options={model.gender.list}
          label={t('filter.gender')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.Gender,
              SearchOperationEnum.IN,
              model.gender.list
            );
          }}
        />
        <TextField
          value={postalCode}
          label={t('filter.PLZ')}
          placeholder={t('filter.PLZ')}
          type="number"
          inputProps={{ min: 0 }}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersPostalCode, value);
          }}
        />
        <DatePicker
          value={fromInsuredPartners ? new Date(fromInsuredPartners) : null}
          label={t('filter.fromDateBirth')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              to: toInsuredPartners ? new Date(toInsuredPartners) : null,
              from: value,
              groupId: FilterGroupsEnum.InsuredPartnersBirthDate
            });
          }}
        />
        <TextField
          value={lastName}
          label={t('filter.lastName')}
          placeholder={t('filter.lastName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersName, value.toString());
          }}
        />

        <TextField
          value={city}
          label={t('filter.city')}
          placeholder={t('filter.city')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersCity, value);
          }}
        />
        <DatePicker
          value={toInsuredPartners ? new Date(toInsuredPartners) : null}
          label={t('filter.toDateBirth')}
          onChange={(value) => {
            const date: string = value && !isNaN(value.getTime()) ? value?.toISOString() : '';
            handleRangeChange({
              from: fromInsuredPartners,
              to: date,
              groupId: FilterGroupsEnum.InsuredPartnersBirthDate
            });
          }}
        />
      </div>
    </>
  );
};
