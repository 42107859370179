import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

type SigantureDialogProps = {
  isOpen: boolean;
  onManualSignatureClick: () => Promise<void>;
  onDigitalSignatureClick: () => Promise<void>;
  onClose: () => void;
};

export const SignatureDialog = ({
  isOpen,
  onManualSignatureClick,
  onDigitalSignatureClick,
  onClose
}: SigantureDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'actions.signature'
  });
  const [isDigitalLoading, setIsDigitalLoading] = useState(false);
  const [isManualLoading, setIsManualLoading] = useState(false);
  const isLoading = isDigitalLoading || isManualLoading;

  const handleDigitalSignatureClick = async () => {
    setIsDigitalLoading(true);

    await onDigitalSignatureClick();

    setIsDigitalLoading(false);
  };

  const handleManualSignatureClick = async () => {
    setIsManualLoading(true);

    await onManualSignatureClick();

    setIsManualLoading(false);
  };

  return (
    <Dialog fullWidth maxWidth={'md'} open={isOpen} onClose={onClose}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <div className="pl-[16px]">
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('title')}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                {t('content.description')}
              </Typography>
              <div className="grid grid-cols-2 gap-[32px] mt-[32px]">
                <div>
                  <Typography variant="bodyLGBold" className="flex text-text-100">
                    {t('content.manual.title')}
                  </Typography>
                  <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[8px] ">
                    {t('content.manual.description')}
                  </Typography>
                </div>
                <div>
                  <Typography variant="bodyLGBold" className="flex text-text-100">
                    {t('content.digital.title')}
                  </Typography>
                  <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[8px] ">
                    {t('content.digital.description')}
                  </Typography>
                </div>
              </div>
            </DialogContentText>
          </div>
        </div>
        <div className="flex justify-between mt-[32px]">
          <Button size="large" onClick={onClose}>
            {t('cancel')}
          </Button>
          <div className="flex justify-between gap-[16px]">
            <Button
              variant="outlined"
              size="large"
              startIcon={
                isManualLoading ? (
                  <CircularProgress color="inherit" size={16} />
                ) : (
                  <FileDownloadOutlinedIcon />
                )
              }
              onClick={handleManualSignatureClick}
              disabled={isLoading}
            >
              {t('manual')}
            </Button>
            <Button
              size="large"
              variant="contained"
              startIcon={isDigitalLoading ? <CircularProgress color="inherit" size={16} /> : null}
              onClick={handleDigitalSignatureClick}
              disabled={isLoading}
            >
              {t('digital')}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
