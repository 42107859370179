import { sortBy } from 'lodash';
import difference from 'lodash/difference';
import intersection from 'lodash/intersection';
import moment from 'moment';

import {
  Criteria,
  CriteriaType as CriteriaTypeEnum,
  Partner,
  PartnerService,
  PolicyService,
  SearchOperationEnum
} from 'models/portal-generated';

type CriteriaType = Array<{
  key?: string;
  operation?: SearchOperationEnum;
  value?: unknown;
}>;

export const numberRegex = /^#?\s*\d+(?:\.\d+)?(?:\s+\d+(?:\.\d+)?)*$/;

export const isPolicyId = (query: string) => numberRegex.test(query);
export const isBirthDate = (query: string) => {
  return moment(query).isValid() || moment(query, 'DD/MM/YYYY', true).isValid();
};
export const isFullName = (query: string) => {
  const arrayOfWords = query.split(' ');
  return arrayOfWords.length > 1;
};

const getPartnerSearchCriteria = (query: string): Criteria => {
  return {
    page: 0,
    size: 100,
    criteriaType: CriteriaTypeEnum.OR,
    searchCriteria: [
      {
        criteria: [
          {
            key: 'firstname',
            operation: SearchOperationEnum.START_WITH,
            value: query
          }
        ]
      },
      {
        criteria: [
          {
            key: 'lastname',
            operation: SearchOperationEnum.START_WITH,
            value: query
          }
        ]
      }
    ]
  };
};

export type SearchResult = Partner & {
  isHolder: boolean;
  isPolicy: boolean;
  isInsuredPerson: boolean;
};

const findPartners = async (query: string): Promise<Array<Partner>> => {
  if (isBirthDate(query) && !isFullName(query)) {
    const date = moment(query, 'DD/MM/YYYY', true).isValid()
      ? moment(query, 'DD/MM/YYYY')
      : moment(query);
    const partners = await PartnerService.getPartners({
      requestBody: {
        page: 0,
        size: 100,
        criteriaType: CriteriaTypeEnum.OR,
        searchCriteria: [
          {
            criteria: [
              {
                key: 'birthDate',
                operation: SearchOperationEnum.LIKE,
                value: date.format('YYYY-MM-DD')
              }
            ]
          }
        ]
      }
    });
    return partners.results || [];
  }

  if (isFullName(query)) {
    const arrayOfWords = query.split(' ');
    const filters = arrayOfWords.reduce<CriteriaType>((prev, word) => {
      const filtersForWord: CriteriaType = [
        {
          key: 'firstname',
          operation: SearchOperationEnum.START_WITH,
          value: word
        },
        {
          key: 'lastname',
          operation: SearchOperationEnum.START_WITH,
          value: word
        }
      ];
      const res = [...(prev || []), ...(filtersForWord || [])];
      return res;
    }, []);

    const partners = await PartnerService.getPartners({
      requestBody: {
        ...getPartnerSearchCriteria(query),
        searchCriteria: [
          {
            criteria: filters,
            criteriaType: CriteriaTypeEnum.OR
          }
        ]
      }
    });
    return partners.results || [];
  }

  const partners = await PartnerService.getPartners({
    requestBody: getPartnerSearchCriteria(query)
  });
  return partners.results || [];
};

const findPoliciesById = async (query: string): Promise<Array<Partner>> => {
  const id = query.replace(/[\s#.]/g, '');
  const policiesResponse = await PolicyService.getPolicies({
    requestBody: {
      page: 0,
      size: 100,
      searchCriteria: [
        {
          criteria: [
            {
              key: 'id',
              operation: SearchOperationEnum.START_WITH,
              value: id
            }
          ]
        }
      ]
    }
  });

  if (policiesResponse.total === 0) return [];

  return policiesResponse.results.map(({ policyHolder, id }) => {
    return {
      ...policyHolder,
      holderPolicies: [id]
    };
  });
};

export const performSearch = async (query: string) => {
  const pristineQuery = query.trim();
  let partnersSearchResponse: Partner[] = [];
  let policiesByIdSearchResponse: Partner[] = [];
  let sortedResults: Partner[];

  if (isPolicyId(pristineQuery)) {
    policiesByIdSearchResponse = await findPoliciesById(pristineQuery);
  } else {
    partnersSearchResponse = await findPartners(pristineQuery);
  }

  const partnersSpreadByPolicies = partnersSearchResponse.reduce<Array<Partner>>(
    (prev, partner) => {
      const insuredPolicies = partner.insuredPolicies || [];
      const holderPolicies = partner.holderPolicies || [];
      const heldAndInsuredPolicyIds = intersection(insuredPolicies, holderPolicies);
      const insuredPolicyIds = difference(insuredPolicies, heldAndInsuredPolicyIds);
      const heldPolicyIds = difference(holderPolicies, heldAndInsuredPolicyIds);

      const partnersWithBothRoles: Array<Partner> = heldAndInsuredPolicyIds.map((id) => {
        return {
          ...partner,
          holderPolicies: [id],
          insuredPolicies: [id]
        };
      });

      const onlyInsuredPartners: Array<Partner> = insuredPolicyIds.map((id) => {
        return {
          ...partner,
          insuredPolicies: [id],
          holderPolicies: []
        };
      });

      const onlyHolderPartners: Array<Partner> = heldPolicyIds.map((id) => {
        return {
          ...partner,
          insuredPolicies: [],
          holderPolicies: [id]
        };
      });

      return [...prev, ...partnersWithBothRoles, ...onlyInsuredPartners, ...onlyHolderPartners];
    },
    []
  );

  if (isPolicyId(pristineQuery)) {
    sortedResults = sortBy(policiesByIdSearchResponse, (item) => {
      const aPolicy = item.holderPolicies?.[0] || item.insuredPolicies?.[0];

      if (typeof aPolicy === 'number') {
        return aPolicy;
      } else {
        return 0;
      }
    });
  } else {
    sortedResults = sortBy<Partner>(partnersSpreadByPolicies, 'lastname');
  }

  return sortedResults;
};
