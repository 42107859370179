import Chip from '@mui/material/Chip';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { TariffDescription } from 'pages/PrivateHealthInsurance/components/TariffDescription';

export const TariffLabel = ({
  tariffLabel,
  tooltip
}: {
  tariffLabel?: string;
  tooltip?: string;
}) => {
  return (
    <DarkTooltip title={<TariffDescription description={tooltip || ''} />} placement="right" arrow>
      <span>
        {tariffLabel && (
          <Chip
            label={tariffLabel}
            className="rounded-[8px] text-[12px] text-white-100"
            variant="filled"
            color="primary"
          />
        )}
      </span>
    </DarkTooltip>
  );
};
