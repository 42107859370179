import { useQuery } from '@tanstack/react-query';

import {
  AgentResponse,
  CriteriaType,
  SearchOperationEnum,
  ServiceCenterService
} from 'models/portal-generated';

const performSearch = async (query: string): Promise<AgentResponse> => {
  const response = await ServiceCenterService.getAgentByCriteria({
    requestBody: {
      page: 0,
      size: 10,
      criteriaType: CriteriaType.OR,
      sort: [{ field: 'agentId', order: 'ASCENDING' }],
      searchCriteria: [
        {
          criteriaType: CriteriaType.OR,
          criteria: [
            {
              key: 'agentId',
              operation: SearchOperationEnum.LIKE,
              value: query
            }
          ]
        },
        {
          criteriaType: CriteriaType.OR,
          criteria: [
            {
              key: 'partner.firstname',
              operation: SearchOperationEnum.LIKE,
              value: query
            }
          ]
        },
        {
          criteriaType: CriteriaType.OR,
          criteria: [
            {
              key: 'partner.lastname',
              operation: SearchOperationEnum.LIKE,
              value: query
            }
          ]
        }
      ]
    }
  });

  return response;
};

type Params = {
  searchQuery: string;
  disabled?: boolean;
};

export const useSearchAgents = ({ searchQuery, disabled }: Params) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['agentsByCriteria', searchQuery],
    queryFn: () => performSearch(searchQuery),
    enabled: !!searchQuery.length && !disabled
  });

  return {
    result: data?.results || [],
    isLoading,
    isError,
    error
  };
};
