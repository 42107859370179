import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_KEYCLOAK_DEV
      : window.location.origin,
  realm: /vermittler.*\.lkh\.net|.*-internal-.*\.lkh\.net/.test(window.location.hostname) ? 'lkh_users' : 'vermittler_portal',
  clientId: 'vermittler-auth'
};
const keycloak = new Keycloak({ ...keycloakConfig });

export default keycloak;