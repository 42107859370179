import { useQuery } from '@tanstack/react-query';

import { log } from 'lkh-portal-ui-library';
import {
  Criteria,
  CriteriaType,
  PoliciesResponse,
  PolicyService,
  SearchOperationEnum
} from 'models/portal-generated';

import { TabType } from '../GridGroup';

const fetchReminders = (agentId: string | undefined): Promise<PoliciesResponse> => {
  const payload: { requestBody: Criteria } = {
    requestBody: {
      page: 0,
      size: 10,
      searchCriteria: [
        {
          criteria: [
            {
              key: 'hasReminder',
              operation: SearchOperationEnum.EQUALS,
              value: true
            }
          ]
        }
      ]
    }
  };

  if (agentId) {
    payload.requestBody?.searchCriteria?.push({
      criteriaType: CriteriaType.AND,
      criteria: [
        {
          key: 'agentId',
          operation: SearchOperationEnum.EQUALS,
          value: agentId
        }
      ]
    });
  }

  return PolicyService.getPolicies(payload);
};
const fetchContracts = (agentId: string | undefined): Promise<PoliciesResponse> => {
  const payload: { requestBody: Criteria } = {
    requestBody: {
      page: 0,
      size: 10
    }
  };

  if (agentId) {
    payload.requestBody.searchCriteria = [
      {
        criteriaType: CriteriaType.AND,
        criteria: [
          {
            key: 'agentId',
            operation: SearchOperationEnum.EQUALS,
            value: agentId
          }
        ]
      }
    ];
  }
  return PolicyService.getPolicies(payload);
};

type CounterType = {
  [TabType.Contracts]: number;
  [TabType.Reminders]: number;
};

export const useFetchCount = (agentId: string | undefined) => {
  const fetchCounts = async (): Promise<CounterType> => {
    try {
      const [allContracts, reminders] = await Promise.all([
        fetchContracts(agentId),
        fetchReminders(agentId)
      ]);

      return {
        contracts: allContracts.total,
        reminders: reminders.total
      };
    } catch (error) {
      log.error(error);
      return Promise.resolve({
        contracts: 0,
        reminders: 0
      });
    }
  };

  return useQuery<CounterType>({
    queryKey: ['count', agentId],
    queryFn: fetchCounts
  });
};
