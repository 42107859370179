import { inputLabelClasses } from '@mui/material/InputLabel';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { Components, Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay';
// When using TypeScript 4.x and above
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { colors } from 'styles/colors';

export const muiDatePickerOverride: Components<Omit<Theme, 'components'>>['MuiDatePicker'] = {
  defaultProps: {
    slots: {
      /**
       * For some reasone our global TextField override doesnt work for DatePicker.
       * So same styling logic is reprodused here.
       */
      textField: (params) => {
        const hoverStyles = {
          borderColor: params.error ? colors.danger[60] : colors.primary[80]
        };
        const disabledHoverStyle = {
          borderColor: 'none'
        };

        const disabledStyles = params.disabled
          ? {
              ':hover fieldset': disabledHoverStyle
            }
          : {
              ':hover fieldset': hoverStyles
            };

        const outlinedDisabledStyles = params.disabled
          ? {
              ':hover fieldset': disabledHoverStyle
            }
          : {
              '&:hover fieldset': hoverStyles
            };

        return (
          <TextField
            {...params}
            sx={{
              [`& .${inputLabelClasses.root}`]: disabledStyles,
              [`& .${outlinedInputClasses.root}`]: outlinedDisabledStyles
            }}
          />
        );
      }
    }
  }
};

export const muiPickerDayOverride: Components<Omit<Theme, 'components'>>['MuiPickersDay'] = {
  styleOverrides: {
    root: {
      [`&.${pickersDayClasses.selected}`]: {
        ':focus': {
          backgroundColor: colors.primary[80],
          colors: colors.white[100]
        },
        backgroundColor: colors.primary[80],
        color: colors.white[100]
      }
    }
  }
};
