import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ApplicationService } from 'models/extension-generated';

type Params = {
  offerId?: string;
};

export const useApplicationQuery = ({ offerId }: Params) => {
  const { t } = useTranslation('view360');

  return useQuery({
    queryKey: [offerId],
    queryFn: async () => {
      if (!offerId) {
        throw new Error('Offer ID is missing');
      }
      try {
        const response = await ApplicationService.getApplicationById({
          id: offerId
        });

        return response;
      } catch (error) {
        toast.error(t<string>('toastMessages.somethingWentWrong', { ns: 'common' }));
      }
    },
    refetchOnWindowFocus: 'always',
    refetchInterval: 10000
  });
};
