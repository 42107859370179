import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { CircularProgress, MenuItem } from '@mui/material';

import { getCSVExport, getCSVProvisionsExport } from '../Grid/hooks/useContracts';
import { CsvIcon } from './CSVIcon';
import { useContractPageContext } from 'pages/Contracts/context';
import { downloadCsv } from 'utilities/fetchDocument';

type GetCSVArgs = Parameters<typeof getCSVExport>;
type GetCSVProvisionsArgs = Parameters<typeof getCSVProvisionsExport>;

type CSVCallbackFn = (
  ...args: GetCSVArgs | GetCSVProvisionsArgs
) => ReturnType<typeof getCSVExport | typeof getCSVProvisionsExport>;

type CallbackFnType =
  | { type: 'GetCSVFunction'; func: CSVCallbackFn }
  | { type: 'GetCSVProvisionsFunction'; func: CSVCallbackFn };

type DownloadCsvProps = {
  setIsLoading: (loading: boolean) => void;
  downloadCallback: CallbackFnType;
};

export function DownloadCsv({ setIsLoading, downloadCallback }: DownloadCsvProps) {
  const { t } = useTranslation('contract');
  const { filters, sorting } = useContractPageContext();
  const [loading, setLoading] = useState<boolean>(false);

  const isGetCSVFn = downloadCallback.type === 'GetCSVFunction';

  const handleDownload = async () => {
    setLoading(true);
    setIsLoading(true);

    try {
      const data = await downloadCallback.func(filters, sorting);
      downloadCsv(data, isGetCSVFn ? t('contractCSVFileName') : t('provisionsCSVFileName'));
    } catch (error) {
      const errorMsg = t('tab.exportCSVError');
      toast.error(errorMsg);
    } finally {
      setLoading(false);
      setIsLoading(false);
    }
  };

  return (
    <MenuItem onClick={handleDownload} disabled={loading} sx={{ whiteSpace: 'normal' }}>
      <div className="flex">
        <span className="flex items-center" style={{ marginRight: '18px' }}>
          {loading ? <CircularProgress color="inherit" size={16} /> : <CsvIcon />}
        </span>
        <span className="text-text-80 text-[14px] flex items-center">
          {isGetCSVFn ? t('viaCSV') : t('viaCSVProvisions')}
        </span>
      </div>
    </MenuItem>
  );
}
