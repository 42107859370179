import { useTranslation } from 'react-i18next';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';

import { downloadForm, downloadFormByReferenceId } from 'utilities/fetchDocument';

type DetailsLinkProps = {
  documentId: string;
  isGetByReferenceId?: boolean;
  applicationStart?: string;
};

export const DetailsLink = ({
  documentId,
  isGetByReferenceId,
  applicationStart = ''
}: DetailsLinkProps) => {
  const { t } = useTranslation('wizardTariffs');

  const handleOpen = async () => {
    let doc;
    if (isGetByReferenceId) {
      doc = await downloadFormByReferenceId({ id: documentId, date: applicationStart });
    } else {
      doc = await downloadForm({ id: documentId });
    }
    const file = new Blob([doc.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank', 'noreferrer');
  };
  return (
    <div className="flex justify-start items-center space-x-1 group">
      <Link
        component="button"
        onClick={handleOpen}
        classes={{ root: 'text-sm font-normal group-hover:text-highlight-80' }}
        color="primary"
      >
        {t('utils.seeDetails')}
      </Link>
      <OpenInNewIcon
        className="cursor-pointe group-hover:fill-highlight-80"
        onClick={handleOpen}
        color="primary"
        sx={{ width: 16, height: 16 }}
      />
    </div>
  );
};
