import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

type DeleteTariffDialogProps = {
  id?: string;
  isOpen: boolean;
  onDelete?: () => void;
  onClose?: () => void;
};
export const DeleteTariffDialog = ({ id, isOpen, onDelete, onClose }: DeleteTariffDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDialog = () => {
    onClose?.();
  };

  const handleDeleteClick = () => {
    setIsDeleting(true);

    try {
      onDelete?.();
    } finally {
      setIsDeleting(false);
      onClose?.();
    }
  };

  return (
    <Dialog disableScrollLock fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex space-x-[16px]">
          <div>
            <ErrorIcon className="text-danger-60" />
          </div>
          <div>
            <Typography className="flex text-text-100" variant="bodyLGBold">
              {t('orderOptions.deleteTariffConfirmation.title', { tariffName: id })}
            </Typography>
            <DialogContentText>
              <Typography className="flex text-text-80 mt-[4px]" variant="bodyMDRegular">
                {t('orderOptions.deleteTariffConfirmation.message')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="flex gap-[16px] justify-end mt-[32px]">
          <Button size="large" onClick={handleCloseDialog}>
            {t('orderOptions.deleteTariffConfirmation.abort')}
          </Button>
          <Button
            data-testid="orderOptions.deleteTariffConfirmation.delete"
            size="large"
            variant="contained"
            color="error"
            startIcon={
              isDeleting ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <DeleteForeverOutlinedIcon />
              )
            }
            onClick={handleDeleteClick}
            disabled={isDeleting}
          >
            {isDeleting
              ? t('orderOptions.deleteTariffConfirmation.wait')
              : t('orderOptions.deleteTariffConfirmation.delete')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
