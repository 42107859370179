import { createPortal } from 'react-dom';

import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { DarkTooltip } from 'lkh-portal-ui-library';
import { Tariff, TariffCalculationResult, TariffSectionEnum } from 'models/extension-generated';

import { TariffDescription } from '../../TariffDescription';
import { DeleteTariffDialog } from 'components/OfferTariffTable/components/DeleteTariffDialog/DeleteTariffDialog';
import {
  DELETEABLE_TARIFF_SECTIONS,
  UNDELETEABLE_TARIFF_IDS
} from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { formatPrice } from 'utilities/string';

import styles from '../Sidebar.module.scss';

type TariffRowProps = {
  tariff: Tariff;
  calculation: TariffCalculationResult;
  tariffsList: Array<Tariff>;
  selectedTariffs: Array<Tariff>;
};

export const TariffRow = ({
  tariff,
  calculation,
  tariffsList,
  selectedTariffs
}: TariffRowProps) => {
  const { partnerId, reducer } = useHealthInsuranceContext();
  const { removeTariff, updateValue } = reducer;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { t } = useTranslation('common');
  const showDeleteAction =
    tariff &&
    DELETEABLE_TARIFF_SECTIONS.includes(tariff?.section) &&
    !UNDELETEABLE_TARIFF_IDS.includes(tariff.id);

  const getTariffDescription = (id: string) => {
    const rawDescription = tariffsList.find((tariff) => tariff.id === id)?.description || '';
    if (!rawDescription) return '';
    return <TariffDescription description={rawDescription} />;
  };

  function hasRiskPremium(calculation: TariffCalculationResult): boolean {
    const risk = calculation.calculationMonthly?.riskSurcharges;
    if (risk && risk > 0) {
      return true;
    }
    return false;
  }

  return (
    <div className={classNames(styles.tariffCalculation, 'mb-[16px]')}>
      <DarkTooltip title={getTariffDescription(calculation.tariffId || '')} placement="left" arrow>
        <Chip
          label={calculation.tariffId}
          className="rounded-[8px] text-white-100"
          variant="filled"
          color="primary"
        />
      </DarkTooltip>
      <div className={styles.tariffPrice}>
        {hasRiskPremium(calculation) && (
          <div
            className={classNames(
              styles.risk,
              'pr-[8px] mr-[8px] text-text-60 font-normal text-sm'
            )}
          >
            {t('riskSurchargesShorthand')}
            {formatPrice(calculation.calculationMonthly?.riskSurcharges)}
          </div>
        )}
        <Typography className={classNames(styles.price, 'm-0 text-text-80 text-sm font-bold')}>
          {formatPrice(calculation.calculationMonthly?.amount)}
        </Typography>
        {showDeleteAction && (
          <div className={styles.deleteAction}>
            {isDialogOpen &&
              createPortal(
                <DeleteTariffDialog
                  id={calculation.tariffId}
                  isOpen={isDialogOpen}
                  onClose={() => setIsDialogOpen(false)}
                  onDelete={() => {
                    if (tariff) {
                      // constants
                      const TARIFF_SECTION_TO_BOOL_FIELD: Partial<
                        Record<keyof typeof TariffSectionEnum, string>
                      > = {
                        [TariffSectionEnum.AUSLANDSREISEKRANKENVERSICHERUNG]: `partners[${partnerId}].applicationInformation.tariffInformation.hasInternationalTravelInsurance`,
                        [TariffSectionEnum.KRANKENHAUSTAGEGELD]: `partners[${partnerId}].applicationInformation.tariffInformation.hasDailyHospitalAllowance`,
                        [TariffSectionEnum.KRANKENTAGEGELD]: `partners[${partnerId}].applicationInformation.tariffInformation.hasDailySicknessAllowance`,
                        [TariffSectionEnum.PFLEGEGELD]: `partners[${partnerId}].applicationInformation.tariffInformation.hasDailyCareAllowance`,
                        [TariffSectionEnum.PFLEGEPFLICHT]: `partners[${partnerId}].applicationInformation.tariffInformation.hasSalaryDuringIllness`,
                        [TariffSectionEnum.OPTIONAL]: `partners[${partnerId}].applicationInformation.tariffInformation.hasOptionForAdditionalInsurance`
                      };

                      // check if there are any other tariff from the same section
                      const hasOtherTariffFromSection = selectedTariffs.some(
                        (t) => t.section === tariff.section && t.id !== tariff.id
                      );
                      // set the boolean field to false
                      const boolField = TARIFF_SECTION_TO_BOOL_FIELD[tariff.section];

                      // if not, set the boolean field to false
                      if (!hasOtherTariffFromSection && boolField) {
                        updateValue({ key: boolField, value: false });
                      }

                      removeTariff({
                        partnerId,
                        tariff: tariff
                      });
                    }
                  }}
                />,
                document.body
              )}
            <div data-testid="delete-icon-container" onClick={() => setIsDialogOpen(true)}>
              <IconButton size="small">
                <DeleteForeverOutlinedIcon />
              </IconButton>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
