import { useEffect, useState } from 'react';

import { CellValueChangedEvent, GridApi, GridReadyEvent, RowClassParams } from 'ag-grid-community';

import { Table } from 'lkh-portal-ui-library';

import { ActionMenuCellProps } from './cells/ActionMenuCell';
import { FormattingStyle } from './cells/AdditionalRiskCell/AdditionalRiskCell';
import { getColumnDefinitions } from './columnDefinitions';

import styles from './OfferTariffTable.module.scss';

export type TariffRow = {
  id: string;
  product: string;
  tariffLabel?: { tariffLabel: string; tooltip: string };
  price?: { total?: number; maxRisSurcharge?: number; statutorySurcharge?: number };
  highMoneyAmount?: number;
  entitlement?: boolean;
  addedRiskAmount?: number;
  actionComponent?: ActionMenuCellProps;
};

type TariffProps = {
  allowEditing?: boolean;
  tariffs: Array<TariffRow>;
  onTariffEdit?: (event: CellValueChangedEvent<TariffRow>) => void;
  summaryRow: TariffRow;
};

export const OfferTariffTable = ({
  allowEditing = true,
  tariffs,
  summaryRow,
  onTariffEdit
}: TariffProps) => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [tariffFormattingStyles, setTariffFormattingStyles] = useState(
    new Array(tariffs.length).fill(FormattingStyle.PERCENT)
  );

  useEffect(() => {
    gridApi?.setRowData(tariffs);
    setTariffFormattingStyles(new Array(tariffs.length).fill(FormattingStyle.PERCENT));
  }, [tariffs.length]);

  const handleGridReady = (event: GridReadyEvent) => {
    event.api.sizeColumnsToFit();
    setGridApi(event.api);
  };

  const handleCellEdit = (event: CellValueChangedEvent<TariffRow>) => {
    allowEditing && onTariffEdit?.(event);
  };

  const handleFormattingStyleChange = (
    tariffIndex: number,
    newformattingStyle: FormattingStyle | null
  ) => {
    if (newformattingStyle) {
      setTariffFormattingStyles((prev) => {
        const newFormattingStyles = [...prev];
        newFormattingStyles[tariffIndex] = newformattingStyle;
        return newFormattingStyles;
      });
    }
  };

  function renderOddRowDifferentColor(params: RowClassParams) {
    const rowIndex = params.node.rowIndex || 0;
    if (rowIndex % 2 === 0) {
      return styles.row;
    }
    return `bg-background ${styles.row}`;
  }

  return (
    <div className="table-override">
      <Table
        columnDefs={getColumnDefinitions(
          allowEditing,
          tariffFormattingStyles,
          handleFormattingStyleChange
        )}
        onCellValueChanged={handleCellEdit}
        rowData={[...tariffs, summaryRow]}
        onGridReady={handleGridReady}
        enableCellTextSelection
        suppressCellFocus
        suppressDragLeaveHidesColumns
        tooltipShowDelay={0}
        tooltipMouseTrack
        stopEditingWhenCellsLoseFocus
        getRowClass={renderOddRowDifferentColor}
        defaultColDef={{
          suppressSizeToFit: false,
          autoHeight: false,
          suppressMovable: true
        }}
      />
    </div>
  );
};
