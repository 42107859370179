/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useTranslation } from 'react-i18next';

import { Autocomplete, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { model } from '../models';
import { BooleanEnum } from '../models/models';
import { FilterGroup } from 'models/criteriaTypes';
import { CriteriaType, PolicyStatusEnum, SearchOperationEnum } from 'models/portal-generated';

import MultiSelectAll from '../Multiselect';
import { FilterGroupsEnum, FilterGroupsKeys } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';
import { DropdownOption } from 'pages/Contracts/types';

export const SpecialSearch = () => {
  const { t } = useTranslation('contract');

  const {
    filters,
    handleListFilterChange,
    getSelectedValues,
    handleCustomFilterChange,
    removeFilter,
    getCustomFilterValue,
    handleDateRangeChange
  } = useContractPageContext();

  const statuses = getSelectedValues(FilterGroupsEnum.Status, model.status.list);
  const inkasoType = getSelectedValues(FilterGroupsEnum.InkassoType, model.inkasotype.list);
  const editReason = getSelectedValues(FilterGroupsEnum.EditReason, model.editReason.list);
  const breContributionRefunds = getSelectedValues(
    FilterGroupsEnum.BreContributionRefunds,
    model.breYears.list
  );
  const federalState = getSelectedValues(
    FilterGroupsEnum.InsuredPartnersFederalState,
    model.federalState.list
  );

  const editDateFilter = filters.active.find((filter) => {
    return filter.groupId === FilterGroupsEnum.EditDate;
  });
  const fromEditDate =
    editDateFilter?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.GREATER_THAN_EQUALS
    )?.value || '';
  const toEditDate =
    editDateFilter?.criteria?.find(
      ({ operation }) => operation === SearchOperationEnum.LESS_THAN_EQUALS
    )?.value || '';

  const fullyInsured = getCustomFilterValue(FilterGroupsEnum.FullyInsured);
  const fullyInsuredFilter: DropdownOption | null = (() => {
    const values = fullyInsured?.criteria?.[0].value || [];
    if (values.includes(BooleanEnum.TRUE)) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.TRUE)!;
    } else if (values.includes(BooleanEnum.FALSE)) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.FALSE)!;
    }
    return null;
  })();

  const discountIdentifier = getCustomFilterValue(FilterGroupsEnum.DiscountIdentifier);
  const discountIdentifierFilter: DropdownOption | null = (() => {
    const operation = discountIdentifier?.criteria?.[0].operation;
    if (operation === SearchOperationEnum.IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.TRUE)!;
    } else if (operation === SearchOperationEnum.NOT_IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.FALSE)!;
    }
    return null;
  })();

  const rejected = getCustomFilterValue(FilterGroupsEnum.Rejected);
  const rejectedValue: DropdownOption | null = (() => {
    const operation = rejected?.criteria?.[0].operation;
    if (operation === SearchOperationEnum.IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.TRUE)!;
    } else if (operation === SearchOperationEnum.NOT_IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.FALSE)!;
    }
    return null;
  })();

  const riskPremium = getCustomFilterValue(FilterGroupsEnum.RiskPremium);
  const riskPremiumValue: DropdownOption | null = (() => {
    const operation = riskPremium?.criteria?.[0].operation;
    if (operation === SearchOperationEnum.IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.TRUE)!;
    } else if (operation === SearchOperationEnum.NOT_IN) {
      return model.boolean.list.find((i) => i.value === BooleanEnum.FALSE)!;
    }
    return null;
  })();

  return (
    <div className="w-full grid grid-cols-3 s:grid-cols-5 gap-[24px] items-end">
      <MultiSelectAll
        value={statuses}
        options={model.status.list}
        label={t('filter.status')}
        onChange={(value) => {
          handleListFilterChange(
            value,
            FilterGroupsEnum.Status,
            SearchOperationEnum.IN,
            model.status.list
          );
        }}
      />

      <Autocomplete
        value={fullyInsuredFilter}
        blurOnSelect
        options={model.boolean.list}
        renderInput={(params) => <TextField {...params} label={t('filter.fullyInsured')} />}
        onChange={(_event, value) => {
          if (!value) {
            removeFilter(FilterGroupsEnum.FullyInsured);
            return;
          }
          handleListFilterChange(
            [value],
            FilterGroupsEnum.FullyInsured,
            SearchOperationEnum.IN,
            model.boolean.list
          );
        }}
      />

      <MultiSelectAll
        value={breContributionRefunds}
        label={t('filter.breContribution')}
        options={model.breYears.list}
        onChange={(value) => {
          handleListFilterChange(
            value,
            FilterGroupsEnum.BreContributionRefunds,
            SearchOperationEnum.IN,
            model.breYears.list
          );
        }}
      />
      <MultiSelectAll
        value={federalState}
        options={model.federalState.list}
        label={t('filter.federalState')}
        onChange={(value) => {
          handleListFilterChange(
            value,
            FilterGroupsEnum.InsuredPartnersFederalState,
            SearchOperationEnum.IN,
            model.federalState.list
          );
        }}
      />
      <MultiSelectAll
        value={editReason}
        options={model.editReason.list}
        label={t('filter.editReason')}
        onChange={(value) => {
          handleListFilterChange(
            value,
            FilterGroupsEnum.EditReason,
            SearchOperationEnum.IN,
            model.editReason.list
          );
        }}
      />
      <DatePicker
        value={fromEditDate ? new Date(fromEditDate) : null}
        label={t('filter.editDateFrom')}
        onChange={(value, context) => {
          if (context.validationError) return;
          handleDateRangeChange({
            to: toEditDate ? new Date(toEditDate) : null,
            from: value,
            groupId: FilterGroupsEnum.EditDate
          });
        }}
      />
      <DatePicker
        value={toEditDate ? new Date(toEditDate) : null}
        label={t('filter.editDateTo')}
        onChange={(value, context) => {
          if (context.validationError) return;
          handleDateRangeChange({
            to: value,
            from: fromEditDate ? new Date(fromEditDate) : null,
            groupId: FilterGroupsEnum.EditDate
          });
        }}
      />
      <Autocomplete
        value={discountIdentifierFilter}
        blurOnSelect
        options={model.boolean.list}
        renderInput={(params) => <TextField {...params} label={t('filter.discountIdentifier')} />}
        onChange={(_event, value, reason) => {
          const hasTruthyFilter = value?.value === BooleanEnum.TRUE;
          if (!value && reason === 'clear') {
            removeFilter(FilterGroupsEnum.DiscountIdentifier);
            return;
          }

          if (hasTruthyFilter) {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.DiscountIdentifier,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.DiscountIdentifier],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.DiscountIdentifier],
                  operation: SearchOperationEnum.IN,
                  value: ['601']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.DiscountIdentifier, group);
          } else {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.DiscountIdentifier,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.DiscountIdentifier],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.DiscountIdentifier],
                  operation: SearchOperationEnum.NOT_IN,
                  value: ['601']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.DiscountIdentifier, group);
          }
        }}
      />
      <Autocomplete
        value={rejectedValue}
        blurOnSelect
        options={model.boolean.list}
        renderInput={(params) => <TextField {...params} label={t('filter.rejected')} />}
        onChange={(_event, value) => {
          const hasTruthyFilter = value?.value === BooleanEnum.TRUE;
          if (!value) {
            removeFilter(FilterGroupsEnum.Rejected);
            return;
          }

          if (hasTruthyFilter) {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.Rejected,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.Rejected],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.Status],
                  operation: SearchOperationEnum.IN,
                  value: PolicyStatusEnum.CANCELED
                },
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.EditReason],
                  operation: SearchOperationEnum.IN,
                  value: ['abgelehnt']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.Rejected, group);
          } else {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.Rejected,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.Rejected],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.Status],
                  operation: SearchOperationEnum.NOT_IN,
                  value: [PolicyStatusEnum.CANCELED]
                },
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.EditReason],
                  operation: SearchOperationEnum.NOT_IN,
                  value: ['abgelehnt']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.DiscountIdentifier, group);
          }
        }}
      />
      <Autocomplete
        value={riskPremiumValue}
        blurOnSelect
        options={model.boolean.list}
        renderInput={(params) => <TextField {...params} label={t('filter.riskPremium')} />}
        onChange={(_event, value) => {
          const hasTruthyFilter = value?.value === BooleanEnum.TRUE;
          if (!value) {
            removeFilter(FilterGroupsEnum.RiskPremium);
            return;
          }

          if (hasTruthyFilter) {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.RiskPremium,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.RiskPremium],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.RiskPremium],
                  operation: SearchOperationEnum.IN,
                  value: ['*WAG', 'WAG']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.RiskPremium, group);
          } else {
            const group: FilterGroup = {
              groupId: FilterGroupsEnum.RiskPremium,
              groupKey: FilterGroupsKeys[FilterGroupsEnum.RiskPremium],
              criteriaType: CriteriaType.AND,
              criteria: [
                {
                  key: FilterGroupsKeys[FilterGroupsEnum.RiskPremium],
                  operation: SearchOperationEnum.NOT_IN,
                  value: ['*WAG', 'WAG']
                }
              ]
            };
            handleCustomFilterChange(FilterGroupsEnum.RiskPremium, group);
          }
        }}
      />
      <MultiSelectAll
        value={inkasoType}
        options={model.inkasotype.list}
        label={t('filter.inkasoType')}
        onChange={(value) => {
          handleListFilterChange(
            value,
            FilterGroupsEnum.InkassoType,
            SearchOperationEnum.IN,
            model.inkasotype.list
          );
        }}
      />
    </div>
  );
};
