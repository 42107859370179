import { ChangeEvent, forwardRef, useImperativeHandle, useState } from 'react';

import { ICellEditor } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import TextField from '@mui/material/TextField';

import { DarkTooltip } from 'lkh-portal-ui-library';

import { Cells } from 'components/OfferTariffTable/cells';
import { FormattingStyle } from 'components/OfferTariffTable/cells/AdditionalRiskCell/AdditionalRiskCell';
import { calculatePercentage, calculateValueFromPercentage } from 'utilities/percentageCalc';

const onKeyDown = (event: { key: string; preventDefault: () => void }) => {
  if (event.key === 'e' || event.key === 'E' || event.key === '-' || event.key === '+') {
    event.preventDefault();
  }
};

export type DoublingEditorProps = {
  tariffIndex: number;
  addedRiskAmount: number;
  formattingStyle: FormattingStyle;
  price: number;
  maxRiskSurcharge: number;
  handleFormattingStyleChange: (
    tariffIndex: number,
    newformattingStyle: FormattingStyle | null
  ) => void;
};

export const DoublingEditor = forwardRef<ICellEditor, DoublingEditorProps>(
  (
    {
      addedRiskAmount,
      price,
      maxRiskSurcharge,
      tariffIndex,
      formattingStyle,
      handleFormattingStyleChange
    },
    ref
  ) => {
    const { t } = useTranslation('common');
    const initialDisplayValue =
      formattingStyle === FormattingStyle.PERCENT
        ? calculatePercentage(addedRiskAmount, price)
        : addedRiskAmount;
    const [value, setValue] = useState<string>(initialDisplayValue.toFixed(2));
    const [isMaxValue, setIsMaxValue] = useState<boolean>(false);
    const isOverMaxRiskSurcharge = (value: number) => value > maxRiskSurcharge - 1;

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => ({
      // the final value to send to the grid, on completion of editing
      // Mandatory - Return the final value - called by the grid once after editing is complete
      getValue() {
        const numericValue = Number(value);
        if (formattingStyle === FormattingStyle.EURO) {
          if (isOverMaxRiskSurcharge(numericValue)) return 0;
          return numericValue;
        } else if (formattingStyle === FormattingStyle.PERCENT) {
          const calculatedValue = calculateValueFromPercentage(price, numericValue);
          if (isOverMaxRiskSurcharge(calculatedValue)) return 0;
          return Math.abs(calculatedValue);
        }
      },
      // Gets called once after initialised. If you return true, the editor will not be
      // used and the grid will continue editing. Use this to make a decision on editing
      // inside the init() function, eg maybe you want to only start editing if the user
      // hits a numeric key, but not a letter, if the editor is for numbers.
      isCancelBeforeStart() {
        return false;
      },
      // Gets called once after editing is complete. If your return true, then the new
      // value will not be used. The editing will have no impact on the record. Use this
      // if you do not want a new value from your gui, i.e. you want to cancel the editing.
      isCancelAfterEnd() {
        return false;
      }
    }));

    const onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      if (value === '') {
        setValue(value);
        setIsMaxValue(false);
        return;
      }

      let newValue = value;
      // remove the initial "0.00" from the start
      if (value.startsWith('0.00') || value.startsWith('0,00')) {
        newValue = value.substring(4);
      }

      const parsedInput = Math.abs(Number(parseFloat(newValue).toFixed(2)));
      if (!isNaN(parsedInput)) {
        if (isOverMaxRiskSurcharge(parsedInput)) {
          setIsMaxValue(true);
        } else {
          setIsMaxValue(false);
        }
        setValue(newValue);
      }
    };

    return (
      <TextField
        fullWidth
        autoFocus
        variant="outlined"
        size="small"
        type="number"
        value={value}
        error={isMaxValue}
        placeholder="0,00"
        InputProps={{
          startAdornment: (
            <Cells.AdditionalRiskCell
              addedRiskAmount={0}
              tariffIndex={tariffIndex}
              formattingStyle={formattingStyle}
              handleFormattingStyleChange={handleFormattingStyleChange}
              hideFormattedAddedRiskAmount
              disableToggle
            />
          ),
          endAdornment: isMaxValue ? (
            <DarkTooltip arrow placement="top-start" title={t('overMaxRiskSurchargeMsg')}>
              <ReportProblemOutlinedIcon className="fill-danger-60" />
            </DarkTooltip>
          ) : null
        }}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    );
  }
);
