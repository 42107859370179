import { ColDef, ICellRendererParams } from 'ag-grid-community';
import classNames from 'classnames';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import GroupIcon from '@mui/icons-material/Group';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar, Typography } from '@mui/material';
import { colors } from 'styles/colors';

import { DarkTooltip } from 'lkh-portal-ui-library';
import { Policy, PolicyStatusEnum } from 'models/portal-generated';

import { ColumnIds } from './constants';
import { VALUE_GETTERS } from './valueFormatter';
import { TableTooltips } from 'components/TableTooltip';
import { formatDateToGermanForm } from 'utilities/dates';
import { formatPrice } from 'utilities/string';

import styled from '../GridGroup.module.scss';

export const cols = (): Array<ColDef<Policy>> => {
  return [
    {
      headerName: i18n.t('table.number', { ns: 'contract' }),
      field: ColumnIds.id,
      minWidth: 120,
      flex: 2,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      valueGetter: VALUE_GETTERS[ColumnIds.id],
      cellRenderer: (props: { value: string }) => {
        return (
          <Typography variant="bodySMBold" className="m-0 text-text-80">
            {props.value}
          </Typography>
        );
      },
      sortable: true,
      cellClass: 'flex items-center'
    },
    {
      cellClass: 'flex items-center',
      headerName: i18n.t('table.name', { ns: 'contract' }),
      flex: 3,
      field: ColumnIds.HolderName,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      valueGetter: VALUE_GETTERS[ColumnIds.HolderName],
      cellRenderer: (props: ICellRendererParams<Policy>) => {
        const hasReminder = (props.data?.reminderAmount || 0) > 0;
        const tooltip = props.data && hasReminder ? <TableTooltips.Reminder /> : undefined;
        if (props.value?.text?.length) {
          return (
            <DarkTooltip title={tooltip} followCursor>
              <div
                className={classNames('flex gap-[10px] items-center', {
                  ['text-danger-100 bg-danger-50']: hasReminder
                })}
              >
                <Avatar sx={{ bgcolor: colors.surface[60], width: 24, height: 24 }}>
                  <PersonOutlineIcon className="text-text-80" sx={{ width: 16, height: 16 }} />
                </Avatar>
                <Typography variant="bodySMRegular" className="text-text-80">
                  {props.value.text}
                </Typography>
              </div>
            </DarkTooltip>
          );
        }
        return <></>;
      },
      sortable: true
    },
    {
      field: ColumnIds.Address,
      cellClass: 'flex items-center',
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      headerName: i18n.t('table.address', { ns: 'contract' }),
      sortable: true,
      flex: 5,
      valueGetter: VALUE_GETTERS[ColumnIds.Address],
      cellRenderer: (props: { value: { text: string; data: Policy } }) => {
        return (
          <DarkTooltip title={<TableTooltips.Address {...props.value.data} />} followCursor>
            <Typography variant="bodySMRegular" className="m-0 text-text-80">
              {props.value.text}
            </Typography>
          </DarkTooltip>
        );
      }
    },
    {
      cellClass: 'flex items-center',
      headerName: i18n.t('table.count', { ns: 'contract' }),
      field: ColumnIds.PartnersCount,
      flex: 1.5,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      valueGetter: VALUE_GETTERS[ColumnIds.PartnersCount],
      cellRenderer: (props: { value: { text: string; data: Policy } }) => {
        return (
          <DarkTooltip title={<TableTooltips.InsuredPartners {...props.value.data} />} followCursor>
            <div className="flex gap-[10px] items-center">
              <Avatar sx={{ bgcolor: colors.surface[60], width: 24, height: 24 }}>
                <GroupIcon className="text-text-80" sx={{ width: 16, height: 16 }} />
              </Avatar>
              <Typography variant="bodySMRegular" className="text-text-80">
                {props.value.text}
              </Typography>
            </div>
          </DarkTooltip>
        );
      },
      sortable: true
    },
    {
      cellClass: 'flex items-center',
      headerName: i18n.t('table.startDate', { ns: 'contract' }),
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      field: ColumnIds.ValidFrom,
      flex: 2,
      sortable: true,
      valueGetter: VALUE_GETTERS[ColumnIds.ValidFrom],
      cellRenderer: (props: { value: string }) => {
        return (
          <Typography variant="bodySMRegular" className="m-0 text-text-80">
            {formatDateToGermanForm(props.value)}
          </Typography>
        );
      }
    },
    {
      headerName: i18n.t('table.price', { ns: 'contract' }),
      field: ColumnIds.Amount,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      cellClass: 'flex items-center',
      flex: 1.5,
      valueGetter: VALUE_GETTERS[ColumnIds.Amount],
      cellRenderer: (props: { value: number }) => {
        return (
          <Typography variant="bodySMRegular" className="m-0 text-text-80">
            {formatPrice(props.value)}
          </Typography>
        );
      },
      sortable: true
    },
    {
      cellClass: 'flex items-center',
      flex: 2,
      headerName: i18n.t('table.status', { ns: 'contract' }),
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      field: ColumnIds.Status,
      sortable: false,
      cellRenderer: (props: ICellRendererParams<Policy, PolicyStatusEnum>) => {
        const { t } = useTranslation('contract');
        const status = props.data?.status;
        const getPolicyStatusClassName = (): string => {
          if (status === PolicyStatusEnum.ACTIVE) return styled.iconActive;
          if (status === PolicyStatusEnum.PENDING) return styled.iconPending;
          if (status === PolicyStatusEnum.HISTORIC) return styled.iconHistoric;
          if (status === PolicyStatusEnum.REACTIVATED) return styled.iconReactivated;
          if (status === PolicyStatusEnum.CANCELED) return styled.iconCancelled;
          if (status === PolicyStatusEnum.IN_PROCESS) return styled.iconInprocess;
          if (status === PolicyStatusEnum.REVISION_IN_PROCESS) return styled.iconRevisionInProcess;

          // if status is not recognized return inactive color
          return styled.iconUnknownStatus;
        };

        return (
          <div className={classNames('m-0 py-s px-[12px] rounded', styled.status)}>
            <div className={getPolicyStatusClassName()} />
            <Typography variant="bodySMBold" className="text-text-80">
              {t(`table.statusEnum.${props.value}`, { ns: 'contract' })}
            </Typography>
          </div>
        );
      }
    },
    {
      cellClass: 'flex items-center justify-end',
      headerName: '',
      flex: 1,
      sortable: false,
      maxWidth: 50,
      headerClass:
        'text-xs font-bold text-text-80 border-solid border-b-[1px] border-t-[0px] border-l-[0px] border-r-[0px] border-text-80',
      field: ColumnIds.id,
      cellRenderer: () => {
        return <OpenInNewIcon className="text-text-80 cursor-pointer" />;
      }
    }
  ];
};
