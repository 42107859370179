/**
 * Calculates the percentage of a part relative to the total.
 * @param part - The part value.
 * @param total - The total value.
 * @returns The calculated percentage value.
 */
export function calculatePercentage(part: number, total: number): number {
  if (total === 0) {
    return 0;
  }
  return (part / total) * 100;
}

/**
 * Calculates the value from a given percentage of a total.
 * @param total - The total value.
 * @param percentage - The percentage value.
 * @returns The calculated value from the percentage of the total.
 */
export function calculateValueFromPercentage(total: number, percentage: number): number {
  return (total * percentage) / 100;
}
