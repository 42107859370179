import { ActionFunction, ApplicationsState } from '../types';

export const setLastName: ActionFunction<string> = (state: ApplicationsState, payload) => {
  return {
    ...state,
    filters: {
      ...state.filters,
      lastname: payload
    }
  };
};
