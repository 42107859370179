import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Typography } from '@mui/material';
import { colors } from 'styles/colors';

import { AlertIcon, Icon, Loader, LoaderType } from 'lkh-portal-ui-library';
import { PersonRoleEnum, TariffCalculationResult } from 'models/extension-generated';

import { useApplicationCalculate } from '../../../../hooks/useApplicationCalculate';
import { useApplicationHelpers } from '../../../../hooks/useApplicationHelpers/useApplicationHelpers';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { DownloadButton } from '../DownloadButton';
import { PartnerSummary } from './PartnerSummary';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';
import { useWizardNavigation } from 'pages/PrivateHealthInsurance/hooks/useWizardNavigation';
import { formatPrice } from 'utilities/string';

import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  const { t } = useTranslation('wizardSidebar');
  const { state } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { currentScreen } = useWizardNavigation();
  const { data, isLoading, error, message } = useApplicationCalculate(state.model, questions);
  const { getPartnersByRole } = useApplicationHelpers(state.model);

  function getPartnerPrice(id: string): number {
    const partner = data?.partners.find(({ partnerId }) => partnerId === id);

    if (error) {
      return 0;
    }

    if (partner && partner.calculationMonthly) {
      return partner.calculationMonthly.totalAmount || 0;
    }

    return 0;
  }

  function getPartnerTariffs(id: string): Array<TariffCalculationResult> {
    const partner = data?.partners.find(({ partnerId }) => partnerId === id);
    if (partner && partner.tariffCalculations) {
      return partner.tariffCalculations;
    }

    return [];
  }

  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const overallSum = data?.calculationMonthly?.amount
    ? formatPrice(data?.calculationMonthly?.amount || 0)
    : '-,-- €';

  return (
    <div className={classNames(styles.sidebar, 'px-[12px] l:px-[48px] py-[48px] pt-[32px]')}>
      <div className="text-right m-b-50">
        <div className="row text-left">
          <Typography
            variant="headlineXLGBold"
            className="pb-m mb-m hidden m:block"
            style={{
              borderBottom: `1px solid ${colors.text[60]}`
            }}
          >
            {t('calculation.offerOverview')}
          </Typography>
        </div>
        <div className="row">
          <div className="flex justify-between">
            <div className="pb-8 flex text-text-100">
              <Typography variant="bodyLGBold">{insuredPartners.length}</Typography>
              <Typography variant="bodyLGRegular" className="ml-s">
                {insuredPartners.length > 1 ? 'Personen' : 'Person'}
              </Typography>
            </div>
            <div className="flex pb-8">
              {isLoading ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                <Typography
                  variant="bodyLGBold"
                  className={`${error ? 'text-danger-60' : 'text-text-100'}`}
                >
                  {overallSum}
                </Typography>
              )}

              <Typography variant="bodyLGRegular" className="pl-s text-text-40">
                / {t('calculation.month')}
              </Typography>
            </div>
          </div>
        </div>

        <div className="hidden m:flex">
          <DownloadButton currentPage={currentScreen} />
        </div>
      </div>

      <div className="m-b-16">
        {insuredPartners.map((partner) => (
          <PartnerSummary
            key={partner.id}
            partner={partner}
            price={getPartnerPrice(partner.id || '')}
            tariffs={getPartnerTariffs(partner.id || '')}
            isLoading={isLoading}
            isError={error ? true : false}
          />
        ))}
      </div>
      {error && message && (
        <div className={classNames('p-24 bg-error-500', styles.errorContainer)}>
          <Icon icon={<AlertIcon />} />
          <div className={classNames('m-l-16', styles.textContainer)}>
            <Typography variant="bodyMDRegular" className="d-block">
              {t('calculation.errorTitle')}
            </Typography>
            <Typography variant="bodySMRegular" className="d-block m-t-8">
              {message}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export const MobileSidebarHeader = () => {
  const { t } = useTranslation('wizardSidebar');
  const { currentScreen } = useWizardNavigation();
  const { state } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { data, isLoading, error } = useApplicationCalculate(state.model, questions);
  const { getPartnersByRole } = useApplicationHelpers(state.model);
  const insuredPartners = getPartnersByRole(PersonRoleEnum.INSURED_PERSON);
  const overallSum = error ? '-,--' : formatPrice(data?.calculationMonthly?.amount || 0);

  return (
    <div className="flex justify-between w-full text-white-100 ">
      <div className="mr-[8px] flex items-center">
        <Typography fontSize={'12px'}>
          {t('calculation.insuredPerson.count', { count: insuredPartners.length })}
        </Typography>
      </div>
      <div className="flex items-center">
        <div className="mr-[24px]">
          <DownloadButton currentPage={currentScreen} />
        </div>
        <div>
          {isLoading ? (
            <Loader type={LoaderType.Circular} />
          ) : (
            <Typography fontSize={'14px'} className="font-bold">
              {overallSum}
            </Typography>
          )}
        </div>
        <Typography>&nbsp;{t('calculation.frequency.month')}</Typography>
      </div>
    </div>
  );
};
