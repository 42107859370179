import { useTranslation } from 'react-i18next';

import { OptionSelect } from './OptionalSection';

export const getOptions = (noAnswerLabel?: string): OptionSelect[] => {
  const { t } = useTranslation('wizardTariffs');
  return [
    {
      label: t(`utils.yes`),
      value: true
    },
    {
      label: noAnswerLabel || t(`utils.no`),
      value: false
    }
  ];
};
