import { axios } from '../services/axios';
import { AxiosResponse } from 'axios';

import { DropdownOption } from 'lkh-portal-ui-library';
import { FilterGroup } from 'models/criteriaTypes';
import {
  CriteriaType,
  FormService,
  NamedFile,
  PolicyService,
  SearchOperationEnum
} from 'models/portal-generated';

import {
  AGE_OVER_60_DOCUMENT_ID,
  DISCLAIMER_DOCUMENT
} from 'pages/PrivateHealthInsurance/constants';

export function getFormFilesMeta(category: string, type: DropdownOption) {
  const listCriteria: Array<FilterGroup> = [];

  if (category !== '') {
    listCriteria.push({
      criteria: [
        {
          key: 'category',
          operation: SearchOperationEnum.EQUALS,
          value: category
        }
      ]
    });
  }

  if (type.value.toString().toLowerCase().trim() !== 'all') {
    listCriteria.push({
      criteria: [
        {
          key: 'type',
          operation: SearchOperationEnum.EQUALS,
          value: type.value
        }
      ]
    });
  }

  return FormService.getFormsMetadata({
    requestBody: {
      criteriaType: CriteriaType.AND,
      page: 0,
      size: 500,
      sort: [],
      searchCriteria: [
        {
          criteria: listCriteria
        }
      ]
    }
  });
}

type DownloadFormParams = Parameters<typeof FormService.downloadForm>[0];
type DownloadFormByReferenceIdParams = Parameters<typeof FormService.downloadFormByReferenceId>[0];
type DownloadDocumentForPolicyParams = Parameters<
  typeof PolicyService.downloadDocumentForPolicy
>[0];

export function downloadForm({ id }: DownloadFormParams): Promise<AxiosResponse<string>> {
  return axios.get(`/api/form/${id}`, { responseType: 'blob' });
}

export function downloadDocumentForPolicy({
  policyId,
  docId
}: DownloadDocumentForPolicyParams): Promise<AxiosResponse<string>> {
  return axios.get(`/api/policy/${policyId}/document/${docId}`, { responseType: 'blob' });
}

export function downloadFormByReferenceId({
  id,
  date
}: DownloadFormByReferenceIdParams): Promise<AxiosResponse<string>> {
  return axios.get(`/api/form/getByReferenceId/${id}?date=${date}`, { responseType: 'blob' });
}

export const downloadDocument = async (documentId: string) => {
  const response = await downloadForm({ id: documentId });

  const fileURL = window.URL.createObjectURL(new Blob([response.data]));
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.download = documentId;
  document.body.appendChild(fileLink);

  fileLink.click();
  fileLink.remove();
};

export const downloadData = (data: BlobPart, filename: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadCsv = (data: BlobPart, filename: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: 'text/csv;charset=utf-8;'
    })
  );

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const downloadAgeOver60Form = async (date: string): Promise<void> => {
  try {
    const response = await downloadFormByReferenceId({ id: AGE_OVER_60_DOCUMENT_ID, date });
    downloadData(response.data, `${AGE_OVER_60_DOCUMENT_ID}.pdf`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error occurred during download:', error);
    throw new Error(`Error occurred during download: ${error}`);
  }
};

/**
 * function to download GDV zip files.
 * Triggers a download in the browser.
 */
export const downloadGDVZipFiles = (namedFiles: Array<NamedFile>) => {
  /**
   * Helper function to trigger a download of a base64 encoded file.
   *
   * @param data - The base64 encoded file content.
   * @param filename - The name of the file to be downloaded.
   */
  function download(data: string, filename: string) {
    const link = document.createElement('a');
    // Set the href attribute to a data URL containing the base64 encoded file content
    link.setAttribute('href', 'data:text/plain;base64,' + data);
    link.setAttribute('download', filename);

    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  namedFiles.forEach((file, index) => {
    // Provide a fallback name using the index if file.name is undefined
    const fileName = file.name ? `${file.name}.zip` : `GDV_${index}.zip`;
    // If the file has content, trigger a download with the file's content and name
    file.content && download(file.content, fileName);
  });
};

export const downloadDisclaimerDocument = async (applicationStart: string | undefined) => {
  try {
    const response = await downloadFormByReferenceId({
      id: DISCLAIMER_DOCUMENT,
      date: applicationStart
    });
    downloadData(response.data, 'Informationen zum Datenschutz.pdf');
  } catch (error) {
    throw new Error(`Error occured during download: ${error}`);
  }
};
