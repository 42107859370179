import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Form, formModelGet } from 'lkh-portal-ui-library';

import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

import styles from './PreInsuranceStartEndForm.module.scss';

type PreInsuranceStartEndFormProps = {
  startDateKey: string;
  endDateKey: string;
  ongoingKey: string;
};

const { Checkbox, DatePicker } = Form.Components;

export const PreInsuranceStartEndForm = ({
  startDateKey,
  endDateKey,
  ongoingKey
}: PreInsuranceStartEndFormProps) => {
  const { t } = useTranslation('wizardGeneral');
  const {
    state: { model },
    reducer: { updateValue }
  } = useHealthInsuranceContext();
  const isOnGoing = formModelGet(model, ongoingKey);

  const handleOngoingChange = (item: boolean) => {
    if (item === true) {
      updateValue({
        key: endDateKey,
        value: ''
      });
    }
  };

  return (
    <>
      <div className="grid-res-2">
        <div>
          <DatePicker
            componentKey={startDateKey}
            label={t(`longTerm.preInsurance.fields.yearFrom`)}
          />
        </div>
      </div>
      <div className="grid-res-2">
        <div>
          <DatePicker
            componentKey={endDateKey}
            label={t(`longTerm.preInsurance.fields.yearTo`)}
            disabled={isOnGoing}
          />
        </div>
        <div className={classNames(styles.ongoingContainer)}>
          <Checkbox
            componentKey={ongoingKey}
            onChange={handleOngoingChange}
            description={t('longTerm.preInsurance.fields.ongoing')}
          />
        </div>
      </div>
    </>
  );
};
