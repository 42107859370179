import Typography from '@mui/material/Typography';

import { FileDownloadBox, Form, formModelGet, useApplicationHelpers } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { PersonRoleEnum } from 'models/extension-generated';

import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

export const AccompaniedDocumentsDownload = ({
  onClick,
  name
}: {
  onClick?: () => void;
  name: string;
}) => {
  const { state } = useHealthInsuranceContext();
  const { getSinglePartnerByRole } = useApplicationHelpers(state.model);

  const { id: holderId = '' } = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const hasDownloadedContractInformationDocumentPath = partnerField(
    holderId,
    'applicationInformation.hasDownloadedContractInformationDocument'
  );
  const hasDownloadedContractInformationDocument = formModelGet(
    state.model,
    hasDownloadedContractInformationDocumentPath
  );
  const { hasError, message } = Form.hooks.useFormComponent(
    hasDownloadedContractInformationDocumentPath
  );

  return (
    <>
      <span data-testid="download-document">
        <FileDownloadBox
          isDone={hasDownloadedContractInformationDocument}
          label={name}
          onClick={onClick}
        />
      </span>
      {hasError && (
        <div className="text-error-700">
          <Typography>{message || 'hasError'}</Typography>
        </div>
      )}
    </>
  );
};
