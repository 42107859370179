/**
 * This file is designed according to LKH confluence page section 4.3. Overview of Application Roles
 * https://confluence.lkh.net/pages/viewpage.action?spaceKey=TL&title=Berechtigungskonzept+Point-of-Sales+-+Vermittlerportal#BerechtigungskonzeptPointofSalesVermittlerportal-4.AnwendungsrollenundBerechtigungen
 */
export enum RealmRole {
  MASTER_AGENT = 'MASTER_AGENT',
  SUPPORT_AGENT = 'SUPPORT_AGENT',
  SERVICE_LKH_INTERNAL = 'SERVICE_LKH_INTERNAL',
  AGENTPORTAL_USER_MANAGEMENT = 'AGENTPORTAL_USER_MANAGEMENT',
  ADMIN = 'ADMIN'
}
