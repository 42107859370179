import { useState } from 'react';

import classNames from 'classnames';

import { Input } from 'lkh-portal-ui-library';

import styles from './Slider.module.scss';

export enum LabelsMode {
  ALL = 'ALL',
  ODD = 'ODD',
  EVEN = 'EVEN'
}

export type SliderProps = {
  val?: number;
  min: number;
  max: number;
  step: number;
  initialValue?: number;
  labelsMode?: LabelsMode;
  showHelper?: {
    unit?: string;
  };
  onChange?: (value: number) => void;
  onReset?: () => void;
};

export const Slider = ({
  min,
  max,
  step,
  initialValue = min,
  labelsMode = LabelsMode.EVEN,
  showHelper,
  onChange
}: SliderProps) => {
  const [value, setValue] = useState<number>(initialValue);
  const [manualValue, setManualValue] = useState<string>(`${initialValue}`);

  function handleChange(e: React.FormEvent<HTMLInputElement>) {
    const value = +e.currentTarget.value;
    setValue(value);
    setManualValue(value.toString());
    onChange?.(value);
  }

  function generateLabels() {
    const opts = [];
    const optsCount = Math.floor(max / step);

    for (let i = 0; i < optsCount; i++) {
      const realValue = (i + 1) * step;
      const percentage = (i / (optsCount - 1)) * 100;

      opts.push(
        <li
          key={i}
          className={styles.label}
          style={{ left: `${percentage}%` }}
          title={`${realValue}%`}
        >
          {realValue}
        </li>
      );
    }
    return opts;
  }

  return (
    <div className={styles.slideContainer}>
      {showHelper && (
        <div className={styles.helper}>
          <Input value={manualValue} unitText={showHelper.unit} viewOnly />
        </div>
      )}

      <input
        type="range"
        min={min}
        max={max}
        step={step}
        className={styles.slider}
        value={value}
        onChange={handleChange}
      />

      <ul
        className={classNames(styles.labelsList, {
          [styles.showOdd]: labelsMode === LabelsMode.ODD,
          [styles.showEven]: labelsMode === LabelsMode.EVEN
        })}
      >
        {generateLabels()}
      </ul>

      <div className={styles.bullet}></div>
    </div>
  );
};
