import { FilterGroup } from 'models/criteriaTypes';
import { SearchOperationEnum } from 'models/portal-generated';

import { FilterGroupsEnum } from '../constants';

export const CONTRACT_FILTERS = [
  FilterGroupsEnum.ValidFrom,
  FilterGroupsEnum.MatchCode,
  FilterGroupsEnum.PolicyHolderName,
  FilterGroupsEnum.PolicyHolderFirstName,
  FilterGroupsEnum.MainContactPostalCode,
  FilterGroupsEnum.MainContactCity,
  FilterGroupsEnum.MainContactStreet,
  FilterGroupsEnum.MainContactHouseNumber,
  FilterGroupsEnum.MainContactValidFrom,
  FilterGroupsEnum.Tariff,
  FilterGroupsEnum.NotTariff,
  FilterGroupsEnum.NotTariffGroupId,
  FilterGroupsEnum.TariffGroupId,
  FilterGroupsEnum.AgentId,
  FilterGroupsEnum.OrganizationUnit
];

export const CONTRACT_FILTERS_RANGE = [
  FilterGroupsEnum.PolicyHolderBirthDate,
  FilterGroupsEnum.Documented,
  FilterGroupsEnum.Id
];

export const INSURED_PERSON_FILTERS = [
  FilterGroupsEnum.Gender,
  FilterGroupsEnum.InsuredPartnersName,
  FilterGroupsEnum.InsuredPartnersFirstName,
  FilterGroupsEnum.InsuredPartnersMatchCode,
  FilterGroupsEnum.InsuredPartnersPostalCode,
  FilterGroupsEnum.InsuredPartnersCity,
  FilterGroupsEnum.InsuredPartnersStreet,
  FilterGroupsEnum.InsuredPartnersHouseNumber,
  FilterGroupsEnum.InsuredPartnersValidFrom,
  FilterGroupsEnum.InsuredPartnersJobType,
  FilterGroupsEnum.WorkActivity
];

export const INSURED_PERSON_FILTERS_RANGE = [FilterGroupsEnum.InsuredPartnersBirthDate];

export const SPECIAL_SEARCH_FILTERS = [
  FilterGroupsEnum.Status,
  FilterGroupsEnum.FullyInsured,
  FilterGroupsEnum.BreContributionRefunds,
  FilterGroupsEnum.InsuredPartnersFederalState,
  FilterGroupsEnum.EditReason,
  FilterGroupsEnum.DiscountIdentifier,
  FilterGroupsEnum.Rejected,
  FilterGroupsEnum.InkassoType,
  FilterGroupsEnum.RiskPremium
];

/**
 * Calculates the count of active filters based on the provided filter groups.
 * @param activeFilters - Currently active filters
 * @param filters - Array of filter groups to consider as basic filters
 * @param rangeFilters - Array of filter groups to consider as range filters
 * @returns The count of active filters
 */
export const getActiveFiltersCount = (
  activeFilters: FilterGroup[],
  filters: FilterGroupsEnum[],
  rangeFilters: FilterGroupsEnum[]
): number => {
  const basicFiltersCount = activeFilters.filter(({ groupId }) =>
    filters.includes(groupId as FilterGroupsEnum)
  ).length;

  const rangeFilterCount = activeFilters.reduce((acc, next) => {
    let sumOfCriteria = 0;
    const hasRange = rangeFilters.includes(next.groupId as FilterGroupsEnum);
    const hasFrom = next.criteria?.some(
      (c) => c.operation === SearchOperationEnum.GREATER_THAN_EQUALS
    );
    const hasTo = next.criteria?.some((c) => c.operation === SearchOperationEnum.LESS_THAN_EQUALS);
    sumOfCriteria += hasFrom ? 1 : 0;
    sumOfCriteria += hasTo ? 1 : 0;

    return hasRange ? acc + sumOfCriteria : acc;
  }, 0);

  return basicFiltersCount + rangeFilterCount;
};
