import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import { CertificateIcon, Icon } from 'lkh-portal-ui-library';

import { formatPrice } from 'utilities/string';

export type PersonAccodrionHeaderProps = {
  name?: string;
  role?: string;
  amount?: number;
  isPolicyHolder?: boolean;
};

export const PersonAccodrionHeader = ({
  name,
  role,
  amount,
  isPolicyHolder
}: PersonAccodrionHeaderProps) => {
  return (
    <div className="flex w-[calc(100%-50px)] justify-center items-center">
      <div className="flex-none p-0 ">
        <Avatar
          className="mr-[16px]"
          sx={{ backgroundColor: colors.surface[60], width: 28, height: 28 }}
        >
          <PersonOutlineOutlinedIcon className="fill-text-80" />
        </Avatar>
      </div>

      <div className="grow">
        <div className="mb-[8px]">
          <Typography variant="bodyMDBold">{role}</Typography>
        </div>
        <div translate="no" className="flex items-center">
          <Typography>{name}</Typography>
          {isPolicyHolder && <Icon icon={<CertificateIcon />} className="ml-[8px] text-logo-500" />}
        </div>
      </div>

      {amount && <div className="flex-none p-0">{formatPrice(amount)}</div>}
    </div>
  );
};
