import { ColDef, GroupCellRendererParams, ValueGetterParams } from 'ag-grid-community';

import i18n from 'services/translation';

import { Cells } from './cells';
import { FormattingStyle } from './cells/AdditionalRiskCell/AdditionalRiskCell';
import { DoublingEditor } from './components';
import { TariffRow } from './OfferTariffTable';
import { TableHeaderTooltip } from 'components/Tooltips/TableHeaderTooltip';

import styles from './OfferTariffTable.module.scss';

export enum ColumnIds {
  Product = 'product',
  Tariff = 'tariff',
  Step = 'step',
  Entitlement = 'entitlement',
  RiskPremium = 'addedRiskAmount',
  Price = 'price',
  ActionCell = 'actionCell'
}

const commonCellProperties: ColDef = {
  cellClass: styles.cell,
  headerClass: styles.header,
  tooltipComponent: TableHeaderTooltip
};

export const getColumnDefinitions = (
  allowEditing: boolean,
  tariffFormattingStyles: FormattingStyle[],
  handleFormattingStyleChange: (
    tariffIndex: number,
    newformattingStyle: FormattingStyle | null
  ) => void
): Array<ColDef<TariffRow>> => {
  return [
    {
      ...commonCellProperties,
      field: ColumnIds.Product,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.product,
      cellRenderer: ({ data, rowIndex, api }: GroupCellRendererParams<TariffRow>) => {
        const isLastRow = rowIndex === api.getLastDisplayedRow();
        return <Cells.Product product={data?.product} isSummaryRow={isLastRow} />;
      },
      headerName: i18n.t('table.product', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.product', { ns: 'wizardOffer' }),
      flex: 3
    },
    {
      ...commonCellProperties,
      field: ColumnIds.Tariff,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.tariffLabel,
      cellRenderer: ({ data }: GroupCellRendererParams<TariffRow>) => {
        return (
          <Cells.TariffLabel
            tariffLabel={data?.tariffLabel?.tariffLabel}
            tooltip={data?.tariffLabel?.tooltip}
          />
        );
      },
      headerName: i18n.t('table.tariff', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.tariff', { ns: 'wizardOffer' }),
      flex: 2
    },
    {
      ...commonCellProperties,
      field: ColumnIds.Step,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.highMoneyAmount,
      cellRenderer: ({ data }: GroupCellRendererParams<TariffRow>) => {
        return <Cells.HighMoneyAmount highMoneyAmount={data?.highMoneyAmount} />;
      },
      headerName: i18n.t('table.highMoneyAmount', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.highMoneyAmount', { ns: 'wizardOffer' }),
      flex: 2
    },
    {
      ...commonCellProperties,
      field: ColumnIds.Entitlement,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.entitlement,
      cellRenderer: ({ data, rowIndex, api }: GroupCellRendererParams<TariffRow>) => {
        const isLastRow = rowIndex === api.getLastDisplayedRow();
        return <Cells.EntitlementCell isEnabled={data?.entitlement} hideCell={isLastRow} />;
      },
      headerName: i18n.t('table.entitlement', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.entitlement', { ns: 'wizardOffer' }),
      minWidth: 80,
      flex: 2
    },
    {
      ...commonCellProperties,
      colId: ColumnIds.RiskPremium,
      field: ColumnIds.RiskPremium,
      editable: allowEditing,
      cellEditor: DoublingEditor,
      cellEditorParams: ({ data, rowIndex }: GroupCellRendererParams<TariffRow>) => {
        const calculatedPrice =
          (data?.price?.total ?? 0) -
          (data?.addedRiskAmount ?? 0) -
          (data?.price?.statutorySurcharge ?? 0);
        return {
          tariffIndex: rowIndex,
          price: calculatedPrice < 0 ? 0 : calculatedPrice,
          maxRiskSurcharge: data?.price?.maxRisSurcharge,
          addedRiskAmount: data?.addedRiskAmount ?? 0,
          formattingStyle: tariffFormattingStyles.at(rowIndex),
          handleFormattingStyleChange: handleFormattingStyleChange
        };
      },
      headerName: i18n.t('table.riskPremium', { ns: 'wizardOffer' }),
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.addedRiskAmount,
      cellRenderer: ({ data, rowIndex, api }: GroupCellRendererParams<TariffRow>) => {
        const isLastRow = rowIndex === api.getLastDisplayedRow();
        const addedRiskAmount = data?.addedRiskAmount ?? 0;

        let calculatedPrice: number | undefined;

        if (data?.price?.total === undefined || data?.addedRiskAmount === undefined) {
          calculatedPrice = undefined;
        } else {
          calculatedPrice =
            data.price.total - data.addedRiskAmount - (data?.price?.statutorySurcharge ?? 0);
        }

        return (
          <Cells.AdditionalRiskCell
            tariffIndex={rowIndex}
            formattingStyle={tariffFormattingStyles.at(rowIndex)}
            addedRiskAmount={addedRiskAmount}
            isSummaryRow={isLastRow}
            price={calculatedPrice}
            hideFormattingToggle={isLastRow}
            handleFormattingStyleChange={handleFormattingStyleChange}
          />
        );
      },
      headerTooltip: i18n.t('table.riskPremium', { ns: 'wizardOffer' }),
      type: 'rightAligned',
      flex: 2,
      minWidth: 200,
      autoHeight: true,
      cellClass: [
        styles.cellEditor,
        'flex w-full h-full !border-none !shadow-none leading-[1.1rem]'
      ]
    },
    {
      ...commonCellProperties,
      headerClass: [styles.header, 'ag-right-aligned-header'],
      cellClass: [styles.cell, 'ag-right-aligned-cell'],
      colId: ColumnIds.Price,
      cellStyle: {
        flexDirection: 'row-reverse'
      },
      flex: 1,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.price,
      cellRenderer: ({ data }: GroupCellRendererParams<TariffRow>) => {
        return <Cells.Price price={data?.price?.total} />;
      },
      headerName: i18n.t('table.price', { ns: 'wizardOffer' }),
      headerTooltip: i18n.t('table.price', { ns: 'wizardOffer' }),
      minWidth: 80
    },
    {
      ...commonCellProperties,
      colId: ColumnIds.ActionCell,
      cellClass: 'p-0',
      flex: 0.5,
      maxWidth: 75,
      valueGetter: (params: ValueGetterParams<TariffRow>) => params.data?.actionComponent,
      cellRenderer: ({ data }: GroupCellRendererParams<TariffRow>) => {
        return <Cells.ActionMenuCell {...data?.actionComponent} />;
      },
      minWidth: 40
    }
  ];
};
