import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Button, CircularProgress, Dialog, Typography } from '@mui/material';

import { formatInput } from 'utilities/dates';
import { downloadFormByReferenceId } from 'utilities/fetchDocument';

export const newBornFormInfo = {
  id: 'K03_11',
  name: 'Anmeldung_eines_Neugeborenen_K03_11_0622.pdf'
};

type NewBornDialogProps = {
  isNewBornDialogOpen: boolean;
  setIsNewBornDialogOpen: (value: boolean) => void;
};

export const NewBornDialog = ({
  isNewBornDialogOpen,
  setIsNewBornDialogOpen
}: NewBornDialogProps) => {
  const { t } = useTranslation('productsPage');
  const [isDownloadingNewBornDoc, setIsDownloadingNewBornDoc] = useState(false);
  const handleCloseNewBornDialog = () => setIsNewBornDialogOpen(false);

  const handleDownloadNewBorn = async () => {
    setIsDownloadingNewBornDoc(true);
    try {
      const now = formatInput(new Date());
      const response = await downloadFormByReferenceId({ id: newBornFormInfo.id, date: now });

      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.download = newBornFormInfo.name;
      document.body.appendChild(fileLink);

      fileLink.click();
      fileLink.remove();
    } finally {
      setIsDownloadingNewBornDoc(false);
    }
  };
  return (
    <Dialog disableScrollLock open={isNewBornDialogOpen} onClose={handleCloseNewBornDialog}>
      <div className="py-[24px] px-[32px]">
        <div className="inline-flex gap-8">
          <ErrorOutlineOutlinedIcon className="text-warning-60 text-2xl" />
          <div className="pl-[16px]">
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('productsPage:modal.newBorns.header')}
            </Typography>
            <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px]">
              {t('productsPage:modal.newBorns.description')}
            </Typography>
          </div>
        </div>
        <div className="flex justify-end gap-[16px] mt-[32px]">
          <Button size="large" onClick={handleCloseNewBornDialog}>
            {t('productsPage:modal.cancelButton')}
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={handleDownloadNewBorn}
            disabled={isDownloadingNewBornDoc}
            endIcon={
              isDownloadingNewBornDoc ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <FileDownloadOutlinedIcon />
              )
            }
          >
            {t('productsPage:modal.downloadButton')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
