/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * In welchem Verhältnis sind Sie als Humanmediziner tätig?
 */
export enum WorkingDoctorRelationshipEnum {
    EMPLOYED = 'EMPLOYED',
    SELF_EMPLOYED = 'SELF_EMPLOYED',
}
