import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

import { ApplicationService } from 'models/extension-generated';

type SignApplicationDialogProps = {
  appId: string;
  isOpen: boolean;
  onClose: () => void;
  process?: () => void;
};
export const SignApplicationDialog = ({
  appId,
  isOpen,
  onClose,
  process = () => {
    /**/
  }
}: SignApplicationDialogProps) => {
  const { t } = useTranslation(['common', 'dashboard', 'wizardPrivateHealthInsurance']);
  const [isChangingStatus, setIsChangingStatus] = useState(false);

  const handleCloseDialog = () => {
    onClose();
  };

  const changeApplicationStatus = async () => {
    if (!appId) return;
    setIsChangingStatus(true);
    try {
      await ApplicationService.signApplicationExternally({ id: appId });
      toast.success(t<string>('wizardPrivateHealthInsurance:orderOptions.menu.deleteConfirm'));
    } catch (error) {
      toast.error(t<string>('common:toastMessages.somethingWentWrong'));
    } finally {
      setIsChangingStatus(false);
      handleCloseDialog();
      process();
    }
  };

  return (
    <Dialog disableScrollLock fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex mb-[32px]">
          <div>
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('dashboard:actions.changeStatusConfirmation.title')}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex  text-text-80 mt-[4px] ">
                {t('dashboard:actions.changeStatusConfirmation.message')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="py-[24px] px-[32px] bg-surface-60 border border-solid border-text-20 rounded-lg">
          <Typography variant="bodyLGBold" className="flex text-text-100">
            {t('dashboard:actions.changeStatusConfirmation.hints.title')}
          </Typography>
          <ul className="pl-[24px] list-disc">
            {[1, 2].map((hint, hintIndex) => {
              return (
                <li key={`sign-app-dialog-hint-${hint}`}>
                  <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                    {t(`dashboard:actions.changeStatusConfirmation.hints.hint${hintIndex + 1}`)}
                  </Typography>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex gap-[16px] justify-end mt-[32px]">
          <Button size="large" onClick={handleCloseDialog}>
            {t('dashboard:actions.changeStatusConfirmation.abort')}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="primary"
            startIcon={
              isChangingStatus ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <BookmarkAddedOutlinedIcon />
              )
            }
            onClick={changeApplicationStatus}
            disabled={isChangingStatus}
          >
            {isChangingStatus
              ? t('dashboard:actions.changeStatusConfirmation.wait')
              : t('dashboard:actions.changeStatusConfirmation.changeStatus')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
