import { Application, Partner, PersonRoleEnum } from 'models/extension-generated';

type UseApplicationHelpers = {
  getPartnerById: (id: string) => Partner;
  getPartnersByRole: (role: PersonRoleEnum) => Array<Partner>;
  getSinglePartnerByRole: (role: PersonRoleEnum) => Partner;
  isHolderAlsoPayer: () => boolean;
  isHolderInsured: (partner: Partner) => boolean;
  formatPartnerName: (id: string) => string;
};

export const useApplicationHelpers = (application?: Application): UseApplicationHelpers => {
  /**
   * Returns partner instance from the application
   * Throws an error if partner does not exist
   *
   * @param id unique partner ID
   * @returns partner
   */
  function getPartnerById(id: string): Partner {
    const partner = application?.partners?.find((p) => p.id === id);
    if (!partner) {
      throw new Error('Partner not found within an application!');
    }
    return partner;
  }

  /**
   * Returns partners list by a given role
   *
   * @param role identifier
   * @returns partners list
   */
  function getPartnersByRole(role: PersonRoleEnum): Array<Partner> {
    return application?.partners?.filter((p) => p.roles.includes(role)) || [];
  }

  /**
   * Returns a single partner by a given role or throws an error if not found
   *
   * @param role identifier
   * @returns partner with a given role
   */
  function getSinglePartnerByRole(role: PersonRoleEnum): Partner {
    const partner = getPartnersByRole(role)[0];
    if (!partner) {
      throw new Error(`Partner with role ${role} does not exist!`);
    }
    return partner;
  }

  /**
   * Checks of the insurance holder & payment contributor is the same person
   *
   * @returns boolean if both IDs are the same
   */
  function isHolderAlsoPayer(): boolean {
    const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
    const payer = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR);
    return holder.id === payer.id;
  }

  /**
   * Checks if the same person is both: insured & insurance holder
   */
  function isHolderInsured(partner: Partner): boolean {
    return (
      partner.roles.includes(PersonRoleEnum.INSURED_PERSON) &&
      partner.roles.includes(PersonRoleEnum.POLICY_HOLDER)
    );
  }

  /**
   * Formats partner name to "{lastName}, {firstName}" format
   */
  function formatPartnerName(id: string): string {
    const partner = getPartnerById(id);

    const name = `${partner.lastname}, ${partner.firstname}`;

    return name;
  }

  return {
    getPartnerById,
    getPartnersByRole,
    getSinglePartnerByRole,
    isHolderAlsoPayer,
    isHolderInsured,
    formatPartnerName
  };
};
