import { useTranslation } from 'react-i18next';

import { Form, formModelGet, RadioOption } from 'lkh-portal-ui-library';
import { InsuranceCompanyOptions } from 'models';

import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

const { YesNo, Dropdown, Input } = Form.Components;

type PreInsuranceTypeFormProps = {
  isPublicKey: string;
  insuranceCompanyKey: string;
  privateInsuranceCompanyKey: string;
  onIsPublicChange?: (item: boolean) => void;
};

export const PreInsuranceTypeForm = ({
  insuranceCompanyKey,
  privateInsuranceCompanyKey,
  isPublicKey,
  onIsPublicChange
}: PreInsuranceTypeFormProps) => {
  const { t } = useTranslation('wizardGeneral');
  const {
    state: { model },
    reducer: { updateValue }
  } = useHealthInsuranceContext();
  const isPublic = formModelGet(model, isPublicKey);

  const resetDependantValues = () => {
    updateValue({
      key: insuranceCompanyKey,
      value: undefined
    });
    updateValue({
      key: privateInsuranceCompanyKey,
      value: undefined
    });
  };

  const handleIsPublicChange = (item: RadioOption) => {
    updateValue({
      key: isPublicKey,
      value: item.value
    });

    resetDependantValues();
    onIsPublicChange?.(Boolean(item.value));
  };

  return (
    <div className="grid-res-2 items-center mt-[24px]">
      <div>
        <YesNo
          componentKey={isPublicKey}
          onChange={handleIsPublicChange}
          truthyLabel={t('longTerm.preInsurance.fields.isPublic.truthy')}
          falsyLabel={t('longTerm.preInsurance.fields.isPublic.falsy')}
        />
      </div>
      <div>
        {isPublic === true && (
          <Input
            label={t('longTerm.preInsurance.fields.isPublic.publicInsurance.label')}
            componentKey={insuranceCompanyKey}
          />
        )}
        {isPublic === false && (
          <Dropdown
            label={t('longTerm.preInsurance.fields.isPublic.privateInsurance.label')}
            placeholder={t('longTerm.preInsurance.fields.isPublic.privateInsurance.placeholder')}
            componentKey={privateInsuranceCompanyKey}
            options={InsuranceCompanyOptions}
          />
        )}
      </div>
    </div>
  );
};
