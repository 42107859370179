import { useTranslation } from 'react-i18next';

import { Chip, Collapse, Typography } from '@mui/material';

import { DarkTooltip, Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { HealthQuestionDetail } from 'models/extension-generated';

import { useHealthQuestionDiagnosesErrors } from '../../DiagnosesForm/useDiagnosesValidation';
import { QuestionWrapperProps } from '../HealthQuestions';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';
import { getQuestionKey } from 'pages/PrivateHealthInsurance/context/QuestionsContext/questionsGetters';

const { YesNo } = Form.Components;

export function ClosedQuestion({
  healthQuestionId,
  questionWrapperProps,
  partnerId,
  onYesNoClick,
  onChipClick
}: {
  partnerId: string;
  healthQuestionId: string;
  questionWrapperProps?: QuestionWrapperProps;
  onYesNoClick?: (value: boolean) => void;
  onChipClick?: (healthQuestionId: string) => void;
}) {
  const { t } = useTranslation('wizardHealth');
  const { getQuestionText } = useQuestionsContext();
  const { state } = useHealthInsuranceContext();

  const detailsKey = partnerField(
    partnerId,
    `applicationInformation.health[${healthQuestionId}].details`
  );
  const value = formModelGet(state.model, getQuestionKey(partnerId, healthQuestionId));
  const details: HealthQuestionDetail[] = formModelGet(state.model, detailsKey) || [];
  const errors = useHealthQuestionDiagnosesErrors({ healthQuestionId: healthQuestionId });

  return (
    <>
      <Typography variant="bodyLGRegular" className="grid-res mb-[0px] s:mb-[16px]">
        {getQuestionText(healthQuestionId)}
      </Typography>
      <div className="grid-res-2 mt-[0px] flex flex-col justify-start">
        <YesNo
          error={errors.length > 0}
          componentKey={getQuestionKey(partnerId, healthQuestionId)}
          onChange={(item) => {
            onYesNoClick?.(item.value as boolean);
          }}
          disabled={questionWrapperProps?.isDisabled}
        />
        {details.length > 0 && (
          <Collapse in={value === true}>
            <div className="text-left">
              <Typography variant="bodySMBold" className="mb-[16px]">
                {t('section.diagnosis.detailsTitle')}
              </Typography>
              <div className="space-y-[8px] flex flex-col">
                {details.map(({ id, diagnosis }) => {
                  return (
                    <Chip
                      clickable
                      onClick={() => {
                        onChipClick?.(healthQuestionId);
                      }}
                      className="text-highlight-80 bg-surface-60 w-fit"
                      key={id}
                      label={
                        <DarkTooltip title={diagnosis}>
                          <Typography variant="bodyMDBold" className="truncate">
                            {diagnosis}
                          </Typography>
                        </DarkTooltip>
                      }
                    />
                  );
                })}
              </div>
            </div>
          </Collapse>
        )}
      </div>
    </>
  );
}
