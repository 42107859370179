import i18n from 'services/translation';

import { FilterModel } from '../../../types';

export const getAgentIdFilterOptions = (agentIds: Array<string>): Omit<FilterModel, 'selected'> => {
  const agentIdOptions = agentIds.map((id) => {
    return {
      value: id,
      label: id
    };
  });
  return {
    list: agentIdOptions,
    placeholder: i18n.t('filter.agentId', { ns: 'contract' })
  };
};
