import { useTranslation } from 'react-i18next';

import { PaymentContributorDetailProps } from 'models/view360models';

import { Accordion, AccordionDetails, AccordionSummary } from 'components/Accordion';
import { AccordionHeader } from 'components/AccordionHeader';
import { SimpleTable } from 'components/SimpleTable';
import { formatBoolean, optionalValue } from 'pages/Contract360Page/utils/formatters';
import { formatGermanDate } from 'utilities/dates';

export const PaymentContributorDetailSummary = (props: PaymentContributorDetailProps) => {
  const { t: tl } = useTranslation(['policy', 'contractsEnums']);
  const { t } = useTranslation('view360');

  return (
    <Accordion>
      <AccordionSummary
        aria-controls="payment-contributor-detail-summary"
        id="payment-contributor-detail-summary-header"
      >
        <AccordionHeader t={tl} />
      </AccordionSummary>
      <AccordionDetails>
        <div className="flex flex-col py-[24px] bg-surface-60 px-[16px] rounded-b-lg">
          <div className="flex space-x-[32px] mb-[32px] w-full">
            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.personalData')}
                rows={[
                  {
                    label: t(`person.attributes.salutation`),
                    value: optionalValue(props.salutation)
                  },
                  {
                    label: t(`person.attributes.academicTitle`),
                    value: optionalValue(props.academicTitle)
                  },
                  {
                    label: t(`person.attributes.firstName`),
                    value: optionalValue(props.firstName)
                  },
                  {
                    label: t(`person.attributes.lastName`),
                    value: optionalValue(props.lastName)
                  },
                  {
                    label: t(`person.attributes.birthDate`),
                    value: optionalValue(props.birthDate, formatGermanDate)
                  }
                ]}
              />
            </div>

            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.bankData')}
                rows={[
                  {
                    label: t(`person.attributes.holderIsAlsoPaymentContributor`),
                    value: formatBoolean(props.isPolicyHolder)
                  },
                  { label: t(`person.attributes.iban`), value: optionalValue(props.iban) },
                  {
                    label: t(`person.attributes.bankName`),
                    value: optionalValue(props.bankName)
                  },
                  { label: t(`person.attributes.bic`), value: optionalValue(props.bic) },
                  {
                    label: t(`person.attributes.hasPaymentAuthorization`),
                    value: optionalValue(props.hasPaymentAuthorization, formatBoolean)
                  }
                ]}
              />
            </div>
          </div>

          <div className="flex space-x-[32px] w-full">
            <div className="basis-1/2">
              <SimpleTable
                title={t('person.attributes.address')}
                rows={[
                  { label: t(`person.attributes.street`), value: optionalValue(props.street) },
                  {
                    label: t(`person.attributes.houseNumber`),
                    value: optionalValue(props.houseNumber)
                  },
                  {
                    label: t(`person.attributes.postalCode`),
                    value: optionalValue(props.postalCode)
                  },
                  { label: t(`person.attributes.city`), value: optionalValue(props.city) },
                  { label: t(`person.attributes.country`), value: optionalValue(props.country) },
                  {
                    label: t(`person.attributes.hasForeignResidence`),
                    value: optionalValue(props.hasForeignResidence, formatBoolean)
                  },
                  {
                    label: t(`person.attributes.foreignCountry`),
                    value: optionalValue(props.foreignCountry)
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
