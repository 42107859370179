import { useTranslation } from 'react-i18next';

import { PersonRoleEnum } from '../../../../models/extension-generated';
import { Form, PartnerForm } from 'lkh-portal-ui-library';

import { useApplicationHelpers } from '../../../../hooks/useApplicationHelpers/useApplicationHelpers';
import { WizardSlot } from '../../../../layout/wizard/slots';
import { InsuranceHolderPictogram } from '../../components/pictograms';
import { StepHeading } from '../../components/StepHeading';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useConfigContext } from 'contexts/ConfigContext';
import { getIbanInfo } from 'utilities/bankDetails';

export const InsuranceHolderStep = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state, dispatch } = useHealthInsuranceContext();
  const { getSinglePartnerByRole, isHolderInsured } = useApplicationHelpers(state.model);

  const holder = getSinglePartnerByRole(PersonRoleEnum.POLICY_HOLDER);
  const holderId = holder.id || '';
  const payerId = getSinglePartnerByRole(PersonRoleEnum.PAYMENT_CONTRIBUTOR).id || '';
  const isInsured = isHolderInsured(holder);

  return (
    <>
      <StepHeading icon={<InsuranceHolderPictogram />} title={t('insuranceHolder.title')} />
      <div className="layout-res mt-[-24px]">
        <WizardSlot.Main>
          <Form state={state} dispatch={dispatch}>
            <PartnerForm
              config={{
                useConfigContext: useConfigContext,
                useInsuranceContext: useHealthInsuranceContext
              }}
              partnerId={holderId}
              hasPersonalData={{ disabled: isInsured }}
              hasMaritalStatus
              hasEmployment={{ disabled: isInsured }}
              hasAddress={{ disabled: isInsured }}
              hasTaxNumber={{ disabled: isInsured }}
              hasForeignAddress={{ disabled: isInsured }}
              hasContact
              hasLKHNumber
              hasBonity={{ disabled: isInsured }}
            />

            <PartnerForm
              config={{
                useConfigContext: useConfigContext,
                useInsuranceContext: useHealthInsuranceContext
              }}
              getIbanInfo={getIbanInfo}
              partnerId={payerId}
              hasBankDetails
            />
          </Form>
        </WizardSlot.Main>
      </div>
    </>
  );
};
