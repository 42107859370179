/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InsuranceCompanyEnum {
    ALLIANZ_PRIVATE = 4034,
    ALTE_OLDENBURGER = 4142,
    ARAG = 4112,
    AXA = 4095,
    BARMENIA_A_G = 4042,
    BARMENIA_AG = 4147,
    BAYERISCHE_BEAMTENKRANKENKASSE = 4134,
    BBV = 4127,
    CENTRAL = 4004,
    CONCORDIA = 4118,
    CONTINENTALE = 4001,
    DEBEKA = 4028,
    DEUTSCHER_RING = 4013,
    DEVK = 4131,
    DKV = 4044,
    DUSSELDORFER = 4115,
    FREIE_ARZT_UND_MEDIZINKASSE = 4053,
    GOTHAER = 4119,
    GPV_PKV_VERBAND = 9999,
    HALLESCHE = 4043,
    HANSEMERKUR = 4144,
    HUK_COBURG = 4117,
    INTER = 4031,
    INTER_NEU = 4145,
    KRANKENUNTERSTUTZUNGSKASSE_HANNOVER = 4104,
    KVB = 9997,
    LKH = 4011,
    LIGA = 4051,
    LVM = 4109,
    MECKLENBURGISCHE = 4141,
    MUNCHENER = 4037,
    NURNBERGER = 4125,
    OTTONOVA = 4146,
    PAX = 4143,
    PBEAKK = 9996,
    PROVINZIAL = 4135,
    R_PLUS_V = 4116,
    SIGNAL = 4002,
    ST_MARTINUS = 4106,
    SUDDEUTSCHE = 4039,
    UNION = 4108,
    UNIVERSA = 4045,
    VICTORIA = 4105,
    WURTTEMBERGISCHE = 4139,
}
