import { ReactNode, useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';

import { PartnerProgress } from '../PartnerProgress';
import { wizardElement } from 'utilities/scroll';

type StepHeadingProps = {
  icon: ReactNode;
  title: string;
  showProgress?: boolean;
};

export const StepHeading = ({ title, showProgress }: StepHeadingProps) => {
  const accordionHeader = document.getElementById('sticky-accordion');
  const [offset, setOffset] = useState(accordionHeader?.offsetHeight);

  useEffect(() => {
    if (!accordionHeader) return;
    const resizeObserver = new ResizeObserver(() => {
      setOffset(accordionHeader.offsetHeight);
    });
    resizeObserver.observe(accordionHeader);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <>
      <div className="layout-res pt-[24px]">
        <div className="grid-mx-res">
          <Typography variant="headlineXLGRegular" className="text-navigation-80">
            {title}
          </Typography>
        </div>
      </div>
      {showProgress && (
        <div
          id={wizardElement.top}
          style={{
            top: `${offset}px`
          }}
          className="sticky m:top-0 z-[100] bg-white-100 "
        >
          <PartnerProgress />
        </div>
      )}
    </>
  );
};
