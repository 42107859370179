import { useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router';

import { useTranslation } from 'react-i18next';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ProgressBar } from 'lkh-portal-ui-library';

import { useScrollTop } from '../../hooks/useScrollTop';
import { WizardLayout } from '../../layout/wizard';
import { WizardSlot } from '../../layout/wizard/slots';
import { AddPartnerButton } from './components/AddPartnerButton';
import { AgentPickerButton } from './components/AgentPickerButton';
import { BackButton } from './components/BackButton';
import { ContinueButton } from './components/ContinueButton';
import { HomeButton } from './components/HomeButton/HomeButton';
import { Loading } from './components/Loading';
import { OrderOptionsMenu } from './components/OrderOptionsMenu';
import { MobileSidebarHeader, Sidebar } from './components/Sidebar';
import { SupportButton } from './components/SupportButton/SupportButton';
import { ValidationError } from './components/ValidationError/ValidationError';
import { WizardRoutesEnum } from './constants';
import {
  HealthInsuranceContextProvider,
  useHealthInsuranceContext
} from './context/HealthInsuranceContext';
import { QuestionsContextProvider } from './context/QuestionsContext';
import { useWizardNavigation, WizardNavigationContextProvider } from './hooks/useWizardNavigation';
import { addPartnerButtonPages, getNextButtonText, noNextButtonIconPages } from './utils/actions';
import { WizardScreen } from './WizardRouter';
import { LegalFooter } from 'components/LegalFooter';
import { wizardElement } from 'utilities/scroll';

const Content = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { currentScreen, currentSection, completeSections, goTo } = useWizardNavigation();
  const mainWizardSlotRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const { state, isLoading } = useHealthInsuranceContext();
  useScrollTop(mainWizardSlotRef, [currentScreen]);
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.up('md'));

  const progressSteps = useMemo(
    () => [
      { step: 1, label: t('navigation.steps.insuredPeople') },
      { step: 2, label: t('navigation.steps.holder') },
      { step: 3, label: t('navigation.steps.summary') }
    ],
    []
  );

  function handleProgressBarChange(index: number) {
    switch (index) {
      case 0:
        goTo(WizardRoutesEnum.HealthQuestions, state.model.id);
        return;
      case 1:
        goTo(WizardRoutesEnum.InsuranceHolder, state.model.id);
        return;
      default:
        goTo(WizardRoutesEnum.PrivateData, state.model.id);
    }
  }

  return (
    <>
      {pathname.includes(WizardRoutesEnum.Done) && <Outlet />}
      {!pathname.includes(WizardRoutesEnum.Done) && (
        <WizardLayout>
          <WizardSlot.TopNavigation>
            <ProgressBar
              stepList={progressSteps}
              completeList={completeSections}
              active={currentSection}
              leftContent={<HomeButton />}
              rightContent={
                <div className="flex items-center gap-[16px] ml-[12px]">
                  <SupportButton showText={mobileSize} />
                  <AgentPickerButton />
                  <OrderOptionsMenu saveDisabled={currentScreen === WizardRoutesEnum.PrivateData} />
                </div>
              }
              onSelectStep={handleProgressBarChange}
            />
          </WizardSlot.TopNavigation>
          {/* Desktop Sidebar */}
          <WizardSlot.RightPanel>
            <div>
              <Sidebar />
            </div>
          </WizardSlot.RightPanel>
          <WizardSlot.Main ref={mainWizardSlotRef}>
            {/* Mobile Sidebar */}
            {/* Added accordionWrapper */}
            <div
              id="sticky-accordion"
              className="flex flex-col sticky top-0 z-[100] w-full s:w-auto m:hidden"
            >
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="text-white" />}
                  className="bg-navigation-80"
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <MobileSidebarHeader />
                </AccordionSummary>
                <AccordionDetails className="bg-surface-60">
                  <div className="block max-w-full [@media(width>=992px)]:hidden">
                    <Sidebar />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="bg-white-100 min-h-screen">
              {isLoading ? <Loading /> : <WizardScreen currentScreen={currentScreen} />}
            </div>
            <div className="layout-res">
              <Typography variant="bodyMDRegular" className="grid-res text-text-80">
                {t('common:fieldRequired')}
              </Typography>
            </div>
            <div id={wizardElement.bottom} className="sticky bottom-0 z-[100]">
              <div className="layout-res">
                <div className="flex flex-col gap-[16px] s:flex-row s:justify-between s:flex-nowrap items-center bg-white-100 py-m mt-[16px] mb-[24px] space-y-6 s:space-y-0">
                  <div className="flex flex-nowrap items-center space-x-[16px]">
                    {WizardRoutesEnum.PrivateData !== currentScreen && (
                      <div>
                        <BackButton />
                      </div>
                    )}
                    {addPartnerButtonPages.includes(currentScreen) && (
                      <div className="whitespace-nowrap">
                        <AddPartnerButton />
                      </div>
                    )}
                  </div>

                  <div className="flex flex-nowrap items-center justify-items-end w-full space-x-[16px] min-w-0">
                    <div className="flex-grow min-w-0">
                      <ValidationError />
                    </div>

                    <div className="flex-shrink-0">
                      <ContinueButton
                        title={getNextButtonText(currentScreen)}
                        isOnLastPage={noNextButtonIconPages.includes(currentScreen)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <LegalFooter />
          </WizardSlot.Main>
        </WizardLayout>
      )}
    </>
  );
};

export function PrivateHealthInsurancePage() {
  return (
    <WizardNavigationContextProvider>
      <HealthInsuranceContextProvider>
        <QuestionsContextProvider>
          <Content />
        </QuestionsContextProvider>
      </HealthInsuranceContextProvider>
    </WizardNavigationContextProvider>
  );
}
