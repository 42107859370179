import { useTranslation } from 'react-i18next';

import { Tab, Tabs } from '@mui/material';

import { useDashboardPageContext } from 'pages/Dashboard/context';
import { TabType } from 'pages/Dashboard/context/types';

export const DashboardTabs = () => {
  const { t } = useTranslation('dashboard');
  const {
    state: { selectedTab },
    actions: { setActiveTab }
  } = useDashboardPageContext();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue as TabType);
  };

  return (
    <Tabs value={selectedTab} onChange={handleChange}>
      <Tab
        value={TabType.OpenApplications}
        label={t('tab.openApplications', { ns: 'dashboard' })}
        className="min-h-[20px]"
      />
      <Tab
        value={TabType.SubmittedApplications}
        label={t('tab.submittedApplications', { ns: 'dashboard' })}
        className="min-h-[20px]"
      />
    </Tabs>
  );
};
