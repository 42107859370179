import { PortalLayout } from 'layout/portal';

import { useState } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { WizardDialog } from '../../components/WizardDialog';
import { NewBornDialog } from './modals/NewbornDialog';
import { ProductCard } from './ProductCard';

import styles from './ProductsPage.module.scss';

export const ProductsPage = () => {
  const [isWizardDialogOpen, setIsWizardDialogOpen] = useState(false);
  const [isNewBornDialogOpen, setIsNewBornDialogOpen] = useState(false);

  const handleOpenNewBornDialog = () => setIsNewBornDialogOpen(true);
  const handleOpenWizardDialog = () => setIsWizardDialogOpen(true);

  const { t } = useTranslation(['productsPage', 'productCard']);

  return (
    <PortalLayout>
      <div className={styles.productsPage}>
        <NewBornDialog
          isNewBornDialogOpen={isNewBornDialogOpen}
          setIsNewBornDialogOpen={setIsNewBornDialogOpen}
        />
        <WizardDialog
          isWizardDialogOpen={isWizardDialogOpen}
          onDialogClose={() => setIsWizardDialogOpen(false)}
        />
        <div className={classNames(styles.mainContent, 'p-t-36')}>
          <ProductCard
            headerText={t('productCard:healthIns.header')}
            imageSource="/images/healthIns.jpg"
            onClick={handleOpenWizardDialog}
          />
          <ProductCard
            headerText={t('productCard:newBorns.header')}
            imageSource="/images/newBorn.jpg"
            onClick={handleOpenNewBornDialog}
          />
        </div>
      </div>
    </PortalLayout>
  );
};
