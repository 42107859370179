import { Trans, useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import { DownloadOfferOption } from '../../DownloadOfferDialog';

type DownloadStageProps = {
  downloadOfferOption: DownloadOfferOption;
  handleCancalationOfDownload: () => void;
};

export default function DownloadStage({
  downloadOfferOption,
  handleCancalationOfDownload
}: DownloadStageProps) {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: `downloadOfferDialog.downloadOptions.${downloadOfferOption}Download`
  });
  return (
    <DialogContent>
      <Typography component={'div'} variant="bodyMDBold" className="text-text-100 mb-s">
        {t('downloadingMsg.title')}
      </Typography>
      <Typography component={'div'} variant="bodyMDRegular" className="text-text-80">
        {t('downloadingMsg.info')}
      </Typography>
      <div className="flex justify-between gap-[16px] mt-[24px]">
        <CircularProgress color="inherit" size={24} className="self-center" />
        <Button size="large" onClick={handleCancalationOfDownload}>
          <Trans i18nKey="downloadOfferDialog.cancel" ns="wizardPrivateHealthInsurance" />
        </Button>
      </div>
    </DialogContent>
  );
}
