import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import { PolicyRevision } from 'models/portal-generated';

import { formatGermanDate } from 'utilities/dates';

type RevisionProps = {
  /**
   * Contract uinque ID
   */
  contractId?: string;

  /**
   * Current revision displayed
   */
  current: PolicyRevision;

  /**
   * All available contract revisions
   * (assumes it is not sorted yet)
   */
  revisions: Array<PolicyRevision>;
};

export const Revisions = ({ revisions, current, contractId }: RevisionProps) => {
  const sortedRevisions = [...revisions].sort((a, b) => (a?.revision < b?.revision ? 1 : -1));

  const { t } = useTranslation('view360');
  const navigate = useNavigate();

  const options = useMemo<
    Array<{ policyId: string; revision: number; validFrom: string; validTo: string | null }>
  >(() => {
    return addToDate(sortedRevisions);
  }, [revisions]);

  function handleChange(revision?: number) {
    handleClose();
    navigate(`/360/vertrag/${contractId}/${revision}`);
  }

  function addToDate(policyRevisions: PolicyRevision[]) {
    const newList = [];

    for (let i = 0; i < policyRevisions.length; i++) {
      const revision = policyRevisions[i];

      let toDate = null;
      if (i > 0) {
        toDate = new Date(revision.validFrom);
        toDate.setDate(toDate.getDate() - 1);
      }

      const newRevision = {
        policyId: revision.policyId,
        revision: revision.revision,
        validFrom: revision.validFrom,
        validTo: toDate ? toDate.toISOString().slice(0, 10) : null
      };

      newList.push(newRevision);
    }

    return newList;
  }

  const [revisionAnchor, setRevisionAnchor] = useState<null | HTMLElement>(null);
  const openRevision = Boolean(revisionAnchor);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setRevisionAnchor(event.currentTarget);
  };
  const handleClose = () => {
    setRevisionAnchor(null);
  };

  const isNewVersion =
    sortedRevisions[0].policyId === current?.policyId &&
    sortedRevisions[0].revision === current?.revision &&
    sortedRevisions[0].validFrom === current?.validFrom;
  return (
    <>
      <Button
        className="text-navigation-80 "
        variant="text"
        endIcon={
          <ExpandCircleDownOutlinedIcon
            className={classNames(
              'transition text-navigation-80 transform-gpu',
              openRevision ? 'rotate-180' : 'rotate-0'
            )}
          />
        }
        onClick={handleClick}
      >
        <Typography
          variant="headlineXLGRegular"
          className="flex justify-start space-x-[4px] text-navigation-80"
        >
          <span>{t('contract.attributes.from')}</span>
          <span>
            {current?.validFrom ? formatGermanDate(current?.validFrom) : ''}
            {isNewVersion ? ' ' + t('contract.attributes.newestVersion') : ''}
          </span>
        </Typography>
      </Button>

      <Menu
        anchorEl={revisionAnchor}
        open={openRevision}
        onClose={handleClose}
        disableScrollLock
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              width: isNewVersion ? '332px' : '274px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px #00000026)'
            }
          }
        }}
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.revision} onClick={() => handleChange(option.revision)}>
              <div className="mr-[16px]">
                {option.revision === current.revision ? (
                  <CheckOutlinedIcon className="text-primary-80" />
                ) : (
                  <div className="w-[24px] h-[24px]" />
                )}
              </div>
              <div className="flex flex-col">
                <Typography variant="bodyMDRegular" className="text-text-80">
                  {t('revision.from')} {formatGermanDate(option.validFrom)} [{option.revision}]
                </Typography>
                <Typography variant="bodySMRegular" className="text-text-60">
                  {option.validTo
                    ? t('revision.to') + ' ' + formatGermanDate(option.validTo)
                    : ' ' + t('revision.current')}
                </Typography>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
