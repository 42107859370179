import { ReactNode } from 'react';

import classNames from 'classnames';

import Typography from '@mui/material/Typography';

import { AlertIcon, Chip, ChipType, Icon, ValidationCheckIcon } from 'lkh-portal-ui-library';

import { useWizardProgessContext } from './WizardProgressContext';

import styles from './WizardProgress.module.scss';

export type WizardProgressStepProps = {
  id: string;
  text: string | ReactNode;
  icon?: ReactNode;
  hasError?: boolean;
};

export const Step = ({
  id,
  text,
  icon = <ValidationCheckIcon />,
  hasError
}: WizardProgressStepProps) => {
  const { active, completed, onClick } = useWizardProgessContext();
  const isActive = active === id;
  const isComplete = completed.includes(id);

  const handleClick = () => {
    onClick?.(id);
  };

  return (
    <Chip
      type={ChipType.Pill}
      className={classNames(styles.step, {
        [styles.defaultStep]: !isComplete && !isActive,
        [styles.completeStep]: isComplete || isActive,
        [styles.activeStep]: isActive,
        [styles.errorStep]: hasError
      })}
      onClick={handleClick}
    >
      <Icon icon={hasError ? <AlertIcon /> : icon} className={classNames(styles.icon, 'm-r-8')} />
      <Typography variant="bodySMBold">{text}</Typography>
    </Chip>
  );
};
