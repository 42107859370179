import classNames from 'classnames';

import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';

import { AlertIcon, Icon } from 'lkh-portal-ui-library';

import styles from './NoResult.module.scss';

type NoResultProps = {
  className?: string;
  loading?: boolean;
  warningMessage: string;
  warningDescription: string;
};

export const NoResult = ({
  className,
  loading,
  warningMessage,
  warningDescription
}: NoResultProps) => {
  if (loading)
    return (
      <div className={classNames(styles.noResult, className)}>
        <CircularProgress color="primary" size={16} />
      </div>
    );

  return (
    <div className={classNames(styles.noResult, className)}>
      <Icon
        color="lkh-color-primary-80"
        className={classNames(styles.warningIcon, 'm-b-16', 'text-warning-700')}
        icon={<AlertIcon />}
      />
      <Typography variant="bodyMDRegular" className="mb-[8px]">
        {warningMessage}
      </Typography>
      <Typography variant="bodySMRegular" className="text-neutral-800">
        {warningDescription}
      </Typography>
    </div>
  );
};
