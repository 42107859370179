import { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import { PolicyHolderDetailProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { formatBoolean, optionalValue } from '../../utils/formatters';
import { PersonAccodrionHeader } from '../PersonAccodrionHeader';
import { AccordionHandle, SectionAccordion } from '../SectionAccordion';
import { SimpleTable } from 'components/SimpleTable';

export const PolicyHolderDetail = forwardRef<AccordionHandle, PolicyHolderDetailProps>(
  (props, ref) => {
    const { t } = useTranslation('view360');

    return (
      <SectionAccordion
        header={<PersonAccodrionHeader name={props.name} role={t(`policyHolder.role`)} />}
        ref={ref}
      >
        <div className="row reset-container m-t-24 m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.personalData')}
              rows={[
                {
                  label: t(`person.attributes.salutation`),
                  value: optionalValue(props.salutation)
                },
                {
                  label: t(`person.attributes.academicTitle`),
                  value: optionalValue(props.academicTitle)
                },
                { label: t(`person.attributes.firstName`), value: optionalValue(props.firstName) },
                { label: t(`person.attributes.lastName`), value: optionalValue(props.lastName) },
                {
                  label: t(`person.attributes.birthDate`),
                  value: optionalValue(props.birthDate, formatGermanDate)
                },
                {
                  label: t(`person.attributes.maritalStatus`),
                  value: optionalValue(props.maritalStatus)
                }
              ]}
            />
          </div>

          <div className="col-6">
            <SimpleTable
              title={t('person.attributes.companyData')}
              rows={[
                {
                  label: t(`person.attributes.professionalPosition`),
                  value: optionalValue(props.professionalPosition)
                },
                {
                  label: t(`person.attributes.currentOccupation`),
                  value: optionalValue(props.currentOccupation)
                },
                { label: t(`person.attributes.employer`), value: optionalValue(props.employer) }
              ]}
            />
          </div>

          <div className="row m-t-32">
            <div className="col-6">
              <SimpleTable
                title={t('person.attributes.address')}
                rows={[
                  {
                    label: t(`person.attributes.isLivingInGermany`),
                    value: optionalValue(props.isLivingInGermany, formatBoolean)
                  },
                  { label: t(`person.attributes.street`), value: optionalValue(props.street) },
                  {
                    label: t(`person.attributes.houseNumber`),
                    value: optionalValue(props.houseNumber)
                  },
                  {
                    label: t(`person.attributes.postalCode`),
                    value: optionalValue(props.postalCode)
                  },
                  { label: t(`person.attributes.city`), value: optionalValue(props.city) },
                  { label: t(`person.attributes.country`), value: optionalValue(props.country) },
                  {
                    label: t(`person.attributes.hasForeignResidence`),
                    value: optionalValue(props.hasForeignResidence, formatBoolean)
                  },
                  {
                    label: t(`person.attributes.foreignCountry`),
                    value: optionalValue(props.foreignCountry)
                  }
                ]}
              />
            </div>

            <div className="col-6">
              <SimpleTable
                title={t('person.attributes.contactData')}
                rows={[
                  { label: t(`person.attributes.email`), value: optionalValue(props.email) },
                  { label: t(`person.attributes.phone`), value: optionalValue(props.phone) }
                ]}
              />
            </div>
          </div>

          <div className="row m-t-32">
            <div className="col-6">
              <SimpleTable
                title={t('person.attributes.legalRepresentationData')}
                rows={[
                  {
                    label: t(`person.attributes.hasLegalRepresentative`),
                    value: optionalValue(props.hasLegalRepresentative, formatBoolean)
                  },
                  {
                    label: t(`person.attributes.acceptsSoleCustody`),
                    value: optionalValue(props.acceptsSoleCustody, formatBoolean)
                  }
                ]}
              />
            </div>
            <div className="col-6">
              <SimpleTable
                title={t('person.attributes.marketingData')}
                rows={[
                  {
                    label: t(`person.attributes.acceptedMarketingStreams`),
                    value: optionalValue(props.acceptedMarketingStreams)
                  }
                ]}
              />
            </div>
          </div>
        </div>
      </SectionAccordion>
    );
  }
);
