import { WizardSlot } from 'layout/wizard/slots';

import { Form } from 'lkh-portal-ui-library';

import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { EmploymentData } from './EmploymentData';
import { TariffQuestions } from './TarriffQuestions';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { PrivateDataPictogram } from 'pages/PrivateHealthInsurance/components/pictograms';
import { StepHeading } from 'pages/PrivateHealthInsurance/components/StepHeading';

export const TariffDataStep = () => {
  const { state, dispatch, partnerId } = useHealthInsuranceContext();
  const { formatPartnerName } = useApplicationHelpers(state.model);
  const name = formatPartnerName(partnerId);

  return (
    <>
      <StepHeading icon={<PrivateDataPictogram />} title={name} showProgress />
      <div className="layout-res">
        <WizardSlot.Main>
          <Form state={state} dispatch={dispatch}>
            <EmploymentData />
            <TariffQuestions />
          </Form>
        </WizardSlot.Main>
      </div>
    </>
  );
};
