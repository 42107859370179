import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import {
  GmbhShareholderEnum,
  NeedSituationEnum,
  WorkingDoctorRelationshipEnum
} from 'models/extension-generated';

import { useProvidedTariffs } from '../../../../contexts/TariffsContext';

const { Input, YesNo, Dropdown, DatePicker } = Form.Components;

export const SickBenefitsTariffDetails = () => {
  const { section, partnerId, state } = useProvidedTariffs();
  const { t } = useTranslation('wizardTariffs', { keyPrefix: `sections.${section}` });

  const needSituation = formModelGet(
    state.model,
    partnerField(partnerId, 'applicationInformation.tariffInformation.needSituation')
  );
  const workingDoctorRelationship = formModelGet(
    state.model,
    partnerField(partnerId, 'applicationInformation.tariffInformation.workingDoctorRelationship')
  );

  const isSelfEmployed = workingDoctorRelationship === WorkingDoctorRelationshipEnum.SELF_EMPLOYED;
  const shouldDisplayQuestions =
    needSituation === NeedSituationEnum.SU_P ||
    (needSituation === NeedSituationEnum.HMU_P && isSelfEmployed);
  if (!shouldDisplayQuestions) {
    return <></>;
  }

  const hasSalaryDuringIllnessKey = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.hasSalaryDuringIllness'
  );
  const hasSalaryDuringIllness = formModelGet(state.model, hasSalaryDuringIllnessKey);

  return (
    <Form.Section>
      <div className="my-[24px] text-center s:text-left">
        <Typography variant="bodyLGBold">{t('independence.title')}</Typography>
      </div>
      <Typography variant="bodyLGRegular">
        {t('independence.selfEmployedSince.question')}
      </Typography>
      <div className="grid-res-2 ml-[0px] mt-[16px]">
        <div>
          <DatePicker
            componentKey={partnerField(
              partnerId,
              'applicationInformation.tariffInformation.selfEmployedSince'
            )}
          />
        </div>
      </div>
      <Typography variant="bodyLGRegular">{t('independence.companyType.question')}</Typography>
      <div className="grid-res-2 ml-[0px] mt-[16px]">
        <div>
          <Input
            componentKey={partnerField(
              partnerId,
              'applicationInformation.tariffInformation.companyType'
            )}
            label={t('independence.companyType.label')}
          />
        </div>
      </div>
      <Typography variant="bodyLGRegular">{t('independence.employeesCount.question')}</Typography>
      <div className="grid-res-2 ml-[0px] mt-[16px]">
        <div>
          <Input
            componentKey={partnerField(
              partnerId,
              'applicationInformation.tariffInformation.employeesCount'
            )}
            placeholder="0"
            type="number"
          />
        </div>
      </div>
      <div>
        <Typography variant="bodyLGRegular">
          {t('independence.gmbhShareholder.question')}
        </Typography>
      </div>
      <div className="grid-res-2 ml-[0px] mt-[16px]">
        <div>
          <Dropdown
            componentKey={partnerField(
              partnerId,
              'applicationInformation.tariffInformation.gmbhShareholder'
            )}
            options={[
              {
                value: GmbhShareholderEnum.NOT,
                label: t('independence.gmbhShareholder.options.no')
              },
              {
                value: GmbhShareholderEnum.YES_DOMINANT,
                label: t('independence.gmbhShareholder.options.yesDominant')
              },
              {
                value: GmbhShareholderEnum.YES_NO_DOMINANT,
                label: t('independence.gmbhShareholder.options.yesNonDominant')
              }
            ]}
          />
        </div>
      </div>
      <div className="grid-res-2 ml-[0px]">
        <div>
          <YesNo
            componentKey={hasSalaryDuringIllnessKey}
            question={t('independence.hasSalaryDuringIllness.question')}
          />
        </div>
      </div>
      {hasSalaryDuringIllness && (
        <div className="grid-res-2 ml-[0px]">
          <div>
            <Typography variant="bodyLGRegular" className="mb-[16px]">
              {t('independence.salaryDuringIllnessDuration.question')}
            </Typography>
            <Input
              type="number"
              componentKey={partnerField(
                partnerId,
                'applicationInformation.tariffInformation.salaryDuringIllnessDuration'
              )}
            />
          </div>
        </div>
      )}
    </Form.Section>
  );
};
