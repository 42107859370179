import { queryConfig } from 'config';

import { useQuery } from '@tanstack/react-query';

import { PartnerService } from 'models/portal-generated';

function useAgentData() {
  const { data, isLoading, isFetching, isError } = useQuery({
    queryKey: ['agentData'],
    gcTime: queryConfig.oneMinuteCache.gcTime,
    queryFn: () => PartnerService.getAgentById()
  });
  return {
    data,
    isLoading: isLoading || isFetching,
    isError
  };
}

export default useAgentData;
