import classNames from 'classnames';

import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';
import PercentOutlinedIcon from '@mui/icons-material/PercentOutlined';
import { styled } from '@mui/material/styles';
import MuiToggleButton, { toggleButtonClasses } from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { colors } from 'styles/colors';

import { calculatePercentage } from 'utilities/percentageCalc';
import { formatPriceAsTextWithoutCurrency } from 'utilities/string';

const StyledToggleButton = styled(MuiToggleButton)({
  [`&.${toggleButtonClasses.root}`]: {
    borderColor: colors.surface[40]
  },
  [`&:not(.${toggleButtonClasses.selected})`]: {
    backgroundColor: 'transparent',
    '& svg': {
      fill: colors.text[60]
    },
    '&:hover': {
      backgroundColor: colors.surface[60]
    }
  },
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: colors.text[80],
    '& svg': {
      fill: colors.white[100]
    },
    '&:hover': {
      backgroundColor: colors.text[60]
    }
  }
});

export enum FormattingStyle {
  PERCENT = 'percent',
  EURO = 'euro'
}

type AdditionalRiskCellProps = {
  tariffIndex: number;
  formattingStyle?: FormattingStyle;
  addedRiskAmount: number;
  price?: number;
  disableToggle?: boolean;
  hideFormattingToggle?: boolean;
  hideFormattedAddedRiskAmount?: boolean;
  isSummaryRow?: boolean;
  handleFormattingStyleChange: (
    tariffIndex: number,
    newFormattingStyle: FormattingStyle | null
  ) => void;
};

export const AdditionalRiskCell = ({
  tariffIndex,
  formattingStyle = FormattingStyle.EURO,
  addedRiskAmount,
  price,
  isSummaryRow = false,
  disableToggle = false,
  hideFormattingToggle = false,
  hideFormattedAddedRiskAmount = false,
  handleFormattingStyleChange
}: AdditionalRiskCellProps) => {
  const formatedAddedRisk = () => {
    if (!price && price !== 0) return '-,--';
    if (isSummaryRow) return `+${formatPriceAsTextWithoutCurrency(addedRiskAmount)}`;
    return formattingStyle === FormattingStyle.PERCENT
      ? formatPriceAsTextWithoutCurrency(calculatePercentage(addedRiskAmount, price))
      : formatPriceAsTextWithoutCurrency(addedRiskAmount);
  };

  if (hideFormattingToggle) {
    return (
      <div className="flex w-full h-full items-center align-middle justify-between gap-[44px]">
        <div className="flex-none w-[64px]" />
        {!hideFormattedAddedRiskAmount && (
          <Typography
            component="div"
            variant={isSummaryRow ? 'bodySMBold' : 'bodySMRegular'}
            className={classNames('flex-1 text-text-60', { 'text-text-80': isSummaryRow })}
          >
            {formatedAddedRisk()}
          </Typography>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full h-full items-center align-middle justify-between gap-[44px]">
      <ToggleButtonGroup
        className="flex-none w-[64px]"
        orientation="horizontal"
        size="small"
        disabled={disableToggle}
        onChange={(_e, newFormattingStyle) =>
          handleFormattingStyleChange(tariffIndex, newFormattingStyle as FormattingStyle)
        }
        value={formattingStyle}
        exclusive
        sx={{ maxWidth: 64, width: 64 }}
      >
        <StyledToggleButton value={FormattingStyle.PERCENT}>
          <PercentOutlinedIcon sx={{ width: 16, height: 16 }} />
        </StyledToggleButton>
        <StyledToggleButton value={FormattingStyle.EURO}>
          <EuroOutlinedIcon sx={{ width: 16, height: 16 }} />
        </StyledToggleButton>
      </ToggleButtonGroup>
      {!hideFormattedAddedRiskAmount && (
        <Typography
          component="div"
          variant={isSummaryRow ? 'bodySMBold' : 'bodySMRegular'}
          className={classNames('flex-1 text-text-60', { 'text-text-80': isSummaryRow })}
        >
          {formatedAddedRisk()}
        </Typography>
      )}
    </div>
  );
};
