import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { ContractReminderProps } from '../../../../models/view360models';
import { Icon, InformationCircleIcon } from 'lkh-portal-ui-library';

import { optionalValue } from '../../utils/formatters';
import { formatGermanDate } from 'utilities/dates';
import { formatPrice } from 'utilities/string';

export const ContractReminders = ({ reminders }: ContractReminderProps) => {
  const { t } = useTranslation('view360', { keyPrefix: 'reminder' });
  if (!reminders.length) {
    return <></>;
  }

  return (
    <div className="border-solid border-2 border-warning-60 rounded-[8px] px-[32px] py-[24px] my-[32px]">
      <div className="row">
        <div className="col-auto no-padding">
          <Icon icon={<InformationCircleIcon />} size="lg" className="text-warning-60" />
        </div>
        <div className="col">
          <div className="m-b-8">
            <Typography variant="bodySMBold">{t('title')}</Typography>
          </div>

          {reminders.map(({ date = '', levelText = '', amount }) => {
            return (
              <div className="row reset-container mb-[16px] last:mb-[0px]" key={date}>
                <div className="col-4 col-lg-3 col-xl-2">
                  <div className="mb-[4px]">
                    <Typography className="text-neutral-700">{t('attributes.date')}</Typography>
                  </div>
                  <div className="flex">
                    <Typography variant="bodyMDBold">
                      {optionalValue(date, formatGermanDate)}
                    </Typography>
                  </div>
                </div>
                <div className="col-4 col-lg-3 col-xl-2">
                  <div className="mb-[4px]">
                    <Typography className="text-neutral-700">{t('attributes.level')}</Typography>
                  </div>
                  <div>
                    <Typography variant="bodyMDBold">{optionalValue(levelText)}</Typography>
                  </div>
                </div>
                <div className="col-4 col-lg-3 col-xl-2">
                  <div className="mb-[4px]">
                    <Typography className="text-neutral-700">{t('attributes.amount')}</Typography>
                  </div>
                  <div className="flex">
                    <Typography variant="bodyMDBold" className="text-error-700">
                      {optionalValue(amount, formatPrice)}
                    </Typography>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
