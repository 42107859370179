import { ReactElement, useState } from 'react';

import classNames from 'classnames';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';

type ActionMenuProps = {
  isLight?: boolean;
  items: ReactElement;
  button?: ReactElement;
  onMenuClose?: () => void;
};

export function ActionMenu({
  isLight = false,
  items,
  button,
  onMenuClose = () => {
    /**/
  }
}: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openActionMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeActionMenu = () => {
    setAnchorEl(null);
    onMenuClose();
  };

  return (
    <>
      {button ? (
        <span onClick={openActionMenu}>{button}</span>
      ) : (
        <div
          role="button"
          className={classNames(
            'flex items-center justify-center w-[40px] h-[40px] rounded-[4px] cursor-pointer hover:bg-white-20 active:bg-white-40',
            { 'bg-white-40': open }
          )}
          onClick={openActionMenu}
        >
          <MoreVertIcon className={isLight ? 'text-white-100' : 'text-text-100'} />
        </div>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClick={() => {
          setTimeout(() => {
            setAnchorEl(null);
          }, 200);
        }}
        onClose={closeActionMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        disableScrollLock
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              borderRadius: '4px',
              mt: 1.5,
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: button ? 10 : 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0
              }
            }
          }
        }}
      >
        {items}
      </Menu>
    </>
  );
}
