import { useCallback, useState } from 'react';

export enum SortingOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}
export interface Sortable {
  field?: string;
  order?: SortingOrder;
}

export type UseSort = Sortable & {
  set: (field: string, order: SortingOrder) => void;
  reset: () => void;
};

export function useSort(defaultField?: string, defaultOrder?: SortingOrder): UseSort {
  const [field, setField] = useState<string | undefined>(defaultField);
  const [order, setOrder] = useState<SortingOrder | undefined>(defaultOrder);

  const set = useCallback((field: string, order: SortingOrder): void => {
    setField(field);
    setOrder(order);
  }, []);

  const reset = useCallback((): void => {
    setField(undefined);
    setOrder(undefined);
  }, []);

  return {
    field,
    order,
    set,
    reset
  };
}
