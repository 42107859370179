import { DependencyList, useEffect } from 'react';

import { GridApi } from 'ag-grid-community';

/** The useAgGridOverlay hook is a custom React hook designed to manage the display of overlays in an AG Grid component.
 *  It shows a “No Rows” overlay when data is being loaded or when there is no data to display.
 *  Once the data is loaded and available, it hides the overlay.
 *
 * @param gridApi - This is an instance of GridApi from ag-grid-community. It provides methods to control the grid, such as showing or hiding overlays.
 * @param loading - A boolean value indicating whether data is currently being loaded. If true, the overlay will be shown.
 * @param dataLength - A number representing the length of the data array.
 * @param deps - An optional array of dependencies (DependencyList). These are additional dependencies that the useEffect hook will watch for changes. By default, this is an empty array.
 */
export const useAgGridOverlay = (
  gridApi: GridApi | null,
  loading: boolean,
  dataLength: number,
  deps: DependencyList = []
) => {
  useEffect(() => {
    if (gridApi) {
      gridApi.showNoRowsOverlay();
      if (!loading && dataLength > 0) {
        gridApi.hideOverlay();
      }
    }
  }, [loading, gridApi, dataLength, ...deps]);
};
