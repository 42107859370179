import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { TariffSectionEnum } from 'models/extension-generated';

import { SupplementaryTariff } from '../../../components/SupplementaryTariff';
import { RequiresTariffsProvider, TariffsContext } from '../../../contexts/TariffsContext';

export const OptionalTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.OPTIONAL;
  const { t } = useTranslation('wizardTariffs');

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <div className="grid-res mb-[24px]">
        <Typography variant="bodyLGBold">{t(`sections.${SECTION}.question`)}</Typography>
      </div>

      <TariffsContext.Consumer>
        {(ctx) =>
          ctx &&
          ctx.sectionTariffs.map((tariff) => (
            <SupplementaryTariff key={tariff.id} tariff={tariff} />
          ))
        }
      </TariffsContext.Consumer>
    </RequiresTariffsProvider>
  );
};
