import { formatPriceAsText } from './string';

/**
 * Generates a price label based on the given parameters.
 *
 * @param hasPriceAdditions - Indicates if there are price additions.
 * @param hasPriceReductions - Indicates if there are price reductions.
 * @param monthlyRiskAmount - The monthly risk amount. If not provided, it defaults to 0.
 * @returns Returns a formatted price label if there are price additions or reductions. Otherwise, returns undefined.
 */
export const getPriceLabel = (
  hasPriceAdditions: boolean,
  hasPriceReductions: boolean,
  monthlyRiskAmount: number | undefined
) => {
  if (hasPriceAdditions || hasPriceReductions) {
    const absoluteMonthlyRiskAmount = Math.abs(monthlyRiskAmount || 0);
    const isNegative = (monthlyRiskAmount || 0) < 0;

    const sign = isNegative ? '-' : '+';
    const formattedPrice = formatPriceAsText(absoluteMonthlyRiskAmount);

    return `${sign} ${formattedPrice}`;
  }
  return undefined;
};
