export const PrivateDataPictogram = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M31.3599 31.0006H30.6399C30.6399 24.5686 25.8629 18.7686 19.2809 17.2086C19.1309 17.1726 19.0199 17.0456 19.0059 16.8916C18.9909 16.7386 19.0769 16.5926 19.2179 16.5296C22.0789 15.2566 23.9279 12.4136 23.9279 9.28862C23.9279 4.91762 20.3719 1.36162 16.0009 1.36162C11.6289 1.36162 8.07289 4.91762 8.07289 9.28862C8.07289 12.4136 9.92189 15.2566 12.7839 16.5296C12.9249 16.5926 13.0099 16.7386 12.9959 16.8916C12.9819 17.0456 12.8709 17.1726 12.7209 17.2086C6.13689 18.7686 1.35989 24.5686 1.35989 31.0006H0.639893C0.639893 24.5406 5.21389 18.6886 11.6419 16.7526C9.00789 15.2136 7.35089 12.3776 7.35089 9.28762C7.35089 4.51962 11.2299 0.640625 15.9989 0.640625C20.7669 0.640625 24.6459 4.51962 24.6459 9.28762C24.6459 12.3776 22.9899 15.2136 20.3559 16.7526C26.7859 18.6886 31.3599 24.5406 31.3599 31.0006ZM15.9999 30.3606C15.9079 30.3606 15.8159 30.3256 15.7459 30.2556L11.7839 26.2936C11.2719 25.7816 10.9889 25.1026 10.9859 24.3786C10.9829 23.6476 11.2659 22.9586 11.7829 22.4406C12.2989 21.9256 12.9809 21.6426 13.7039 21.6406C13.7049 21.6406 13.7069 21.6406 13.7079 21.6406C14.4339 21.6406 15.1179 21.9246 15.6339 22.4406L15.9999 22.8056L16.3649 22.4406C17.3979 21.4096 19.1869 21.4106 20.2169 22.4406C20.7439 22.9676 21.0279 23.6696 21.0149 24.4166C21.0009 25.1266 20.7179 25.7926 20.2169 26.2946L16.2549 30.2566C16.1839 30.3256 16.0919 30.3606 15.9999 30.3606ZM13.7089 22.3606C13.7079 22.3606 13.7069 22.3606 13.7059 22.3606C13.1739 22.3616 12.6719 22.5706 12.2929 22.9496C11.9119 23.3306 11.7039 23.8376 11.7059 24.3756C11.7079 24.9076 11.9169 25.4076 12.2929 25.7836L15.9999 29.4916L19.7069 25.7836C20.0759 25.4146 20.2849 24.9246 20.2939 24.4026C20.3039 23.8536 20.0959 23.3376 19.7069 22.9496C18.9479 22.1896 17.6359 22.1886 16.8739 22.9496L16.2549 23.5686C16.1139 23.7096 15.8869 23.7096 15.7459 23.5686L15.1269 22.9496C14.7459 22.5696 14.2429 22.3606 13.7089 22.3606Z" />
    </svg>
  );
};
