/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum PreInsuranceTypeEnum {
    HEALTH = 'HEALTH',
    CARE = 'CARE',
    SICK_PAY = 'SICK_PAY',
    SUPPLEMENTARY = 'SUPPLEMENTARY',
}
