export const InsuranceHolderPictogram = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.3599 31.0006H27.6399V25.7636C27.6399 20.3446 23.9609 15.6876 18.6939 14.4396C18.5439 14.4036 18.4329 14.2766 18.4189 14.1236C18.4039 13.9696 18.4899 13.8236 18.6309 13.7606C20.9709 12.7196 22.4819 10.3956 22.4819 7.84163C22.4819 4.26863 19.5749 1.36162 16.0019 1.36162C12.4279 1.36162 9.52089 4.26863 9.52089 7.84163C9.52089 10.3966 11.0329 12.7196 13.3719 13.7606C13.5129 13.8236 13.5979 13.9696 13.5839 14.1236C13.5699 14.2766 13.4589 14.4046 13.3089 14.4396C8.03889 15.6876 4.35989 20.3446 4.35989 25.7636V31.0006H3.63989V25.7636C3.63989 20.3196 7.13689 15.5996 12.2509 13.9846C10.1279 12.6886 8.79889 10.3666 8.79889 7.84063C8.79889 3.87063 12.0289 0.640625 15.9999 0.640625C19.9709 0.640625 23.2009 3.87063 23.2009 7.84063C23.2009 10.3656 21.8719 12.6876 19.7489 13.9846C24.8629 15.5996 28.3599 20.3186 28.3599 25.7636V31.0006ZM20.2549 23.2556L19.7449 22.7456L14.9999 27.4916L13.2539 25.7456L12.7449 26.2556L14.9999 28.5096L20.2549 23.2556Z"
        fill="#626766"
      />
    </svg>
  );
};
