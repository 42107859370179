import { useTranslation } from 'react-i18next';

import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { TariffSectionEnum } from 'models/extension-generated';

import { SectionTitle } from '../../../components/SectionTitle';
import { TariffRadioGroup } from '../../../components/TariffRadioGroup';
import { RequiresTariffsProvider } from '../../../contexts/TariffsContext';

export const AmbulantTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.AMBULANT;
  const { t } = useTranslation('wizardTariffs');

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <TariffRadioGroup
        title={<SectionTitle title={t(`sections.${SECTION}.question`)} />}
        tariffLabel={t('utils.deductible')}
      />
    </RequiresTariffsProvider>
  );
};
