import { useState } from 'react';

import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import CircularProgress from '@mui/material/CircularProgress';

import { PolicyService } from 'models/portal-generated';

import { downloadData, downloadForm } from 'utilities/fetchDocument';

type DownloadFormArgs = Parameters<typeof downloadForm>[0];
type DownloadDocumentForPolicyArgs = Parameters<typeof PolicyService.downloadDocumentForPolicy>[0];

type DownloadFormFunction = (args: DownloadFormArgs) => Promise<AxiosResponse<string>>;
type DownloadDocumentForPolicyFunction = (
  args: DownloadDocumentForPolicyArgs
) => Promise<AxiosResponse<string>>;

type CallbackFnType =
  | { type: 'DownloadFormFunction'; func: DownloadFormFunction }
  | { type: 'DownloadDocumentForPolicyFunction'; func: DownloadDocumentForPolicyFunction };

export type DownloadIconGroupProps = {
  documentInfo: readonly [docId: string, docName: string, policyId?: string];
  downloadCallback: CallbackFnType;
};

export const DownloadIconGroup = ({ documentInfo, downloadCallback }: DownloadIconGroupProps) => {
  const { t } = useTranslation();
  const [docId, docName, policyId] = documentInfo;
  const [downLoading, setDownloadLoading] = useState(false);

  function isDownloadFormFunction(
    fn: CallbackFnType
  ): fn is Extract<CallbackFnType, { type: 'DownloadFormFunction' }> {
    return fn.type === 'DownloadFormFunction';
  }

  const handleDownload = async () => {
    setDownloadLoading(true);
    try {
      let response;
      if (isDownloadFormFunction(downloadCallback)) {
        response = await downloadCallback.func({ id: docId });
      } else {
        response = await downloadCallback.func({ policyId: Number(policyId), docId: docId });
      }

      downloadData(response.data, `${docName}.pdf`);
    } catch (err) {
      const errorMsg = t('common:toastMessages.somethingWentWrong');
      toast.error(errorMsg);
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleOpen = async () => {
    try {
      let response;
      if (isDownloadFormFunction(downloadCallback)) {
        response = await downloadCallback.func({ id: docId });
      } else {
        response = await downloadCallback.func({ policyId: Number(policyId), docId: docId });
      }

      const file = new Blob([response.data], {
        type: 'application/pdf'
      });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank', 'noreferrer');
    } catch (error) {
      const errorMsg = t('common:toastMessages.somethingWentWrong');
      toast.error(errorMsg);
    }
  };

  return (
    <div className="flex items-center justify-center gap-[12px]">
      <LaunchIcon onClick={handleOpen} className="cursor-pointer" />
      {!downLoading ? (
        <FileDownloadOutlinedIcon
          onClick={handleDownload}
          className="cursor-pointer"
          data-testid="download-button"
        />
      ) : (
        <CircularProgress className="text-text-80" size={24} />
      )}
    </div>
  );
};
