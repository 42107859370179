import { SecondLevelTabsProps } from './types';

export const SELECT_ALL = 'SELECT_ALL';

export enum FilterGroupsEnum {
  Status = 'Status',
  PolicyHolderBirthDate = 'PolicyHolderBirthDate',
  InsuredPartnersFirstName = 'InsuredPartnersFirstName',
  InsuredPartnersName = 'InsuredPartnersName',
  OrganizationUnit = 'OrganizationUnit',
  InsuredPartnersBirthDate = 'InsuredPartnersBirthDate',
  InsuredPartnersPostalCode = 'InsuredPartnersPostalCode',
  InsuredPartnersCity = 'InsuredPartnersCity',
  InsuredPartnersStreet = 'InsuredPartnersStreet',
  InsuredPartnersHouseNumber = 'InsuredPartnersHouseNumber',
  InsuredPartnersJobType = 'InsuredPartnersJobType',
  MainContactStreet = 'MainContactStreet',
  MainContactPostalCode = 'MainContactPostalCode',
  MainContactCity = 'MainContactCity',
  MainContactHouseNumber = 'MainContactHouseNumber',
  PolicyHolderFirstName = 'PolicyHolderFirstName',
  PolicyHolderName = 'PolicyHolderName',
  EditReason = 'EditReason',
  AgentId = 'AgentId',
  InsuredPartnersFederalState = 'InsuredPartnersFederalState',
  Tariff = 'Tariff',
  NotTariff = 'NotTariff',
  Id = 'Id',
  MatchCode = 'Matchcode',
  ValidFrom = 'ValidFrom',
  MainContactValidFrom = 'MainContactValidFrom',
  InsuredPartnersValidFrom = 'InsuredPartnersValidFrom',
  InsuredPartnersMatchCode = 'InsuredPartnersMatchCode',
  InkassoType = 'InkassoType',
  Gender = 'Gender',
  Documented = 'Documented',
  DiscountIdentifier = 'DiscountIdentifier',
  BreContributionRefunds = 'BreContributionRefunds',
  FullyInsured = 'FullyInsured',
  WorkActivity = 'WorkActivity',
  TariffGroupId = 'TariffGroupId',
  NotTariffGroupId = 'NotTariffGroupId',
  RiskPremium = 'RiskPremium',
  Rejected = 'Rejected',
  TariffListView = 'TariffListView',
  InsuredPartners = 'InsuredPartners',
  EditDate = 'EditDate'
}
export const FilterGroupsKeys = {
  [FilterGroupsEnum.Status]: 'status',
  [FilterGroupsEnum.PolicyHolderBirthDate]: 'policyHolder.birthDate',
  [FilterGroupsEnum.InsuredPartnersFirstName]: 'insuredPartners.partner.firstname',
  [FilterGroupsEnum.InsuredPartnersName]: 'insuredPartners.partner.lastname',
  [FilterGroupsEnum.MatchCode]: 'matchCode',
  [FilterGroupsEnum.OrganizationUnit]: 'organizationUnit',
  [FilterGroupsEnum.InsuredPartnersFederalState]: 'insuredPartners.federalState',
  [FilterGroupsEnum.InsuredPartnersBirthDate]: 'insuredPartners.partner.birthDate',
  [FilterGroupsEnum.InsuredPartnersPostalCode]: 'insuredPartners.partner.contactList.postalCode',
  [FilterGroupsEnum.InsuredPartnersCity]: 'insuredPartners.partner.contactList.city',
  [FilterGroupsEnum.InsuredPartnersStreet]: 'insuredPartners.partner.contactList.street',
  [FilterGroupsEnum.InsuredPartnersHouseNumber]: 'insuredPartners.partner.contactList.houseNumber',
  [FilterGroupsEnum.InsuredPartnersJobType]: 'insuredPartners.partner.jobType',
  [FilterGroupsEnum.MainContactStreet]: 'mainContact.street',
  [FilterGroupsEnum.MainContactPostalCode]: 'mainContact.postalCode',
  [FilterGroupsEnum.MainContactCity]: 'mainContact.city',
  [FilterGroupsEnum.MainContactHouseNumber]: 'mainContact.houseNumber',
  [FilterGroupsEnum.Id]: 'id',
  [FilterGroupsEnum.PolicyHolderFirstName]: 'policyHolder.firstname',
  [FilterGroupsEnum.EditReason]: 'editReason',
  [FilterGroupsEnum.AgentId]: 'agentId',
  [FilterGroupsEnum.Tariff]: 'insuredPartners.tariffId',
  [FilterGroupsEnum.NotTariff]: 'insuredPartners.tariffId',
  [FilterGroupsEnum.PolicyHolderName]: 'policyHolder.lastname',
  [FilterGroupsEnum.ValidFrom]: 'validFrom',
  [FilterGroupsEnum.MainContactValidFrom]: 'mainContact.validFrom',
  [FilterGroupsEnum.InsuredPartnersValidFrom]: 'insuredPartners.partner.contactList.validFrom',
  [FilterGroupsEnum.InsuredPartnersMatchCode]: 'insuredPartners.partner.matchCode',
  [FilterGroupsEnum.InkassoType]: 'kontoList.inkassoTypeText',
  [FilterGroupsEnum.Gender]: 'insuredPartners.partner.gender',
  [FilterGroupsEnum.Documented]: 'documented',
  [FilterGroupsEnum.DiscountIdentifier]: 'insuredPartners.discounts.discountIdentifier',
  [FilterGroupsEnum.BreContributionRefunds]: 'breContributionRefunds.breYear',
  [FilterGroupsEnum.FullyInsured]: 'insuredPartners.fullyInsured',
  [FilterGroupsEnum.WorkActivity]: 'insuredPartners.partner.workActivity',
  [FilterGroupsEnum.TariffGroupId]: 'insuredPartners.tariffGroupId',
  [FilterGroupsEnum.NotTariffGroupId]: 'insuredPartners.tariffGroupId',
  [FilterGroupsEnum.RiskPremium]: 'insuredPartners.discounts.subtype',
  [FilterGroupsEnum.Rejected]: '',
  [FilterGroupsEnum.TariffListView]: 'tariffView.tariffList',
  [FilterGroupsEnum.InsuredPartners]: 'insuredPartners',
  [FilterGroupsEnum.EditDate]: 'editDate'
};

export const TabMapping: Record<string | number, SecondLevelTabsProps> = {
  [SecondLevelTabsProps.Contract]: SecondLevelTabsProps.Contract,
  [SecondLevelTabsProps.InsuredPerson]: SecondLevelTabsProps.InsuredPerson,
  [SecondLevelTabsProps.SpecialSearch]: SecondLevelTabsProps.SpecialSearch
};

export enum TabType {
  Contracts = 'contracts',
  Reminders = 'reminders'
}
