/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FachStatusEnum {
    ACTIVE = 0,
    ANWARTSCHAFT = 1,
    BEITRAGS_UND_LEISTUNGSFREI = 2,
    BEGINNVERLEGT = 7,
    WIEDERINKRAFTGESETZT = 8,
    STORNIERT = 9,
    IN_ARBEIT = 10,
    BEGINNVERLEGT_ANWARTSCHAFT = 71,
    BEGINNVERLEGT_BEITRAGSFREI = 72,
}
