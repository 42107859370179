import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Button, { ButtonClasses } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { DownloadOfferButton } from './components/DownloadOfferButton/DownloadOfferButton';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import {
  downloadOfferPages,
  downloadProtocolPages
} from 'pages/PrivateHealthInsurance/utils/actions';
import { downloadData, downloadFormByReferenceId } from 'utilities/fetchDocument';

type DownloadButtonProps = {
  currentPage: WizardRoutesEnum;
};

export const buttonClasses: Partial<ButtonClasses> = {
  colorPrimary: 'text-white-100 m:text-primary-80 border-white-100 m:border-primary-80',
  disabled: 'text-white-100 m:text-primary-80'
};

export const DownloadButton = ({ currentPage }: DownloadButtonProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state } = useHealthInsuranceContext();
  const [isDownloadingProtocol, setIsDownloadingProtocol] = useState(false);

  const downloadProtocol = async () => {
    setIsDownloadingProtocol(true);
    try {
      const response = await downloadFormByReferenceId({
        id: 'BP_01',
        date: state.model.applicationStart || ''
      });
      downloadData(response.data, `${t('actions.downloadProt.fileName')}.pdf`);
    } finally {
      setIsDownloadingProtocol(false);
    }
  };

  return (
    <>
      {downloadProtocolPages.includes(currentPage) && (
        <Button
          className="mt-[0px] m:mt-[16px]"
          variant="outlined"
          classes={buttonClasses}
          size="small"
          disabled={isDownloadingProtocol}
          startIcon={
            isDownloadingProtocol ? (
              <CircularProgress color="inherit" size={16} />
            ) : (
              <FileDownloadOutlinedIcon />
            )
          }
          onClick={downloadProtocol}
        >
          {t('actions.downloadProt.label')}
        </Button>
      )}
      {downloadOfferPages.includes(currentPage) && <DownloadOfferButton />}
    </>
  );
};
