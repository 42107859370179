import { WizardSlot } from 'layout/wizard/slots';

import { useMemo } from 'react';

import { CellValueChangedEvent } from 'ag-grid-community';
import sortBy from 'lodash/sortBy';
import { useTranslation } from 'react-i18next';

import { Form, formModelGet } from 'lkh-portal-ui-library';
import { applicationField, partnerField } from 'models';
import { Tariff } from 'models/extension-generated';

import { getHolderFormat, ParsedPartner, parsePartner } from '../Summary/utils';
import { PersonDetailSummary } from './components/PersonDetails/PersonDetailSummary';
import { PriceSummary } from './components/PriceSummary';
import { Summary } from './components/Summary';
import { Tariffs } from './components/Tariffs';
import { ColumnIds, TariffRow } from 'components/OfferTariffTable';
import { useConfigContext } from 'contexts/ConfigContext';
import { useApplicationCalculate } from 'hooks/useApplicationCalculate';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { PrivateDataPictogram } from 'pages/PrivateHealthInsurance/components/pictograms';
import { StepHeading } from 'pages/PrivateHealthInsurance/components/StepHeading';
import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';
import { useExtendedReducer } from 'pages/PrivateHealthInsurance/hooks/useExtendedReducer';

export const Offer = () => {
  const { config } = useConfigContext();

  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state, partnerId, dispatch, reducer } = useHealthInsuranceContext();
  const { questions } = useQuestionsContext();
  const { isLoading, data } = useApplicationCalculate(state.model, questions, false);
  const { validatePages } = useApplicationValidation(state, reducer, partnerId);
  const { updateTariff } = useExtendedReducer(dispatch);
  const { formatPartnerName, getPartnerById } = useApplicationHelpers(state.model);
  const calculation = data?.partners.find((p) => partnerId === p.partnerId);
  const tariffsPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.selectedTariffs'
  );

  const selectedTariffs: Array<Tariff> = sortBy(
    formModelGet(state.model, tariffsPath) || [],
    'sequenceOfOrder'
  );
  const addedRiskAmountTotal = selectedTariffs.reduce((prev, next) => {
    const addedRiskAmount = next.addedRiskAmount || 0;
    return prev + addedRiskAmount;
  }, 0);
  const partner = getPartnerById(partnerId);
  const name = formatPartnerName(partnerId);
  const needSituation = partner.applicationInformation?.tariffInformation?.needSituation;
  const insuranceStartKey = applicationField('applicationStart');
  const insuranceStart: string = formModelGet(state.model, insuranceStartKey);

  const parsedPartner: ParsedPartner = parsePartner(getPartnerById(partnerId));

  const handleTariffEdit = (event: CellValueChangedEvent<TariffRow>) => {
    const editedColumn = event.column.getColId();
    validatePages({
      pages: [WizardRoutesEnum.Offer],
      completeValidation: false,
      currentPartnerId: partnerId
    });

    if (editedColumn === ColumnIds.RiskPremium) {
      return updateTariff({
        partnerId,
        value: event.data.addedRiskAmount,
        key: 'addedRiskAmount',
        tariffId: event.node.data?.id || ''
      });
    } else {
      return;
    }
  };
  const _partner = useMemo(() => {
    return getHolderFormat(parsedPartner, config);
  }, [parsedPartner, config.countries]);

  return (
    <>
      <StepHeading icon={<PrivateDataPictogram />} title={name} showProgress />
      <div className="layout-res">
        <WizardSlot.Main>
          <Form.Section title={t('insuredPeople.steps.offer.title')}>
            <Form state={state} dispatch={dispatch}>
              <div className="grid-mx-res">
                <Summary partner={partner} applicationStart={insuranceStart} />
                <PersonDetailSummary {..._partner} />
              </div>
              <Tariffs
                tariffs={selectedTariffs}
                onTariffEdit={handleTariffEdit}
                partnerId={partnerId}
              />
              <div className="grid-res my-[0px]">
                <PriceSummary
                  isLoading={isLoading}
                  calculation={calculation}
                  needSituation={needSituation}
                  applicationStart={insuranceStart}
                  addedRiskAmountTotal={addedRiskAmountTotal}
                />
              </div>
            </Form>
          </Form.Section>
        </WizardSlot.Main>
      </div>
    </>
  );
};
