import cloneDeep from 'lodash/cloneDeep';

import { ApplicationStatusEnum } from 'models/extension-generated';

import {
  deleteApplication,
  resetFilters,
  setApplications,
  setDateFilter,
  setFirstName,
  setId,
  setLastName,
  setSelectedTab,
  setStatus
} from './actions';
import { setAgent } from './actions/SetAgent';
import { sortApplications } from './actions/sorting';
import {
  ApplicationFilterAction,
  ApplicationFilterActions,
  ApplicationsState,
  TabType
} from './types';

export const ApplicationGroups = {
  OpenApplications: [
    ApplicationStatusEnum.OFFER_CREATED,
    ApplicationStatusEnum.OFFER_REFUSED,
    ApplicationStatusEnum.OFFER_PUBLISHED,
    ApplicationStatusEnum.OFFER_INVALID,
    ApplicationStatusEnum.APPLICATION_PUBLISHED
  ],
  SubmittedApplications: [
    ApplicationStatusEnum.APPLICATION_CREATED,
    ApplicationStatusEnum.APPLICATION_RELEASED,
    ApplicationStatusEnum.APPLICATION_REFUSED,
    ApplicationStatusEnum.APPLICATION_TRANSMITTED,
    ApplicationStatusEnum.IN_RESEARCH,
    ApplicationStatusEnum.FOUR_EYES_TEST,
    ApplicationStatusEnum.APPLICATION_POLICED,
    ApplicationStatusEnum.APPLICATION_NOT_POLICED
  ]
};

export const applicationFiltersReducer = (
  applicationsState: ApplicationsState,
  action: ApplicationFilterAction
): ApplicationsState => {
  const state = cloneDeep(
    applicationsState || {
      filters: {},
      selectedTab: TabType.OpenApplications,
      applications: []
    }
  );

  switch (action.type) {
    case ApplicationFilterActions.SET_DATE_RANGE: {
      return setDateFilter(state, action.payload);
    }
    case ApplicationFilterActions.SET_STATUS: {
      return setStatus(state, action.payload);
    }
    case ApplicationFilterActions.RESET_FILTER: {
      return resetFilters(state);
    }
    case ApplicationFilterActions.SET_AGENT_ID: {
      return setAgent(state, action.payload);
    }
    case ApplicationFilterActions.SET_ACTIVE_TAB: {
      return setSelectedTab(state, action.payload);
    }
    case ApplicationFilterActions.SET_APPLICATIONS: {
      return setApplications(state, action.payload);
    }
    case ApplicationFilterActions.SET_SORT_BY: {
      return sortApplications(state, action.payload);
    }
    case ApplicationFilterActions.DELETE_APPLICATION: {
      return deleteApplication(state, action.payload);
    }
    case ApplicationFilterActions.SET_FIRSTNAME: {
      return setFirstName(state, action.payload);
    }
    case ApplicationFilterActions.SET_LASTNAME: {
      return setLastName(state, action.payload);
    }
    case ApplicationFilterActions.SET_ID: {
      return setId(state, action.payload);
    }

    default: {
      return state;
    }
  }
};
