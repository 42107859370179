import { PropsWithChildren } from 'react';

import { WizardProgressContextProvider, WizardProgressContextType } from './WizardProgressContext';

import styles from './WizardProgress.module.scss';

export type WizardProgressProps = PropsWithChildren & WizardProgressContextType;

export const Container = ({ children, active, completed, onClick }: WizardProgressProps) => {
  return (
    <WizardProgressContextProvider active={active} completed={completed} onClick={onClick}>
      <div className={styles.wizardProgress}>{children}</div>
    </WizardProgressContextProvider>
  );
};
