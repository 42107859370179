import { createRef, MutableRefObject, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import classNames from 'classnames';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { AccordionHandle } from '../SectionAccordion';

export enum View360NavigationEnum {
  Overview = 'overview',
  Documents = 'documents',
  PolicyHolder = 'policy-holder',
  PaymentContributor = 'payment-contributor',
  InsuredPerson = 'insured-person',
  Account = 'account',
  AdditionalContractInfo = 'additional-contract-info',
  Agent = 'agent',
  Organization = 'organization'
}

type View360NavigationItem = {
  label: string | ReactNode;
  key: View360NavigationEnum | string;
  ref?: MutableRefObject<AccordionHandle | null>;
};

export type View360Navigation = {
  items: Array<View360NavigationItem>;
  isContract: boolean;
};

export const DocumentNavigation = ({ isContract, items }: View360Navigation) => {
  const [activeSection, setActiveSection] = useState<string | null>(items[0]?.key);
  const navigate = useNavigate();
  const { t } = useTranslation('view360');

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  function handleScroll() {
    function getVisiblePercentage(rect: DOMRect): number {
      const stickyHeader = document.getElementsByClassName('sticky');
      const windowHeight = window.innerHeight;
      const maxVisibleHeight = stickyHeader
        ? Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0 + stickyHeader[0].clientHeight)
        : Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0);
      return (maxVisibleHeight / rect.height) * 100;
    }

    const sections = document.querySelectorAll('section');

    let mostVisibleSectionId: string | null = null;
    let maxVisiblePercentage = 0;

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      const visiblePercentage = getVisiblePercentage(rect);

      if (visiblePercentage > maxVisiblePercentage) {
        maxVisiblePercentage = visiblePercentage;
        mostVisibleSectionId = section.id;
      }
    });

    if (sections instanceof NodeList && sections.length > 0) {
      if (window.scrollY <= 10) {
        setActiveSection(sections[0].id);
      } else if (typeof mostVisibleSectionId !== 'undefined') {
        setActiveSection(mostVisibleSectionId);
      } else {
        //TODO Add error handling logic
        // console.error('No visible section could be determined.');
      }
    } else {
      //TODO Add error handling logic
      // console.error('Sections array is empty or not properly defined.');
    }
  }

  function handleClick(key: string, ref?: MutableRefObject<AccordionHandle | null>): void {
    if (ref) {
      ref.current?.open();
    }

    if (key === 'overview') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      // Need a timeout so tne accordion is open and the section now takes its proper size
      setTimeout(
        () => {
          const element = document.getElementById(key);
          const stickyHeader = document.getElementsByClassName('sticky');
          if (element) {
            const scrollPosition = window.scrollY;
            const newScrollPosition = stickyHeader
              ? element.getBoundingClientRect().top + scrollPosition - stickyHeader[0].clientHeight
              : element.getBoundingClientRect().top + scrollPosition;

            window.scrollTo({
              top: newScrollPosition,
              behavior: 'smooth'
            });
          }
        },
        ref ? 300 : 0
      );
    }
  }

  const handleBack = () => (isContract ? navigate('/bestand') : navigate('/dashboard'));

  return (
    <div className="sticky top-0 pt-[24px] ">
      <div className="flex gap-[8px] items-center mb-[16px]">
        <ArrowBackIcon className="text-primary-80" />
        <Link
          component="button"
          onClick={handleBack}
          className="text-[14px] leading-[20px]"
          color="primary"
        >
          {isContract ? t('navigation.backToContracts') : t('navigation.backToDashboard')}
        </Link>
      </div>
      <Typography component={'ul'} className={'p-0 m-0 list-none'}>
        {items.map(({ label, key, ref }) => (
          <Typography
            component="li"
            role="button"
            className={classNames(
              'bg-white text-text-100 border border-b border-solid border-t-0 border-r-0 border-l-0 border-text-20 last:border-b-0 first:rounded-t last:rounded-b p-m transition-all duration-[200] ease-out',
              {
                'border border-solid border-b-0 border-t-0 border-r-0 border-l-4 border-l-primary-80 bg-primary-40 text-title-60':
                  activeSection === key
              }
            )}
            onClick={() => handleClick(key, ref)}
          >
            {label}
          </Typography>
        ))}
      </Typography>
    </div>
  );
};

type CreateNavigationParams = {
  insuredPersons: Array<View360NavigationItem>;
  includeContractItems?: boolean;
  hasAppropriateRealmRole?: boolean;
};

export const createNavigation = ({
  insuredPersons,
  includeContractItems = false,
  hasAppropriateRealmRole = true
}: CreateNavigationParams): View360Navigation => {
  const commonItems = [
    {
      label: i18next.t('navigation.overview', { ns: 'view360' }),
      key: View360NavigationEnum.Overview,
      ref: createRef<AccordionHandle>()
    },
    {
      label: i18next.t('navigation.documents', { ns: 'view360' }),
      key: View360NavigationEnum.Documents,
      ref: createRef<AccordionHandle>()
    },
    {
      label: i18next.t('navigation.policyHolder', { ns: 'view360' }),
      key: View360NavigationEnum.PolicyHolder,
      ref: createRef<AccordionHandle>()
    }
    // TODO Temporarily hidden: covered by accounts section
    // {
    //   label: i18next.t('navigation.paymentContributor', { ns: 'view360' }) as string,
    //   key: View360NavigationEnum.PaymentContributor,
    //   ref: createRef<AccordionHandle>()
    // },
  ];

  const additionalItems = [
    {
      label: i18next.t('navigation.account', { ns: 'view360' }),
      key: View360NavigationEnum.Account,
      ref: createRef<AccordionHandle>()
    },
    {
      label: i18next.t('navigation.additionalContractInfo', { ns: 'view360' }),
      key: View360NavigationEnum.AdditionalContractInfo,
      ref: createRef<AccordionHandle>()
    },
    {
      label: i18next.t('navigation.agent', { ns: 'view360' }),
      key: View360NavigationEnum.Agent,
      ref: createRef<AccordionHandle>()
    }
    // Temporarily hidden
    // {
    //   label: i18next.t('navigation.organization', { ns: 'view360' }),
    //   key: View360NavigationEnum.Organization,
    //   ref: createRef<AccordionHandle>()
    // }
  ];

  if (!hasAppropriateRealmRole) additionalItems.pop();

  return {
    isContract: true,
    items: [...commonItems, ...insuredPersons, ...(includeContractItems ? additionalItems : [])]
  };
};
