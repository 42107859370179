import { queryConfig } from 'config';

import { useQuery } from '@tanstack/react-query';

import {
  Criteria,
  CriteriaType,
  PolicyDocumentMetadata,
  PolicyService,
  SearchOperationEnum
} from 'models/portal-generated';

import { Paginated } from 'hooks/usePagination';
import { Sortable } from 'hooks/useSort';

type Params = {
  policyId: string;
  sorting: Sortable;
  pagination: Paginated;
};

export const usePolicyDocuments = ({ policyId, sorting, pagination }: Params) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: [
      'policyDocuments',
      policyId,
      sorting.field,
      sorting.order,
      pagination.page,
      pagination.size
    ],
    gcTime: queryConfig.oneMinuteCache.gcTime,
    queryFn: () => {
      const policyIdCriteria = {
        criteriaType: CriteriaType.OR,
        criteria: [
          {
            // This Id is specified on backend for retrievieng documents based on policyId
            key: 'vsnr',
            operation: SearchOperationEnum.EQUALS,
            value: policyId
          }
        ]
      };

      const query: Criteria = {
        page: pagination.page,
        size: pagination.size || 10,
        criteriaType: CriteriaType.AND,
        searchCriteria: [policyIdCriteria]
      };

      if (sorting.field) {
        query.sort = [
          {
            field: sorting.field,
            order: sorting.order
          }
        ];
      }

      return PolicyService.getPolicyDocuments({ requestBody: query });
    }
  });

  return {
    result: data?.results || ([] as PolicyDocumentMetadata[]),
    total: data?.total || 0,
    isLoading,
    isError,
    error
  };
};
