import { queryConfig } from 'config';

import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { NestedSearchCriteria } from 'models/extension-generated';
import { FormCategory, SearchOperationEnum } from 'models/portal-generated';

import { useFormsPageContext } from '../../context';
import { CounterType, fetchCounts } from 'utilities/fetchFormMetadata';

type FormsTabProps = {
  category: FormCategory;
  resetFilters: (category: FormCategory) => void;
};

export const FormTabs = ({ category, resetFilters }: FormsTabProps) => {
  const { t } = useTranslation('formsPage');
  const { filters } = useFormsPageContext();

  const { data, isLoading } = useQuery<CounterType>({
    queryKey: ['formTabsCount'],
    queryFn: fetchCounts,
    gcTime: queryConfig.oneMinuteCache.gcTime,
    staleTime: queryConfig.oneMinuteCache.staleTime
  });

  const handleTabChange = (category: FormCategory) => {
    if (category in FormCategory && category === FormCategory.LKH) {
      resetFilters(FormCategory.LKH);
      const categoryCriteria: NestedSearchCriteria = {
        criteria: [
          {
            key: 'category',
            operation: SearchOperationEnum.EQUALS,
            value: FormCategory.LKH
          }
        ]
      };
      filters.set([categoryCriteria], false);
    } else {
      resetFilters(FormCategory.VERTRIEB);
      const categoryCriteria: NestedSearchCriteria = {
        criteria: [
          {
            key: 'category',
            operation: SearchOperationEnum.EQUALS,
            value: FormCategory.VERTRIEB
          }
        ]
      };
      filters.set([categoryCriteria], false);
    }
  };

  return (
    <Tabs
      value={category}
      onChange={(_event, value: FormCategory) => {
        handleTabChange(value);
      }}
    >
      <Tab
        value={FormCategory.LKH}
        label={t('table.categories.LKH')}
        icon={
          !isLoading ? (
            <Chip
              label={data?.[FormCategory.LKH]}
              size="small"
              className="bg-highlight-80 text-white-100 text-xs font-bold"
            />
          ) : undefined
        }
        iconPosition="start"
        className="min-h-[20px]"
      />
      <Tab
        value={FormCategory.VERTRIEB}
        label={t('table.categories.VERTRIEB')}
        icon={
          !isLoading ? (
            <Chip
              label={data?.[FormCategory.VERTRIEB]}
              size="small"
              className="bg-highlight-80 text-white-100 text-xs font-bold"
            />
          ) : undefined
        }
        iconPosition="start"
        className="min-h-[20px]"
      />
    </Tabs>
  );
};
