import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';

import { partnerField } from '../../../../../../../models';
import { TariffSectionProps } from '../../../models/TariffsSectionProps';
import { formModelGet } from 'lkh-portal-ui-library';
import { TariffSectionEnum } from 'models/extension-generated';

import { OptionalSection } from '../../../components/OptionalSection';
import { getOptions } from '../../../components/OptionalSection/helpers';
import { SectionTitle } from '../../../components/SectionTitle';
import { TariffWithSlider } from '../../../components/TariffWithSlider';
import { RequiresTariffsProvider, useProvidedTariffs } from '../../../contexts/TariffsContext';
import { DetailsLink } from '../DetailsLink/DetailsLink';
import { INSURANCE_OPTIONS_DOCUMENT } from 'pages/PrivateHealthInsurance/constants';

// Actual content of the tariffs section (see below for the provided wrapper)
const DailyHospitalTariffsContent = () => {
  const MIN = 5;
  const MAX = 150;
  const STEP = 5;
  const { sectionTariffs, section, updateHealthQuestions, partnerId, reducer, state } =
    useProvidedTariffs();
  const { clearTariffs, giveTariff } = reducer;
  const { t } = useTranslation('wizardTariffs', { keyPrefix: `sections.${section}` });

  const handleWantsTariffChange = (value: boolean): void => {
    // Always clear tariffs within this section on change
    clearTariffs({ partnerId, section });

    // If opted yes, give the default tariff with minimum amount to the partner
    if (value) {
      const tariff = sectionTariffs[0];
      const copy = cloneDeep(tariff);
      copy.highMoneyAmount = MIN;

      giveTariff({ partnerId, tariff: copy });
      updateHealthQuestions();
    }
  };

  const options = getOptions(t('noAnswer'));
  const hasDailyHospitalAllowancePath =
    'applicationInformation.tariffInformation.hasDailyHospitalAllowance';
  const value = formModelGet(state.model, partnerField(partnerId, hasDailyHospitalAllowancePath));
  const selected = options.find((opt) => opt.value === value);

  return (
    <OptionalSection
      componentKey={hasDailyHospitalAllowancePath}
      selected={selected}
      title={
        <SectionTitle
          title={
            <div className="flex gap-[8px] items-center content-center">
              <div className="text-text-80 text-base font-bold">{t('question')}</div>
              <DetailsLink documentId={INSURANCE_OPTIONS_DOCUMENT} />
            </div>
          }
        />
      }
      noAnswerText={t('noAnswer')}
      onChange={handleWantsTariffChange}
    >
      <TariffWithSlider title={t('sliderHeader')} min={MIN} max={MAX} step={STEP} />
    </OptionalSection>
  );
};

// Wrapper in order to use context within the content
export const DailyHospitalTariffs = ({ tariffs }: TariffSectionProps) => {
  const SECTION = TariffSectionEnum.KRANKENHAUSTAGEGELD;

  return (
    <RequiresTariffsProvider section={SECTION} tariffs={tariffs}>
      <DailyHospitalTariffsContent />
    </RequiresTariffsProvider>
  );
};
