import { ColDef, ICellRendererParams, ValueGetterParams } from 'ag-grid-community';

import { Chip, Typography } from '@mui/material';

import { DarkTooltip, Resolutions, TooltipInfo } from 'lkh-portal-ui-library';
import { FachStatusEnum } from 'models/portal-generated';

import i18n from 'services/translation';

import { TariffRow } from '../types';

import styles from './columns.module.scss';

export enum Ids {
  Title = 'title.label',
  Status = 'status',
  Tier = 'tier',
  ValidFrom = 'validFrom',
  OriginalTariffPrice = 'originalTariffPrice',
  Reduction = 'reduction',
  Surcharge = 'surcharge',
  Other = 'other',
  BestandTotal = 'bestandTotal',
  Discount = 'discount',
  AdjustmentPrice = 'adjustmentPrice.label',
  FinalTariffPrice = 'finalTariffPrice'
}

function StatusItem({ value, color }: { value: string; color: string }) {
  const text = i18n.t<string>(`contractsEnums:tariffStatus.${value}`);
  return (
    <DarkTooltip title={text} arrow placement="right">
      <div className="px-[8px] py-[4px] flex items-center justify-start space-x-[8px] min-w-full h-[32px] rounded-[4px] bg-surface-60">
        <div>
          <div className={`${color} w-[8px] h-[8px] rounded-full`} />
        </div>
        <div className="text-text-80 text-[12px] font-[700] leading-[16px] truncate">{text}</div>
      </div>
    </DarkTooltip>
  );
}

const title: ColDef<TariffRow> = {
  maxWidth: 130,
  colId: Ids.Title,
  headerName: i18n.t('common:tariffs.title'),
  pinned: 'left',
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.title.label;
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <DarkTooltip title={data?.title.tooltip} arrow placement="right">
          <div className="px-[12px] py-[4px] flex items-center justify-center h-[24px] rounded-[8px] bg-highlight-80">
            <div className="text-white-100 text-[12px] font-[700] leading-[16px] truncate">
              {value}
            </div>
          </div>
        </DarkTooltip>
      );
    }
    return value;
  }
};

const status: ColDef<TariffRow> = {
  maxWidth: 130,
  colId: Ids.Status,
  headerName: i18n.t('common:tariffs.status'),
  tooltipComponent: null,
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.status;
  },
  cellRenderer: ({ value }: ICellRendererParams<TariffRow>) => {
    switch (value) {
      case FachStatusEnum.ACTIVE:
        return <StatusItem value={value} color="bg-success-60" />;
      case FachStatusEnum.ANWARTSCHAFT:
      case FachStatusEnum.BEITRAGS_UND_LEISTUNGSFREI:
      case FachStatusEnum.BEGINNVERLEGT:
      case FachStatusEnum.WIEDERINKRAFTGESETZT:
      case FachStatusEnum.IN_ARBEIT:
      case FachStatusEnum.BEGINNVERLEGT_ANWARTSCHAFT:
      case FachStatusEnum.BEGINNVERLEGT_BEITRAGSFREI:
        return <StatusItem value={value} color="bg-warning-60" />;
      case FachStatusEnum.STORNIERT:
        return <StatusItem value={value} color="bg-danger-60" />;
      default:
        return value;
    }
  }
};

const tier: ColDef<TariffRow> = {
  maxWidth: 140,
  colId: Ids.Tier,
  headerName: i18n.t('common:tariffs.tier'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.tier;
  },
  cellRenderer: ({ value }: ICellRendererParams<TariffRow>) => {
    if (value) {
      return (
        <Chip
          label={value}
          className="rounded-[8px] text-[12px] text-highlight-80 bg-none border border-solid font-bold border-highlight-80"
          variant="filled"
          color="primary"
        />
      );
    }
    return <></>;
  }
};

const validFrom: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.ValidFrom,
  headerName: i18n.t('common:tariffs.validFrom'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.validFrom;
  },
  cellRenderer: ({ value }: ICellRendererParams<TariffRow>) => {
    return (
      <Typography variant="bodySMRegular" className="py-[4px]">
        {value}
      </Typography>
    );
  }
};

const originalPrice: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.OriginalTariffPrice,
  headerName: i18n.t('common:tariffs.originalPrice'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.originalTariffPrice?.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    return (
      <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
        <div>
          <Typography variant="bodySMRegular" className="text-text-80">
            {value}
          </Typography>
        </div>
        {data?.originalTariffPrice?.tooltip && (
          <div>
            <TooltipInfo dark text={data?.originalTariffPrice?.tooltip} placement="bottom" />
          </div>
        )}
      </div>
    );
  }
};

const reduction: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.Reduction,
  headerName: i18n.t('common:tariffs.reduction'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.reduction?.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMRegular" className="text-text-80">
              {value}
            </Typography>
          </div>
          {data?.reduction?.tooltip && (
            <div>
              <TooltipInfo dark text={data?.reduction?.tooltip} placement="bottom" />
            </div>
          )}
        </div>
      );
    }
    return value;
  }
};

const surcharge: ColDef<TariffRow> = {
  maxWidth: 135,
  colId: Ids.Surcharge,
  headerName: i18n.t('common:tariffs.surcharge'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.surcharge.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMRegular" className="text-text-80">
              {value}
            </Typography>
          </div>
          {data?.surcharge?.tooltip && (
            <div>
              <TooltipInfo dark text={data?.surcharge?.tooltip} placement="bottom" />
            </div>
          )}
        </div>
      );
    }
    return value;
  }
};

const other: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.Other,
  headerName: i18n.t('common:tariffs.other'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.other.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMRegular" className="text-text-80">
              {value}
            </Typography>
          </div>
          {data?.other?.tooltip && (
            <div>
              <TooltipInfo dark text={data?.other?.tooltip} placement="bottom" />
            </div>
          )}
        </div>
      );
    }
    return value;
  }
};

const bestandTotal: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.BestandTotal,
  headerName: i18n.t('common:tariffs.bestandTotal'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.bestandTotal.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMBold" className="text-text-80">
              {value}
            </Typography>
          </div>
          {data?.bestandTotal?.tooltip && (
            <div>
              <TooltipInfo dark text={data?.bestandTotal?.tooltip} placement="bottom" />
            </div>
          )}
        </div>
      );
    }
    return value;
  }
};

const discount: ColDef<TariffRow> = {
  maxWidth: 125,
  colId: Ids.Discount,
  headerName: i18n.t('common:tariffs.discount'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.discount.label || '';
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center self-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMRegular" className="text-text-80">
              {value}
            </Typography>
          </div>
          <div>
            <TooltipInfo dark text={data?.discount?.tooltip} placement="bottom" />
          </div>
        </div>
      );
    }
    return value;
  }
};

const adjustmentPrice: ColDef<TariffRow> = {
  maxWidth: 140,
  colId: Ids.AdjustmentPrice,
  headerName: i18n.t('common:tariffs.priceAdjustment'),
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.adjustmentPrice.label;
  },
  cellRenderer: ({ data, value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <div className="flex items-center justify-between space-x-[12px] w-[100px]">
          <div>
            <Typography variant="bodySMRegular" className="text-text-80">
              {value}
            </Typography>
          </div>
          {data?.adjustmentPrice?.tooltip && (
            <div>
              <TooltipInfo dark text={data?.adjustmentPrice?.tooltip} placement="bottom" />
            </div>
          )}
        </div>
      );
    }
    return value;
  }
};

const finalPrice: ColDef<TariffRow> = {
  maxWidth: 150,
  colId: Ids.FinalTariffPrice,
  headerClass: [styles.finalPriceHeader, 'ag-right-aligned-header'],
  headerName: i18n.t('common:tariffs.finalPrice'),
  cellClass: [styles.finalPrice],
  pinned: 'right',
  valueGetter: ({ data }: ValueGetterParams<TariffRow>) => {
    return data?.finalTariffPrice;
  },
  cellRenderer: ({ value }: ICellRendererParams<TariffRow>) => {
    if (value?.length) {
      return (
        <Typography variant="bodySMBold" className="text-text-80">
          {value}
        </Typography>
      );
    }
    return value;
  }
};

export const getColumnDefinition = (resolution: Resolutions): Array<ColDef<TariffRow>> => {
  switch (resolution) {
    case Resolutions.xl:
      return [
        title,
        status,
        tier,
        validFrom,
        originalPrice,
        reduction,
        surcharge,
        other,
        bestandTotal,
        discount,
        finalPrice
      ];
    case Resolutions.lg:
      return [
        title,
        status,
        tier,
        originalPrice,
        reduction,
        surcharge,
        other,
        bestandTotal,
        discount,
        finalPrice
      ];
    case Resolutions.md:
    case Resolutions.sm:
    case Resolutions.xs:
      return [title, status, tier, originalPrice, adjustmentPrice, finalPrice];
  }
};
