import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { Fragment, KeyboardEvent, useCallback, useState } from 'react';

import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { AgentMetadata } from 'models/portal-generated';

import { useSearchAgents } from 'hooks/useSearchAgents';

const formatAgentDetails = (agent: SelectedAgent) => {
  const id = agent.id.trim();
  const name =
    agent.lastname && agent.firstname
      ? `${agent.lastname}, ${agent.firstname}`
      : agent.lastname
      ? `${agent.lastname}`
      : agent.firstname
      ? `${agent.firstname}`
      : '';

  const space = '\u00A0\u00A0'; // 2 non-breaking spaces

  return `${id}${name.length > 0 ? space : ''}${name}`;
};

const DEBOUNCE_TIME = 500;

export type SelectedAgent = { id: string; firstname?: string; lastname?: string };

export type AgentsSearchProps = {
  agent: SelectedAgent | null;
  onAgentChange: (agent: SelectedAgent | null) => void;
  options?: any[] & AgentMetadata[];
  disabled?: boolean;
  error?: string;
};

export const AgentsSearch = ({
  agent,
  onAgentChange,
  disabled,
  error,
  options
}: AgentsSearchProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'navigation.agentPicker.agentPickerDialog.default'
  });

  const hasOptions = options && options.length > 0;
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { isLoading, result } = useSearchAgents({ searchQuery, disabled: hasOptions });
  const getSearchDelayed = useCallback(
    debounce((text) => {
      setSearchQuery(text);
    }, DEBOUNCE_TIME),
    []
  );

  const handleChange = (event: React.SyntheticEvent, newValue: string | null) => {
    const eventKey = (event as KeyboardEvent)?.key;
    const value = newValue?.replace(/[.#-$]/g, '')?.trim() || '';

    if (event?.type === 'keydown' && (eventKey === 'Backspace' || eventKey === 'Delete')) {
      return;
    }

    if (value === agent?.id) return;
    onAgentChange(null);
    getSearchDelayed(value);
    setOpen(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && searchQuery.length) {
      setOpen(true);
      getSearchDelayed(searchQuery);
    }
  };
  const handleFocus = () => {
    if (searchQuery.length) {
      setOpen(true);
      getSearchDelayed(searchQuery);
    }
  };
  const handleErase = () => {
    setSearchQuery('');
    onAgentChange(null);
  };

  const noOptionsText = (() => {
    // if (result === null) return agent ? formatAgentDetails(agent) : null;
    if (agent) return 'Geben Sie Name oder Nummer des Vermittlers ein, um die Suche zu beginnen';
    if (!agent) return 'Keine Optionen';
  })();

  return (
    <Autocomplete
      disabled={disabled}
      id="async-search-agents"
      value={agent}
      openText="opentext"
      ListboxProps={{ sx: { overflowX: 'hidden' } }}
      freeSolo={!!result?.length}
      loadingText={
        <Typography variant="bodyLGRegular" className="p-[20px] h-[28px] inline-flex items-center">
          {t('search.loading')}
        </Typography>
      }
      noOptionsText={noOptionsText}
      disableClearable
      forcePopupIcon={false}
      open={open}
      onOpen={() => searchQuery.length && setOpen(true)}
      onClose={() => setOpen(false)}
      options={result || []}
      getOptionLabel={(agent) => {
        return formatAgentDetails(agent);
      }}
      loading={isLoading}
      clearOnEscape
      fullWidth
      onChange={(_event: React.SyntheticEvent, newValue: SelectedAgent | null) => {
        if (!newValue?.id) return;
        onAgentChange(newValue);
      }}
      onInputChange={handleChange}
      onKeyDown={handleKeyDown}
      openOnFocus={false}
      onFocus={handleFocus}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          helperText={error}
          label={t('search.label')}
          placeholder={t('search.placeholder')}
          InputProps={{
            ...params.InputProps,
            endAdornment: !disabled ? (
              <Fragment>
                {isLoading ? <CircularProgress size="small" /> : null}
                {agent ? (
                  <IconButton onClick={handleErase}>
                    <CancelOutlinedIcon fontSize="small" />
                  </IconButton>
                ) : null}
              </Fragment>
            ) : (
              <></>
            )
          }}
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const { ...optionProps } = props;

        const agent = formatAgentDetails(option);
        const matches = match(agent, inputValue, { insideWords: true });
        const parts = parse(agent, matches);

        return (
          <li {...optionProps}>
            {/* <li key={key} {...optionProps}> */}
            <div>
              {parts.map((part, index) => (
                <span
                  key={`part-text-${index}`}
                  style={{
                    fontWeight: part.highlight ? 700 : 400
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
};
