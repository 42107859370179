import { DependencyList, RefObject, useLayoutEffect } from 'react';

/**
 * Triggers scroll top on dependencies change
 *
 * Also provides method in case the scroll should be triggered manually
 */
export const useScrollTop = (ref?: RefObject<HTMLElement>, deps?: DependencyList) => {
  /**
   * Manual trigger
   */
  function scrollTop(): void {
    if (ref && ref.current) {
      ref.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  useLayoutEffect(() => {
    scrollTop();
  }, deps);

  return { scrollTop };
};
