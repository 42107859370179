import { WIZARD_MAIN_ID } from 'layout/wizard/slots/Main';

import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Fab } from '@mui/material';

import { LEGAL_FOOTER_ID } from 'components/LegalFooter/LegalFooter';

const marginBottom = 36;

const getFooterHeight = () => {
  const legalFooter = document.getElementById(LEGAL_FOOTER_ID);
  const mainContent = document.getElementById(WIZARD_MAIN_ID);

  if (!mainContent || !legalFooter) return 0;

  const isScrolledToBottom =
    mainContent.scrollTop + mainContent.clientHeight >=
    mainContent.scrollHeight - legalFooter.offsetHeight;
  const legalFooterHeight = isScrolledToBottom ? legalFooter.offsetHeight : 0;
  return legalFooterHeight;
};

export const OpenModalButton = ({ openModal }: { openModal: () => void }) => {
  const { t } = useTranslation('wizardHealth', {});
  const footer = document.getElementById('wizard-actions');
  const [offset, setOffset] = useState(footer?.offsetHeight);

  useEffect(() => {
    if (!footer) return;

    const resizeObserver = new ResizeObserver(() => {
      const navFooterHeight = document.getElementById('wizard-actions');
      if (!navFooterHeight) return;
      const legalFooterHeight = getFooterHeight();
      setOffset(navFooterHeight.offsetHeight + legalFooterHeight + marginBottom);
    });
    const mainContent = document.getElementById(WIZARD_MAIN_ID);

    mainContent?.addEventListener('scroll', () => {
      const navFooterHeight = document.getElementById('wizard-actions');

      if (!navFooterHeight) return;
      const legalFooterHeight = getFooterHeight();
      setOffset(navFooterHeight.offsetHeight + legalFooterHeight + marginBottom);
    });

    resizeObserver.observe(footer);

    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <Fab
      aria-label="open-diagnosis-modal-button"
      sx={{
        bottom: `${offset}px`
      }}
      variant="extended"
      size="medium"
      className="bg-text-80 hover:bg-highlight-80 active:bg-highlight-90 text-white-100 font-bold text-[14px] leading-[20px] tracking-wider fixed right-[30px] s:right-[56px] m:right-[502px] l:right-[558px] flex items-center"
      onClick={() => openModal()}
    >
      {t('section.diagnosis.openButtonTitle')}
      <OpenInNewIcon className="ml-[8px]" />
    </Fab>
  );
};
