import { useEffect, useState } from 'react';

import { Article, News, NewsService } from 'models/portal-generated';

import { NewsHook } from '../context';

export async function fetchNews(): Promise<News> {
  return NewsService.getNews();
}

export function useNews(): NewsHook {
  const [loading, setLoading] = useState<boolean>(false);
  const [news, setNews] = useState<Array<Article>>([]);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);

    const fetchNewsFn = async () => {
      try {
        const response: News = await fetchNews();
        if (response) {
          setNews(response);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchNewsFn();
  }, []);

  return { news, loading, error };
}
