import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { applicationField, partnerField } from '../../../../models';
import { Form, formModelGet, Loader, LoaderType } from 'lkh-portal-ui-library';
import { Tariff } from 'models/extension-generated';

import { WizardSlot } from '../../../../layout/wizard/slots';
import { calculateAge } from '../../../../utilities/dates';
import { PrivateDataPictogram } from '../../components/pictograms';
import { StepHeading } from '../../components/StepHeading';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { HealthQuestions } from './HealthQuestions';
import { NoHealthQuestions } from './NoHealthQuestions/NoHealthQuestions';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';
import { useQuestionsContext } from 'pages/PrivateHealthInsurance/context/QuestionsContext';

import styles from './HealthDataStep.module.scss';

export const HealthDataStep = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state, dispatch, partnerId } = useHealthInsuranceContext();
  const { formatPartnerName } = useApplicationHelpers(state.model);
  const name = formatPartnerName(partnerId);
  const tariffsPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.selectedTariffs'
  );
  const tariffs: Array<Tariff> = formModelGet(state.model, tariffsPath) || [];
  const birthDate = formModelGet(state.model, partnerField(partnerId, 'birthDate'));
  const insuranceStartKey = applicationField('applicationStart');
  const insuranceStart = formModelGet(state.model, insuranceStartKey);

  const {
    isLoading,
    questions,
    getQuestionLabel,
    getQuestionText,
    getQuestionUnit,
    getQuestionMaxValue,
    getQuestionKey,
    fetchQuestions
  } = useQuestionsContext();

  useEffect(() => {
    fetchQuestions({ tariffs, birthDate, insuranceStart });
  }, []);

  const isUnder15 =
    calculateAge(formModelGet(state.model, partnerField(partnerId, `birthDate`))) < 15;

  return (
    <>
      <StepHeading icon={<PrivateDataPictogram />} title={name} showProgress />
      <div className="layout-res">
        <WizardSlot.Main>
          {isLoading && questions ? (
            <Loader className={styles.loader} type={LoaderType.Circular} />
          ) : (
            <>
              {questions.length === 0 ? (
                <NoHealthQuestions />
              ) : (
                <Form.Section title={t('insuredPeople.steps.health.title')}>
                  <Form state={state} dispatch={dispatch}>
                    <HealthQuestions
                      partnerId={partnerId}
                      isUnder15={isUnder15}
                      questions={questions}
                      isLoading={isLoading}
                      getQuestionLabel={getQuestionLabel}
                      getQuestionText={getQuestionText}
                      getQuestionUnit={getQuestionUnit}
                      getQuestionMaxValue={getQuestionMaxValue}
                      getQuestionKey={getQuestionKey}
                      applicationStart={state.model.applicationStart || ''}
                    />
                  </Form>
                </Form.Section>
              )}
            </>
          )}
        </WizardSlot.Main>
      </div>
    </>
  );
};
