import { queryConfig } from 'config';

import { useRef } from 'react';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { CriteriaType } from '../../../../../models/extension-generated';
import { FilterGroup } from 'models/criteriaTypes';
import {
  CancelablePromise,
  Criteria,
  PoliciesResponse,
  PolicyService,
  PolicyStatusEnum,
  SearchOperationEnum
} from 'models/portal-generated';

import { resolveSortingObject } from './sorting';
import { AgentSessionData, useAgentSession } from 'hooks/useAgentSession';
import { UseFilter } from 'hooks/useFilter';
import { Paginated } from 'hooks/usePagination';
import { Sortable } from 'hooks/useSort';
import { FilterGroupsEnum, FilterGroupsKeys } from 'pages/Contracts/constants';
import { ContractHook } from 'pages/Contracts/context';
import { getAgentIdForFilters } from 'utilities/agentPicker';

interface UseContracts {
  pagination: Paginated;
  filters: UseFilter;
  sorting: Sortable;
}

export async function getCSVExport(
  filters: UseFilter,
  sorting: Sortable
): CancelablePromise<string> {
  const query: Criteria = {
    page: 0,
    size: 0,
    criteriaType: CriteriaType.AND
  };

  if (sorting.field) {
    query.sort = resolveSortingObject(sorting);
  } else {
    // no sorting field was set, remove it from the query
    delete query.sort;
  }

  if (filters.active.length > 0) {
    query.searchCriteria = [...filters.active];
  }
  const res = await axios.post(
    '/api/policy/csv/export',
    { requestBody: query },
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      }
    }
  );
  return res.data;
}

export async function getCSVProvisionsExport(
  filters: UseFilter,
  sorting: Sortable
): CancelablePromise<string> {
  const query: Criteria = {
    page: 0,
    size: 0,
    criteriaType: CriteriaType.AND
  };

  if (sorting.field) {
    query.sort = resolveSortingObject(sorting);
  } else {
    // no sorting field was set, remove it from the query
    delete query.sort;
  }

  const statusFilterGroup = filters.active.find(
    (group) => group.groupId === FilterGroupsEnum.Status
  );

  if (filters.active.length > 0 && statusFilterGroup) {
    query.searchCriteria = [...filters.active];
  } else {
    query.searchCriteria = [...filters.active];

    // New status filter with the 'ACTIVE' status
    const statusFilter: FilterGroup = {
      groupId: FilterGroupsEnum.Status,
      groupKey: FilterGroupsKeys[FilterGroupsEnum.Status],
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: FilterGroupsKeys[FilterGroupsEnum.Status],
          operation: SearchOperationEnum.IN,
          value: [PolicyStatusEnum.ACTIVE]
        }
      ]
    };
    query.searchCriteria.push(statusFilter);
  }

  const res = await axios.post(
    '/api/policy/csv/export/provision',
    { requestBody: query },
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      }
    }
  );
  return res.data;
}
export async function getRemindersExport(
  filters: UseFilter,
  sorting: Sortable
): CancelablePromise<string> {
  const query: Criteria = {
    page: 0,
    size: 0,
    criteriaType: CriteriaType.AND
  };

  if (sorting.field) {
    query.sort = resolveSortingObject(sorting);
  } else {
    // no sorting field was set, remove it from the query
    delete query.sort;
  }

  const statusFilterGroup = filters.active.find(
    (group) => group.groupId === FilterGroupsEnum.Status
  );

  if (filters.active.length > 0 && statusFilterGroup) {
    query.searchCriteria = [...filters.active];
  } else {
    query.searchCriteria = [...filters.active];

    // New status filter with the 'ACTIVE' status
    const statusFilter: FilterGroup = {
      groupId: FilterGroupsEnum.Status,
      groupKey: FilterGroupsKeys[FilterGroupsEnum.Status],
      criteriaType: CriteriaType.OR,
      criteria: [
        {
          key: FilterGroupsKeys[FilterGroupsEnum.Status],
          operation: SearchOperationEnum.IN,
          value: [PolicyStatusEnum.ACTIVE]
        }
      ]
    };
    query.searchCriteria.push(statusFilter);
  }

  const res = await axios.post(
    '/api/policy/csv/export/reminders',
    { requestBody: query },
    {
      responseType: 'blob',
      headers: {
        Accept: 'application/octet-stream'
      }
    }
  );
  return res.data;
}

const fetchContracts = (
  params: {
    page: number;
    size: number;
    filters: UseFilter;
    sorting: Sortable;
    agentSession: AgentSessionData['agentSession'];
  },
  cancelRef: React.MutableRefObject<CancelablePromise<PoliciesResponse> | null>
): Promise<PoliciesResponse> => {
  const { page, size, filters, sorting, agentSession } = params;
  const agentId = getAgentIdForFilters(agentSession);
  const query: Criteria = {
    page,
    size: size || 10,
    criteriaType: CriteriaType.AND
  };

  if (agentId) {
    query.searchCriteria = [
      {
        criteriaType: CriteriaType.AND,
        criteria: [
          {
            key: 'agentId',
            operation: SearchOperationEnum.EQUALS,
            value: agentId
          }
        ]
      }
    ];
  }

  if (sorting.field) {
    query.sort = resolveSortingObject(sorting);
  } else {
    delete query.sort;
  }

  if (filters.active.length > 0) {
    query.searchCriteria = filters.active;
  }

  const cancelablePromise = PolicyService.getPolicies({ requestBody: query });
  cancelRef.current = cancelablePromise;

  return cancelablePromise;
};

export function useContracts({ filters, pagination, sorting }: UseContracts): ContractHook {
  const cancelRef = useRef<CancelablePromise<PoliciesResponse> | null>(null);
  const { agentSession } = useAgentSession();

  const queryContracts = async (): Promise<PoliciesResponse> => {
    if (cancelRef.current && cancelRef.current instanceof CancelablePromise) {
      cancelRef.current.cancel();
    }

    return fetchContracts(
      {
        page: pagination.page,
        size: pagination.size || 10,
        filters,
        sorting,
        agentSession
      },
      cancelRef
    );
  };

  const { data, error, isLoading, isFetching, refetch } = useQuery<PoliciesResponse, Error>({
    queryKey: [
      'contracts',
      pagination.page,
      pagination.size,
      filters.active,
      sorting.field,
      sorting.order,
      agentSession.agentId,
      agentSession.agentPickerEnabled,
      agentSession.hasServiceLKHInternalRole,
      agentSession.name
    ],
    queryFn: queryContracts,
    placeholderData: (prevData) => prevData,
    gcTime: queryConfig.oneMinuteCache.gcTime
  });

  const contracts = data?.results || [];
  const total = data?.total || 0;

  return {
    contracts,
    total,
    loading: isLoading || isFetching,
    error: !!error,
    fetchContractsFn: refetch
  };
}
