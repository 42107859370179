import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

type DeleteApplicationDialogProps = {
  appId: string;
  isOpen: boolean;
  onClose: () => void;
  process?: () => Promise<void>;
};
export const DeleteApplicationDialog = ({
  appId,
  isOpen,
  onClose,
  process = () => Promise.resolve()
}: DeleteApplicationDialogProps) => {
  const { t } = useTranslation('dashboard');
  const [isDeleting, setIsDeleting] = useState(false);

  const handleCloseDialog = () => {
    onClose();
  };

  const deleteApplication = async () => {
    if (!appId) return;
    setIsDeleting(true);

    try {
      await process();
      toast.success(t<string>('wizardPrivateHealthInsurance:orderOptions.menu.deleteConfirm'));
    } catch (error) {
      toast.error(t<string>('common:toastMessages.somethingWentWrong'));
    } finally {
      setIsDeleting(false);
      handleCloseDialog();
    }
  };

  return (
    <Dialog disableScrollLock fullWidth maxWidth="sm" open={isOpen} onClose={handleCloseDialog}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <div>
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('actions.deleteConfirmation.title', { orderId: appId })}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                {t('actions.deleteConfirmation.message')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="flex gap-[16px] justify-end mt-[32px]">
          <Button size="large" onClick={handleCloseDialog}>
            {t('actions.deleteConfirmation.abort')}
          </Button>
          <Button
            size="large"
            variant="contained"
            color="error"
            startIcon={
              isDeleting ? (
                <CircularProgress color="inherit" size={16} />
              ) : (
                <DeleteForeverOutlinedIcon />
              )
            }
            onClick={deleteApplication}
            disabled={isDeleting}
          >
            {isDeleting
              ? t('actions.deleteConfirmation.wait')
              : t('actions.deleteConfirmation.delete')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
