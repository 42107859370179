import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { Form } from 'lkh-portal-ui-library';
import { PartnerIdProp } from 'models';
import { PreInsuranceTypeEnum } from 'models/extension-generated';

import { useHealthInsuranceContext } from '../../../../../context/HealthInsuranceContext';
import { usePreInsurances } from '../../hooks/usePreInsurance';
import { PreInsuranceContextMenu } from '../PreInsuranceContextMenu';
import { PreInsuranceStartEndForm } from '../PreInsuranceStartEndForm';
import { PreInsuranceTypeForm } from '../PreInsuranceTypeForm';
import { PreInsuranceWrapper } from '../PreInsuranceWrapper';

const { Input } = Form.Components;

export const SickDayPreInsurance = ({ partnerId }: PartnerIdProp) => {
  const TYPE = PreInsuranceTypeEnum.SICK_PAY;
  const TOGGLE = 'hasSickPayPreInsurance';

  const { t } = useTranslation('wizardGeneral');

  const { state } = useHealthInsuranceContext();
  const { preInsurances, preInsuranceKey } = usePreInsurances(state.model, partnerId, TYPE);

  return (
    <PreInsuranceWrapper
      type={TYPE}
      partnerId={partnerId}
      preInsuranceKey={'hasSickPayPreInsurance'}
    >
      {preInsurances.map((preInsurance) => (
        <section key={preInsurance.id}>
          <PreInsuranceContextMenu
            title={t('longTerm.preInsurance.title')}
            toggleKey={TOGGLE}
            partnerId={partnerId}
            preInsurance={preInsurance}
          />
          <PreInsuranceTypeForm
            insuranceCompanyKey={preInsuranceKey(preInsurance.id, 'insuranceCompany')}
            privateInsuranceCompanyKey={preInsuranceKey(preInsurance.id, 'privateInsuranceCompany')}
            isPublicKey={preInsuranceKey(preInsurance.id, 'isPublic')}
          />
          <PreInsuranceStartEndForm
            startDateKey={preInsuranceKey(preInsurance.id, 'startDate')}
            endDateKey={preInsuranceKey(preInsurance.id, 'endDate')}
            ongoingKey={preInsuranceKey(preInsurance.id, 'ongoing')}
          />

          <div className="grid-res-2">
            <div>
              <Typography variant="bodyMDRegular" className="mb-[24px]">
                {t('longTerm.preInsurance.fields.sickPayAmount.text')}
              </Typography>
              <Input
                type="number"
                componentKey={preInsuranceKey(preInsurance.id, 'benefitAmount')}
                placeholder={'0'}
              />
            </div>
          </div>
          <div className="grid-res">
            <Divider />
          </div>
        </section>
      ))}
    </PreInsuranceWrapper>
  );
};
