import { partnerField, PartnerIdProp } from '../../../../../../../models';
import { formModelGet } from 'lkh-portal-ui-library';
import { NeedSituationEnum } from 'models/extension-generated';

import { CarePreInsurance } from '../CarePreInsurance';
import { GeneralValues } from '../GeneralValues';
import { PrivateHealthPreInsurance } from '../PrivateHealthPreInsurance';
import { SickDayPreInsurance } from '../SickPayPreInsurance';
import { SupplementaryPreInsurance } from '../SupplementaryPreInsurance';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

type GeneralQuestionsProps = PartnerIdProp;

export const GeneralQuestions = ({ partnerId }: GeneralQuestionsProps) => {
  const { state } = useHealthInsuranceContext();
  const needSituationPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.needSituation'
  );
  const needSituation = formModelGet(state.model, needSituationPath);

  return (
    <>
      <PrivateHealthPreInsurance partnerId={partnerId} />
      <CarePreInsurance partnerId={partnerId} />
      <SickDayPreInsurance partnerId={partnerId} />
      <SupplementaryPreInsurance partnerId={partnerId} />
      {needSituation !== NeedSituationEnum.ZVGKV && <GeneralValues partnerId={partnerId} />}
    </>
  );
};
