import { useTranslation } from 'react-i18next';

import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { model } from '../models';
import { SearchOperationEnum } from 'models/portal-generated';

import MultiSelectAll from '../Multiselect';
import { FilterGroupsEnum } from 'pages/Contracts/constants';
import { useContractPageContext } from 'pages/Contracts/context';

export const ExpandedInsuredPeopleFilters = () => {
  const { t } = useTranslation('contract');
  const {
    handleDateRangeChange,
    handleTextFilterChange,
    getSelectedValue,
    getSelectedValues,
    handleListFilterChange
  } = useContractPageContext();

  const firstName = getSelectedValue(FilterGroupsEnum.InsuredPartnersFirstName) || '';
  const street = getSelectedValue(FilterGroupsEnum.InsuredPartnersStreet);
  const insuredPartnersMatchCode = getSelectedValue(FilterGroupsEnum.InsuredPartnersMatchCode);
  const insuredPartnersHouseNumber = getSelectedValue(FilterGroupsEnum.InsuredPartnersHouseNumber);
  const workActivity = getSelectedValues(FilterGroupsEnum.WorkActivity, model.workActivity.list);

  const jobType = getSelectedValues(FilterGroupsEnum.InsuredPartnersJobType, model.profession.list);

  const insuredPartnersAddressValidFrom = getSelectedValue(
    FilterGroupsEnum.InsuredPartnersValidFrom
  );

  return (
    <>
      {/* Big screen */}
      <div className="w-full hidden s:grid grid-cols-5 gap-[24px] items-end">
        <TextField
          value={firstName}
          label={t('filter.firstName')}
          placeholder={t('filter.firstName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersFirstName, value);
          }}
        />
        <div />
        <DatePicker
          value={insuredPartnersAddressValidFrom ? new Date(insuredPartnersAddressValidFrom) : null}
          label={t('filter.insuredPartnerValidFrom')}
          onChange={(value, context) => {
            if (context.validationError) return;
            handleDateRangeChange({
              from: value,
              groupId: FilterGroupsEnum.InsuredPartnersValidFrom
            });
          }}
        />
        <div />
        <div />
        <TextField
          value={insuredPartnersMatchCode}
          label={t('filter.matchCode')}
          placeholder={t('filter.matchCode')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersMatchCode, value);
          }}
        />
      </div>
      {/* Small screens */}
      <div className="w-full grid s:hidden grid-cols-3 gap-[24px] items-end">
        <TextField
          value={firstName}
          label={t('filter.firstName')}
          placeholder={t('filter.firstName')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersFirstName, value);
          }}
        />
        <TextField
          value={street}
          label={t('filter.street')}
          placeholder={t('filter.street')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersStreet, value);
          }}
        />
        <TextField
          value={insuredPartnersMatchCode}
          label={t('filter.matchCode')}
          placeholder={t('filter.matchCode')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;
            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersMatchCode, value);
          }}
        />
        <MultiSelectAll
          value={jobType}
          options={model.profession.list}
          label={t('filter.profession')}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.InsuredPartnersJobType,
              SearchOperationEnum.IN,
              model.profession.list
            );
          }}
        />
        <TextField
          value={insuredPartnersHouseNumber}
          label={t('filter.houseNumber')}
          placeholder={t('filter.houseNumber')}
          onChange={(event) => {
            const {
              target: { value }
            } = event;

            handleTextFilterChange(FilterGroupsEnum.InsuredPartnersHouseNumber, value);
          }}
        />
        <div />
        <MultiSelectAll
          label={t('filter.workActivity')}
          options={model.workActivity.list}
          onChange={(value) => {
            handleListFilterChange(
              value,
              FilterGroupsEnum.WorkActivity,
              SearchOperationEnum.IN,
              model.workActivity.list
            );
          }}
          value={workActivity}
        />
        <DatePicker
          value={insuredPartnersAddressValidFrom ? new Date(insuredPartnersAddressValidFrom) : null}
          label={t('filter.insuredPartnerValidFrom')}
          onChange={(value, context) => {
            if (context.validationError) return;

            handleDateRangeChange({
              from: value,
              groupId: FilterGroupsEnum.InsuredPartnersValidFrom
            });
          }}
        />
      </div>
    </>
  );
};
