import { TabType } from './constants';

export type DropdownValue = string | number;

export type DropdownOption = {
  value: DropdownValue;
  label?: string;
};

export interface FilterModel {
  list: DropdownOption[];
  placeholder?: string;
  selected: string | DropdownOption | undefined;
}

export type Range = {
  from?: string;
  to?: string;
  oldFrom?: string;
  oldTo?: string;
};

export enum SecondLevelTabsProps {
  Contract = 'contract',
  InsuredPerson = 'insuredPerson',
  SpecialSearch = 'specialSearch'
}

export type TabsProps = {
  selectedTab: TabType;
  setSelectedTab: (tab: TabType) => void;
};

export type CounterType = {
  [TabType.Contracts]: number;
  [TabType.Reminders]: number;
};
