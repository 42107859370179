import { axios } from 'services/axios';

import { ApplicationService } from 'models/extension-generated';

import { downloadData } from 'utilities/fetchDocument';

type DownloadOfferParams = Parameters<typeof ApplicationService.downloadOffer>[0];
type DownloadOrderParams = Parameters<typeof ApplicationService.downloadOrder>[0];

export const downloadContract = async ({ id: applicationId }: DownloadOrderParams) => {
  const response = await axios.get(`/api/application/${applicationId}/order`, {
    responseType: 'arraybuffer',
    headers: {
      accept: 'application/octet-stream'
    }
  });

  downloadData(response.data, `${applicationId}.pdf`);
};

export const downloadOfferDocument = async ({
  requestBody: application,
  downloadAccompanyDocs,
  signal
}: DownloadOfferParams & { signal?: AbortSignal }) => {
  const response = await axios.post<string>(
    '/api/application/offer',
    { ...application, id: application.id },
    {
      params: {
        downloadAccompanyDocs
      },
      responseType: 'blob',
      signal
    }
  );

  if (response.data) {
    downloadData(response.data, `${application.id}-VS.pdf`);
  }
};
