import { PortalLayout } from 'layout/portal';

import classNames from 'classnames';

import { GridGroup } from './components/Grid';
import { ContractContextProvider } from './context/ContractPageContext';

import styles from './ContractsPage.module.scss';

export const ContractsPage = () => {
  return (
    <PortalLayout>
      <ContractContextProvider>
        <div className={classNames(styles.contractPage)}>
          <div className={classNames(styles.mainContent, 'pl-[36px] pr-[64px]')}>
            <GridGroup />
          </div>
        </div>
      </ContractContextProvider>
    </PortalLayout>
  );
};

export default ContractsPage;
