import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { FormCategory, FormMetadata } from 'models/portal-generated';

import { useFormsData } from '../hooks/useFormsData';
import { UseFilter, useFilter } from 'hooks/useFilter';
import { UsePagination, usePagination } from 'hooks/usePagination';
import { UseSort, useSort } from 'hooks/useSort';

export type FormsContextValue = {
  total: number;
  formsData: Array<FormMetadata>;
  pagination: UsePagination;
  filters: UseFilter;
  sorting: UseSort;
  loading: boolean;
  category: FormCategory;
  setCategory: (category: FormCategory) => void;
  name: string;
  setName: (name: string) => void;
  code: string;
  setCode: (code: string) => void;
};

export const FormsContext = createContext<FormsContextValue | null>(null);

type FormsContextProviderProps = { children: ReactNode };

export const FormsContextProvider = ({ children }: FormsContextProviderProps) => {
  const [category, setCategory] = useState<FormCategory>(FormCategory.LKH);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const pagination = usePagination({ page: 0, size: 10 });
  const filters = useFilter();
  const sorting = useSort();

  useEffect(() => {
    pagination.goTo(0);
  }, [category, name, code, filters.active]);

  const { formsData, total, loading } = useFormsData({
    filters,
    category,
    name,
    code,
    pagination,
    sorting
  });

  const ctx: FormsContextValue = useMemo(() => {
    return {
      total,
      pagination,
      filters,
      sorting,
      formsData,
      loading,
      category,
      name,
      code,
      setCategory,
      setName,
      setCode
    };
  }, [
    total,
    pagination,
    filters,
    filters.active.length,
    sorting,
    formsData,
    loading,
    category,
    name,
    code,
    setCategory,
    setName,
    setCode
  ]);
  return <FormsContext.Provider value={ctx}>{children}</FormsContext.Provider>;
};

export const useFormsPageContext = (): FormsContextValue => {
  const context = useContext(FormsContext);

  if (!context) {
    throw new Error('useFormsPageContext was used outside of its Provider');
  }

  return context;
};
