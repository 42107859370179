import i18n from 'i18next';

import { GenderEnum, InkassoTypeEnum, PolicyStatusEnum } from 'models/portal-generated';

import { FilterModel } from '../../../types';

export enum BooleanEnum {
  TRUE = 'TRUE',
  FALSE = 'FALSE'
}

export const model: Record<string, Omit<FilterModel, 'selected'>> = {
  status: {
    list: [
      {
        value: PolicyStatusEnum.ACTIVE,
        label: i18n.t('table.statusEnum.ACTIVE', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.PENDING,
        label: i18n.t('table.statusEnum.PENDING', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.HISTORIC,
        label: i18n.t('table.statusEnum.HISTORIC', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.IN_PROCESS,
        label: i18n.t('table.statusEnum.IN_PROCESS', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.REVISION_IN_PROCESS,
        label: i18n.t('table.statusEnum.REVISION_IN_PROCESS', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.REACTIVATED,
        label: i18n.t('table.statusEnum.REACTIVATED', { ns: 'contract' })
      },
      {
        value: PolicyStatusEnum.CANCELED,
        label: i18n.t('table.statusEnum.CANCELED', { ns: 'contract' })
      }
    ],
    placeholder: i18n.t('filter.status', { ns: 'contract' })
  },

  profession: {
    list: [
      {
        value: '1',
        label: i18n.t<string>('table.employmentGroup.employed', { ns: 'contract' })
      },
      {
        value: '4',
        label: i18n.t('table.employmentGroup.onBenefits', { ns: 'contract' })
      },
      {
        value: '3',
        label: i18n.t('table.employmentGroup.renterPensioner', { ns: 'contract' })
      },
      {
        value: '5',
        label: i18n.t('table.employmentGroup.selfEmployed', { ns: 'contract' })
      },
      {
        value: '2',
        label: i18n.t('table.employmentGroup.other', { ns: 'contract' })
      }
    ]
  },

  gender: {
    list: [
      {
        value: GenderEnum.FEMALE,
        label: i18n.t('filter.female', { ns: 'contract' })
      },
      {
        value: GenderEnum.MALE,
        label: i18n.t('filter.male', { ns: 'contract' })
      }
    ]
  },

  workActivity: {
    list: [
      {
        value: '00043',
        label: i18n.t('ProfessionEnum.PHARMACIST', { ns: 'contractsEnums' })
      },
      {
        value: '00041',
        label: i18n.t('ProfessionEnum.DOCTOR', { ns: 'contractsEnums' })
      },
      {
        value: '00081',
        label: i18n.t('ProfessionEnum.WORKER_ELIGIBLE_ALLOWANCE', { ns: 'contractsEnums' })
      },
      {
        value: '00086',
        label: i18n.t('ProfessionEnum.WORKER_NOT_ELIGIBLE_ALLOWANCE', { ns: 'contractsEnums' })
      },
      {
        value: '00060',
        label: i18n.t('ProfessionEnum.ELIGIBLE_EMPLOYEE_OFFICE_WORK_ONLY', { ns: 'contractsEnums' })
      },
      {
        value: '00166',
        label: i18n.t('ProfessionEnum.NON_ELIGIBLE_EMPLOYEE_NON_OFFICE_WORK_ONLY', {
          ns: 'contractsEnums'
        })
      },
      {
        value: '00065',
        label: i18n.t('ProfessionEnum.NON_ELIGIBLE_EMPLOYEE_OFFICE_WORK_ONLY', {
          ns: 'contractsEnums'
        })
      },
      {
        value: '00077',
        label: i18n.t('ProfessionEnum.ELIGIBLE_WITH_100_RISK_PREMIUM', { ns: 'contractsEnums' })
      },
      {
        value: '00091',
        label: i18n.t('ProfessionEnum.ELIGIBLE_PENSIONERS', { ns: 'contractsEnums' })
      },
      {
        value: '00067',
        label: i18n.t('ProfessionEnum.ELIGIBLE_HEALTH_PRACTITIONER_PUBLIC_SECTOR', {
          ns: 'contractsEnums'
        })
      },
      {
        value: '00062',
        label: i18n.t('ProfessionEnum.NON_ELIGIBLE_HEALTH_PRACTITIONER_IN_EMPLOYMENT', {
          ns: 'contractsEnums'
        })
      },
      {
        value: '00079',
        label: i18n.t('ProfessionEnum.OTHER_ELIGIBLE_CIVIL_SERVANTS', { ns: 'contractsEnums' })
      },
      {
        value: '00066',
        label: i18n.t('ProfessionEnum.NON_ELIGIBLE_TECHNICAL_COMMERCIAL_SCIENTIFIC_EMPLOYEE', {
          ns: 'contractsEnums'
        })
      },
      {
        value: '00097',
        label: i18n.t('ProfessionEnum.PROFESSIONAL_FOOTBALLER', { ns: 'contractsEnums' })
      },
      {
        value: '00073',
        label: i18n.t('ProfessionEnum.FEDERAL_ARMED_FORCES', { ns: 'contractsEnums' })
      },
      {
        value: '00074',
        label: i18n.t('ProfessionEnum.FEDERAL_BORDER_GUARD', { ns: 'contractsEnums' })
      },
      {
        value: '00076',
        label: i18n.t('ProfessionEnum.FEDERAL_STATE_LOCAL_CIVIL_SERVANT', { ns: 'contractsEnums' })
      },
      {
        value: '00048',
        label: i18n.t('ProfessionEnum.FREELANCER', { ns: 'contractsEnums' })
      },
      {
        value: '00032',
        label: i18n.t('ProfessionEnum.SALES_REPRESENTATIVE', { ns: 'contractsEnums' })
      },
      {
        value: '00012',
        label: i18n.t('ProfessionEnum.GARDENER', { ns: 'contractsEnums' })
      },
      {
        value: '00033',
        label: i18n.t('ProfessionEnum.INNKEEPER', { ns: 'contractsEnums' })
      },
      {
        value: '00093',
        label: i18n.t('ProfessionEnum.HOUSWIFE_HOUSHUSBAND', { ns: 'contractsEnums' })
      },
      {
        value: '00031',
        label: i18n.t('ProfessionEnum.BUSINESS', { ns: 'contractsEnums' })
      },
      {
        value: '00011',
        label: i18n.t('ProfessionEnum.FARMER', { ns: 'contractsEnums' })
      },
      {
        value: '00098',
        label: i18n.t('ProfessionEnum.GUIDE', { ns: 'contractsEnums' })
      },
      {
        value: '00045',
        label: i18n.t('ProfessionEnum.OPTICIAN', { ns: 'contractsEnums' })
      },
      {
        value: '00044',
        label: i18n.t('ProfessionEnum.PHYSIOTHERAPIST', { ns: 'contractsEnums' })
      },
      {
        value: '00042',
        label: i18n.t('ProfessionEnum.LAWYER', { ns: 'contractsEnums' })
      },
      {
        value: '00095',
        label: i18n.t('ProfessionEnum.PASTOR', { ns: 'contractsEnums' })
      },
      {
        value: '00726',
        label: i18n.t('ProfessionEnum.PILOT', { ns: 'contractsEnums' })
      },
      {
        value: '00072',
        label: i18n.t('ProfessionEnum.POLICE_OFFICER', { ns: 'contractsEnums' })
      },
      {
        value: '00075',
        label: i18n.t('ProfessionEnum.POST_OFFICE_OR_RAILWAY', { ns: 'contractsEnums' })
      },
      {
        value: '00052',
        label: i18n.t('ProfessionEnum.PRIVATE_TEACHER', { ns: 'contractsEnums' })
      },
      {
        value: '00051',
        label: i18n.t('ProfessionEnum.ACTOR', { ns: 'contractsEnums' })
      },
      {
        value: '00094',
        label: i18n.t('ProfessionEnum.PUPIL', { ns: 'contractsEnums' })
      },
      {
        value: '00021',
        label: i18n.t('ProfessionEnum.SELF_EMPLOYED_CRAFTSMEN', { ns: 'contractsEnums' })
      },
      {
        value: '00039',
        label: i18n.t('ProfessionEnum.OTHER_SELF_EMPLOYED_TRADER', { ns: 'contractsEnums' })
      },
      {
        value: '00049',
        label: i18n.t('ProfessionEnum.OTHER_SELF_EMPLOYED', { ns: 'contractsEnums' })
      },
      {
        value: '00099',
        label: i18n.t('ProfessionEnum.OTHER', { ns: 'contractsEnums' })
      }
    ]
  },

  productGroup: {
    list: [
      {
        value: 'PFL',
        label: i18n.t('productGroup.pfl', { ns: 'contract' })
      },
      {
        value: 'ZHN',
        label: i18n.t('productGroup.zhn', { ns: 'contract' })
      },
      {
        value: 'BAS',
        label: i18n.t('productGroup.bas', { ns: 'contract' })
      },
      {
        value: 'KMP',
        label: i18n.t('productGroup.kmp', { ns: 'contract' })
      },
      {
        value: 'KHT',
        label: i18n.t('productGroup.kht', { ns: 'contract' })
      },
      {
        value: 'STR',
        label: i18n.t('productGroup.str', { ns: 'contract' })
      },
      {
        value: 'TGG',
        label: i18n.t('productGroup.tgg', { ns: 'contract' })
      },
      {
        value: 'AUS',
        label: i18n.t('productGroup.aus', { ns: 'contract' })
      },
      {
        value: 'SON',
        label: i18n.t('productGroup.son', { ns: 'contract' })
      },
      {
        value: 'AMB',
        label: i18n.t('productGroup.amb', { ns: 'contract' })
      },
      {
        value: 'EB',
        label: i18n.t('productGroup.eb', { ns: 'contract' })
      }
    ]
  },

  federalState: {
    list: [
      {
        value: 'BB',
        label: 'BB'
        // BB -> Brandenburg
      },
      {
        value: 'BD',
        label: 'BD'
        // BD -> Baden-Württemberg
      },
      {
        value: 'BL',
        label: 'BL'
        // BL -> Bavaria (Bayern)
      },
      {
        value: 'BW',
        label: 'BW'
        // BW -> Berlin
      },
      {
        value: 'BY',
        label: 'BY'
        // BY -> Bavaria (Bayern)
      },
      {
        value: 'HB',
        label: 'HB'
        // HB -> Bremen
      },
      {
        value: 'HH',
        label: 'HH'
        // HH -> Hamburg
      },
      {
        value: 'HS',
        label: 'HS'
        // HS -> Hesse (Hessen)
      },
      {
        value: 'MV',
        label: 'MV'
        // MV -> Mecklenburg-Vorpommern
      },
      {
        value: 'ND',
        label: 'ND'
        // ND -> Lower Saxony (Niedersachsen)
      },
      {
        value: 'NW',
        label: 'NW'
        // NW -> North Rhine-Westphalia (Nordrhein-Westfalen)
      },
      {
        value: 'RP',
        label: 'RP'
        // RP -> Rhineland-Palatinate (Rheinland-Pfalz)
      },
      {
        value: 'SA',
        label: 'SA'
        // SA -> Saxony (Sachsen)
      },
      {
        value: 'SC',
        label: 'SC'
        // SC -> Saarland
      },
      {
        value: 'SH',
        label: 'SH'
        // SH -> Schleswig-Holstein
      },
      {
        value: 'SL',
        label: 'SL'
        // SL -> Saxony-Anhalt (Sachsen-Anhalt)
      },
      {
        value: 'TH',
        label: 'TH'
        // TH -> Thuringia (Thüringen)
      }
    ]
  },

  organizationUnit: {
    list: [
      {
        value: 'N',
        label: 'N'
      },
      {
        value: 'WI',
        label: 'WI'
      },
      {
        value: 'S/W',
        label: 'S/W'
      },
      {
        value: 'H/O',
        label: 'H/O'
      },
      {
        value: 'L',
        label: 'L'
      },
      {
        value: 'HB',
        label: 'HB'
      },
      {
        value: 'KI',
        label: 'KI'
      },
      {
        value: 'OL',
        label: 'OL'
      },
      {
        value: 'HV',
        label: 'HV'
      },
      {
        value: 'LG',
        label: 'LG'
      },
      {
        value: 'HH',
        label: 'HH'
      },
      {
        value: 'S/O',
        label: 'S/O'
      },
      {
        value: 'HVS',
        label: 'HVS'
      },
      {
        value: 'M/O',
        label: 'M/O'
      },
      {
        value: 'WÜ',
        label: 'WÜ'
      },
      {
        value: 'H/W',
        label: 'H/W'
      },
      {
        value: 'D',
        label: 'D'
      },
      {
        value: 'M/W',
        label: 'M/W'
      },
      {
        value: 'MS',
        label: 'MS'
      },
      {
        value: 'B',
        label: 'B'
      },
      {
        value: 'KS',
        label: 'KS'
      }
    ]
  },

  breYears: {
    list: [
      {
        value: '2016',
        label: '2016'
      },
      {
        value: '2017',
        label: '2017'
      },
      {
        value: '2018',
        label: '2018'
      },
      {
        value: '2019',
        label: '2019'
      },
      {
        value: '2020',
        label: '2020'
      },
      {
        value: '2021',
        label: '2021'
      },
      {
        value: '2022',
        label: '2022'
      },
      {
        value: '2023',
        label: '2023'
      }
    ]
  },

  editReason: {
    list: [
      {
        value: 'Ablehnung eines Neu- oder Bestandsantrages',
        label: 'Ablehnung eines Neu- oder Bestandsantrages'
      },
      {
        value: 'Antrag auf Beginnverlegung',
        label: 'Antrag auf Beginnverlegung'
      },
      {
        value: 'Antrag auf Kranken- und Pflegeversicherung',
        label: 'Antrag auf Kranken- und Pflegeversicherung'
      },
      {
        value: 'Antrag auf Krankenversicherung',
        label: 'Antrag auf Krankenversicherung'
      },
      {
        value: 'Antrag auf Pflegeversicherung',
        label: 'Antrag auf Pflegeversicherung'
      },
      {
        value: 'Antrag auf Vertragszusammenlegung',
        label: 'Antrag auf Vertragszusammenlegung'
      },
      {
        value: 'Anpassung der Tarife wegen Änderung der Beihilfe',
        label: 'Anpassung der Tarife wegen Änderung der Beihilfe'
      },
      {
        value: 'Aufhebung ab Beginn',
        label: 'Aufhebung ab Beginn'
      },
      {
        value: 'Aufhebung eines Leistungsausschlusses',
        label: 'Aufhebung eines Leistungsausschlusses'
      },
      {
        value: 'Aufhebung eines Wagniszuschlages',
        label: 'Aufhebung eines Wagniszuschlusses'
      },
      {
        value: 'Aufhebung der Beitragsbegrenzung für Ehepartner',
        label: 'Aufhebung der Beitragsbegrenzung für Ehepartner'
      },
      {
        value: 'Aufhebung der Krankentagegeldversicherung',
        label: 'Aufhebung der Krankentagegeldversicherung'
      },
      {
        value: 'Aufhebung des Versicherungsvertrages',
        label: 'Aufhebung des Versicherungsvertrages'
      },
      {
        value: 'Aufhebung wegen Nichtzahlung des Erstbeitrages',
        label: 'Aufhebung wegen Nichtzahlung des Erstbeitrages'
      },
      {
        value: 'Aufhebung wegen Widerruf',
        label: 'Aufhebung wegen Widerruf'
      },
      {
        value: 'Beitragsänderung',
        label: 'Beitragsänderung'
      },
      {
        value: 'Beginn der Anwartschaftsversicherung',
        label: 'Beginn der Anwartschaftsversicherung'
      },
      {
        value: 'Beginn der Ruhensversicherung',
        label: 'Beginn der Ruhensversicherung'
      },
      {
        value: 'Berichtigungsantrag',
        label: 'Berichtigungsantrag'
      },
      {
        value: 'Ende der Anwartschaftsversicherung',
        label: 'Ende der Anwartschaftsversicherung'
      },
      {
        value: 'Ende der Ruhensversicherung',
        label: 'Ende der Ruhensversicherung'
      },
      {
        value: 'Ende des Versicherungsverhältnisses wegen Auslandsverzugs',
        label: 'Ende des Versicherungsverhältnisses wegen Auslandsverzugs'
      },
      {
        value: 'Ende des Versicherungsverhältnisses wegen Tod',
        label: 'Ende des Versicherungsverhältnisses wegen Tod'
      },
      {
        value: 'Ihre Kündigung des Versicherungsvertrages',
        label: 'Ihre Kündigung des Versicherungsvertrages'
      },
      {
        value: 'Ihre Teilkündigung des Versicherungsvertrages',
        label: 'Ihre Teilkündigung des Versicherungsvertrages'
      },
      {
        value: 'Korrektur des Geburtsdatums',
        label: 'Korrektur des Geburtsdatums'
      },
      {
        value: 'Korrektur des vorherigen Versicherungsscheines',
        label: 'Korrektur des vorherigen Versicherungsscheines'
      },
      {
        value: 'Kündigung der Pflegeversicherung gemäß § 14/2 AVB',
        label: 'Kündigung der Pflegeversicherung gemäß § 14/2 AVB'
      },
      {
        value: 'Kündigung des Versicherungsvertrages',
        label: 'Kündigung des Versicherungsvertrages'
      },
      {
        value: 'Kündigung wegen Beitragsänderung',
        label: 'Kündigung wegen Beitragsänderung'
      },
      {
        value: 'Kündigung wegen Versicherungspflicht',
        label: 'Kündigung wegen Versicherungspflicht'
      },
      {
        value: 'Nachmeldung ab Geburt',
        label: 'Nachmeldung ab Geburt'
      },
      {
        value: 'Namen/Adressänderung',
        label: 'Namen/Adressänderung'
      },
      {
        value: 'Neuer Versicherungsnehmer',
        label: 'Neuer Versicherungsnehmer'
      },
      {
        value: 'ordentliche Kündigung des Versicherungsvertrages',
        label: 'ordentliche Kündigung des Versicherungsvertrages'
      },
      {
        value: 'ordentliche Teilkündigung des Versicherungsvertrages',
        label: 'ordentliche Teilkündigung des Versicherungsvertrages'
      },
      {
        value: 'Reduzierung des Krankentagegeldes',
        label: 'Reduzierung des Krankentagegeldes'
      },
      {
        value: 'Reduzierung eines Wagniszuschlages',
        label: 'Reduzierung eines Wagniszuschlages'
      },
      {
        value: 'sonstige Änderung',
        label: 'sonstige Änderung'
      },
      {
        value: 'Teilaufhebung des Versicherungsvertrages',
        label: 'Teilaufhebung des Versicherungsvertrages'
      },
      {
        value: 'Teilkündigung wegen Beitragsänderung',
        label: 'Teilkündigung wegen Beitragsänderung'
      },
      {
        value: 'Technische Änderung',
        label: 'Technische Änderung'
      },
      {
        value: 'Vereinbarung eines Wagniszuschlages',
        label: 'Vereinbarung eines Wagniszuschlages'
      },
      {
        value: 'Vertragstrennung',
        label: 'Vertragstrennung'
      },
      {
        value: 'Vertragsbeendigung wegen Kündigung aus wichtigem Grund',
        label: 'Vertragsbeendigung wegen Kündigung aus wichtigem Grund'
      },
      {
        value: 'Weiterführung des Versicherungsvertrages',
        label: 'Weiterführung des Versicherungsvertrages'
      },
      {
        value: 'Wiederinkraftsetzung',
        label: 'Wiederinkraftsetzung'
      },
      {
        value: 'Zahlungsmodalitäten geändert',
        label: 'Zahlungsmodalitäten geändert'
      },
      {
        value: 'Zuordnung zu einer anderen Bezirksdirektion - neue Vertragsnummer',
        label: 'Zuordnung zu einer anderen Bezirksdirektion - neue Vertragsnummer'
      },
      {
        value: 'Änderung AGB (Druck)',
        label: 'Änderung AGB (Druck)'
      },
      {
        value: 'Änderung der Beitragsrückerstattung bei kostenbewusstem Verhalten',
        label: 'Änderung der Beitragsrückerstattung bei kostenbewusstem Verhalten'
      },
      {
        value: 'Änderungsantrag',
        label: 'Änderungsantrag'
      }
    ]
  },

  inkasotype: {
    list: [
      {
        value: InkassoTypeEnum.EINZUG,
        label: 'Einzug'
      },
      {
        value: InkassoTypeEnum.RECHNUNG,
        label: 'Rechnung'
      },
      {
        value: InkassoTypeEnum.UBERWEISUNG,
        label: 'Überweisung'
      }
    ]
  },

  boolean: {
    list: [
      {
        value: BooleanEnum.TRUE,
        label: i18n.t('filter.yes', { ns: 'contract' })
      },
      {
        value: BooleanEnum.FALSE,
        label: i18n.t('filter.no', { ns: 'contract' })
      }
    ]
  } as FilterModel
};
