import { Sortable } from 'hooks/useSort';

export const resolveSortingObject = (
  sort: Sortable
): Array<{
  field?: string;
  order?: 'ASCENDING' | 'DESCENDING';
}> => {
  return [
    {
      field: sort.field || '',
      order: sort.order
    }
  ];
};
