/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Article } from '../models/Article';
import type { News } from '../models/News';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class NewsService {

    /**
     * Get all news
     * @returns News Successfully retrieved all news
     * @throws ApiError
     */
    public static getNews(): CancelablePromise<News> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/news',
            errors: {
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

    /**
     * Get artcicle by id
     * @returns Article Successfully retrieved all articles
     * @throws ApiError
     */
    public static getArticle({
        id,
    }: {
        /**
         * News unique identifier
         */
        id: number,
    }): CancelablePromise<Article> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/news/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found - The policy was not found`,
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

    /**
     * Get all news as RSS
     * @returns string Successfully retrieved all news
     * @throws ApiError
     */
    public static getRssNews({
        clientType,
        month,
    }: {
        /**
         * Parameters indicates who calls the RSS endpoint.
         */
        clientType?: 'MOBILE_APP' | 'PORTAL',
        /**
         * Parameters indicates for which month the RSS is returned.
         */
        month?: number,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/news/rss',
            query: {
                'clientType': clientType,
                'month': month,
            },
            errors: {
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

    /**
     * Get article  as RSS
     * @returns string Successfully retrieved all news
     * @throws ApiError
     */
    public static getRssArticle({
        id,
    }: {
        /**
         * News unique identifier
         */
        id: number,
    }): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/public/article/rss/{id}',
            path: {
                'id': id,
            },
            errors: {
                500: `Internal server error - unexpected error occured`,
            },
        });
    }

}
