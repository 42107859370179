import { forwardRef } from 'react';

import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { AdditionalContractInfoProps } from '../../../../models/view360models';

import { formatGermanDate } from '../../../../utilities/dates';
import { formatPrice } from '../../../../utilities/string';
import { optionalValue } from '../../utils/formatters';
import { AccordionHandle, SectionAccordion } from '../SectionAccordion';
import { SimpleTable } from 'components/SimpleTable';

export const AdditionalContractInfo = forwardRef<AccordionHandle, AdditionalContractInfoProps>(
  (
    {
      reasonForEdit,
      lastUpdated,
      startDate,
      applicationDate,
      sumTotal,
      amountKV,
      amountPPV,
      amountBRE,
      amountSTABZ,
      amountWithoutRZUBRE,
      specialConditions,
      specialConditionsExplanation
    },
    ref
  ) => {
    const { t } = useTranslation('view360', { keyPrefix: 'additionalContractInfo' });

    return (
      <SectionAccordion
        header={<Typography variant="bodyMDBold">{t(`title`)}</Typography>}
        ref={ref}
      >
        <div className="row reset-container m-t-24 m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('contractDetails')}
              rows={[
                {
                  label: t('attributes.reasonForEdit'),
                  value: optionalValue(reasonForEdit)
                },
                {
                  label: t('attributes.lastUpdated'),
                  value: optionalValue(lastUpdated, formatGermanDate)
                }
              ]}
            />
          </div>
          <div className="col-6">
            <SimpleTable
              title={t('insurancePeriod')}
              rows={[
                {
                  label: t('attributes.startDate'),
                  value: optionalValue(startDate, formatGermanDate)
                },
                {
                  label: t('attributes.applicationDate'),
                  value: optionalValue(applicationDate, formatGermanDate)
                }
              ]}
            />
          </div>
        </div>
        <div className="row reset-container n-t-32 m-b-24">
          <div className="col-6">
            <SimpleTable
              title={t('sums')}
              rows={[
                {
                  label: t('attributes.sumTotal'),
                  value: optionalValue(sumTotal, formatPrice)
                },
                {
                  label: t('attributes.amountKV'),
                  value: optionalValue(amountKV, formatPrice)
                },
                {
                  label: t('attributes.amountPPV'),
                  value: optionalValue(amountPPV, formatPrice)
                },
                {
                  label: t('attributes.amountBRE'),
                  value: optionalValue(amountBRE, formatPrice)
                },
                {
                  label: t('attributes.amountSTABZ'),
                  value: optionalValue(amountSTABZ, formatPrice)
                },
                {
                  label: t('attributes.amountWithoutRZUBRE'),
                  value: optionalValue(amountWithoutRZUBRE, formatPrice)
                }
              ]}
            />
          </div>
          <div className="col-6">
            <SimpleTable
              title={t('termsOfContract')}
              rows={[
                {
                  label: t('attributes.specialConditions'),
                  value: optionalValue(specialConditions)
                }
              ]}
            />
            <div className="m-t-6">
              <Typography>{specialConditionsExplanation}</Typography>
            </div>
          </div>
        </div>
      </SectionAccordion>
    );
  }
);
