import { useTranslation } from 'react-i18next';

import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import { PolicyHolderDetailProps } from '../../../../models/view360models';

import { optionalValue } from '../../utils/formatters';
import { SimpleTable } from 'components/SimpleTable';

function ReleasePictogram() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.6 21.5L5.7 18.3L2.1 17.5L2.45 13.8L0 11L2.45 8.2L2.1 4.5L5.7 3.7L7.6 0.5L11 1.95L14.4 0.5L16.3 3.7L19.9 4.5L19.55 8.2L22 11L19.55 13.8L19.9 17.5L16.3 18.3L14.4 21.5L11 20.05L7.6 21.5ZM9.95 14.55L15.6 8.9L14.2 7.45L9.95 11.7L7.8 9.6L6.4 11L9.95 14.55Z"
        fill="#00ACA9"
      />
    </svg>
  );
}

export const PolicyHolderSummary = ({
  name = '',
  phone = '',
  email = '',
  address = ''
}: PolicyHolderDetailProps) => {
  const { t } = useTranslation('view360');

  return (
    <div className="flex space-x-[0px]">
      <div className="flex-none w-14">
        <Avatar className="bg-surface-60">
          <ReleasePictogram />
        </Avatar>
      </div>

      <div className="flex-1">
        <div className="m-b-8 text-neutral-600">
          <Typography>{t('policyHolder.role')}</Typography>
        </div>
        <div translate="no" className="mb-[16px]">
          <Typography variant="bodyMDBold">{name}</Typography>
        </div>
        <SimpleTable
          rows={[
            {
              label: t('person.attributes.phone'),
              value: optionalValue(phone)
            },
            {
              label: t('person.attributes.email'),
              value: optionalValue(email)
            },
            {
              label: t('person.attributes.address'),
              value: optionalValue(address)
            }
          ]}
        />
      </div>
    </div>
  );
};
