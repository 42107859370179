import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

export const EntitlementCell = ({
  isEnabled,
  hideCell = false
}: {
  isEnabled?: boolean;
  hideCell?: boolean;
}) => {
  const { t } = useTranslation('wizardTariffs');

  if (hideCell) return <></>;

  return (
    <Typography variant="bodySMRegular" className="text-text-60">
      {isEnabled ? t('utils.yes') : t('utils.no')}
    </Typography>
  );
  // If you need to use isEnabled to conditionally apply something, you would incorporate it here.
};
