/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum FormType {
    ANTRAGE = 'ANTRAGE',
    AVB = 'AVB',
    MUSTER = 'MUSTER',
    KOMBIDRUCKSTUCKE = 'KOMBIDRUCKSTUCKE',
    SONSTIGES = 'SONSTIGES',
    TARIFE = 'TARIFE',
    UBERSICHT = 'UBERSICHT',
    ZUSATZANTRAGE = 'ZUSATZANTRAGE',
    BERATUNGSHILFEN_KV = 'BERATUNGSHILFEN_KV',
    SELBSTAUSKUNFTE = 'SELBSTAUSKUNFTE',
    SONSTIGE_RICHTLINIEN = 'SONSTIGE_RICHTLINIEN',
    PROSPEKTE = 'PROSPEKTE',
}
