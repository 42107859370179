import Typography from '@mui/material/Typography';

export const Product = ({
  product,
  isSummaryRow = false
}: {
  product?: string;
  isSummaryRow?: boolean;
}) => {
  return (
    <Typography variant={isSummaryRow ? 'bodySMBold' : 'bodySMRegular'} className="text-text-80">
      {product}
    </Typography>
  );
};
