import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { Dialog, DialogContent } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';

import { AgentsSearch } from '../AgentsSearch';
import { SelectedAgent } from '../AgentsSearch/AgentsSearch';

type AgentPickerDialogProps = {
  isDialogOpen: boolean;
  translations?: {
    title?: string;
    description?: string;
  };
  handleAgentSelection: (agentData: {
    agentId: string;
    name: string;
    hasServiceLKHInternalRole: boolean;
    agentPickerEnabled: boolean;
  }) => Promise<void>;
  onDialogClose: () => void;
};

export const AgentPickerDialog = ({
  translations,
  isDialogOpen,
  onDialogClose,
  handleAgentSelection
}: AgentPickerDialogProps) => {
  const [agent, setAgent] = useState<SelectedAgent | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('wizardPrivateHealthInsurance', {
    keyPrefix: 'navigation.agentPicker.agentPickerDialog'
  });

  const selectAgent = async () => {
    setIsLoading(true);
    if (!agent) return;
    try {
      await handleAgentSelection({
        agentId: agent.id.trim(),
        name: `${agent.firstname || ''} ${agent.lastname || ''}`.trim(),
        hasServiceLKHInternalRole: true,
        agentPickerEnabled: true
      });
      onDialogClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      PaperProps={{ maxwidth: 399 }}
      open={isDialogOpen}
      onClose={onDialogClose}
      disableScrollLock
    >
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex flex-col mb-[32px]">
          <div className="flex justify-center mb-[4px]">
            <Avatar className="bg-surface-60">
              <HowToRegOutlinedIcon className="fill-text-100" />
            </Avatar>
          </div>
          <div>
            <div className="flex justify-center text-base font-bold leading-6 text-text-100">
              {translations?.title || t('default.title')}
            </div>
            <DialogContentText>
              <span className="flex text-sm font-normal leading-5 text-text-60 mt-[4px]">
                {translations?.description || t('default.description')}
              </span>
            </DialogContentText>
          </div>
        </div>
        {isLoading && (
          <div className="flex flex-col mb-[8px]">
            <div className="flex justify-center mb-[32px]">
              <CircularProgress color="primary" size={24} />
            </div>
            <div>
              <DialogContentText>
                <span className="flex text-sm font-normal leading-5 text-text-60">
                  {t('loading.description')}
                </span>
              </DialogContentText>
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="flex justify-start">
            <AgentsSearch
              agent={agent}
              onAgentChange={(agent) => {
                setAgent(agent);
              }}
            />
          </div>
        )}
        <div className="flex justify-center mt-[32px]">
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={selectAgent}
            disabled={!agent}
          >
            {t('default.confirm')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
