import { ValueGetterParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';

import { List, ListItem } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ColumnIds, VALUE_GETTERS } from '../../pages/Contracts/components/Grid/models';
import { Application, PersonRoleEnum } from 'models/extension-generated';
import { Policy } from 'models/portal-generated';

export const Reminder = () => {
  const { t } = useTranslation('contract');
  return (
    <div>
      <Typography variant="bodySMRegular" className="text-white-100">
        {t('hasReminder')}
      </Typography>
    </div>
  );
};

export const Address = (data: Policy | undefined) => {
  const { t } = useTranslation('contract');
  const addressGetter = VALUE_GETTERS[ColumnIds.Address];
  const address = addressGetter?.({ data: data } as ValueGetterParams);

  if (address.text) {
    return (
      <div>
        <Typography variant="bodySMRegular" className="text-white-100">
          {t('table.address')}
        </Typography>
        <div className="mt-[12px]">
          <Typography variant="bodySMRegular" className="text-white-100">
            {address.text}
          </Typography>
        </div>
      </div>
    );
  }
  return <></>;
};

export const InsuredPartners = (data: Policy | undefined) => {
  const { t } = useTranslation('contract');
  return (
    <div>
      <Typography variant="bodySMRegular" className="text-white-100">
        {t('table.insuredPersons')}
      </Typography>
      <List
        sx={{
          listStyleType: 'disc',
          pl: 2,
          '& .MuiListItem-root': {
            display: 'list-item',
            p: 0.5
          }
        }}
        disablePadding
      >
        {data?.insuredPartners?.map((partner) => {
          return (
            <ListItem
              className="text-white-100 text-xs"
              key={`insured-partner-${partner.partner?.id}`}
            >
              <Typography variant="bodySMRegular">{`${partner.partner?.lastname}, ${partner.partner?.firstname}`}</Typography>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export const InsuredPartnersApplication = (data: Application | undefined) => {
  const { t } = useTranslation('dashboard');

  const getPartners = () => {
    return data?.partners
      ?.filter(({ roles }) => roles.includes(PersonRoleEnum.INSURED_PERSON))
      .map((partner) => {
        return (
          <ListItem key={partner.id} className="text-white-100 text-[12px]">
            <Typography variant="bodySMRegular">{`${partner.lastname}, ${partner.firstname}`}</Typography>
          </ListItem>
        );
      });
  };

  return (
    <div>
      <Typography variant="bodySMRegular" className="text-white-100">
        {t('table.insuredPersons.long')}
      </Typography>
      {data?.partners && (
        <List
          disablePadding
          sx={{
            listStyleType: 'disc',
            pl: 2,
            '& .MuiListItem-root': {
              display: 'list-item',
              p: 0.5
            }
          }}
        >
          {getPartners()}
        </List>
      )}
    </div>
  );
};
