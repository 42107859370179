export const config = {
  realm: 'master',
  'ssl-required': 'external',
  resource: 'testClient ',
  'public-client': true,
  'confidential-port': 0,
  onLoad: 'login-required'
};

export const queryConfig = {
  oneMinuteCache: {
    gcTime: 1000 * 60,
    staleTime: 1000 * 60
  },
  oneHourCache: {
    gcTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60
  }
};
