import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, CircularProgress } from '@mui/material';

type DigitalSignatureProps = {
  onSignDigitallyClick?: () => void;
};

export const DigitalSignature = ({ onSignDigitallyClick }: DigitalSignatureProps) => {
  const { t } = useTranslation('view360');
  const [isLoading, setIsLoading] = useState(false);

  const handleStartDigitalSignature = async () => {
    setIsLoading(true);
    try {
      await onSignDigitallyClick?.();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      variant="outlined"
      onClick={handleStartDigitalSignature}
      disabled={isLoading}
      endIcon={isLoading ? <CircularProgress color="inherit" size={16} /> : null}
    >
      {t('signature.digital.button.title')}
    </Button>
  );
};
