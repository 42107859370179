import intersection from 'lodash/intersection';

import { WizardRoutesEnum } from 'pages/PrivateHealthInsurance/constants';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';
import { getSchemaByScreen } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation/schemas';

export const useHealthQuestionDiagnosesErrors = ({
  healthQuestionId
}: {
  healthQuestionId: string;
}): Array<string> => {
  const { state, partnerId } = useHealthInsuranceContext();

  const validationResults = Array.from(state.validationResults.keys());
  const stepFields = getSchemaByScreen(
    WizardRoutesEnum.HealthQuestions,
    state.model,
    partnerId
  ).filter((path) => path.includes('details') && path.includes(healthQuestionId));
  return intersection(validationResults, stepFields);
};
