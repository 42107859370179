import { useKeycloak } from '@react-keycloak/web';

type UseUserReturnType = {
  agentIds: Array<string>;
};

export const useUser = (): UseUserReturnType => {
  const { keycloak } = useKeycloak();

  const tokenParsed = keycloak.tokenParsed || {};
  const agentIds = typeof tokenParsed.agentId === 'string' ? tokenParsed.agentId.split(',') : [];

  return {
    agentIds
  };
};
