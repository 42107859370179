import { formModelGet } from 'lkh-portal-ui-library';
import { applicationField } from 'models';

import { SelectedAgent } from 'pages/PrivateHealthInsurance/components/AgentsSearch/AgentsSearch';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

export const useAgentPickerData = (): [
  SelectedAgent | null,
  (agent: SelectedAgent | null) => void
] => {
  const { state, reducer } = useHealthInsuranceContext();

  const agentIdPath = applicationField('agentId');
  const agentIdValue: string | null = formModelGet(state.model, agentIdPath);
  const selectedAgentId: SelectedAgent | null = agentIdValue
    ? {
        id: agentIdValue
      }
    : null;

  const setAgent = (agent: SelectedAgent | null) => {
    reducer.updateValue({
      key: agentIdPath,
      value: agent?.id
    });
  };

  return [selectedAgentId, setAgent];
};
