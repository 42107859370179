import { v4 } from 'uuid';

import { useTranslation } from 'react-i18next';

import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { PersonRoleEnum } from 'models/extension-generated';

import { WizardRoutesEnum } from '../../constants';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { useWizardNavigation } from '../../hooks/useWizardNavigation';
import { useApplicationValidation } from 'pages/PrivateHealthInsurance/hooks/useApplicationValidation';

export const AddPartnerButton = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { reducer, selectPartner, state, partnerId } = useHealthInsuranceContext();
  const { isValidating, validateMinimalPartner } = useApplicationValidation(
    state,
    reducer,
    partnerId
  );
  const { goTo } = useWizardNavigation();
  const { createPartner } = reducer;

  const handleAddInsuredPerson = async () => {
    try {
      const validationResult = await validateMinimalPartner(partnerId);

      if (validationResult.size === 0) {
        const id = v4();

        createPartner({ role: PersonRoleEnum.INSURED_PERSON, id });
        selectPartner(id);
        goTo(WizardRoutesEnum.PrivateData, state.model.id);
      }
    } catch (error) {
      // TODO: handle error
    }
  };

  return (
    <Button
      color="primary"
      variant="text"
      size="large"
      startIcon={
        isValidating ? (
          <CircularProgress color="inherit" size={16} />
        ) : (
          <PersonAddAlt1OutlinedIcon className="w-[24px] h-[24px]" />
        )
      }
      onClick={handleAddInsuredPerson}
      disabled={isValidating}
    >
      {isValidating ? t('actions.submitting') : t('actions.addInsuredPerson')}
    </Button>
  );
};
