import { PropsWithChildren, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
  TextFieldProps,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { DarkTooltip, Form } from 'lkh-portal-ui-library';
import { partnerField, RecursiveKeyOf } from 'models';
import { HealthQuestion, Partner } from 'models/extension-generated';

import { HealthQuestionDetailJoined } from './DiagnosesModal';
import { Errors } from './diagnosisReducer';
import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

const Title = (
  props: PropsWithChildren & {
    className?: string;
  }
) => {
  return (
    <Typography
      fontSize="14px"
      lineHeight="20px"
      className={`text-text-80 font-bold ${props.className}`}
    >
      {props.children}
    </Typography>
  );
};

export type DetailValue =
  | string
  | boolean
  | Array<string>
  | undefined
  | null
  | { id: string; order: string };

const getDiagnosisKey: ({
  partnerId,
  healthQuestionId,
  diagnosisId,
  property
}: {
  partnerId: string;
  healthQuestionId: string;
  diagnosisId: string;
  property: keyof HealthQuestionDetailJoined;
}) => string = ({ partnerId, healthQuestionId, diagnosisId, property }) => {
  const fieldPath =
    `applicationInformation.health[${healthQuestionId}].details[${diagnosisId}].${property}` as RecursiveKeyOf<Partner>;
  return partnerField(partnerId, fieldPath);
};

export const DiagnosisForm = (props: {
  yesQuestionsOptions: Array<{ label: string; value: string }>;
  error: Errors;
  healthQuestions: HealthQuestion[];
  diagnosis?: HealthQuestionDetailJoined;
  onChange: (value: DetailValue, key: keyof HealthQuestionDetailJoined) => void;
}) => {
  const { partnerId } = useHealthInsuranceContext();
  const { diagnosis } = props;
  const healthQuestionLabel = props.yesQuestionsOptions.find(
    (q) => q.value === props.diagnosis?.question?.id
  )?.label;

  const diagnosisId = diagnosis?.id || '';
  const healthQuestionId = diagnosis?.question?.id || '';
  const diagnosisKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'diagnosis'
  });
  const doctorKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'doctor'
  });
  const sickLeaveDurationKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'sickLeaveDuration'
  });
  const treatmentStartKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'treatmentStart'
  });
  const treatmentEndKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'treatmentEnd'
  });
  const hasOperationKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'hasOperation'
  });
  const hasConsequencesKey = getDiagnosisKey({
    diagnosisId,
    healthQuestionId,
    partnerId,
    property: 'hasConsenquences'
  });

  const { hasError: diagnosisError } = Form.hooks.useFormComponent(diagnosisKey);
  const { hasError: doctorError } = Form.hooks.useFormComponent(doctorKey);
  const { hasError: sickLeaveDurationError } = Form.hooks.useFormComponent(sickLeaveDurationKey);
  const { hasError: treatmentStartError } = Form.hooks.useFormComponent(treatmentStartKey);
  const { hasError: treatmentEndError } = Form.hooks.useFormComponent(treatmentEndKey);
  const { hasError: hasOperationError } = Form.hooks.useFormComponent(hasOperationKey);
  const { hasError: hasConsequencesError } = Form.hooks.useFormComponent(hasConsequencesKey);

  const { t } = useTranslation('wizardHealth', {
    keyPrefix: 'section.diagnosis'
  });

  const handleOnChange = (key: keyof HealthQuestionDetailJoined, value: DetailValue) => {
    props.onChange(value, key);
  };

  const commonTextFieldProps = useMemo<TextFieldProps>(
    () => ({
      rows: 5,
      multiline: true,
      placeholder: t('attributes.placeholder'),
      label: t('attributes.answer'),
      fullWidth: true,
      FormHelperTextProps: {
        className: 'text-right mt-[-20px]'
      }
    }),
    []
  );

  return (
    <div className="space-y-[16px]">
      <div className="grid grid-cols-3 gap-[16px]">
        <div className="flex flex-col justify-between">
          <Title className="mb-[16px]">{t('attributes.diagnose')} </Title>
          <TextField
            {...commonTextFieldProps}
            error={props.error.get('diagnosis') || diagnosisError}
            value={props.diagnosis?.diagnosis}
            onChange={(event) => {
              const { value } = event.target;
              if (value.length > 200) return;

              handleOnChange('diagnosis', value);
            }}
            helperText={`${props?.diagnosis?.diagnosis?.length}/200`}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Title className="mb-[16px]">{t('attributes.doctor')} </Title>
          <TextField
            {...commonTextFieldProps}
            value={props.diagnosis?.doctor}
            error={props.error.get('doctor') || doctorError}
            helperText={`${props.diagnosis?.doctor?.length}/200`}
            onChange={(event) => {
              const { value } = event.target;
              if (value.length > 200) return;
              handleOnChange('doctor', value);
            }}
          />
        </div>
        <div className="flex flex-col justify-between">
          <Title className="mb-[16px]">{t('attributes.sickLeaveDuration')}</Title>
          <TextField
            {...commonTextFieldProps}
            value={props.diagnosis?.sickLeaveDuration}
            error={props.error.get('sickLeaveDuration') || sickLeaveDurationError}
            helperText={`${props.diagnosis?.sickLeaveDuration?.length}/200`}
            onChange={(event) => {
              const { value } = event.target;
              if (value.length > 200) return;
              handleOnChange('sickLeaveDuration', value);
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-[16px]">
        <div className="flex justify-between col-span-4">
          <Title>{t('attributes.treatmentDuration')} </Title>
        </div>
        <div className="flex justify-between col-span-8">
          <Title>{t('attributes.otherInformation')} </Title>
        </div>

        <div className="flex  items-center col-span-4">
          <div className="grid grid-cols-2 gap-[16px] py-[16px]">
            <DatePicker
              views={['year', 'month']}
              format="MM.yyyy"
              label={t('attributes.start')}
              value={
                props.diagnosis?.treatmentStart ? new Date(props.diagnosis.treatmentStart) : null
              }
              slotProps={{
                textField: {
                  error: props.error.get('treatmentStart') || treatmentStartError
                }
              }}
              onChange={(value, context) => {
                if (context.validationError) return;

                handleOnChange('treatmentStart', value ? value.toISOString() : undefined);
              }}
            />
            <DatePicker
              views={['year', 'month']}
              label={t('attributes.end')}
              format="MM.yyyy"
              value={props.diagnosis?.treatmentEnd ? new Date(props.diagnosis.treatmentEnd) : null}
              slotProps={{
                textField: {
                  error: props.error.get('treatmentEnd') || treatmentEndError
                }
              }}
              onChange={(value, context) => {
                if (context.validationError) return;

                handleOnChange('treatmentEnd', value ? value.toISOString() : undefined);
              }}
            />
          </div>
        </div>
        <div className="flex items-center col-span-8">
          <FormGroup className="grid grid-cols-3">
            <FormControl error={hasOperationError}>
              <FormControlLabel
                checked={props.diagnosis?.hasOperation}
                onChange={(_event, checked) => {
                  handleOnChange('hasOperation', checked);
                }}
                control={<Checkbox />}
                label={
                  <Typography
                    fontSize="14px"
                    lineHeight="20px"
                    className={classNames({
                      'text-text-80': !hasConsequencesError,
                      'text-danger-60': hasConsequencesError
                    })}
                  >
                    {t('attributes.operation')}
                  </Typography>
                }
              />
            </FormControl>

            <FormControl error={hasConsequencesError}>
              <FormControlLabel
                value={props.diagnosis?.hasConsenquences}
                checked={props.diagnosis?.hasConsenquences}
                onChange={(_event, checked) => {
                  handleOnChange('hasConsenquences', checked);
                }}
                control={<Checkbox />}
                label={
                  <Typography
                    fontSize="14px"
                    lineHeight="20px"
                    className={classNames({
                      'text-text-80': !hasConsequencesError,
                      'text-danger-60': hasConsequencesError
                    })}
                  >
                    {t('attributes.consenquences')}
                  </Typography>
                }
              />
            </FormControl>
          </FormGroup>
        </div>
      </div>

      {/* Health questions dropdown */}
      <div>
        <Title className="mb-[16px]">{t('attributes.selectRelatedQuestions')}</Title>
        <DarkTooltip
          title={
            healthQuestionLabel ? (
              <Typography fontSize="12px" lineHeight={'16px'} className="tracking-wide">
                {healthQuestionLabel}
              </Typography>
            ) : null
          }
        >
          <Autocomplete
            value={props.yesQuestionsOptions.find((q) => q.value === props.diagnosis?.question?.id)}
            blurOnSelect
            options={props.yesQuestionsOptions}
            renderInput={(params) => (
              <TextField
                {...params}
                error={props.error.get('question')}
                label={t('healthQuestions')}
              />
            )}
            data-testid="autocomplete"
            onChange={(_event, value) => {
              const selectedQuestion = value?.value;
              const question = props.healthQuestions.find((q) => q.id === selectedQuestion);

              handleOnChange('question', {
                id: question?.id || '',
                order: question?.order.toString() || '0'
              });
            }}
          />
        </DarkTooltip>
      </div>
    </div>
  );
};
