import { addMonths, format, setDate } from 'date-fns';
import moment from 'moment';

type DateInput = Date | number | string;

const ADULTHOOD_AGE = 18;

/**
 * `formatDateToGermanForm` is a function that converts a date string from the format separted by '/' to the German date format separated by dot.
 *
 * @param date - The date string to be converted. It should be in the format 'YYYY/MM/DD'.
 *
 * @returns The converted date string in the German date format 'YYYY.MM.DD'.
 *
 * @example
 * // returns '2022.12.31'
 * formatDateToGermanForm('2022/12/31');
 */
export const formatDateToGermanForm = (date: string): string => {
  return date.replaceAll('/', '.');
};

/**
 * `formatDateAndTime` is a function that formats a date and time into the format 'dd. MM. yyyy HH:mm'.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date and time string.
 *
 * @example
 * // returns '31. 12. 2022 10:30'
 * formatDateAndTime(new Date('2022-12-31T10:30:00Z'));
 */
export const formatDateAndTime = (date: DateInput): string => {
  return format(new Date(date), 'dd.MM.yyyy HH:mm');
};

/**
 * `formatShortDate` is a function that formats a date into the format 'dd.MM.yyyy'.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date string.
 */
export const formatShortDate = (date: DateInput): string => {
  if (!date) return '';
  const momentDate = moment(date).toISOString();
  return format(new Date(momentDate), 'dd.MM.yyyy');
};

/**
 * `formatDate` is a function that formats a date into the format 'yyyy/MM/dd'.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date string.
 */
export const formatDate = (date: DateInput): string => {
  if (!date) return '';
  const momentDate = moment(date).toISOString();
  return format(new Date(momentDate), 'yyyy/MM/dd');
};

/**
 * `formatGermanDate` is a function that formats a date into the German notation format 'dd.MM.yyyy'.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date string.
 */
export const formatGermanDate = (date: DateInput): string => {
  return format(new Date(date), 'dd.MM.yyyy');
};

/**
 * `formatMonthAndYear` is a function that formats a date into the format 'MMM yyyy'.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date string.
 */
export const formatMonthAndYear = (date: DateInput): string => {
  if (!date) return '';
  return format(new Date(date), 'MMM yyyy');
};

/**
 * `calculateAge` is a function that calculates the age of a person given their date of birth and an optional 'from' date.
 *
 * @param dateOfBirth - The date of birth of the person. It should be a string in a format recognized by the moment.js library (e.g., 'YYYY-MM-DD').
 * @param fromDate - An optional parameter specifying the date from which the age is calculated. If not provided, the current date is used. It should be a string in a format recognized by the moment.js library.
 *
 * @returns The age of the person in years. If the date of birth is not provided or is not a valid date, the function will return NaN.
 *
 * @example
 * // returns 22
 * calculateAge('2000-01-01', '2022-12-31');
 */
export function calculateAge(dateOfBirth?: string | null, fromDate?: string): number {
  const dateOfBirthObject = moment(dateOfBirth);

  let fromDateObject = moment();

  if (fromDate) {
    fromDateObject = moment(fromDate).endOf('year');
  }

  return fromDateObject.diff(dateOfBirthObject, 'years');
}

/**
 * `formatInput` is a function that formats a date into the format 'yyyy-MM-dd.
 *
 * @param date - The date to be formatted. It can be a Date object, a number (timestamp), or a string.
 *
 * @returns The formatted date string.
 */
export function formatInput(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

/**
 * Checks if a partner is an adult based on their date of birth.
 *
 * @param dateOfBirth - The partner's date of birth.
 * @param fromDate - The date from which to calculate the age. If not provided, the current date is used.
 * @returns Returns true if the partner is an adult, false otherwise.
 */
export function isAdult(dateOfBirth: string, fromDate?: string) {
  const partnerAge = calculateAge(dateOfBirth, fromDate);

  return partnerAge >= ADULTHOOD_AGE;
}

/**
 * Gets the first day of the next month.
 *
 * @returns Returns the first day of the next month in a string format.
 */
export function getFirstOfNextMonth(): string {
  const firstOfNextMonth = setDate(addMonths(new Date(), 1), 1);
  return formatInput(firstOfNextMonth);
}
