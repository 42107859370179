import { ActionFunction, ApplicationsState, SetAgentPayload } from '../types';

export const setAgent: ActionFunction<SetAgentPayload> = (state: ApplicationsState, payload) => {
  const { filters } = state;

  return {
    ...state,
    filters: {
      ...filters,
      agentId: payload?.agentId
    }
  };
};
