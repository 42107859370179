import { useEffect, useState } from 'react';

import { formModelGet } from 'lkh-portal-ui-library';
import { partnerField } from 'models';
import { NeedSituationEnum, Tariff, TariffService } from 'models/extension-generated';

import { useHealthInsuranceContext } from 'pages/PrivateHealthInsurance/context/HealthInsuranceContext';

type UseTariffQuestionReturnType = {
  isLoading: boolean;
  tariffs: Array<Tariff>;
};

export const useTariffQuery = (partnerId: string): UseTariffQuestionReturnType => {
  const [isLoading, setIsLoading] = useState(false);
  const [tariffs, setTariffs] = useState<Array<Tariff>>([]);

  const { state } = useHealthInsuranceContext();

  const federalStatePath = partnerField(partnerId, 'applicationInformation.federalState');
  const hasClaimSince2012Path = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.hasClaimSince2012'
  );
  const needSituationPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.needSituation'
  );
  const claimAmountPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.claimAmount'
  );
  const isParentInsuredInLKHPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.isParentInsuredInLKH'
  );
  const workingDoctorRelationshipPath = partnerField(
    partnerId,
    'applicationInformation.tariffInformation.workingDoctorRelationship'
  );

  const needSituation: NeedSituationEnum = formModelGet(state.model, needSituationPath);
  const federalState = formModelGet(state.model, federalStatePath);
  const hasClaimSince2012 = formModelGet(state.model, hasClaimSince2012Path);
  const claimAmount = formModelGet(state.model, claimAmountPath);
  const isParentInsuredInLKH = formModelGet(state.model, isParentInsuredInLKHPath);
  const workingDoctorRelationship = formModelGet(state.model, workingDoctorRelationshipPath);
  const availableStartDate = state.model.applicationStart;

  // Make search query on filters change
  useEffect(() => {
    const fetchTariffQuestionsFn = async () => {
      setIsLoading(true);

      try {
        const response = await TariffService.getTariffsByFilters({
          requestBody: {
            federalState,
            hasClaimSince2012,
            needSituation,
            claimAmount,
            isParentInsuredInLKH,
            doctorEmploymentType: workingDoctorRelationship
          }
        });
        setTariffs(response);
      } catch (error) {
        setTariffs([]);
      } finally {
        setIsLoading(false);
      }
    };

    availableStartDate && fetchTariffQuestionsFn();
  }, [
    federalState,
    needSituation,
    hasClaimSince2012,
    claimAmount,
    isParentInsuredInLKH,
    workingDoctorRelationship,
    availableStartDate
  ]);

  return {
    isLoading,
    tariffs
  };
};
