import { useQuery } from '@tanstack/react-query';

import { DropdownOption } from 'lkh-portal-ui-library/dist/components/MaterialSelect/MaterialSelect';
import { PolicyService } from 'models/portal-generated';

type UseTariffs = {
  tariffs: Array<DropdownOption>;
  isLoading: boolean;
};

const fetchTariffs = async (): Promise<Array<DropdownOption>> => {
  const response = await PolicyService.getPoliciesTariffs();

  return response.map((tariff) => {
    return {
      value: tariff,
      label: tariff
    };
  });
};

export const useTariffs = (): UseTariffs => {
  const { isLoading, data } = useQuery({
    queryKey: ['tariffs'],
    queryFn: fetchTariffs
  });

  return { isLoading, tariffs: data || [] };
};
