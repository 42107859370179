import { useTranslation } from 'react-i18next';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';

type IncompletePersonDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const IncompletePersonDialog = ({ isOpen, onClose }: IncompletePersonDialogProps) => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  return (
    <Dialog disableScrollLock fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogContent className="py-[24px] px-[32px]">
        <div className="flex">
          <ErrorOutlineOutlinedIcon className="text-danger-60 text-2xl" />
          <div className="pl-[16px]">
            <Typography variant="bodyLGBold" className="flex text-text-100">
              {t('actions.incompletePartnerDialog.title')}
            </Typography>
            <DialogContentText>
              <Typography variant="bodyMDRegular" className="flex text-text-80 mt-[4px] ">
                {t('actions.incompletePartnerDialog.description')}
              </Typography>
            </DialogContentText>
          </div>
        </div>
        <div className="flex gap-[16px] justify-end mt-[32px]">
          <Button size="large" variant="contained" color="primary" onClick={onClose}>
            {t('actions.incompletePartnerDialog.actionTitle')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
