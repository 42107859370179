import { PortalLayout } from 'layout/portal';

import { useKeycloak } from '@react-keycloak/web';
import { useTranslation } from 'react-i18next';

import { RealmRole } from 'models';

import { RowData, SimpleTable } from 'components/SimpleTable';
import useAgentData from 'hooks/useAgentData/useAgentData';

export const ProfilePage = () => {
  const { t } = useTranslation('profile');
  const { data } = useAgentData();
  const { keycloak } = useKeycloak();

  const contactList = data?.contactList?.at(0);

  const determineAgentRole = () => {
    if (keycloak.hasRealmRole(RealmRole.MASTER_AGENT)) return t('agentData.roleNames.masterAgent');
    if (keycloak.hasRealmRole(RealmRole.SUPPORT_AGENT))
      return t('agentData.roleNames.supportAgent');
    if (keycloak.hasRealmRole(RealmRole.SERVICE_LKH_INTERNAL))
      return t('agentData.roleNames.serviceLkhInternal');
    return '';
  };

  const personalInfo: RowData = [
    { label: t('personalOrCompanyData.firstName'), value: keycloak?.tokenParsed?.given_name || '' },
    {
      label: t('personalOrCompanyData.lastNameOrCompanyName'),
      value: keycloak?.tokenParsed?.family_name || ''
    }
  ];

  const addressInfo: RowData = [
    { label: t('addressData.street'), value: contactList?.street || '' },
    { label: t('addressData.houseNumber'), value: contactList?.houseNumber || '' },
    { label: t('addressData.postalCode'), value: contactList?.postalCode || '' },
    { label: t('addressData.city'), value: contactList?.city || '' },
    { label: t('addressData.country'), value: contactList?.country || '' }
  ];

  const agentData: RowData = [
    { label: t('agentData.role'), value: determineAgentRole() },
    { label: t('agentData.agentIds'), value: keycloak?.tokenParsed?.agentId || '' }
  ];

  const contactInfo: RowData = [
    { label: t('contactData.phone'), value: contactList?.phone || '' },
    { label: t('contactData.email'), value: contactList?.email || '' }
  ];

  return (
    <PortalLayout>
      <div className="px-s py-[13px]">
        <div className="grid grid-cols-1 m:grid-cols-2">
          <div className="p-[16px]">
            <SimpleTable title={t('personalOrCompanyData.title')} rows={personalInfo} />
          </div>
          <div className="p-[16px]">
            <SimpleTable title={t('agentData.title')} rows={agentData} />
          </div>
        </div>
        <div className="grid grid-cols-1 m:grid-cols-2">
          <div className="p-[16px]">
            <SimpleTable title={t('addressData.title')} rows={addressInfo} />
          </div>
          <div className="p-[16px]">
            <SimpleTable title={t('contactData.title')} rows={contactInfo} />
          </div>
        </div>
      </div>
    </PortalLayout>
  );
};
