import { useTranslation } from 'react-i18next';

import { Form, PartnerForm } from 'lkh-portal-ui-library';

import { WizardSlot } from '../../../../layout/wizard/slots';
import { GeneralQuestionsPictogram } from '../../components/pictograms';
import { StepHeading } from '../../components/StepHeading';
import { useHealthInsuranceContext } from '../../context/HealthInsuranceContext';
import { GeneralQuestions } from './GeneralQuestions/components/GeneralQuestions';
import { useConfigContext } from 'contexts/ConfigContext';
import { useApplicationHelpers } from 'hooks/useApplicationHelpers/useApplicationHelpers';

export const GeneralDataStep = () => {
  const { t } = useTranslation('wizardPrivateHealthInsurance');
  const { state, dispatch, partnerId } = useHealthInsuranceContext();
  const { formatPartnerName } = useApplicationHelpers(state.model);
  const name = formatPartnerName(partnerId);

  return (
    <>
      <StepHeading icon={<GeneralQuestionsPictogram />} title={name} showProgress />
      <div className="layout-res">
        <WizardSlot.Main>
          <Form.Section title={t('insuredPeople.steps.generalQuestions.title')}>
            <Form state={state} dispatch={dispatch}>
              <GeneralQuestions partnerId={partnerId} />
              <PartnerForm
                config={{
                  useConfigContext: useConfigContext,
                  useInsuranceContext: useHealthInsuranceContext
                }}
                partnerId={partnerId}
                hasBonity
              />
            </Form>
          </Form.Section>
        </WizardSlot.Main>
      </div>
    </>
  );
};
