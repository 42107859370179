export const ArrowRight = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      color="currentColor"
    >
      <mask id="mask0_4402_68011" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
        <rect width="20" height="20" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_4402_68011)">
        <path
          d="M10.0002 16.6668L8.81266 15.5002L13.4793 10.8335H3.3335V9.16683H13.4793L8.81266 4.50016L10.0002 3.3335L16.6668 10.0002L10.0002 16.6668Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
