import { PortalLayout } from 'layout/portal';

import { ThemeProvider } from '@mui/material';
import { filterTheme } from 'styles/theme/components/filterTheme';

import { FormsData } from './components/FormsData/FormsData';
import { FormsContextProvider } from './context/FormsPageContext';

export const FormsPage = () => {
  return (
    <PortalLayout>
      <ThemeProvider theme={filterTheme}>
        <FormsContextProvider>
          <div className="w-full box-border relative bg-white-100">
            <FormsData />
          </div>
        </FormsContextProvider>
      </ThemeProvider>
    </PortalLayout>
  );
};
