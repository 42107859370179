import { ReactNode } from 'react';

import { BaseAddress } from '../models/portal-generated';
import { PreInsurance } from 'lkh-portal-ui-library/dist/models/extension-generated';

import i18n from 'services/translation';

export enum CurrencyEnum {
  EURO = 'EURO'
}

const CurrencyHtmlFormatted = {
  EURO: '\u20AC'
};

export function formatAddress(address: BaseAddress): string {
  return `${address.postalCode} ${address.city}, ${address.street} ${address.houseNumber}`;
}

export function formatPriceAsText(
  value?: number,
  currency: CurrencyEnum = CurrencyEnum.EURO
): string {
  return `${(value?.toFixed(2) || '').replace('.', ',')} ${CurrencyHtmlFormatted[currency]}`;
}

export function formatPriceAsTextWithoutCurrency(value?: number): string {
  if (
    value === undefined ||
    isNaN(value) ||
    value === null ||
    value === Infinity ||
    value === -Infinity
  ) {
    return '';
  }

  // Format the number to a fixed number of decimal places, converting the decimal point
  return value.toFixed(2).replace('.', ',');
}

export function formatPrice(
  value?: number,
  currency: CurrencyEnum = CurrencyEnum.EURO,
  fractionDigits = 2
): ReactNode {
  return (
    <>
      {(value?.toFixed(fractionDigits) || '').replace('.', ',')}&nbsp;
      {CurrencyHtmlFormatted[currency]}
    </>
  );
}

export function formatPreInsuranceCompany(preInsurance: PreInsurance): string {
  return preInsurance.insuranceCompany
    ? preInsurance.insuranceCompany
    : i18n.t(`contractsEnums:InsuranceCompanyEnum.${preInsurance.privateInsuranceCompany}`);
}
